<template>
  <AppWidgetTemplate
    @delete:click="$emit('delete:click')"
    :title="$t('projectView.info.widgets.latestImages')"
    :isLoading="isLoading"
    :emptyState="emptyState"
    v-bind="templateProps"
  >
    <div class="overflow-y-auto pb-3 pt-1 px-7" style="height: calc(100% - 52px)">
      <v-row>
        <v-col
          v-for="(image, index) in images"
          :key="index"
          class="d-flex child-flex pa-2"
          cols="3"
        >
          <v-img
            :src="image.blobUrl"
            aspect-ratio="1"
            contain
            class="grey lighten-4 cursor-pointer rounded-lg"
            @click="openAppFilePreviewDialog(image)"
          >
          </v-img>
        </v-col>
      </v-row>
    </div>
    <AppFilePreviewDialog
      v-model="activeFileId"
      :fileIds="
        images.map((image) => {
          return { id: image.id };
        })
      "
      @dialog:close="onPreviewDialogClose"
      @storage:update="onStorageUpdate"
    />
  </AppWidgetTemplate>
</template>

<script>
import { mapState } from "vuex";
import emptyStateImage from "@/assets/empty_state_images.svg";
export default {
  props: {
    projectId: String,
    templateProps: Object,
  },
  data() {
    return {
      activeFileId: null,
    };
  },
  watch: {
    projectId: {
      handler(newProjectId, oldProjectId) {
        if (newProjectId && newProjectId !== oldProjectId) {
          this.$store.dispatch("fileCollection/resetState");
          this.getImages(newProjectId, "image");
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState("fileCollection", {
      images: (state) => state.files,
      isLoading: (state) => state.isLoading,
    }),
    emptyState() {
      const translationPath = "projectView.widgets.images";
      const emptyState = {
        title: this.$t(`${translationPath}.title`),
        subTitle: this.$t(`${translationPath}.subTitle`),
        image: emptyStateImage,
        isNoData: !this.isLoading && this.images.length === 0,
      };
      return emptyState;
    },
  },
  methods: {
    openAppFilePreviewDialog(file) {
      this.activeFileId = file.id;
    },
    onPreviewDialogClose() {
      this.activeFileId = null;
    },
    async getImages(projectId, type) {
      await this.$store.dispatch("fileCollection/getFileCollection", {
        projectId,
        type,
      });
    },
    onStorageUpdate() {
      this.getImages(this.projectId, "image");
    },
  },
  mounted() {},
};
</script>
