<template>
  <v-dialog v-model="localValue" max-width="600px">
    <v-card>
      <v-card-title> {{ title }} </v-card-title>
      <v-card-text>
        <AppDefaultChart :chartData="chartData" :chartOptions="chartOptions" />
      </v-card-text>
      <!-- <v-card-actions>
          <v-btn color="primary" text @click="localValue = false">Close</v-btn>
        </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>
<script>
import { chartMixins } from "@/helpers/mixins/chartMixins";
export default {
  mixins: [chartMixins],
  props: {
    value: Boolean,
    chartData: Object,
    chartOptions: Object,
    title: String,
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
};
</script>
