import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

const state = () => ({
  isLoading: true,
  referrals: [],
});

const getters = {};

const actions = {
  async getReferrals({ commit }) {
    return axios
      .get(`${apiUrl}billing/referral`)
      .then(({ data }) => {
        const referrals = data?.referrals || [];
        commit("setReferrals", referrals);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => commit("loading", false));
  },
};

const mutations = {
  setReferrals(state, referrals) {
    state.referrals = referrals;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const referrals = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
