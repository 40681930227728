import _ from "lodash";
import { MODULE_FIELD_CONFIG, MODULE_ITEM_CONFIG } from "../constants";

/**
 * Generates a search string for a given item based on the module configuration.
 * @param {Object} item - The item to generate the search string for.
 * @param {string} moduleName - The name of the module.
 * @returns {string} - The generated search string.
 */
export function getSearchStr(item, moduleName) {
  // Retrieve the title field and subtitle fields configuration for the module
  const titleField = MODULE_FIELD_CONFIG[moduleName].TITLE_FIELD;
  const subTitleFields = MODULE_FIELD_CONFIG[moduleName].SUB_TITLE_FIELDS;

  // Get the display data for the title field
  const titleData = getDisplayData(item, titleField);
  const title = titleData.text;

  // Get the display data for each subtitle field
  const subTitles = subTitleFields.map((field) => {
    const subTitleData = getDisplayData(item, field);
    return subTitleData.text;
  });

  // Concatenate the title and subtitles to form the search string
  const searchStr = [title, ...subTitles].join("").toLowerCase();
  return searchStr;
}

/**
 * Formats a list of items by adding a search string to each item.
 * @param {Array} items - The list of items to format.
 * @param {string} moduleName - The name of the module.
 * @returns {Array} - The list of items with the search string added.
 */
export function formatItemWithSearchStr(items = [], moduleName) {
  // Map each item to a new object with the search string added
  const itemsWithSearchStr = items?.map((item) => {
    const searchStr = getSearchStr(item, moduleName);
    return { ...item, searchStr };
  });
  return itemsWithSearchStr;
}

/**
 * Retrieves the display data (icon, text, icon size) for a given item and configuration.
 * @param {Object} item - The item to retrieve the display data for.
 * @param {Object} config - The configuration for the item.
 * @returns {Object} - The display data for the item.
 */
export const getDisplayData = (item, config) => {
  // Retrieve the nested module item based on the pathToModule. If no path, it means the item is already the module item.
  const moduleItem = config.pathToModule === "" ? item : _.get(item, config.pathToModule);
  // Retrieve the module configuration based on the dataKey
  const moduleConfig = MODULE_ITEM_CONFIG.get(config.dataKey);

  // Return default values if the module item or configuration is not found
  if (!moduleItem || !moduleConfig) {
    return { icon: "", text: "", iconSize: 0 };
  }

  // Return the display data (icon, text, icon size) for the module item
  return {
    icon: moduleConfig.getIcon(moduleItem),
    text: moduleConfig.getText(moduleItem),
    to: moduleConfig.getTo ? moduleConfig.getTo(moduleItem) : null,
    translationKeys: moduleConfig.translationKeys,
    iconSize: moduleConfig.iconSize,
  };
};
