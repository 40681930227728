<template>
  <v-dialog v-model="localDialog" scrollable max-width="600px">
    <v-card>
      <div class="dialog__close-btn">
        <v-btn icon dark @click="close">
          <v-icon>{{ $icons.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </div>
      <v-card-title>
        <span class="headline">{{ $t("integration.autogear.loginDialog.title") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="loginForm"
          v-model="login.valid"
          v-on:submit.prevent
          @submit="onNormalLoginSubmit"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("integration.autogear.loginDialog.email") }}
            </div>
            <AppDefaultTextField
              v-model="login.username.value"
              :rules="$rules.NAME_RULES"
              autofocus
              type="email"
            />
          </div>

          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("integration.autogear.loginDialog.password") }}
            </div>
            <AppDefaultTextField
              v-model="login.password.value"
              :rules="$rules.NAME_RULES"
              type="password"
              @keydown.enter="onEnterPress"
            />
          </div>
        </v-form>
        <v-btn
          ref="submitBtn"
          class="mb-3"
          depressed
          block
          color="primary"
          @click="onNormalLoginSubmit"
          :loading="isLoggingIn"
        >
          {{ $t("integration.autogear.loginDialog.login") }}
        </v-btn>

        <div>
          <AppLinkText link="https://app.autogear.com/forgot-password" underline-on-hover>
            {{ $t("integration.autogear.loginDialog.forgotPassword") }}
          </AppLinkText>
        </div>
        <div>
          <AppLinkText link="https://app.autogear.com/free-trial" underline-on-hover>
            {{ $t("integration.autogear.loginDialog.createAccount") }}
          </AppLinkText>
        </div>

        <!-- This is useful when we implement social login -->
        <!-- <div class="d-flex align-center pt-5">
          <div class="flex-grow-1">
            <v-divider />
          </div>
          <div class="px-2">
            {{ $t("integration.autogear.loginDialog.or") }}
          </div>
          <div class="flex-grow-1">
            <v-divider />
          </div>
        </div> -->

        <AppErrorAlert v-if="hasError">
          {{ $t("integration.autogear.loginDialog.loginFailed") }}
        </AppErrorAlert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { setupCustomAuth } from "../../../../services/integration/integrationSetup/customSetupService";

export default {
  props: {
    dialog: Boolean,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  data() {
    return {
      login: {
        valid: false,
        username: {
          value: "",
        },
        password: {
          value: "",
        },
      },
      isLoggingIn: false,
      hasError: false,
    };
  },
  methods: {
    close() {
      this.localDialog = false;
    },
    onEnterPress() {
      this.onNormalLoginSubmit();
    },
    onNormalLoginSubmit() {
      const valid = this.$refs.loginForm.validate();
      if (valid) {
        this.isLoggingIn = true;
        this.hasError = false;
        const jsonLogin = JSON.stringify({
          username: this.login.username.value,
          password: this.login.password.value,
        });
        const code = window.btoa(jsonLogin);
        setupCustomAuth({ integrationName: this.$constant.AUTOGEAR, code })
          .then(() => {
            this.getActiveIntegrations();
            this.close();
            this.isLoggingIn = false;
          })
          .catch((err) => {
            this.isLoggingIn = false;
            this.hasError = true;
          });
      }
    },
    async getActiveIntegrations() {
      await this.$store.dispatch("activeIntegrations/getActiveIntegrations");
    },
  },
};
</script>
