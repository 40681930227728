<template>
  <v-dialog v-model="localDialog" scrollable max-width="600px">
    <v-card>
      <v-card-title>{{ $t("common.details") }}</v-card-title>
      <v-card-text class="primary-text--text" v-if="product">
        <AppProductDetailsDialogItem
          :label="$t('common.productFields.productNumber')"
          :value="product.code"
        />
        <AppProductDetailsDialogItem
          :label="$t('common.productFields.name')"
          :value="product.name"
        />
        <AppProductDetailsDialogItem
          :label="$t('common.productFields.supplier')"
          :value="clientName"
        />
        <AppProductDetailsDialogItem
          :label="$t('common.productFields.amount')"
          :value="amountText"
        />
        <AppProductDetailsDialogItem
          :label="$t('common.productFields.unitPrice')"
          :value="product.unitPrice"
          isCurrency
        />
        <AppProductDetailsDialogItem
          v-if="hasProductIntegrationEnabled"
          :label="$t('common.accounting')"
          :value="sentOrNotSentText"
        />
        <AppProductDetailsDialogItem
          v-if="hasProductIntegrationEnabled"
          :label="$t('common.productFields.reference')"
          :value="reference"
        />
        <AppProductDetailsDialogItem
          :label="$t('common.productFields.addedDate')"
          :value="formatDate(product.addedAt)"
        />
        <AppProductDetailsDialogItem
          :label="$t('common.productFields.addedBy')"
          :value="addedBy"
        />
        <AppProductDetailsDialogItem
          :label="$t('common.productFields.comment')"
          :value="comment"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn text color="primary" @click="onClose">
          {{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {
    dialog: Boolean,
    product: Object,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    sentOrNotSentText() {
      let key;
      if (this.product.status === "SENT") {
        key = "common.sent";
      } else if (this.product.status === "NOT_SENT") {
        key = "common.notSent";
      }
      return this.$t(key);
    },
    unitLabel() {
      return this.product?.unit.label;
    },
    addedBy() {
      return this.product?.user?.fullName;
    },
    clientName() {
      return this.product?.client?.name;
    },
    comment() {
      return this.product?.comment;
    },
    reference() {
      return this.product?.reference;
    },
    amountText() {
      if (!this.product?.unit?.label) {
        return this.product.amount;
      }
      if (this.product?.unit?.label && this.product?.amount) {
        return this.product.amount + " " + this.product.unit.label;
      }
      return null;
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapGetters("activeIntegrations", {
      hasProductIntegrationEnabled: "hasProductIntegrationEnabled",
    }),
  },
  methods: {
    onClose() {
      this.localDialog = false;
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD.MM.YYYY");
    },
  },
};
</script>
