<template>
  <AppRemindDialog
    v-model="localDialog"
    :items="signers"
    :getStatusText="getStatusText"
    :getStatusColor="getStatusColor"
    :inProgressStatus="$constant.FORM_SIGN_SESSION_STATUS.IN_PROGRESS"
    @submit="handleSubmit"
  />
</template>

<script>
import { remindFormSign } from "@/services/form/formSignSession";

export default {
  props: {
    dialog: Boolean,
    formId: String,
    signers: Array,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(dialog) {
        this.$emit("dialog:change", dialog);
      },
    },
  },
  methods: {
    getStatusText(signer) {
      return this.$t(`formManagement.signatureSession.status.${signer.status}`);
    },
    getStatusColor(signer) {
      const colorMap = {
        [this.$constant.FORM_SIGN_SESSION_STATUS.IN_PROGRESS]: "warning",
        [this.$constant.FORM_SIGN_SESSION_STATUS.COMPLETED]: "success",
        [this.$constant.FORM_SIGN_SESSION_STATUS.DECLINED]: "error",
      };
      return `${colorMap[signer.status] || "primary"}--text`;
    },
    handleSubmit({ emails, message }) {
      remindFormSign({
        body: {
          formId: this.formId,
          emails,
          comment: message,
        },
      })
        .then(() => {
          this.$emit("submit");
          this.localDialog = false;
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
  },
};
</script>
