<template>
  <div class="pl-3 pb-1 text-overline subtitle--text" v-if="!isMini">
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("appDrawer", {
      isMini: "isDrawerMiniVariant",
    }),
  },
};
</script>
