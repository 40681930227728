<template>
  <div>
    <div v-if="!isMini" class="px-6 pt-4 pb-2">
      <AppDrawerHeader
        :title="title"
        :subTitle="$t('drawer.header.settings.subTitle')"
        disable-sub-title-tooltip
      />
    </div>
    <div v-else class="pa-3 d-flex align-center justify-center">
      <AppRectangleIcon :icon="$icons.SOLID.ROUTE.SETTINGS" :tooltipText="title" />
    </div>
    <AppMiniContentSelectionDivider />

    <AppNavigationList>
      <AppNavigationItem
        @click="onGoBack"
        :prependIcon="$icons.SOLID.ACTION.GO_PREV_LEVEL"
        :title="$t('drawer.route.goBack')"
      />
    </AppNavigationList>
    <v-divider class="primary-border" />
  </div>
</template>

<script>
import { routerMixins } from "@/helpers/mixins";
import { mapGetters } from "vuex";

export default {
  mixins: [routerMixins],
  computed: {
    ...mapGetters("appDrawer", {
      isMini: "isDrawerMiniVariant",
    }),
    title() {
      return this.$t("drawer.header.settings.title");
    },
  },
  methods: {
    onGoBack() {
      this.routerMixins_goBack({
        fallbackRoute: { name: this.$routeNames.DASHBOARD.ROOT },
      });
    },
  },
};
</script>
