import { MODULE_FIELD_CONFIG } from "@/helpers/constants";
import { formatItemWithSearchStr } from "@/helpers/util/moduleItem";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getSearchClients({ params } = {}) {
  return axios
    .get(`${apiUrl}search/client`, {
      params: { ...params, fields: MODULE_FIELD_CONFIG.CLIENT.FIELDS_QUERY_PARAM },
    })
    .then(({ data }) => {
      const clients = formatItemWithSearchStr(data?.clients, "CLIENT");
      return clients;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
