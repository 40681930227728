import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getCmsFormTemplate({ id }) {
  return axios
    .get(`${apiUrl}template/cms-form/${id}`)
    .then(({ data }) => {
      return data ? data.template : null;
    })
    .catch(error => {
      throw new Error(error);
    });
}

export async function getCmsFormTemplateItems({ id }) {
  return axios
    .get(`${apiUrl}template/cms-form/${id}/items`)
    .then(({ data }) => {
      return data?.items || [];
    })
    .catch(error => {
      throw new Error(error);
    });
}
