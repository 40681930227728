<template>
  <div class="p-relative" :class="hidePaddingBottom ? '' : 'mb-4'">
    <v-tabs
      :height="40"
      :backgroundColor="backgroundColor"
      :id="tabId"
      v-model="activeTab"
    >
      <v-tabs-slider color="primary" />
      <v-tab
        v-for="(tab, index) in tabs"
        :key="index"
        :to="tab.to"
        :class="isActivePath(tab) ? 'v-tab--active' : ''"
        @click="onTabClick"
        @change="$emit('change', tab)"
        replace
      >
        {{ tab.title }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: "",
    };
  },
  props: {
    tabs: Array,
    tabId: String,
    backgroundColor: {
      type: String,
      default: "background",
    },
    hidePaddingBottom: Boolean,
    value: String,
  },
  watch: {
    value: {
      handler(val) {
        const index = this.tabs.findIndex((tab) => {
          return tab.name === val;
        });
        if (this.activeTab === index) return;
        this.activeTab = index;
      },
      immediate: true,
    },
  },
  methods: {
    onTabClick(e) {
      this.$emit("click", e);
    },
    addTabsUnderline() {
      const vTabs = document.getElementById(this.tabId);
      const tabGroups = vTabs.getElementsByClassName("v-slide-group__wrapper");
      const tabGroup = tabGroups[0];
      if (tabGroup) {
        const div = document.createElement("div");
        div.classList.add("primary-border");
        div.classList.add("w-100");
        div.style = "height: 2px; position: absolute; bottom: 0; z-index: -1!important";
        tabGroup.appendChild(div);
      }
    },
    isActivePath(tab) {
      if (typeof this.activeTab === "number") return;
      if (!tab.activePath) return false;

      const isActive = this.activeTab.includes(tab.activePath);
      return isActive;
    },
  },
  async mounted() {
    this.addTabsUnderline();
  },
};
</script>

<style>
.v-tab {
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 700;
  text-transform: none !important;
}
</style>
