<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline"> {{ $t("formManagement.completion.addQuestion") }} </span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="itemForm"
          v-model="item.valid"
          v-on:submit.prevent
          @submit="onSubmit"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">{{ $t("common.question") }}</div>
            <AppDefaultTextField
              ref="nameRef"
              v-model="item.name.value"
              :rules="$rules.NAME_RULES"
              autofocus
            >
            </AppDefaultTextField>
            <ResponseItemChoices
              v-if="isMultiChoice"
              :values="item.values.value"
              :id="selectedResponseType.key"
              @values:update="onMultiChoiceValuesUpdate"
            />
          </div>

          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("common.responseType") }}
            </div>
            <AppDefaultSelect
              v-if="dialog"
              :value="item.type.value"
              @change="onTypeChange"
              :items="responseTypes"
              :clearable="false"
              item-value="id"
            >
              <template v-slot:selection="{ item }">
                <v-icon>{{ item.icon }}</v-icon>
                <div class="pl-4">{{ item.text }}</div>
              </template>
              <template v-slot:item="{ item }">
                <v-icon>{{ item.icon }}</v-icon>
                <div class="pl-4">{{ item.text }}</div>
              </template>
            </AppDefaultSelect>
          </div>

          <div class="p-relative">
            <v-checkbox
              class="mt-0"
              v-model="item.isRequired.value"
              :label="$t('common.required')"
            />
          </div>

          <div class="p-relative">
            <v-checkbox
              class="mt-0"
              v-model="item.hasOptions.value"
              :label="$t('templateManagement.form.builder.item.options')"
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="localDialog = false"
          :confirmText="$t('common.confirm')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ResponseItemChoices from "@/components/formTemplate/builder/ResponseItemChoices.vue";
export default {
  components: {
    ResponseItemChoices,
  },
  props: {
    dialog: Boolean,
    parentId: String,
    formId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      item: {
        valid: false,
        name: {
          value: "",
        },
        type: {
          value: 0,
        },
        isRequired: {
          value: false,
        },
        hasOptions: {
          value: false,
        },
        values: {
          value: [""],
        },
      },
      responseTypes: [
        {
          id: 0,
          key: this.$constant.TEXTAREA,
          text: this.$t(
            `templateManagement.form.builder.item.type.${this.$constant.TEXTAREA}`,
          ),
          icon: this.$icons.LIGHT.COMMON.TEXT,
        },
        {
          id: 1,
          key: this.$constant.DATE_FIELD,
          text: this.$t(
            `templateManagement.form.builder.item.type.${this.$constant.DATE_FIELD}`,
          ),
          icon: this.$icons.LIGHT.COMMON.CALENDAR_DAY,
        },
        {
          id: 2,
          key: this.$constant.RADIO_GROUP,
          text: `${this.$t(
            `templateManagement.form.builder.item.type.${this.$constant.RADIO_GROUP}`,
          )} (${["YES", "NO", "NA"]})`,
          icon: this.$icons.LIGHT.COMMON.CIRCLE_CHECK,
          values: ["YES", "NO", "NA"],
        },
        {
          id: 3,
          key: this.$constant.RADIO_GROUP,
          text: `${this.$t(
            `templateManagement.form.builder.item.type.${this.$constant.RADIO_GROUP}`,
          )} (${["OK", "DEVIATION", "NA"]})`,
          icon: this.$icons.LIGHT.COMMON.CIRCLE_CHECK,
          values: ["OK", "DEVIATION", "NA"],
        },
        {
          id: 4,
          key: this.$constant.MULTI_CHOICE,
          text: `${this.$t(
            `templateManagement.form.builder.item.type.${this.$constant.MULTI_CHOICE}`,
          )}`,
          icon: this.$icons.LIGHT.COMMON.SQUARE_CHECK,
          values: [""],
        },
      ],
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    selectedResponseType() {
      const selectedResponseType = this.responseTypes.find(
        (type) => type.id === this.item.type.value,
      );
      return selectedResponseType;
    },
    isMultiChoice() {
      return this.selectedResponseType?.key === this.$constant.MULTI_CHOICE;
    },
  },
  methods: {
    onTypeChange(type) {
      if (type === this.selectedResponseType.type) return;
      this.item.type.value = type;
      this.item.values.value = this.selectedResponseType?.values || [];
    },
    onMultiChoiceValuesUpdate({ values }) {
      this.item.values.value = values;
    },
    onSubmit() {
      const valid = this.$refs.itemForm.validate();
      if (valid && this.selectedResponseType) {
        const body = {
          formId: this.formId,
          parentId: this.parentId,
          name: this.item.name.value,
          type: this.selectedResponseType.key,
          values: this.item.values.value || [],
          isRequired: this.item.isRequired.value,
          hasOptions: this.item.hasOptions.value,
        };

        this.$emit("submit", { body });
        this.localDialog = false;
        this.$refs.itemForm.reset();
        this.item.type.value = 0;
      }
    },
  },
};
</script>
