<template>
  <div class="information_drawer">
    <v-toolbar class="border-b" dense flat fixed height="68">
      <v-toolbar-title class="font-weight-bold">{{
        $t("common.overview")
      }}</v-toolbar-title>
    </v-toolbar>
    <AppCenterLoader
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />
    <div class="pa-5 pb-0 secondary-height overflow-y-auto" v-if="!isLoading && template">
      <!-- <div>
        <div class="label--small">{{ $t("common.category") | capitalize }}</div>
        <AppDefaultAutocomplete
          :value="selectedCategory"
          :items="categories"
          item-text="name"
          item-value="id"
          @change="updateTemplateField($event, 'categoryId')"
          :noDataText="$t('common.noCategories')"
          :clearable="false"
        />
      </div> -->
      <div class="pb-5" v-if="typeof template.isRestrictedToTemplateOwner === 'boolean'">
        <div class="label--small">
          {{
            $t("templateManagement.project.drawer.details.restrictedToOrg") | capitalize
          }}
        </div>
        <AppDefaultSwitch
          hide-details
          inset
          class="mt-0"
          :input-value="template.isRestrictedToTemplateOwner"
          @change="updateTemplateField($event, 'isRestrictedToTemplateOwner')"
        />
      </div>
      <div class="pb-5">
        <div class="label--small">
          {{ $t("common.createdBy") | capitalize }}
        </div>
        <AppUserAvatar
          class="text-body-1"
          :user="template.creator"
          show-name
          :truncate="30"
        />
      </div>

      <div class="pb-5">
        <div class="label--small">
          {{ $t("common.creationDate") | capitalize }}
        </div>
        <div class="text-body-1">
          {{ new Date(template.createdAt) | dateFormat("DD.MM.YYYY HH:mm") }}
        </div>
      </div>

      <div class="pb-5">
        <div class="label--small">
          {{ $t("common.lastUpdated") | capitalize }}
        </div>
        <div class="text-body-1">
          {{ new Date(template.updatedAt) | dateFormat("DD.MM.YYYY HH:mm") }}
        </div>
      </div>

      <div class="pb-5">
        <div class="label--small">
          {{ $t("common.id") | capitalize }}
        </div>
        <div class="text-body-1">{{ template.number }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    template: Object,
    categories: Array,
    isLoading: Boolean,
  },
  computed: {
    selectedCategory() {
      return this.template?.category?.id || null;
    },
  },
  methods: {
    updateTemplateField(e, field) {
      this.updateTemplate({ [field]: value });
    },
    updateTemplate(body) {
      this.$emit("template:update", { template: this.template, body });
    },
  },
  mounted() {},
};
</script>
