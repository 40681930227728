const APPROVED = "APPROVED";
const PENDING = "PENDING";
const DECLINED = "DECLINED";

export const TIME_ENTRY_STATUS = { APPROVED, PENDING, DECLINED };

const NOT_STARTED = "NOT_STARTED";
const IN_PROGRESS = "IN_PROGRESS";
const DONE = "DONE";

export const PROGRESS_STATUS = { NOT_STARTED, IN_PROGRESS, DONE };

export const CUSTOM_STATUS_CATEGORY = {
  NOT_STARTED: "NOT_STARTED",
  ACTIVE: "ACTIVE",
  DONE: "DONE",
  CLOSED: "CLOSED",
};

export const CUSTOM_STATUS_CATEGORY_COLOR = {
  [CUSTOM_STATUS_CATEGORY.NOT_STARTED]: "#87909E",
  [CUSTOM_STATUS_CATEGORY.ACTIVE]: "#F8AE00",
  [CUSTOM_STATUS_CATEGORY.DONE]: "#3DB88B",
  [CUSTOM_STATUS_CATEGORY.CLOSED]: "#008844",
};

export const CUSTOM_STATUS_CATEGORIES = Object.values(CUSTOM_STATUS_CATEGORY);
