<template>
  <div class="mw-100 d-flex align-center flex-column pa-6">
    <div class="d-flex align-center w-100 justify-space-between" style="gap: 16px">
      <div class="d-flex align-center">
        <div
          :class="[backgroundColor, 'icon-wrapper']"
          class="mr-5 rounded d-flex align-center justify-center"
        >
          <img v-if="icon" :src="icon" :alt="title" class="card-icon" draggable="false" />
          <v-icon size="26" v-else-if="faIcon">{{ faIcon }}</v-icon>
        </div>
        <div class="d-flex flex-column">
          <div
            class="d-flex text-h6 font-weight-bold align-center"
            v-html="$textHelpers.highlightText(title, search)"
          ></div>
          <div>{{ subTitle }}</div>
        </div>
      </div>
      <div style="max-width: 250px" class="d-flex align-center">
        <slot></slot>
      </div>
    </div>
    <slot name="bottom"></slot>
  </div>
</template>

<style scoped>
.icon-wrapper {
  width: 60px;
  height: 60px;
  user-select: none;
  min-width: 60px;
  max-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
</style>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
    icon: String,
    faIcon: String,
    backgroundColor: {
      type: String,
      default: "input-background--background",
    },
    search: String,
  },
};
</script>
