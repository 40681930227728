<template>
  <AppStorageManager
    ref="storageManager"
    v-bind="$attrs"
    :fileId="fileId"
    :activePreviewMenu="activePreviewMenu"
    :rootId="rootId"
    :folderId="folderId"
    @navigateToFolder="navigateToFolder"
    @previewFile="previewFile"
  />
</template>

<script>
export default {
  computed: {
    fileId() {
      return this.$route.query.fileId;
    },
    activePreviewMenu() {
      return this.$route.query.activeMenu;
    },
    rootId() {
      return this.$route.params.rootId;
    },
    folderId() {
      return this.$route.params.folderId;
    },
  },
  methods: {
    initializeStorageManager() {
      this.$refs.storageManager.initializeStorageManager();
    },
    navigateToFolder({ id, rootId }) {
      this.$router.push({
        params: {
          folderId: id,
          rootId,
        },
      });
    },
    previewFile({ fileId, activeMenu, openAddRecipients }) {
      const query = { ...this.$route.query, fileId, activeMenu, openAddRecipients };
      this.$router.replace({
        query,
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeStorageManager();
    });
  },
};
</script>

<style lang="scss" scoped></style>
