<template>
  <AppDefaultSelect
    hide-details
    :outlined="false"
    :clearable="false"
    class="pa-0 ma-0 remove-v-select-input decrease-select-height"
    backgroundColor="ui-background"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {};
</script>
