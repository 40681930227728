import { REGULAR } from "../icons";
import _ from "lodash";

export const ACTION_KEYS = {
  DELETE: "DELETE",
  EDIT: "EDIT",
  MORE: "MORE",
  RESPONSIBLE: "RESPONSIBLE",
  COPY: "COPY",
  COPY_URL: "COPY_URL",
  COPY_TO_NEXT_DAY: "COPY_TO_NEXT_DAY",
  COPY_TO_NEXT_WEEK: "COPY_TO_NEXT_WEEK",
  COPY_TO_REST_OF_WEEK: "COPY_TO_REST_OF_WEEK",
  COPY_TO_DATE_OR_PERIOD: "COPY_TO_DATE_OR_PERIOD",
  SEND_TO_ACCOUNTING: "SEND_TO_ACCOUNTING",
  DELETE_FROM_ACCOUNTING: "DELETE_FROM_ACCOUNTING",
  DECLINE_REASON: "DECLINE_REASON",
  OVERVIEW: "OVERVIEW",
  DETAILS: "DETAILS",
  OPEN_WITH: "OPEN_WITH",
  GO_TO: "GO_TO",
  EDIT_STATUS: "EDIT_STATUS",
  EDIT_SUPPLIER: "EDIT_SUPPLIER",
  EDIT_PRODUCT_UNIT: "EDIT_PRODUCT_UNIT",
  EDIT_PRODUCT_UNIT_PRICE: "EDIT_PRODUCT_UNIT_PRICE",
  EDIT_ACTIVE_STATUS: "EDIT_ACTIVE_STATUS",
  EDIT_START_DATE: "EDIT_START_DATE",
  EDIT_END_DATE: "EDIT_END_DATE",
  EDIT_DUE_DATE: "EDIT_DUE_DATE",
  EDIT_PROJECT_MANAGER: "EDIT_PROJECT_MANAGER",
  EDIT_RESPONSIBLE: "EDIT_RESPONSIBLE",
  EDIT_MEMBERS: "EDIT_MEMBERS",
  EDIT_CUSTOMER: "EDIT_CUSTOMER",
  EDIT_PERMISSION_LEVEL: "EDIT_PERMISSION_LEVEL",
  EDIT_STATUS: "EDIT_STATUS",
  EDIT_PARENT_PROJECT: "EDIT_PARENT_PROJECT",
  EDIT_PROJECT: "EDIT_PROJECT",
  EDIT_TASK: "EDIT_TASK",
  EDIT_FORM_CATEGORY: "EDIT_FORM_CATEGORY",
  EDIT_IS_SIGNABLE: "EDIT_IS_SIGNABLE",
  PREVIEW: "PREVIEW",
  RENAME: "RENAME",
  DOWNLOAD: "DOWNLOAD",
  DOWNLOAD_WITHOUT_CONVERTED_MERGE_WORDS: "DOWNLOAD_WITHOUT_CONVERTED_MERGE_WORDS",
  MOVE: "MOVE",
  COPY: "COPY",
  LINK_FILE_TO_FOLDER: "LINK_FILE_TO_FOLDER",
  ADD_TO_MERGE: "ADD_TO_MERGE",
  SEND_TO_SIGN: "SEND_TO_SIGN",
  REQUEST_READ_CONFIRMATION: "REQUEST_READ_CONFIRMATION",
  UPLOAD_TO_BOLIGMAPPA: "UPLOAD_TO_BOLIGMAPPA",
  FILL_OUT_FORM: "FILL_OUT_FORM",
  SET_INACTIVE: "SET_INACTIVE",
  INVITE: "INVITE",
};

export const ACTION_TYPES = {
  BULK: "BULK",
  SINGLE: "SINGLE",
};

const { BULK, SINGLE } = ACTION_TYPES;

const {
  DELETE,
  EDIT,
  MORE,
  OVERVIEW,
  RESPONSIBLE,
  COPY,
  COPY_URL,
  COPY_TO_NEXT_DAY,
  COPY_TO_NEXT_WEEK,
  COPY_TO_REST_OF_WEEK,
  COPY_TO_DATE_OR_PERIOD,
  SEND_TO_ACCOUNTING,
  DELETE_FROM_ACCOUNTING,
  DECLINE_REASON,
  DETAILS,
  EDIT_STATUS,
  EDIT_SUPPLIER,
  EDIT_PRODUCT_UNIT,
  EDIT_PRODUCT_UNIT_PRICE,
  EDIT_ACTIVE_STATUS,
  EDIT_START_DATE,
  EDIT_END_DATE,
  EDIT_DUE_DATE,
  EDIT_PROJECT_MANAGER,
  EDIT_RESPONSIBLE,
  EDIT_MEMBERS,
  EDIT_CUSTOMER,
  EDIT_PERMISSION_LEVEL,
  EDIT_PARENT_PROJECT,
  EDIT_PROJECT,
  EDIT_TASK,
  EDIT_FORM_CATEGORY,
  EDIT_IS_SIGNABLE,
  OPEN_WITH,
  GO_TO,
  PREVIEW,
  RENAME,
  DOWNLOAD,
  DOWNLOAD_WITHOUT_CONVERTED_MERGE_WORDS,
  MOVE,
  LINK_FILE_TO_FOLDER,
  ADD_TO_MERGE,
  SEND_TO_SIGN,
  REQUEST_READ_CONFIRMATION,
  UPLOAD_TO_BOLIGMAPPA,
  FILL_OUT_FORM,
  SET_INACTIVE,
  INVITE,
} = ACTION_KEYS;

export const COMMON_ACTIONS = [
  {
    key: DELETE,
    props: {
      labelKey: {
        [SINGLE]: "common.delete",
        [BULK]: "",
      },
      icon: REGULAR.ACTION.DELETE,
      color: {
        dark: "dark-background-error",
        light: "error",
      },
    },
  },
  {
    key: COPY,
    props: {
      labelKey: "common.createCopy",
      icon: REGULAR.ACTION.COPY,
    },
  },
  {
    key: OVERVIEW,
    props: {
      labelKey: "common.overview",
      icon: REGULAR.ROUTE.OVERVIEW,
    },
  },

  {
    key: EDIT,
    props: {
      labelKey: "common.edit",
      icon: REGULAR.ACTION.EDIT,
    },
  },
  {
    key: COPY_URL,
    props: {
      labelKey: "common.copyUrl",
      icon: REGULAR.ACTION.LINK,
    },
  },
  {
    key: EDIT_STATUS,
    props: {
      labelKey: "common.status",
      icon: REGULAR.ACTION.STATUS,
    },
  },
  {
    key: MORE,
    props: {
      labelKey: "common.more",
      icon: REGULAR.ACTION.MENU,
    },
  },
  {
    key: DETAILS,
    props: {
      labelKey: "common.details",
      icon: REGULAR.ROUTE.OVERVIEW,
    },
  },
  {
    key: COPY_TO_NEXT_DAY,
    props: {
      labelKey: "timeRegistration.copy.nextDay",
      icon: REGULAR.COMMON.CALENDAR_DAY,
    },
  },
  {
    key: COPY_TO_NEXT_WEEK,
    props: {
      labelKey: "timeRegistration.copy.nextWeek",
      icon: REGULAR.COMMON.CALENDAR_DAY,
    },
  },
  {
    key: COPY_TO_REST_OF_WEEK,
    props: {
      labelKey: "timeRegistration.copy.restOfWeek",
      icon: REGULAR.COMMON.CALENDAR_WEEK,
    },
  },
  {
    key: COPY_TO_DATE_OR_PERIOD,
    props: {
      labelKey: {
        [SINGLE]: "timeRegistration.copy.dateRange",
        [BULK]: "timeRegistration.copy.copyToDateRange",
      },
      icon: {
        [SINGLE]: REGULAR.COMMON.CALENDAR_WEEK,
        [BULK]: REGULAR.ACTION.COPY,
      },
    },
  },
  {
    key: SEND_TO_ACCOUNTING,
    props: {
      labelKey: "common.sendToAccounting",
      icon: REGULAR.ACTION.SEND_TO_ACCOUNTING,
    },
  },
  {
    key: DELETE_FROM_ACCOUNTING,
    props: {
      labelKey: "timeRegistration.deleteInAccounting.title",
      icon: REGULAR.ACTION.DELETE,
      color: {
        dark: "dark-background-error",
        light: "error",
      },
    },
  },
  {
    key: DECLINE_REASON,
    props: {
      labelKey: "timeRegistration.contextMenu.declinedReason",
      icon: REGULAR.ACTION.WARNING,
    },
  },
  {
    key: EDIT_SUPPLIER,
    props: {
      labelKey: "common.supplier",
      icon: REGULAR.ACTION.SUPPLIER,
    },
  },
  {
    key: EDIT_PRODUCT_UNIT,
    props: {
      labelKey: "common.productFields.unit",
      icon: REGULAR.ACTION.UNIT_TYPE,
    },
  },
  {
    key: EDIT_ACTIVE_STATUS,
    props: {
      labelKey: "common.productFields.status",
      icon: REGULAR.COMMON.TOGGLE_LARGE_ON,
    },
  },
  {
    key: EDIT_PRODUCT_UNIT_PRICE,
    props: {
      labelKey: "common.productFields.unitPrice",
      icon: REGULAR.ACTION.UNIT_PRICE,
    },
  },
  {
    key: EDIT_START_DATE,
    props: {
      labelKey: "projectView.info.startDate",
      icon: REGULAR.COMMON.CALENDAR_DAY,
    },
  },
  {
    key: EDIT_END_DATE,
    props: {
      labelKey: "projectView.info.endDate",
      icon: REGULAR.COMMON.CALENDAR_DAY,
    },
  },
  {
    key: EDIT_DUE_DATE,
    props: {
      labelKey: "taskManagement.list.dueDate",
      icon: REGULAR.COMMON.CALENDAR_DAY,
    },
  },
  {
    key: EDIT_PROJECT_MANAGER,
    props: {
      labelKey: "projectView.info.projectManager",
      icon: REGULAR.ROUTE.USER,
    },
  },
  {
    key: EDIT_RESPONSIBLE,
    props: {
      labelKey: "taskManagement.list.responsible",
      icon: REGULAR.ROUTE.USER,
    },
  },
  {
    key: EDIT_MEMBERS,
    props: {
      labelKey: "taskManagement.list.participants",
      icon: REGULAR.ROUTE.USER,
    },
  },
  {
    key: EDIT_PROJECT,
    props: {
      labelKey: "common.project",
      icon: REGULAR.ROUTE.PROJECT,
    },
  },
  {
    key: EDIT_CUSTOMER,
    props: {
      labelKey: "common.customer",
      icon: REGULAR.ACTION.CUSTOMER,
    },
  },
  {
    key: EDIT_PERMISSION_LEVEL,
    props: {
      labelKey: "common.permissionLevel.title",
      icon: REGULAR.COMMON.LOCK,
    },
  },
  {
    key: EDIT_STATUS,
    props: {
      labelKey: "common.status",
      icon: REGULAR.ACTION.STATUS,
    },
  },
  {
    key: EDIT_PARENT_PROJECT,
    props: {
      labelKey: "projectView.mainProject.title",
      icon: REGULAR.ROUTE.PROJECT,
    },
  },
  {
    key: EDIT_TASK,
    props: {
      labelKey: "common.task",
      icon: REGULAR.ROUTE.TASK,
    },
  },
  {
    key: EDIT_FORM_CATEGORY,
    props: {
      labelKey: "common.category",
      icon: REGULAR.ACTION.CATEGORY,
    },
  },
  {
    key: EDIT_IS_SIGNABLE,
    props: {
      labelKey: "formManagement.signature.simpleSignature",
      icon: REGULAR.ACTION.SIGNATURE,
    },
  },
  {
    key: OPEN_WITH,
    props: {
      labelKey: "fileManagement.fileExplorer.menu.openWith",
      icon: REGULAR.ACTION.OPEN_WITH,
    },
  },
  {
    key: PREVIEW,
    props: {
      labelKey: "common.preview",
      icon: REGULAR.COMMON.EYE,
    },
  },
  {
    key: RENAME,
    props: {
      labelKey: "fileManagement.fileExplorer.menu.rename",
      icon: REGULAR.ACTION.EDIT,
    },
  },
  {
    key: DOWNLOAD,
    props: {
      labelKey: "common.download",
      icon: REGULAR.ACTION.DOWNLOAD,
    },
  },
  {
    key: DOWNLOAD_WITHOUT_CONVERTED_MERGE_WORDS,
    props: {
      labelKey: "common.downloadWithoutConvertedMergeWords",
      icon: REGULAR.ACTION.DOWNLOAD,
      color: {
        dark: "cmsAdmin",
        light: "cmsAdmin",
      },
    },
  },
  {
    key: MOVE,
    props: {
      labelKey: "fileManagement.fileExplorer.menu.move",
      icon: REGULAR.FILE.FILE_EXPORT,
    },
  },
  {
    key: LINK_FILE_TO_FOLDER,
    props: {
      labelKey: "fileManagement.linkedFile.title",
      icon: REGULAR.FILE.FILE_LINKED,
    },
  },
  {
    key: ADD_TO_MERGE,
    props: {
      labelKey: "fileManagement.fileExplorer.menu.addToMerge",
      icon: REGULAR.COMMON.MERGE,
    },
  },
  {
    key: SEND_TO_SIGN,
    props: {
      labelKey: ({ isSent }) => {
        if (isSent) {
          return "formManagement.signatureSession.menuBtn.showSignStatus";
        }
        return "formManagement.signatureSession.menuBtn.sendToSign";
      },
      icon: REGULAR.FILE.FILE_SIGNATURE,
    },
  },
  {
    key: REQUEST_READ_CONFIRMATION,
    props: {
      labelKey: "fileManagement.fileExplorer.menu.requestReadConfirmation",
      icon: REGULAR.COMMON.BOOK_OPEN_READER,
    },
  },
  {
    key: UPLOAD_TO_BOLIGMAPPA,
    props: {
      labelKey: "fileManagement.fileExplorer.menu.uploadToBoligmappa",
      icon: "$boligmappa",
    },
  },
  {
    key: FILL_OUT_FORM,
    props: {
      labelKey: "formManagement.list.fillOut",
      icon: REGULAR.ROUTE.FORM,
    },
  },
  {
    key: GO_TO,
    props: {
      labelKey: "common.goTo",
      icon: REGULAR.COMMON.ARROW_RIGHT,
    },
  },
  {
    key: SET_INACTIVE,
    props: {
      labelKey: "users.action.setInactive",
      icon: REGULAR.COMMON.USER_SLASH,
    },
  },
  {
    key: INVITE,
    props: {
      labelKey: "users.action.invite",
      icon: REGULAR.COMMON.ENVELOPE,
    },
  },
];

const TASK_ACTION_SET = [
  { key: OVERVIEW, types: [SINGLE] },
  { key: COPY_URL, types: [SINGLE] },
  { key: DELETE, types: [BULK, SINGLE] },
  { key: EDIT_STATUS, types: [BULK] },
  { key: EDIT_RESPONSIBLE, types: [BULK] },
  { key: EDIT_MEMBERS, types: [BULK] },
  { key: EDIT_START_DATE, types: [BULK] },
  { key: EDIT_DUE_DATE, types: [BULK] },
  { key: EDIT_PROJECT, types: [BULK] },
];

// Define your action sets
const TIME_ENTRY_ACTION_SET = [
  { key: EDIT, types: [SINGLE] },
  { key: DELETE, types: [BULK, SINGLE] },
  { key: EDIT_STATUS, types: [BULK, SINGLE] },
  { key: COPY, types: [SINGLE] },
  { key: COPY_TO_NEXT_DAY, types: [SINGLE] },
  { key: COPY_TO_NEXT_WEEK, types: [SINGLE] },
  { key: COPY_TO_REST_OF_WEEK, types: [SINGLE] },
  { key: COPY_TO_DATE_OR_PERIOD, types: [BULK, SINGLE] },
  { key: SEND_TO_ACCOUNTING, types: [BULK, SINGLE] },
  { key: DELETE_FROM_ACCOUNTING, types: [BULK, SINGLE] },
  { key: DECLINE_REASON, types: [SINGLE] },
];

const PRODUCT_ACTION_SET = [
  { key: DETAILS, types: [SINGLE] },
  { key: EDIT, types: [SINGLE] },
  { key: EDIT_SUPPLIER, types: [BULK] },
  { key: EDIT_PRODUCT_UNIT, types: [BULK] },
  { key: EDIT_PRODUCT_UNIT_PRICE, types: [BULK] },
  { key: EDIT_ACTIVE_STATUS, types: [BULK] },
  { key: DELETE, types: [BULK, SINGLE] },
];

const PRODUCT_USAGE_ACTION_SET = [
  { key: DETAILS, types: [SINGLE] },
  { key: EDIT, types: [BULK, SINGLE] },
  { key: DELETE, types: [BULK, SINGLE] },
  { key: SEND_TO_ACCOUNTING, types: [BULK, SINGLE] },
];

const STORAGE_ACTION_SET = [
  { key: OPEN_WITH, types: [SINGLE] },
  { key: PREVIEW, types: [SINGLE] },
  { key: RENAME, types: [SINGLE] },
  { key: DOWNLOAD, types: [SINGLE, BULK] },
  { key: DOWNLOAD_WITHOUT_CONVERTED_MERGE_WORDS, types: [SINGLE, BULK] },
  { key: COPY, types: [SINGLE, BULK] },
  { key: MOVE, types: [SINGLE, BULK] },
  { key: LINK_FILE_TO_FOLDER, types: [SINGLE, BULK] },
  { key: ADD_TO_MERGE, types: [SINGLE, BULK] },
  { key: SEND_TO_SIGN, types: [SINGLE] },
  { key: REQUEST_READ_CONFIRMATION, types: [SINGLE] },
  { key: COPY_URL, types: [SINGLE] },
  { key: UPLOAD_TO_BOLIGMAPPA, types: [SINGLE, BULK] },
  { key: DELETE, types: [BULK, SINGLE] },
];

const FORM_ACTION_SET = [
  { key: FILL_OUT_FORM, types: [SINGLE] },
  { key: OVERVIEW, types: [SINGLE] },
  { key: COPY, types: [SINGLE, BULK] },
  { key: SEND_TO_SIGN, types: [SINGLE] },
  { key: COPY_URL, types: [SINGLE] },
  { key: DELETE, types: [SINGLE, BULK] },
  { key: EDIT_STATUS, types: [BULK] },
  { key: EDIT_RESPONSIBLE, types: [BULK] },
  { key: EDIT_DUE_DATE, types: [BULK] },
  { key: EDIT_TASK, types: [BULK] },
  { key: EDIT_PROJECT, types: [BULK] },
  { key: EDIT_FORM_CATEGORY, types: [BULK] },
  { key: EDIT_IS_SIGNABLE, types: [BULK] },
  { key: EDIT_PERMISSION_LEVEL, types: [BULK] },
];

const PROJECT_ACTION_SET = [
  { key: GO_TO, types: [SINGLE] },
  { key: EDIT, types: [SINGLE] },
  { key: COPY_URL, types: [SINGLE] },
  { key: EDIT_STATUS, types: [BULK] },
  { key: EDIT_PROJECT_MANAGER, types: [BULK] },
  { key: EDIT_CUSTOMER, types: [BULK] },
  { key: EDIT_START_DATE, types: [BULK] },
  { key: EDIT_END_DATE, types: [BULK] },
  { key: EDIT_PARENT_PROJECT, types: [BULK] },
  { key: EDIT_PERMISSION_LEVEL, types: [BULK] },
  { key: DELETE, types: [BULK, SINGLE] },
];

const USER_ACTION_SET = [
  { key: INVITE, types: [SINGLE, BULK] },
  { key: SET_INACTIVE, types: [SINGLE, BULK] },
  { key: EDIT, types: [SINGLE] },
  { key: DELETE, types: [BULK, SINGLE] },
];

const CONTACT_ACTION_SET = [
  { key: EDIT, types: [SINGLE] },
  { key: INVITE, types: [SINGLE, BULK] },
  { key: DELETE, types: [BULK, SINGLE] },
];

const CLIENT_ACTION_SET = [
  { key: EDIT, types: [SINGLE] },
  { key: DELETE, types: [BULK, SINGLE] },
];

const FORM_TEMPLATE_ACTION_SET = [
  { key: EDIT, types: [SINGLE] },
  { key: DELETE, types: [BULK, SINGLE] },
  { key: COPY, types: [SINGLE, BULK] },
];

const PROJECT_TEMPLATE_ACTION_SET = [
  { key: EDIT, types: [SINGLE] },
  { key: DELETE, types: [BULK, SINGLE] },
  { key: COPY, types: [SINGLE, BULK] },
];

// Helper function to create merged action sets
const mergeActions = ({ primaryActions, secondaryActions }) => {
  return primaryActions.map((action) => {
    const secondaryAction = secondaryActions.find(
      (secondaryAction) => secondaryAction.key === action.key,
    );
    return secondaryAction ? _.merge({}, secondaryAction, action) : action;
  });
};

export const getBulkActionConfigs = (actionConfigs) => {
  return actionConfigs.filter((action) => action.types && action.types.includes(BULK));
};

export const generateActionConfig = ({ primaryActions, secondaryActions }) => {
  return mergeActions({
    primaryActions,
    secondaryActions,
  });
};

export const STORAGE_ACTIONS = mergeActions({
  primaryActions: STORAGE_ACTION_SET,
  secondaryActions: COMMON_ACTIONS,
});

export const TASK_ACTIONS = mergeActions({
  primaryActions: TASK_ACTION_SET,
  secondaryActions: COMMON_ACTIONS,
});

export const TIME_ENTRY_ACTIONS = mergeActions({
  primaryActions: TIME_ENTRY_ACTION_SET,
  secondaryActions: COMMON_ACTIONS,
});
export const PRODUCT_ACTIONS = mergeActions({
  primaryActions: PRODUCT_ACTION_SET,
  secondaryActions: COMMON_ACTIONS,
});

export const PRODUCT_USAGE_ACTIONS = mergeActions({
  primaryActions: PRODUCT_USAGE_ACTION_SET,
  secondaryActions: COMMON_ACTIONS,
});

export const FORM_ACTIONS = mergeActions({
  primaryActions: FORM_ACTION_SET,
  secondaryActions: COMMON_ACTIONS,
});

export const PROJECT_ACTIONS = mergeActions({
  primaryActions: PROJECT_ACTION_SET,
  secondaryActions: COMMON_ACTIONS,
});

export const USER_ACTIONS = mergeActions({
  primaryActions: USER_ACTION_SET,
  secondaryActions: COMMON_ACTIONS,
});

export const CONTACT_ACTIONS = mergeActions({
  primaryActions: CONTACT_ACTION_SET,
  secondaryActions: COMMON_ACTIONS,
});

export const CLIENT_ACTIONS = mergeActions({
  primaryActions: CLIENT_ACTION_SET,
  secondaryActions: COMMON_ACTIONS,
});

export const FORM_TEMPLATE_ACTIONS = mergeActions({
  primaryActions: FORM_TEMPLATE_ACTION_SET,
  secondaryActions: COMMON_ACTIONS,
});

export const PROJECT_TEMPLATE_ACTIONS = mergeActions({
  primaryActions: PROJECT_TEMPLATE_ACTION_SET,
  secondaryActions: COMMON_ACTIONS,
});
