import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function setFavorite({ model, modelId, isFavorite }) {
  return axios
    .put(`${apiUrl}favorite/user-level`, { model, modelId, isFavorite })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
