import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getMetaStoragesByField(field) {
  return axios
    .get(`${apiUrl}meta-storage`, { params: { field } })
    .then(({ data }) => {
      return data.metaStorages;
    })
    .catch(error => {
      throw new Error(error);
    });
}
