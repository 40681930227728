<template>
  <AppContextMenu
    ref="menu"
    v-on="$listeners"
    v-bind="$attrs"
    height="440"
    width="300"
    :closeOnContentClick="false"
  >
    <AppClientGroupedUserAndContactAutocomplete
      autofocus
      activateOnFocus
      resetOnSubmit
      hide-details
      isReadyToInitialFetch
      :maxWidthMultiplier="1"
      :items="users"
      :loading="isUsersLoading"
      :multiple="multiple"
      @change="onSubmit"
    />
  </AppContextMenu>
</template>

<script>
import { getSearchUsers } from "@/services/search/users";

export default {
  props: {
    queryParams: Object,
    multiple: Boolean,
  },
  data() {
    return {
      users: [],
      isUsersLoading: false,
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
  },
  methods: {
    onSubmit(e) {
      if (this.multiple) {
        if (e.length === 0) return;
        this.$emit("submit", e);
      } else {
        this.$emit("submit", e);
      }
      this.close();
    },
    close() {
      this.$refs.menu.close();
    },
    open(e) {
      this.$refs.menu.open(e);
      this.getUsers();
    },
    getUsers() {
      this.isUsersLoading = true;

      getSearchUsers({ params: this.queryParams })
        .then((users) => {
          this.users = users;
        })
        .finally(() => {
          this.isUsersLoading = false;
        });
    },
  },
};
</script>
