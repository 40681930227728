import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function convertCmsTemplates(body) {
  return axios
    .post(`${apiUrl}template/cms-form/convert`, body)
    .then(({ data }) => {
      return data?.templates || [];
    })
    .catch((error) => {
      throw new Error(error);
    });
}
