<template>
  <AppAddRecipientsDialog
    v-model="localDialog"
    :confirmButtonText="$t('actionRequest.addRecipientsDialog.confirmButton')"
    :genEmptyRecipient="genEmptyRecipient"
    :validateRecipients="validateRecipients"
    :submitAction="submitAction"
    :languages="languages"
    @submit="onSubmit"
    @error="onError"
  />
</template>

<script>
import {
  createActionRequest,
  updateActionRequest,
} from "@/services/actionRequest/actionRequest";

export default {
  data() {
    return {
      languages: [
        { value: "nb", text: this.$t("common.languages.fromLanguageCodes.nb") },
        { value: "en", text: this.$t("common.languages.fromLanguageCodes.en") },
      ],
    };
  },
  props: {
    dialog: Boolean,
    modelId: String,
    model: String,
    type: String,
    actionRequestId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    isEditMode() {
      return !!this.actionRequestId;
    },
  },
  methods: {
    genEmptyRecipient() {
      return { name: "", email: "", language: "nb", userId: null };
    },
    validateRecipients() {
      return true; // No specific validation for action requests
    },
    submitAction(recipients) {
      const baseBody = {
        recipients: recipients.map(({ name, email, language }) => ({
          name,
          email,
          language,
        })),
      };

      if (this.isEditMode) {
        return updateActionRequest({
          id: this.actionRequestId,
          body: baseBody,
        });
      } else {
        return createActionRequest({
          body: {
            ...baseBody,
            modelId: this.modelId,
            model: this.model,
            type: this.type,
          },
        });
      }
    },
    onSubmit() {
      this.$emit("submit");
    },
    onError(err) {
      console.error("Error in submitting:", err);
      // Handle the error as needed
    },
  },
};
</script>
