<template>
  <v-dialog v-model="dialog" persistent style="z-index: 200" width="1405" height="871">
    <v-card
      class="d-flex flex-column align-center trial-expired-dialog-custom-card-background trial-expired-dialog-responsive-giga-padding"
    >
      <div class="d-flex flex-column h-100 w-100 justify-space-between">
        <div class="d-flex flex-column align-center">
          <div
            class="primary--text background-section text-center trial-expired-dialog-responsive-title-font-size font-weight-black"
          >
            {{ $t("trialExpiredDialog.title") }}
          </div>
          <div
            style="padding-top: 14px"
            class="text-center subtitle--text"
            v-html="$t('trialExpiredDialog.subTitle')"
          ></div>
        </div>
        <div style="height: 110px"></div>
        <div class="d-flex justify-space-between align-center">
          <v-card
            flat
            class="px-8 pt-11 pb-6 border-a align-center d-flex flex-column"
            width="300"
            minHeight="250"
          >
            <div
              class="primary--text text-center font-weight-bold"
              style="font-size: 24px"
            >
              {{ $t("trialExpiredDialog.extend.title") }}
            </div>
            <div
              class="subtitle--text pt-4 text-center"
              v-html="$t('trialExpiredDialog.extend.subTitle')"
              style="font-size: 14px"
            ></div>
            <div
              v-if="!hasExtendedTrial"
              class="subtitle--text pt-2"
              style="text-align: center; font-size: 12px"
            >
              {{ $t("trialExpiredDialog.extend.extraSubTitle") }}
            </div>
            <div
              v-else
              class="error--text pt-2"
              style="text-align: center; font-size: 12px"
            >
              {{ $t("trialExpiredDialog.extend.extendLimitExceeded") }}
            </div>
            <v-spacer />
            <v-btn
              :loading="loading.extendTrial"
              :disabled="isLoadingAny || hasExtendedTrial"
              depressed
              text
              class="font-weight-bold mt-2"
              color="primary"
              @click="onExtendTrial"
            >
              {{ $t("trialExpiredDialog.extend.buttonText") }}
            </v-btn>
          </v-card>
          <div style="min-width: 20px"></div>
          <v-card
            flat
            width="360"
            height="360"
            style="background: linear-gradient(180deg, #c7e3e4 64.84%, #b5cbcc 108.38%)"
            class="px-8 py-10 h-100"
          >
            <v-img
              style="
                position: absolute !important;
                top: -34%;
                left: 0;
                right: 0;
                margin: auto;
              "
              class="mb-10"
              contain
              max-height="270"
              src="../../../assets/gripr-logo-mascot.png"
              alt="Gripr mascot holding a Gripr sign"
            />
            <div class="d-flex flex-column justify-end align-center h-100">
              <div
                class="primary--text text-center font-weight-bold"
                style="font-size: 32px"
              >
                {{ $t("trialExpiredDialog.upgrade.title") }}
              </div>
              <div
                class="pt-5 subtitle--text text-center"
                style="font-size: 14px"
                v-html="$t('trialExpiredDialog.upgrade.subTitle')"
              ></div>
              <v-btn
                :loading="loading.billingDetails || loading.upgradeToPro"
                :disabled="isLoadingAny"
                depressed
                text
                class="mt-9 font-weight-bold"
                color="primary"
                @click="onUpgradeToPro"
              >
                {{ $t("trialExpiredDialog.upgrade.buttonText") }}
              </v-btn>
            </div>
          </v-card>
          <div style="min-width: 20px"></div>
          <v-card
            flat
            class="px-8 pt-11 pb-6 border-a align-center d-flex flex-column"
            width="300"
            minHeight="250"
          >
            <div
              class="primary--text text-center font-weight-bold"
              style="font-size: 24px"
            >
              {{ $t("trialExpiredDialog.free.title") }}
            </div>
            <div class="subtitle--text pt-4" style="text-align: center; font-size: 14px">
              {{ $t("trialExpiredDialog.free.subTitle") }}
            </div>
            <v-spacer />
            <v-btn
              :loading="loading.downgradeToFree"
              :disabled="isLoadingAny"
              depressed
              text
              class="font-weight-bold"
              color="primary"
              @click="onDowngradeToFree"
            >
              {{ $t("trialExpiredDialog.free.buttonText") }}
            </v-btn>
          </v-card>
        </div>
      </div>
    </v-card>
    <AppAdjustSubscriptionQuantityDialog
      v-model="adjustSeatsDialog.isActive"
      :intervalType="intervalType"
      :planCode="proPlan?.code"
      :currency="currency"
      :usedSeats="usedSeats"
      :activePlanSubscriptionCode="activePlanSubscription?.plan?.code"
      :quantity="activePlanSubscription?.quantity"
      :pricePerQuantity="proPlan?.price"
      :platformFeeSubscription="platformFeeSubscription"
      :plans="plans"
      @confirm="onUpgradeToProConfirm"
    />
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      loading: {
        extendTrial: false,
        upgradeToPro: false,
        downgradeToFree: false,
        billingDetails: false,
      },
      adjustSeatsDialog: {
        isActive: false,
      },
    };
  },
  computed: {
    ...mapGetters("auth", {
      hasExtendedTrial: "hasExtendedTrial",
    }),
    ...mapGetters("billingDetails", {
      intervalType: "intervalType",
      currency: "currency",
      usedSeats: "usedSeats",
      proPlan: "proPlan",
      activePlanSubscription: "activePlanSubscription",
      platformFeeSubscription: "platformFeeSubscription",
      plans: "plans",
    }),
    isLoadingAny() {
      return Object.values(this.loading).some((val) => val);
    },
  },
  methods: {
    close() {
      this.localDialog = false;
    },
    setLoading(key, val) {
      this.loading[key] = val;
    },
    async getBillingDetails() {
      await this.$store.dispatch("billingDetails/getBillingDetails");
    },
    onExtendTrial() {
      this.updatePlanHelper({ key: "extendTrial", extendTrial: true });
    },
    onUpgradeToPro() {
      this.setLoading("billingDetails", true);
      this.getBillingDetails()
        .then(() => {
          this.adjustSeatsDialog.isActive = true;
        })
        .finally(() => {
          this.setLoading("billingDetails", false);
        });
    },
    onUpgradeToProConfirm(event) {
      this.setLoading("upgradeToPro", true);
      this.updatePlanHelper({
        key: "upgradeToPro",
        planCode: event.planCode,
        quantity: event.quantity,
      });
    },
    onDowngradeToFree() {
      this.updatePlanHelper({
        key: "downgradeToFree",
        planCode: this.$constant.PLAN_CODE.GRIPR_FREE,
      });
    },

    updatePlanHelper({ key, extendTrial, planCode, quantity }) {
      this.setLoading(key, true);

      const data = {
        extendTrial,
        quantity,
        planCode,
        onUpdateFinally: () => this.setLoading(key, false),
      };

      this.onUpdatePlan(data);
    },
    onUpdatePlan({
      quantity,
      planCode,
      extendTrial,
      onUpdateComplete = () => {
        this.close();
      },
      onUpdateError = (error) => {
        this.$store.dispatch("snackbar/snackbar", {
          show: true,
          text: error,
          color: "error",
        });
      },
      onUpdateFinally = () => {},
    }) {
      this.$store
        .dispatch("billingDetails/updateMainSubscription", {
          quantity,
          planCode,
          extendTrial,
          updateKey: "setIsChangingPlan",
        })
        .then(onUpdateComplete)
        .catch(onUpdateError)
        .finally(onUpdateFinally);
    },
  },
};
</script>
