const prefixIconName = (prefix, iconName) =>
  iconName.startsWith("$") ? iconName : `fa-${prefix} fa-${iconName}`;

export const withPrefix = (prefix, icons) => {
  return Object.entries(icons).reduce((prefixedIcons, [key, value]) => {
    const isNestedIcon = typeof value === "object";
    prefixedIcons[key] = isNestedIcon
      ? withPrefix(prefix, value)
      : prefixIconName(prefix, value);
    return prefixedIcons;
  }, {});
};
