<template>
  <v-dialog v-model="localDialog" max-width="1000px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("common.dialogs.addRecipients.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-column overflow-y-auto" style="max-height: 600px">
          <v-form ref="recipientsForm" v-model="isValid" @submit="onSubmit">
            <div
              class="d-flex pt-1"
              v-for="(recipient, index) in recipients"
              :key="index"
            >
              <AppDefaultTextField
                style="width: 200px"
                :rules="[...$rules.REQUIRED]"
                class="pr-3"
                :label="$t('common.name')"
                v-model="recipient.name"
              />
              <AppDefaultTextField
                style="width: 200px"
                :rules="[...$rules.EMAIL_RULES, ...$rules.REQUIRED]"
                class="pr-3"
                :label="$t('common.email')"
                v-model="recipient.email"
              />
              <AppDefaultSelect
                style="width: 50px"
                :items="languages"
                v-model="recipient.language"
                :clearable="false"
              >
                <template v-slot:item="{ item }">
                  <div>{{ item.text }} ({{ item.value }})</div>
                </template>
              </AppDefaultSelect>

              <slot name="additional-fields" :recipient="recipient"></slot>

              <v-btn icon class="mx-3" @click="onRecipientDelete(recipient)">
                <v-icon>{{ $icons.LIGHT.ACTION.DELETE }}</v-icon>
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex flex-column align-start">
        <div>
          <slot name="additional-content"></slot>
          <AppErrorAlert class="mb-2" v-if="showNoDuplicateEmailsError">
            {{ $t("common.dialogs.addRecipients.noDuplicateEmails") }}
          </AppErrorAlert>
          <AppErrorAlert class="mb-2" v-if="showApiError">
            {{ $t("common.dialogs.addRecipients.apiError") }}
          </AppErrorAlert>
        </div>
        <div class="mb-2">
          <v-btn
            outlined
            color="primary"
            @click="dialogs.selectUsersAndContacts.active = true"
          >
            <v-icon class="pr-3">{{ $icons.REGULAR.ACTION.ADD }}</v-icon>
            {{ $t("common.dialogs.addRecipients.addRecipientFromUsersAndContacts") }}
          </v-btn>
          <v-btn outlined color="primary" @click="addEmptyRecipient" class="ml-2">
            <v-icon class="pr-3">{{ $icons.REGULAR.ACTION.ADD }}</v-icon>
            {{ $t("common.dialogs.addRecipients.addEmptyRecipient") }}
          </v-btn>
        </div>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="closeDialog"
          :loading="isSubmitting"
          :confirmText="confirmButtonText"
        />
      </v-card-actions>
    </v-card>
    <AppSelectUsersAndContactsDialog
      v-model="dialogs.selectUsersAndContacts.active"
      @submit="addRecipients"
    />
    <AppDeleteConfirmationDialog
      v-model="dialogs.deleteRecipient.active"
      :title="$t('common.dialogs.addRecipients.confirmDeleteDialog.title')"
      :subtitle="$t('common.dialogs.addRecipients.confirmDeleteDialog.subTitle')"
      skip-validation
      :item="dialogs.deleteRecipient.item"
      @delete="deleteRecipient"
    />
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    confirmButtonText: String,
    genEmptyRecipient: {
      type: Function,
      default: () => ({ name: "", email: "", language: "nb", userId: null }),
    },
    validateRecipients: {
      type: Function,
      default: () => true,
    },
    submitAction: {
      type: Function,
      required: true,
    },
    languages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isValid: false,
      dialogs: {
        deleteRecipient: {
          item: null,
          active: false,
        },
        selectUsersAndContacts: {
          active: false,
        },
      },
      recipients: [],
      users: [],
      isLoadingUsers: false,
      contacts: [],
      isLoadingContacts: false,
      isSubmitting: false,
      showNoDuplicateEmailsError: false,
      showApiError: false,
    };
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    isLoading() {
      return this.isLoadingUsers || this.isLoadingContacts;
    },
  },
  methods: {
    addRecipients(users) {
      for (const user of users) {
        this.addRecipient(user);
      }
    },
    addRecipient(user) {
      const recipient = this.genEmptyRecipient();
      recipient.name = user.name || user.fullName || user.email;
      recipient.email = user.email;
      recipient.userId = user.id;
      this.recipients.push(recipient);
    },
    // changeRecipientNameAndEmail(event, recipient) {
    //   if (!event) {
    //     recipient.userId = null;
    //     return;
    //   }
    //   if (typeof event === "string") {
    //     recipient.name = event;
    //   } else {
    //     recipient.name = event.name;
    //     recipient.userId = event.id;
    //     recipient.email = event.email;
    //   }
    // },
    isRecipientEmpty(recipient) {
      const isUndefined = !recipient.name && !recipient.email;
      const isEmpty = !recipient?.name?.trim() && !recipient?.email?.trim();
      return isUndefined || isEmpty;
    },
    onRecipientDelete(recipient) {
      let isEmpty = this.isRecipientEmpty(recipient);

      if (isEmpty) {
        this.deleteRecipient({ item: recipient });
        return;
      }

      this.dialogs.deleteRecipient.item = recipient;
      this.dialogs.deleteRecipient.active = true;
    },
    deleteRecipient({ item }) {
      const index = this.recipients.indexOf(item);
      if (index === -1) return;
      this.recipients.splice(index, 1);
    },
    addEmptyRecipient() {
      this.recipients.push(this.genEmptyRecipient());
    },
    hasNoDuplicateEmails() {
      const emails = this.recipients
        .map((recipient) => recipient.email)
        .filter((email) => email && email.trim() !== "");
      return new Set(emails).size === emails.length;
    },
    validate() {
      this.showApiError = false;
      this.isValid = this.$refs.recipientsForm.validate();
      const isValidNoDuplicateEmails = this.hasNoDuplicateEmails();
      this.showNoDuplicateEmailsError = !isValidNoDuplicateEmails;
      const isValidCustom = this.validateRecipients(this.recipients);
      return this.isValid && isValidNoDuplicateEmails && isValidCustom;
    },
    onSubmit() {
      const isValid = this.validate();
      if (!isValid) return;
      this.isSubmitting = true;
      this.submitAction(this.recipients)
        .then((data) => {
          this.$emit("submit", data);
          this.resetAndCloseDialog();
        })
        .catch((err) => {
          this.showApiError = true;
          throw new Error(err);
        })
        .finally(() => (this.isSubmitting = false));
    },
    resetAndCloseDialog() {
      this.recipients = [this.genEmptyRecipient()];
      this.closeDialog();
    },
    closeDialog() {
      this.localDialog = false;
      this.showNoDuplicateEmailsError = false;
      this.showApiError = false;
      this.$refs.recipientsForm.resetValidation();
    },
  },
};
</script>
