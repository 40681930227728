<template>
  <div class="quill-editor">
    <v-card flat>
      <div :id="id"></div>
    </v-card>
    <div class="d-flex px-2 pb-2">
      <div :id="toolbarId">
        <slot name="toolbar">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-list" value="bullet"></button>
          <button class="d-flex justify-center align-center" @click="onMentionClick">
            <v-icon small> {{ $icons.LIGHT.COMMON.AT }}</v-icon>
          </button>

          <!--button class="ql-link"></button-->
        </slot>
      </div>
      <v-spacer />
      <v-btn icon @click="onCancel" v-if="showCancel">
        <v-icon>{{ cancelIcon }}</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="onSubmit"
        v-if="showSubmit"
        :loading="submitLoading"
        :disabled="isQuillEmpty()"
      >
        <v-icon>{{ submitIcon }}</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { v4 } from "uuid";
import Quill from "quill";
import { getSearchUsers } from "@/services/search/users";
import MagicUrl from "quill-magic-url";
import "quill-mention/autoregister";
import "quill-mention/dist/quill.mention.css";

Quill.register("modules/magicUrl", MagicUrl);

export default {
  props: {
    content: String,
    showSubmit: Boolean,
    submitIcon: {
      type: String,
      default: function () {
        return this.$icons.LIGHT.COMMON.PAPER_PLANE_TOP;
      },
    },
    submitLoading: Boolean,
    showCancel: Boolean,
    cancelIcon: {
      type: String,
      default: function () {
        return this.$icons.LIGHT.ACTION.CLOSE;
      },
    },
    placeholder: String,
  },
  data() {
    return {
      id: `editor-${v4()}`,
      toolbarId: `toolbar-${v4()}`,
      quill: null,
      users: [],
    };
  },
  methods: {
    onSubmit() {
      if (this.isQuillEmpty()) return;
      this.$emit("submit", { content: this.quill?.root?.innerHTML || "" });
      this.quill.root.innerHTML = null;
    },
    onCancel() {
      this.$emit("cancel", { content: this.quill?.root?.innerHTML || "" });
    },
    async getUsers() {
      return getSearchUsers().then((users) => {
        this.users = users || [];
        return this.users;
      });
    },
    quillInit() {
      this.quill = new Quill(`#${this.id}`, {
        theme: "snow",
        //formats: ["bold", "italic", "mention"],
        modules: {
          magicUrl: true,
          toolbar: `#${this.toolbarId}`,
          mention: {
            allowedChars: /^[A-Za-z\sÄÖåäöÆæØøÅå]*$/,
            renderLoading: () => {
              return `${this.$t("common.loading")}...`;
            },
            mentionDenotationChars: ["@"],
            source: async (searchTerm, renderList, mentionChar) => {
              let values = this.users;
              if (values.length < 1) {
                values = await this.getUsers();
              }
              values = values.map((user) => ({ id: user.id, value: user.name })) || [];
              if (searchTerm.length === 0) {
                renderList(values, searchTerm);
              } else {
                const matches = [];
                for (let item of values) {
                  if (item.value?.toLowerCase().includes(searchTerm.toLowerCase())) {
                    matches.push(item);
                  }
                }
                renderList(matches, searchTerm);
              }
            },
          },
        },
        placeholder: this.placeholder,
      });

      if (this.content) {
        this.quill.root.innerHTML = this.content;
      }

      this.quill.on("text-change", this.onEditorTextChange);
    },
    onEditorTextChange() {
      this.$emit("update", { content: this.quill?.root?.innerHTML || "" });
    },
    isQuillEmpty() {
      if (!this.quill) return true;
      if ((this.quill.getContents()["ops"] || []).length !== 1) return false;
      return this.quill.getText().trim().length === 0;
    },
    onMentionClick() {
      if (!this.quill) return;
      const mentionModule = this.quill.getModule("mention");

      if (mentionModule) {
        mentionModule.openMenu("@");
      }
    },
  },
  mounted() {
    this.quillInit();
  },
};
</script>
