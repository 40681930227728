<template>
  <div class="mr-3">
    <div :class="`${color}--text font-weight-bold text-body-2 text-no-wrap`">
      {{ title }}
    </div>
    <div v-if="!loading" class="text-no-wrap text-subtitle-2">
      {{ timeMixins_formatHoursShort(duration) }}
    </div>
    <div v-else>
      <v-skeleton-loader type="text" class="skeleton-loader-text-custom" />
    </div>
  </div>
</template>

<script>
import { timeMixins } from "@/helpers/mixins";
export default {
  mixins: [timeMixins],
  props: {
    color: String,
    title: String,
    duration: Number,
    loading: Boolean,
  },
};
</script>
