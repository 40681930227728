<template>
  <div>
    <v-list class="pa-0 overflow-y-auto" :max-height="maxHeight" ref="fileList">
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        :class="{ 'drag-over': isDraggingOver }"
        class="file-list-item v-list-item--hover"
        v-ripple="!disableNameEdit"
        @click="!disableNameEdit && openEditMenu(item, index)"
      >
        <AppStorageThumbnail :item="item" class="mr-3" top />
        <v-list-item-content>
          <v-list-item-title ref="contentRef">
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ isFile(item) ? byteSize(item.size) : getStorageDetails(item) }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-btn icon @click.stop="$emit('removeItem', item)">
          <v-icon>{{ $icons.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
    <AppContextMenu
      ref="editMenu"
      :closeOnContentClick="false"
      hide-default-padding
      backgroundColor="transparent"
      contentClass="v-menu__content--input"
    >
      <div style="height: 41px">
        <AppDefaultTextField
          ref="editInput"
          v-model="editedName"
          @focus="$event.target.select()"
          hide-details
          autofocus
          @blur="saveEditedName"
          @keydown.enter="saveEditedName"
        />
      </div>
    </AppContextMenu>
  </div>
</template>

<script>
import { storageMixins } from "@/helpers/mixins";
import byteSize from "byte-size";

export default {
  mixins: [storageMixins],
  props: {
    items: {
      type: Array,
      required: true,
    },
    isDraggingOver: Boolean,
    maxHeight: String,
    disableNameEdit: Boolean,
  },
  data() {
    return {
      editedName: "",
      editedItem: null,
    };
  },
  methods: {
    isFile(item) {
      return item instanceof File;
    },
    isStorage(item) {
      return !this.isFile(item);
    },
    byteSize,
    getStorageDetails(storage) {
      const isFolder = storage.type === this.$constant.FOLDER;
      const isFile = storage.type === this.$constant.FILE;

      if (isFolder) {
        //count folders and countFiles only work 1 level in
        //which might not be approperiate here
        return `${storage.countFiles} files, ${storage.countFolders} folders`;
      } else if (isFile) {
        return `${this.byteSize(storage.size)}`;
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const fileList = this.$refs.fileList.$el;
        if (fileList) {
          fileList.scrollTo({
            top: fileList.scrollHeight,
            behavior: "smooth",
          });
        }
      });
    },
    openEditMenu(item, index) {
      this.editedItem = item;
      this.editedName = item.name;
      const element = this.$refs.contentRef[index];
      this.$refs.editMenu.open({ from: element, direction: "start" });
    },
    saveEditedName() {
      if (this.editedItem && this.editedName.trim() !== "") {
        this.$emit("updateItemName", {
          item: this.editedItem,
          newName: this.editedName.trim(),
        });
      }
      this.$refs.editMenu.close();
      this.editedItem = null;
      this.editedName = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/common/background_colors";
@import "../../../styles/common/common";

.file-list-item {
  @extend .drag-over-transition;
}

.drag-over {
  @extend .success-super-light--background;
}
</style>
