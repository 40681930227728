import { FILE, FILE_RELATION } from "./storage";

// storage e-sign
export const TYPES_ALLOWED_TO_SIGN = [FILE, FILE_RELATION];

export const EXTENSIONS_ALLOWED_TO_SIGN = [
  "docx",
  "doc",
  "pdf",
  "xlsx",
  "ppt",
  "pptx",
  "txt",
];

export const E_SIGN_META_FIELD = "SIGN_STORAGE";
export const E_SIGN_META_STATUS_FIELD = "SIGN_STATUS";

export const E_SIGN_STATUS = {
  NOT_STARTED: "NOT_STARTED",
  DRAFT: "DRAFT",
  IN_PROGRESS: "IN_PROGRESS",
  NOT_COMPLETED: "NOT_COMPLETED",
  NOT_VIEWED: "NOT_VIEWED",
  REVOKED: "REVOKED",
  DECLINED: "DECLINED",
  EXPIRED: "EXPIRED",
  COMPLETED: "COMPLETED",
};

export const E_SIGN_REVOKE_STATUSES = [E_SIGN_STATUS.IN_PROGRESS];
export const E_SIGN_REMIND_STATUSES = [E_SIGN_STATUS.IN_PROGRESS];
