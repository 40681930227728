export const dataTableItemMixins = {
  methods: {
    dataTableItemMixins_onRightClick(pointerEvent) {
      const params = { from: pointerEvent, direction: "bottom" };
      this.openMenu(params);
    },
    dataTableItemMixins_onEllipsisClick(pointerEvent) {
      pointerEvent.stopPropagation();
      const element = this.$refs.ellipsis.$el;
      const params = { from: element, direction: "bottom" };
      this.openMenu(params);
    },
  },
};
