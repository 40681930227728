import { filtersMixins, dateMixins } from "../mixins";
//required base component to have this.tableModel available
export const groupByMixins = {
  mixins: [filtersMixins, dateMixins],
  computed: {
    groupByMixins_groupBy() {
      if (!Object.keys(this.filtersMixins_dataTableOptions).length) return;
      return this.filtersMixins_dataTableOptions?.groupBy[0];
    },
    groupByMixins_noGroupText() {
      return this.$t(`common.noGroup.${this.groupByMixins_groupBy}`);
    },
  },
  methods: {
    groupByMixins_commonHeaderTitle(group) {
      const hasNoGroupValue = group === null || group === undefined || group === "";
      if (hasNoGroupValue) return this.groupByMixins_noGroupText;
      if (
        this.groupByMixins_isGroupSelected(this.filtersMixins_dataFields.DUE_DATE) ||
        this.groupByMixins_isGroupSelected(this.filtersMixins_dataFields.DATE) ||
        this.groupByMixins_isGroupSelected(this.filtersMixins_dataFields.START_DATE)
      ) {
        return (
          this.dateMixins_formatDateWithTranslationsLong(group) ||
          this.groupByMixins_noGroupText
        );
      }

      return null;
    },
    groupByMixins_isGroupSelected(header) {
      return this.groupByMixins_groupBy === header;
    },
    groupByMixins_setGroupBy(groupBy) {
      if (groupBy === this.groupByMixins_groupBy) return;
      let dataTableOptions;
      if (groupBy === undefined) {
        dataTableOptions = { ...this.filtersMixins_dataTableOptions, groupBy: [] };
      } else {
        dataTableOptions = { ...this.filtersMixins_dataTableOptions, groupBy: [groupBy] };
      }
      this.filtersMixins_updateFilters({ dataTableOptions });
    },
    groupByMixins_headerTitleColor(group) {
      if (!this.groupByMixins_isGroupSelected(this.filtersMixins_dataFields.DATE))
        return "";
      return this.groupByMixins_isDateToday(group) ? "info--text" : "";
    },
    groupByMixins_isDateToday(inputDate) {
      return new Date(inputDate).toDateString() === new Date().toDateString();
    },
    groupByMixins_showColumn(columnName) {
      return this.headers.some((h) => h.value === columnName);
    },
  },
};
