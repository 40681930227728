import {
  E_SIGN_META_FIELD,
  EXTENSIONS_ALLOWED_TO_SIGN,
  MINE_TYPE_ALLOWED_TO_MERGE,
  TYPES_ALLOWED_TO_MERGE,
  TYPES_ALLOWED_TO_SIGN,
} from "../constants";
import { uploadFiles } from "@/services/storage/storageUploadService";
import { copyStorages } from "@/services/storage/storageCopyService";

/**
 * Determines whether to update the items in a paginated list.
 *
 * This function is used to decide if the current page's items should be updated
 * in the store or component state. It prevents showing an empty list when
 * filtering results or navigating, especially when the current page number
 * becomes invalid due to a reduced number of total items.
 *
 * @param {number} count - The total number of items across all pages.
 * @param {number} currentPage - The current page number.
 * @param {number} itemsPerPage - The number of items displayed per page.
 * @returns {boolean} True if the items should be updated, false otherwise.
 */
export function shouldUpdateItems(count, currentPage, itemsPerPage) {
  //if its not a data table page, always update
  if (currentPage === undefined || itemsPerPage === undefined) return true;

  if (count === 0) return true;

  const lastPageWithItems = Math.ceil(count / itemsPerPage);
  return currentPage <= lastPageWithItems;
}

export const isESignFile = (file) => {
  if (!file) return false;
  const meta = file.meta || [];
  return meta.some((m) => m.field === E_SIGN_META_FIELD);
};

export function canCreateSignableStorage(file) {
  if (!file) return false;
  const isAllowedFileType = TYPES_ALLOWED_TO_SIGN.some((i) => i === file?.type);
  const isAllowedExtension = EXTENSIONS_ALLOWED_TO_SIGN.some((i) =>
    file?.extension?.includes(i),
  );
  const allAllowedTypes = [isAllowedFileType, isAllowedExtension];
  const canCreate = allAllowedTypes.every((e) => e === true);
  return canCreate;
}

export function canMergeFile(file) {
  const isAllowedFileType = TYPES_ALLOWED_TO_MERGE.some((i) => i === file?.type);
  const isAllowedMimeType = MINE_TYPE_ALLOWED_TO_MERGE.some((i) => i === file?.mime);
  const allAllowedTypes = [isAllowedFileType, isAllowedMimeType];
  const canMerge = allAllowedTypes.every((e) => e === true);
  return canMerge;
}

export function splitItemsBySource(items) {
  const isFile = (item) => item.source === "FILE";
  const isStorage = (item) => item.source === "STORAGE";

  const files = items.filter(isFile);
  const storages = items.filter(isStorage);

  return { files, storages };
}

async function _uploadFiles({
  uploadType,
  uploadTypeId = null,
  files,
  onProgress = () => {},
}) {
  if (files?.length > 0) {
    return uploadFiles(files, uploadType, uploadTypeId, onProgress);
  }
}

async function _copyStorages({ parentId, storages }) {
  if (storages?.length > 0) {
    const body = {
      parentId,
      storages: storages.map((storage) => ({ id: storage.id, name: storage.name })),
    };
    return copyStorages({ body });
  }
}

export async function uploadFilesAndCopyStorages({ upload = {}, copy = {} }) {
  const promises = [];

  if (upload.files?.length > 0) {
    const uploadPromise = _uploadFiles(upload);
    if (uploadPromise) promises.push(uploadPromise);
  }

  const copyPromise = _copyStorages(copy);
  if (copyPromise) promises.push(copyPromise);

  if (promises.length > 0) {
    return await Promise.all(promises);
  }
}
