import axios from "axios";
import {
  isCmsAdmin,
  isGriprCmsUser,
  adminAccessByKey,
  currentRootRouteObject,
  isValidRootRouteName,
} from "../../../helpers/util";
import { FEATURE_NAMES, FEATURE_STATUSES } from "@/helpers/constants";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  user: null,
  isLoadingUserInitially: false,
  changePassword: {
    isChanging: false,
    error: "",
  },
});

const getters = {
  currentUserId: (state) => state.user?.id || null,
  currentUserEmail: (state) => state.user?.email || null,
  activeDomainId: (state) => state.user?.activeDomain || null,
  adminAccesses: (state) => state.user?.adminAccesses || [],
  currentDomain: (state) => state.user?.currentDomain || null,
  domains: (state) => state.user?.domains || [],
  invitedDomains: (state) => state.user?.invitedDomains || [],

  isTimeEntryFeatureEnabled: (state, getters) =>
    getters.isFeatureEnabled(FEATURE_NAMES.TIME_ENTRY),
  isTimeEntryAdvancedFeatureEnabled: (state, getters) =>
    getters.isFeatureEnabled(FEATURE_NAMES.TIME_ENTRY_ADVANCED),
  isCommentFeatureEnabled: (state, getters) =>
    getters.isFeatureEnabled(FEATURE_NAMES.COMMENT),
  isHistoricalLogFeatureEnabled: (state, getters) =>
    getters.isFeatureEnabled(FEATURE_NAMES.HISTORICAL_LOG),
  isLoomRecordingFeatureEnabled: (state, getters) =>
    getters.isFeatureEnabled(FEATURE_NAMES.LOOM_RECORDING),
  isHseFeatureEnabled: (state, getters) => getters.isFeatureEnabled(FEATURE_NAMES.HSE),
  isQaFeatureEnabled: (state, getters) => getters.isFeatureEnabled(FEATURE_NAMES.QA),
  isFileManagementFeatureEnabled: (state, getters) =>
    getters.isFeatureEnabled(FEATURE_NAMES.FILE_MANAGEMENT),
  isFormManagementFeatureEnabled: (state, getters) =>
    getters.isFeatureEnabled(FEATURE_NAMES.FORM_MANAGEMENT),

  features: (state) => state.user?.features || [],
  isFeatureEnabled: (state, getters) => (featureName) =>
    getters.featureByName(featureName)?.status === FEATURE_STATUSES.ENABLED,
  featureByName: (state, getters) => (name) =>
    getters.features.find((f) => f.name === name),

  metaDomainUser: (state) => state.user?.metaDomainUser || null,
  isGriprCmsUser: (state, getters) => isGriprCmsUser(getters.adminAccesses),
  isCmsAdmin: (state, getters) => isCmsAdmin(getters.adminAccesses),
  adminAccessByKey: (state, getters) => (key) =>
    adminAccessByKey({ adminAccesses: getters.adminAccesses, key }),
  isDomainOwner: (state, getters) => getters.currentDomain?.isOwner,
  isDomainAdmin: (state, getters) => getters.currentDomain?.isAdmin,
  isDomainHrAdmin: (state, getters) => getters.currentDomain?.isHrAdmin,
  isDomainSupportUser: (state, getters) => getters.currentDomain?.isSupportUser,
  isDomainContact: (state, getters) => getters.currentDomain?.isContact,
  highestPriorityRole: (state, getters) =>
    getters.currentDomain?.highestPriorityRole || "USER",
  isHighestPriorityRoleContact: (state, getters) =>
    getters.highestPriorityRole === "CONTACT",
  currentRootRouteObject: (state, getters) => currentRootRouteObject(state.user),
  isValidRootRouteName(state, getters) {
    const routeName = getters.currentRootRouteObject?.value || null;
    return isValidRootRouteName({ user: getters.adminAccesses, routeName });
  },
  subscriptionPlan: (state, getters) => getters.currentDomain?.subscriptionPlan,
  hasExtendedTrial: (state, getters) => getters.subscriptionPlan?.hasExtendedTrial,
};

const actions = {
  async getUser({ commit, state }) {
    if (!state.user) commit("loadingInitially", true);
    return axios
      .get(`${apiUrl}auth/me`)
      .then(({ data }) => {
        const user = data;
        localStorage.setItem("language", user.language);
        commit("setUser", user);
        return user;
      })
      .finally(() => {
        commit("loadingInitially", false);
      });
  },
  async changeActiveDomain({ commit }, domainId) {
    return axios.put(`${apiUrl}auth/active-domain`, { domainId }).then(({ data }) => {
      return data.user;
    });
  },
  async create({ commit }, payload) {
    return axios.post(`${apiUrl}auth/create-domain`, payload).then((res) => {
      return res;
    });
  },
  async changePassword({ commit }, data) {
    commit("changingPassword", true);
    commit("setChangePasswordError", "");
    return axios
      .patch(`${apiUrl}auth/change-password`, { ...data }, { skipErrorHandling: true })
      .then((res) => {
        commit("changingPassword", false);
      })
      .catch((error) => {
        commit("changingPassword", false);
        let message = error?.response?.data?.message || "Error";
        message = Array.isArray(message) ? message[0] : message;
        commit("setChangePasswordError", message);
        throw new Error(error);
      });
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  loadingInitially(state, isLoading) {
    state.isLoadingUserInitially = isLoading;
  },
  setChangePasswordError(state, error) {
    state.changePassword.error = error;
  },
  changingPassword(state, isChangingPassword) {
    state.changePassword.isChanging = isChangingPassword;
  },
  updateUserLanguage(state, language) {
    if (state.user) {
      state.user.language = language;
    }
  },
};

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
