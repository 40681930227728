<template>
  <vue-tel-input-vuetify
    ref="phone"
    @input="onPhoneInput"
    @blur="onBlur"
    :value="localValue"
    :rules="computedRules"
    :placeholder="label"
    :defaultCountry="defaultCountry"
    :preferredCountries="preferredCountries"
    :required="required"
    label=""
    :background-color="backgroundColor"
    solo
    outlined
    flat
    valid-characters-only
    dense
  >
  </vue-tel-input-vuetify>
</template>

<script>
export default {
  props: {
    value: String,
    placeholder: String,
    rules: Array,
    required: Boolean,
    hideLabel: Boolean,
    backgroundColor: {
      type: String,
      default: "input-background",
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      isValidPhoneInput: true,
      preferredCountries: ["NO", "SE", "DK", "LT", "CZ", "IT"],
      defaultCountry: "NO",
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    label() {
      if (this.hideLabel) return "";
      return this.placeholder || this.$t("common.mobileNumber");
    },
    computedRules() {
      return [
        ...(this.required ? this.$rules.REQUIRED : []),
        ...this.phoneRules,
        ...(this.rules || []),
      ];
    },
    phoneRules() {
      return [
        this.isValidPhoneInput
          ? true
          : this.$t("settings.account.details.invalidPhoneNumber"),
      ];
    },
  },
  methods: {
    onBlur() {
      if (!this.isValidPhoneInput) return;
      this.$emit("blur", this.value);
    },
    onPhoneInput(formattedNumber, { number, valid }) {
      this.isValidPhoneInput = valid;
      if (!formattedNumber && !this.required) {
        this.isValidPhoneInput = true;
        this.localValue = formattedNumber;
        return;
      }
      if (valid) {
        this.localValue = number.e164;
      }
    },
  },
};
</script>
