export const ADD_FORM_TEMPLATES_WIDGET = {
  name: "ADD_FORM_TEMPLATES", //corresponds to hard coded data from api
  component: {
    widget: "AppAddFormTemplatesWidget",
    libraryItem: "AppAddFormTemplatesWidgetLibraryItem",
  },
};

export const ADD_HSE_TEMPLATES_WIDGET = {
  name: "ADD_HSE_TEMPLATES", //corresponds to hard coded data from api
  component: {
    widget: "AppAddHseTemplatesWidget",
    libraryItem: "AppAddHseTemplatesWidgetLibraryItem",
  },
};

export const CREATE_PROJECT_WIDGET = {
  name: "CREATE_PROJECT", //corresponds to hard coded data from api
  component: {
    widget: "AppCreateProjectWidget",
    libraryItem: "AppCreateProjectWidgetLibraryItem",
  },
};

export const EVENT_LOG_WIDGET = {
  name: "EVENT_LOG", //corresponds to hard coded data from api
  component: {
    widget: "AppEventLogWidget",
    libraryItem: "AppEventLogWidgetLibraryItem",
  },
};

export const LATEST_IMAGES_WIDGET = {
  name: "LATEST_IMAGES", //corresponds to hard coded data from api
  component: {
    widget: "AppLatestImagesWidget",
    libraryItem: "AppLatestImagesWidgetLibraryItem",
  },
};

export const NAVIGATE_TO_FILL_OUT_FORM_WIDGET = {
  name: "NAVIGATE_TO_FILL_OUT_FORM", //corresponds to hard coded data from api
  component: {
    widget: "AppNavigateToFillOutFormWidget",
    libraryItem: "AppNavigateToFillOutFormWidgetLibraryItem",
  },
};

export const TO_DO_LIST_WIDGET = {
  name: "TO_DO_LIST", //corresponds to hard coded data from api
  component: {
    widget: "AppToDoListWidget",
    libraryItem: "AppToDoListWidgetLibraryItem",
  },
};

export const FORM_LIST_WIDGET = {
  name: "FORM_LIST", //corresponds to hard coded data from api
  component: {
    widget: "AppFormListWidget",
    libraryItem: "AppFormListWidgetLibraryItem",
  },
};

export const TASK_LIST_WIDGET = {
  name: "TASK_LIST", //corresponds to hard coded data from api
  component: {
    widget: "AppTaskListWidget",
    libraryItem: "AppTaskListWidgetLibraryItem",
  },
};

export const AUTOGEAR_VEHICLE_WIDGET = {
  name: "AUTOGEAR_VEHICLE", //corresponds to hard coded data from api
  component: {
    widget: "AppAutogearVehicleWidget",
    libraryItem: "AppAutogearVehicleWidgetLibraryItem",
  },
};

export const URL_EMBED_WIDGET = {
  name: "URL_EMBED", //corresponds to hard coded data from api
  component: {
    widget: "AppUrlEmbedWidget",
    libraryItem: "AppUrlEmbedWidgetLibraryItem",
  },
};

export const CUSTOM_CHART_WIDGET = {
  name: "CUSTOM_CHART",
  component: {
    widget: "AppChartWidget",
    libraryItem: "AppCustomChartWidgetLibraryItem",
  },
};

//Add all new widgets here
//This is also the widget order
export const ALL_WIDGETS = [
  CUSTOM_CHART_WIDGET,
  EVENT_LOG_WIDGET,
  LATEST_IMAGES_WIDGET,
  FORM_LIST_WIDGET,
  TASK_LIST_WIDGET,
  TO_DO_LIST_WIDGET,
  AUTOGEAR_VEHICLE_WIDGET,
  URL_EMBED_WIDGET,
  ADD_FORM_TEMPLATES_WIDGET,
  ADD_HSE_TEMPLATES_WIDGET,
  NAVIGATE_TO_FILL_OUT_FORM_WIDGET,
  CREATE_PROJECT_WIDGET,
];

//adds a "new" label to widget library ite
export const NEW_WIDGETS = [CUSTOM_CHART_WIDGET];

export const MULTI_ADD_ALLOWED_WIDGET_NAMES = [CUSTOM_CHART_WIDGET.name];
