<template>
  <div style="height: calc(100% - 68px)" class="overflow-y-auto">
    <AppCenterLoader
      class="ma-0"
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'100%'"
    />

    <template v-if="!isLoading">
      <div class="d-flex flex-column pa-4">
        <div class="mb-4">
          <div class="mb-1 d-flex">
            <div class="font-weight-bold body-2">
              {{ $t("fileManagement.preview.navMenu.e-signature.DECLINED.sentOn") }}
            </div>
            <v-spacer />

            <AppColorChip
              style="max-height: 24px"
              :text="$t('fileManagement.preview.navMenu.e-signature.status.DECLINED')"
              color="error--text"
            />
          </div>
          <div>
            {{ createdDate | dateFormat("DD.MM.YYYY HH:mm") }}
          </div>
        </div>

        <div class="mb-4">
          <div class="mb-1 font-weight-bold body-2">
            {{ $t("fileManagement.preview.navMenu.e-signature.DECLINED.declinedOn") }}
          </div>
          <div>
            {{ declinedDate | dateFormat("DD.MM.YYYY HH:mm") }}
          </div>
        </div>

        <div class="mb-4">
          <div class="mb-1 font-weight-bold body-2">
            {{ $t("fileManagement.preview.navMenu.e-signature.DECLINED.sentBy") }}
          </div>
          <AppUserAvatar :user="sender" :truncate="30" show-name />
        </div>

        <div>
          <div class="mb-1 font-weight-bold body-2">
            {{ $t("fileManagement.preview.navMenu.e-signature.DECLINED.declinedBy") }}
          </div>
          <div>
            <v-divider class="my-2" />
            <div>
              <div class="d-flex flex-column">
                <div class="mb-1">
                  <div class="d-flex">
                    <span class="text-caption">{{ $t("common.name") }}</span>
                  </div>
                  <AppTextTruncateValue
                    :disabled="signer.name.length <= truncateLength"
                    :text="signer.name"
                    :truncate="truncateLength"
                  />
                </div>
                <div class="mb-1">
                  <div class="d-flex">
                    <span class="text-caption">{{ $t("common.email") }}</span>
                  </div>
                  <a
                    class="text-decoration-none primary-link--text"
                    :href="`mailto:${signer.email}`"
                    target="_blank"
                  >
                    <AppTextTruncateValue
                      :disabled="signer.email.length <= truncateLength"
                      :text="signer.email"
                      :truncate="truncateLength"
                    />
                  </a>
                </div>
                <div>
                  <div class="d-flex">
                    <span class="text-caption">
                      {{
                        $t(
                          "fileManagement.preview.navMenu.e-signature.DECLINED.declineMessage",
                        )
                      }}
                    </span>
                  </div>
                  <span>{{ declinedMessage }}</span>
                </div>
              </div>
              <v-divider class="my-2" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      truncateLength: 35,
    };
  },
  props: {
    storageId: String,
  },
  computed: {
    ...mapState("declinedDocumentProperties", {
      properties: (state) => state.properties,
      isPropertiesLoading: (state) => state.isLoading,
    }),
    ...mapGetters("declinedDocumentProperties", {
      sender: "sender",
      signer: "signer",
      createdDate: "createdDate",
      declinedDate: "declinedDate",
      declinedMessage: "declinedMessage",
    }),
    isLoading() {
      return this.isPropertiesLoading || !this.properties;
    },
  },
  methods: {
    getDocumentProperties(storageId, load = true) {
      this.$store.dispatch("declinedDocumentProperties/getProperties", {
        storageId,
        load,
      });
    },
  },
  mounted() {
    this.getDocumentProperties(this.storageId);
  },
};
</script>
