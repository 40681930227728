import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, contactUserRedirectGuard, userGuard } from "../../auth/authGuard";
import multiguard from "../multiguard";

const { ROOT } = ROUTE_NAMES.DASHBOARD;

export const dashboardRoutes = [
  {
    path: "/dashboard",
    name: ROOT,
    beforeEnter: multiguard([authGuard, userGuard(), contactUserRedirectGuard]),
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../../pages/DashboardPage"),
  },
];
