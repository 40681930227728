<template>
  <v-dialog v-model="localDialog" max-width="650" scrollable>
    <v-card>
      <v-card-title class="headline">
        {{ $t("fileManagement.preview.navMenu.e-signature.addMoreDialog.title") }}
      </v-card-title>

      <v-card-text>
        {{ descriptionText() }}
      </v-card-text>

      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onConfirm"
          @cancel="localDialog = false"
          :show="isDomainOwner"
          :disabled="isLoading"
          :loading="isUpdatingESignPurchase"
          :confirmText="
            $t('fileManagement.preview.navMenu.e-signature.addMoreDialog.buyMore')
          "
        />
      </v-card-actions>
    </v-card>

    <AppAddAdditionalPackagesDialog
      v-if="eSignProduct"
      v-model="addProductsDialog"
      :currency="currency"
      :productCode="eSignProduct.code"
      :pricePerQuantity="eSignProduct.price"
      @confirm="purchaseProduct($event)"
    />
  </v-dialog>
</template>
<script>
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      addProductsDialog: false,
    };
  },
  props: {
    dialog: Boolean,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapGetters("auth", {
      isDomainOwner: "isDomainOwner",
    }),
    ...mapState("billingDetails", {
      billingDetails: (state) => state.billingDetails,
      isDetailsLoading: (state) => state.isLoading,
      isUpdatingESignPurchase: (state) => state.isUpdatingESignPurchase,
    }),
    ...mapGetters("billingDetails", {
      currency: "currency",
      eSignProduct: "eSignProduct",
    }),
    isLoading() {
      return !this.billingDetails || this.isDetailsLoading;
    },
  },
  methods: {
    getBillingDetails() {
      this.$store.dispatch("billingDetails/getBillingDetails");
    },
    descriptionText() {
      if (this.isDomainOwner) {
        return this.$t(
          "fileManagement.preview.navMenu.e-signature.addMoreDialog.ownerdescription",
        );
      } else {
        return this.$t(
          "fileManagement.preview.navMenu.e-signature.addMoreDialog.description",
        );
      }
    },
    onConfirm() {
      if (!this.isDomainOwner) return;
      this.addProductsDialog = true;
    },
    async purchaseProduct({ quantity, productCode }) {
      await this.$store.dispatch("billingDetails/purchaseProduct", {
        quantity,
        productCode,
        updateKey: "setIsUpdatingESignPurchase",
      });
      this.$emit("confirm");
      this.localDialog = false;
    },
  },
  mounted() {
    if (this.isDomainOwner) {
      this.getBillingDetails();
    }
  },
};
</script>
