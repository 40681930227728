<template>
  <AppMenuItem
    :text="$t('common.downloadWord')"
    :icon="$icons.REGULAR.FILE.FILE_WORD"
    :disabled="disabled"
    @click="click"
  />
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
