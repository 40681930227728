<script>
import { projectTemplateBuilderMixins } from "@/helpers/mixins";
import Title from "./Title.vue";
import CustomIconTooltip from "./CustomIconTooltip.vue";
import TaskItem from "./TaskItem.vue";
import { VIcon, VBtn } from "vuetify/lib";
import Draggable from "vuedraggable";
export default {
  mixins: [projectTemplateBuilderMixins],
  props: {
    taskItems: Array,
  },
  data() {
    return {
      isAssigningTimeLimit: false,
      isDragging: false,
    };
  },
  methods: {
    onChange(e) {
      if (e.moved) {
        const item = e.moved?.element;
        const newIndex = e.moved?.newIndex;

        this.projectTemplateBuilderMixins_moveItem({
          item,
          body: { index: newIndex },
        });
      }
    },
    removeBorderOnChildrenIfNotDraggingChild() {
      return !this.isDragging ? "project-template__item-container" : "";
    },
    removeBorderBotIfNoRows() {
      return this.taskItems.length === 0 ? "" : "border-b";
    },
    genTaskItems() {
      const taskRows = [];
      for (const item of this.taskItems) {
        const taskRow = this.$createElement(TaskItem, {
          props: {
            item,
            isDragging: this.isDragging,
          },
          on: {
            "item:update": (e) => this.$emit("item:update", e),
            "item:delete": (e) => this.$emit("item:delete", e),
          },
        });
        taskRows.push(taskRow);
      }
      return this.$createElement(
        Draggable,
        {
          class: `border-l border-r ${this.removeBorderBotIfNoRows()} rounded-b-lg overflow-hidden ${this.removeBorderOnChildrenIfNotDraggingChild()}`,
          props: {
            list: this.taskItems,
          },
          attrs: {
            draggable: ".draggable-item",
            animation: this.$constant.DRAG_SETTINGS.ANIMATION_DURATION,
            ghostClass: this.$constant.DRAG_SETTINGS.GHOST_CLASS_HIDDEN,
            dragClass: this.$constant.DRAG_SETTINGS.DRAG_CLASS,
            filter: ".block-draggable",
            forceFallback: true,
            preventOnFilter: false,
          },
          on: {
            change: (e) => this.onChange(e),
            start: (e) => {
              this.isDragging = true;
            },
            end: (e) => {
              this.isDragging = false;
            },
          },
        },
        taskRows,
      );
    },
    createUnsavedTaskItem() {
      const index = this.taskItems.length;
      const data = { name: "", type: "TASK", values: null, index };
      this.$emit("item:createUnsavedItem", { data });

      setTimeout(() => {
        const taskItem = this.taskItems[this.taskItems.length - 1];
        document.getElementById(`taskItemTextField_${taskItem?.refId}`)?.focus();
      }, 20);
    },
    showBorderAndBorderRadiusIfNoItems() {
      return this.taskItems.length === 0 ? "rounded-b-lg" : "";
    },
  },

  render(h) {
    return h("div", [
      h(Title, {
        props: { title: this.$t("templateManagement.project.builder.task.title") },
      }),
      h("div", [
        h(
          "div",
          {
            class: `d-flex rounded-t-lg ${this.showBorderAndBorderRadiusIfNoItems()} pa-2 px-4 border-a text-subtitle-2`,
          },
          [
            h(
              "div",
              {
                class: "",
                style: `width: ${this.projectTemplateBuilderMixins_taskNameWidth}%`,
              },
              this.$t("templateManagement.project.builder.task.taskName"),
            ),
            h(
              "div",
              {
                class: "d-flex pl-2",
                style: `width: ${this.projectTemplateBuilderMixins_dueDateWidth}%`,
              },
              [
                h("div", this.$t("templateManagement.project.builder.task.timeLimit")),
                h(CustomIconTooltip, {
                  props: {
                    text: this.$t(
                      "templateManagement.project.builder.task.timeLimitTooltip",
                    ),
                    icon: this.$icons.LIGHT.COMMON.CIRCLE_QUESTION,
                  },
                }),
              ],
            ),
          ],
        ),
        this.genTaskItems(),
      ]),
      h("div", { class: "pt-2 mb-4" }, [
        h(
          VBtn,
          {
            props: { text: true },
            on: {
              click: () => this.createUnsavedTaskItem(),
            },
          },
          [
            h(VIcon, { class: "pr-3" }, this.$icons.LIGHT.ACTION.CIRCLE_PLUS),
            h(
              "div",
              { class: "font-weight-bold", attrs: { id: "create-task-btn" } },
              this.$t("templateManagement.project.builder.task.createTask"),
            ),
          ],
        ),
      ]),
    ]);
  },
};
</script>
