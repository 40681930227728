<template>
  <!-- must be persistent, if not it breaks the image editor -->
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :retainFocus="false"
    fullscreen
    persistent
    no-click-animation
  >
    <v-card class="file-preview-dialog">
      <div class="file-preview-dialog_menu">
        <div class="file-preview-dialog_menu_actions">
          <AppDefaultTooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" text outlined @click="refreshPreview">
                <v-icon>{{ $icons.LIGHT.ACTION.REFRESH }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("fileManagement.preview.mainToolbar.refreshPreview") }}</span>
          </AppDefaultTooltip>

          <div v-if="canOpenWith">
            <AppToggleMenuBtn
              :text="$t('fileManagement.fileExplorer.menu.openWith')"
              :icon="$icons.SOLID.ACTION.DROPDOWN"
              color="primary"
            >
              <AppMenuItemOpenInOffice
                :item="storage"
                @openInOffice="onOpenInOffice"
                :disabled="!localCanEditFile"
              />
            </AppToggleMenuBtn>
          </div>

          <div v-show="isLoomRecordingFeatureEnabled && isLoomInitialized">
            <v-btn color="primary" id="loom-button" ref="loomButton" text outlined>
              {{ $t("fileManagement.preview.mainToolbar.recordVideo") }}
            </v-btn>
          </div>
        </div>

        <div class="file-preview-dialog_menu_title">
          <v-icon class="mr-2" v-if="storage">
            {{ storageMixins_getStorageIconName(storage) }}
          </v-icon>
          <span>{{ title }}</span>
        </div>

        <v-spacer />
        <div class="d-flex align-center" v-if="!disablePagination">
          <v-btn icon @click="onNavPrevFile" :disabled="isFirstFile || !canNavigateFiles">
            <v-icon>
              {{ $icons.LIGHT.COMMON.CARET_LEFT }}
            </v-icon>
          </v-btn>
          <div style="min-width: 70px" class="d-flex justify-center">
            <v-icon v-if="!canNavigateFiles">
              {{ $icons.SOLID.ACTION.LOADING }}
            </v-icon>
            <div v-else class="mx-1">{{ currentNavIndex + 1 }}/{{ fileCount }}</div>
          </div>
          <v-btn
            icon
            class="mr-8"
            @click="onNavNextFile"
            :disabled="isLastFile || !canNavigateFiles"
          >
            <v-icon>
              {{ $icons.LIGHT.COMMON.CARET_RIGHT }}
            </v-icon>
          </v-btn>
        </div>
        <v-btn icon dark @click="close">
          <v-icon>
            {{ $icons.LIGHT.ACTION.CLOSE }}
          </v-icon>
        </v-btn>
      </div>

      <div class="file-preview-dialog_content">
        <template v-if="isPreviewLoading">
          <AppCenterLoader show :title="$t('common.loading')" />
        </template>
        <template v-else>
          <template v-if="isProcessing">
            <AppCenterLoader
              show
              :title="$t('fileManagement.preview.fileProcessing')"
              :height="'calc(100% - 68px)'"
            />
          </template>
          <AppFilePreview
            v-else
            :key="previewRefreshKey"
            class="w-100"
            :fileId="fileId"
            :src="src"
            :mime="storageMime"
            :canEditFile="localCanEditFile"
            :isLinkedFile="isLinkedFile"
            @preview:update="onPreviewUpdate"
          />
        </template>

        <div class="d-flex">
          <AppStorageDetailsDrawer
            v-if="activeMenu === 'DETAILS'"
            :fileId="fileId"
            @preview:update="onPreviewUpdate"
          />
          <AppHistoryDrawer v-if="activeMenu === 'HISTORY'" :itemId="fileId" />
          <AppCommentDrawer
            v-if="activeMenu === 'COMMENTS'"
            :type="commentModel"
            :parentId="fileId"
          />
          <AppESignDrawer
            v-if="activeMenu === 'E_SIGN'"
            v-show="hasESignMeta"
            :fileId="fileId"
            @preview:update="onPreviewUpdate"
          />
          <AppStorageReadConfirmationDrawer
            v-if="
              activeMenu === 'READ_CONFIRMATION' && dialog && showSendToReadConfirmation
            "
            :storage="storage"
            :isLoading="isLoading"
            @storage:refresh="onPreviewUpdate"
          />
          <AppStorageFileVersionHistoryDrawer
            v-if="activeMenu === 'FILE_VERSION_HISTORY' && dialog && showVersionHistory"
            :storage="storage"
            :isLoading="isLoading"
            :previewVersion="previewVersion"
            @fileVersion:changePreview="onPreviewVersionChange"
            @fileVersion:restore="onPreviewVersionRestore"
          />
          <AppVerticalNavBar
            class="border-t-none"
            :items="menus"
            :activeKey="activeMenu"
            :route="false"
            @item:click="onNavItemClick"
          >
          </AppVerticalNavBar>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { storageMixins } from "@/helpers/mixins";
import { mapState, mapGetters } from "vuex";
import { isSupported, setup } from "@loomhq/record-sdk";
const API_URL = process.env.VUE_APP_API_URL;

export default {
  mixins: [storageMixins],
  data() {
    return {
      activeMenu: "",
      fileId: "",
      currentNavIndex: null,
      hasLoadedFilesInFolder: false,
      isLoomInitialized: false,
      fileAutoUpdateIntervalId: null,
      previewVersion: null,
      updateInterval: null,
      authToken: null,
      previewRefreshKey: 0,
    };
  },
  props: {
    initialFileId: String,
    initialActiveMenu: String,
    folderId: String,
    fileIds: Array,
    disablePagination: Boolean,
    canEditFile: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: "initialFileId",
    event: "initialFileId:change",
  },
  computed: {
    ...mapGetters("auth", {
      isLoomRecordingFeatureEnabled: "isLoomRecordingFeatureEnabled",
      isCommentFeatureEnabled: "isCommentFeatureEnabled",
      activeDomainId: "activeDomainId",
    }),
    isLinkedFile() {
      return this.storage?.type === this.$constant.FILE_RELATION;
    },
    ...mapState("storagePreview", {
      storage: (state) => state.storage,
      filesInFolder: (state) => state.filesInFolder,
      isFilesLoading: (state) => state.isFilesLoading,
    }),
    ...mapGetters("storagePreview", {
      isLoading: "isLoading",
    }),
    ...mapState("wopiDiscovery", {
      wopiDiscovery: (state) => state.discovery,
    }),
    ...mapGetters("wopiDiscovery", {
      getWopiApplicationOpenAction: "getApplicationOpenAction",
    }),
    wopiAction() {
      if (this.storage?.type !== this.$constant.FILE) return null;
      if (!this.storage?.extension) return null;
      const extension = this.storage.extension.split(".").pop();
      const action = this.getWopiApplicationOpenAction(extension);
      return action;
    },
    title() {
      return this.storage?.name;
    },
    files() {
      if (this.fileIds) return this.fileIds;
      if (this.folderId) return this.filesInFolder;
      return [];
    },
    fileCount() {
      return this.files?.length;
    },
    isLastFile() {
      const isLast = this.fileCount === this.currentNavIndex + 1;
      return isLast;
    },
    isFirstFile() {
      const isFirst = this.currentNavIndex === 0;
      return isFirst;
    },
    indexOfCurrentFile() {
      const index = this.files?.findIndex((item) => item.id === this.fileId);
      return index;
    },
    hasESignMeta() {
      return this.storage?.meta?.some(
        (meta) => meta.field === this.$constant.E_SIGN_META_FIELD,
      );
    },
    canOpenWith() {
      if (!this.wopiAction) return false;
      return true;
    },
    canNavigateFiles() {
      const hasFoundIndex = this.indexOfCurrentFile !== -1;
      const isLoadingFilesInFolder = this.folderId ? this.hasLoadedFilesInFolder : true;
      return hasFoundIndex && isLoadingFilesInFolder && !this.isFilesLoading;
    },
    localCanEditFile() {
      const isCurrentVersionInPreview =
        this.previewVersion === this.storage?.fileVersion || !this.previewVersion;
      return this.canEditFile && isCurrentVersionInPreview;
    },
    showSendToReadConfirmation() {
      return !this.storage?.mime?.match("video/*");
    },
    showVersionHistory() {
      return !this.storage?.mime?.match("video/*");
    },
    dialog: {
      get() {
        return this.initialFileId ? true : false;
      },
      set(value) {
        this.$emit("initialFileId:change", value);
      },
    },
    menus() {
      const isNotFileRelation = this.storage?.type !== this.$constant.FILE_RELATION;
      const menus = [
        {
          key: "DETAILS",
          tooltip: this.$t("fileManagement.preview.navMenu.details.title"),
          tooltipDirection: "left",
          icon: this.$icons.LIGHT.ROUTE.OVERVIEW,
        },
      ];
      if (this.isCommentFeatureEnabled) {
        menus.push({
          key: "COMMENTS",
          tooltip: this.$options.filters.capitalize(this.$t("common.comments")),
          tooltipDirection: "left",
          icon: this.$icons.LIGHT.ROUTE.COMMENT,
        });
      }
      if (this.hasESignMeta) {
        menus.push({
          key: "E_SIGN",
          tooltip: this.$t("fileManagement.preview.navMenu.e-signature.title"),
          tooltipDirection: "left",
          icon: this.$icons.LIGHT.ROUTE.SIGNATURE,
        });
      }
      if (this.showSendToReadConfirmation) {
        menus.push({
          key: "READ_CONFIRMATION",
          tooltip: this.$t("fileManagement.preview.navMenu.readConfirmation.title"),
          tooltipDirection: "left",
          icon: this.$icons.LIGHT.ROUTE.READ_CONFIRMATION,
        });
      }
      if (isNotFileRelation && this.showVersionHistory) {
        menus.push({
          key: "FILE_VERSION_HISTORY",
          tooltip: this.$t("fileManagement.preview.navMenu.fileVersionHistory.title"),
          tooltipDirection: "left",
          icon: this.$icons.LIGHT.ROUTE.FILE_VERSION_HISTORY,
        });
      }
      if (isNotFileRelation) {
        menus.push({
          key: "HISTORY",
          tooltip: this.$t("fileManagement.preview.navMenu.historicalLog.title"),
          tooltipDirection: "left",
          icon: this.$icons.LIGHT.ROUTE.EVENT_LOG,
        });
      }

      return menus;
    },
    commentModel() {
      if (!this.storage) return "storage";
      if (this.storage.type === this.$constant.FILE_RELATION) return "relation";
      return "storage";
    },
    isProcessing() {
      return (this.storage?.meta || []).some(
        (meta) => meta.field === "IS_PROCESSING" && meta.value === "TRUE",
      );
    },
    storageMime() {
      return this.storage?.mime;
    },
    isPreviewLoading() {
      return this.isLoading || !this.storage || !this.src;
    },
    src() {
      if (!this.storage || !this.authToken || !this.activeDomainId) return "";

      const baseUrl = `${API_URL}storage/file/${this.fileId}`;
      const queryParams = new URLSearchParams({
        authToken: this.authToken,
        domain: this.activeDomainId,
      });

      if (this.storageMime.match("video/")) {
        return `${baseUrl}/video-stream?${queryParams}`;
      }

      if (this.previewVersion) {
        queryParams.append("version", this.previewVersion);
      }

      if (this.previewRefreshKey) {
        queryParams.append("refresh", this.previewRefreshKey.toString());
      }

      return `${baseUrl}/content?${queryParams}`;
    },
  },
  watch: {
    dialog: {
      handler(val) {
        if (!val) {
          //wait til dialog has closed to reset loader
          setTimeout(() => {
            this.hasLoadedFilesInFolder = false;
          }, 200);
          this.clearFileAutoUpdateInterval();
          this.previewVersion = null;
        } else {
          this.getTokenSilently();
        }
      },
      immediate: true,
    },
    fileId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.clearFileAutoUpdateInterval();
      }
    },
    indexOfCurrentFile: {
      handler(val) {
        if (val !== -1) {
          this.currentNavIndex = this.indexOfCurrentFile;
        }
      },
    },
    initialFileId: {
      handler(initialFileId) {
        if (initialFileId) {
          this.fileId = initialFileId;
          this.getPreviewStorage({ id: initialFileId });
          if (this.folderId) {
            this.getFilesInFolder(this.folderId);
          }
          if (!this.wopiDiscovery) {
            this.getWopiDiscovery();
          }
        }
      },
      immediate: true,
    },
    initialActiveMenu: {
      handler(initialActiveMenu) {
        if (initialActiveMenu) {
          this.activeMenu = initialActiveMenu;
        }
      },
      immediate: true,
    },
    isProcessing: {
      immediate: true,
      handler(isProcessing) {
        if (isProcessing) {
          this.startUpdateInterval();
        } else {
          this.stopUpdateInterval();
        }
      },
    },
  },
  methods: {
    async getPreviewStorage({ id, load = true }) {
      this.fileId = id;
      await this.$store.dispatch("storagePreview/getStorage", { id, load });
    },
    getFilesInFolder(folderId) {
      if (this.disablePagination) return;
      this.$store.dispatch("storagePreview/getFilesInFolder", folderId).then(() => {
        if (!this.hasLoadedFilesInFolder) {
          this.currentNavIndex = this.indexOfCurrentFile;
          this.hasLoadedFilesInFolder = true;
        }
      });
    },
    async refreshPreview() {
      await this.getPreviewStorage({ id: this.fileId, load: false });
      this.previewRefreshKey = Date.now();
    },
    onOpenInOffice() {
      // Clear existing interval if any
      if (this.fileAutoUpdateIntervalId) {
        clearInterval(this.fileAutoUpdateIntervalId);
      }
      // Set a new interval
      this.fileAutoUpdateIntervalId = setInterval(() => {
        this.checkAndUpdatePreview();
      }, 5000);
    },
    clearFileAutoUpdateInterval() {
      if (this.fileAutoUpdateIntervalId) {
        clearInterval(this.fileAutoUpdateIntervalId);
        this.fileAutoUpdateIntervalId = null;
      }
    },
    async checkAndUpdatePreview() {
      const shouldRefresh = await this.$store.dispatch(
        "storagePreview/checkAndUpdatePreview",
        { id: this.fileId },
      );
      if (shouldRefresh) {
        this.previewRefreshKey = Date.now();
      }
    },
    getWopiDiscovery() {
      this.$store.dispatch("wopiDiscovery/getDiscovery");
    },
    onPreviewVersionChange(versionToSet) {
      this.previewVersion = versionToSet;
      this.getPreviewStorage({ id: this.fileId, load: true });
    },
    async onPreviewVersionRestore({ version }) {
      await this.$store.dispatch("storagePreview/restoreFileVersion", {
        id: this.fileId,
        versionId: version.id,
      });
      this.previewVersion = null;
      this.getPreviewStorage({ id: this.fileId, load: true });
    },
    onNavNextFile() {
      const nextFileId = this.files[this.currentNavIndex + 1]?.id;
      if (!nextFileId) return;
      this.currentNavIndex++;
      this.previewVersion = null;

      this.getPreviewStorage({ id: nextFileId });
    },
    onNavPrevFile() {
      const prevFileId = this.files[this.currentNavIndex - 1]?.id;
      if (!prevFileId) return;
      this.currentNavIndex--;
      this.previewVersion = null;

      this.getPreviewStorage({ id: prevFileId });
    },
    onNavItemClick({ item }) {
      this.activeMenu = this.activeMenu !== item.key ? item.key : null;
    },
    onPreviewUpdate() {
      this.getPreviewStorage({ id: this.fileId, load: false });
      this.$emit("storage:update", { id: this.fileId });
    },
    close() {
      this.clearFileAutoUpdateInterval();
      this.$store.commit("storagePreview/setStorage", null);
      this.$emit("dialog:close");
    },
    async initLoom() {
      if (!this.isLoomRecordingFeatureEnabled || location.hostname === "localhost")
        return;
      const { supported, error } = await isSupported();

      if (!supported) {
        console.warn(`Error setting up Loom: ${error}`);
        return;
      }

      const button = document.getElementById("loom-button");
      if (!button) return;

      const { configureButton, status } = await setup({
        publicAppId: process.env.VUE_APP_LOOM_API_KEY,
      });

      const { success } = status();
      if (!success) return;

      configureButton({ element: button });
      this.isLoomInitialized = true;
    },
    startUpdateInterval() {
      this.updateInterval = setInterval(() => {
        this.onPreviewUpdate();
      }, 10000);
    },
    stopUpdateInterval() {
      if (this.updateInterval) {
        clearInterval(this.updateInterval);
        this.updateInterval = null;
      }
    },
    setupOfficeTabListener() {
      this.officeTabMessageHandler = (event) => {
        if (event.data === "officeTabClosed") {
          setTimeout(() => {
            if (this.dialog) {
              this.refreshPreview();
            }
          }, 3000);
        }
      };
      window.addEventListener("message", this.officeTabMessageHandler);
    },
    teardownOfficeTabListener() {
      window.removeEventListener("message", this.officeTabMessageHandler);
    },
    async getTokenSilently() {
      try {
        if (this.authToken) return;
        const token = await this.$auth.getTokenSilently();
        this.authToken = token;
      } catch (error) {
        console.error("Error getting token:", error);
      }
    },
  },
  mounted() {
    this.initLoom();
    this.setupOfficeTabListener();
  },
  beforeDestroy() {
    this.teardownOfficeTabListener();
    this.stopUpdateInterval();
  },
};
</script>
