<template>
  <AppFiltersDrawer
    v-if="localShowDrawer"
    @close="closeFilters"
    :tableModel="tableModel"
    :children="children"
  />
</template>

<script>
import { getSearchUsers } from "@/services/search/users";
import { getHourTypes } from "@/services/timeEntries/hourTypes";
import { filtersItemsMixins } from "@/helpers/mixins";
import { filtersMixins } from "@/helpers/mixins";
import { filtersWatchMixins } from "@/helpers/mixins";

export default {
  mixins: [filtersItemsMixins, filtersMixins, filtersWatchMixins],
  props: {
    showDrawer: Boolean,
    tableModel: String,
    hideProjectLevel: Boolean,
  },
  model: {
    prop: "showDrawer",
    event: "change",
  },
  data() {
    return {
      users: [],
      isUsersLoading: false,
      statusChangers: [],
      isStatusChangersLoading: false,

      hourTypes: [],
      isHourTypesLoading: false,
      tasks: [],
      isTasksLoading: false,
    };
  },
  methods: {
    getData() {
      this.getUsers();
      this.getStatusChangers();
      this.getHourTypes();
    },
    closeFilters() {
      this.localShowDrawer = false;
    },
    async getUsers() {
      this.isUsersLoading = true;
      const params = { alwaysIncludeIds: this.userIds };
      getSearchUsers({ params })
        .then((users) => {
          this.users = users;
        })
        .finally(() => {
          this.isUsersLoading = false;
        });
    },
    async getStatusChangers() {
      this.isStatusChangersLoading = true;
      const params = { alwaysIncludeIds: this.statusChangerIds };
      getSearchUsers({ params })
        .then((users) => {
          this.statusChangers = users;
        })
        .finally(() => {
          this.isStatusChangersLoading = false;
        });
    },
    async getHourTypes() {
      this.isHourTypesLoading = true;
      getHourTypes({ isActive: true })
        .then((hourTypes) => {
          this.hourTypes = hourTypes;
        })
        .finally(() => {
          this.isHourTypesLoading = false;
        });
    },
  },
  computed: {
    localShowDrawer: {
      get() {
        return this.showDrawer;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    projectId() {
      return this.$route.params.projectId;
    },
    projectIds() {
      return this.filtersMixins_filterByKey("projectIds") ?? [];
    },
    taskIds() {
      return this.filtersMixins_filterByKey("taskIds") ?? [];
    },
    statusChangerIds() {
      return this.filtersMixins_filterByKey("statusChangerIds") ?? [];
    },
    userIds() {
      return this.filtersMixins_filterByKey("userIds") ?? [];
    },
    children() {
      const children = [
        {
          is: "AppStatusFilter",
          timeEntries: true,
          labelKey: "filters.timeEntries.status",
          filterValueKey: "status",
          itemValue: "value",
          itemText: "text",
        },
        {
          is: "AppFiltersClientGroupedUserAndContactAutocomplete",
          labelKey: "filters.timeEntries.user",
          filterValueKey: "userIds",
          itemValue: "id",
          itemText: "name",
          items: this.users ?? null,
          loading: this.isUsersLoading,
          userAvatar: true,
        },
        {
          is: "AppFiltersClientGroupedUserAndContactAutocomplete",
          labelKey: "filters.timeEntries.statusChangedBy",
          filterValueKey: "statusChangerIds",
          itemValue: "id",
          itemText: "name",
          items: this.statusChangers ?? null,
          loading: this.isStatusChangersLoading,
          userAvatar: true,
        },
        {
          is: "AppFiltersMultiAutocomplete",
          labelKey: "filters.timeEntries.type",
          filterValueKey: "hourTypeIds",
          itemValue: "id",
          itemText: "name",
          loading: this.isHourTypesLoading,
          items: this.hourTypes,
        },
        !this.projectId && {
          is: "AppFiltersProjectMultiAutocomplete",
          labelKey: "filters.timeEntries.project",
          filterValueKey: "projectIds",
          isReadyToInitialFetch: this.isReadyToInitialFetch,
          queryParams: {
            alwaysIncludeIds: this.projectIds,
          },
        },
        {
          is: "AppFiltersTaskMultiAutocomplete",
          labelKey: "filters.timeEntries.task",
          filterValueKey: "taskIds",
          itemValue: "id",
          itemText: "name",
          showHelper: this.projectIds?.length > 0,
          queryParams: {
            alwaysIncludeIds: this.taskIds,
            projectIds: this.projectIds,
          },
          isReadyToInitialFetch: this.isReadyToInitialFetch,
          refetchOnQueryParamsChange: true,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.timeEntries.hasComment",
          filterValueKey: "hasComment",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.timeEntries.isSynchronized",
          filterValueKey: "isSynchronized",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
      ];

      return children;
    },
  },
};
</script>
