<template>
  <UpgradePlanDialogTemplate
    v-model="active"
    :isLoading="isLoading"
    :plan="plan"
    :currency="currency"
    :usedSeats="usedSeats"
    :intervalType="intervalType"
    :canChangeSubscription="canChangeSubscription"
    :platformFeeSubscription="platformFeeSubscription"
    :activePlanCode="activePlanCode"
    @complete="onUpgradePlan"
  />
</template>

<script>
import { mapGetters, mapState } from "vuex";
import UpgradePlanDialogTemplate from "./UpgradePlanDialogTemplate";

export default {
  components: { UpgradePlanDialogTemplate },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  watch: {
    active: {
      handler(val) {
        if (val) {
          this.getBillingDetails();
        }
      },
    },
  },
  computed: {
    active: {
      get() {
        return this.dialog.active;
      },
      set(active) {
        this.$store.commit("upgradePlanDialog/setActive", active);
      },
    },
    ...mapState("upgradePlanDialog", {
      dialog: (state) => state.dialog,
    }),
    ...mapState("billingDetails", {
      billingDetails: (state) => state.billingDetails,
      isLoading: (state) => state.isLoading,
    }),
    ...mapGetters("billingDetails", {
      currency: "currency",
      usedSeats: "usedSeats",
      intervalType: "intervalType",
      canChangeSubscription: "canChangeSubscription",
      planByCode: "planByCode",
      platformFeeSubscription: "platformFeeSubscription",
      activePlanCode: "activePlanCode",
    }),
    plan() {
      return this.planByCode(this.dialog.planCode);
    },
  },
  methods: {
    onUpgradePlan({ quantity, planCode, onUpgradeComplete, onUpgradeError }) {
      this.$store
        .dispatch("billingDetails/updateMainSubscription", {
          quantity,
          planCode,
          updateKey: "setIsChangingPlan",
        })
        .then(() => {
          onUpgradeComplete();
          if (this.dialog.onAfterUpgrade) {
            this.dialog.onAfterUpgrade();
          }
        })
        .catch((error) => {
          onUpgradeError(error);
        });
    },
    getBillingDetails() {
      this.$store.dispatch("billingDetails/getBillingDetails");
    },
  },
};
</script>
