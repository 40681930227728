<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("integration.boligmappa.room.title") }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="boligmappaRoom"
          v-model="boligmappaRoom.valid"
          v-on:submit.prevent
          @submit="onCreate"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("integration.boligmappa.room.roomType") }}
            </div>
            <AppDefaultAutocomplete
              v-model="boligmappaRoom.roomType.value"
              :rules="boligmappaRoom.roomType.rules"
              :items="roomTypes"
              item-text="name"
              item-value="id"
              :loading="isLoading"
            />
          </div>

          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("integration.boligmappa.room.roomName") }}
            </div>
            <AppDefaultTextField
              v-model="boligmappaRoom.title.value"
              :rules="boligmappaRoom.title.rules"
            />
          </div>

          <div class="p-relative">
            <div class="label--small">
              {{ $t("integration.boligmappa.room.description") }}
            </div>
            <AppDefaultTextarea
              v-model="boligmappaRoom.description.value"
              :rules="boligmappaRoom.description.rules"
            />
          </div>
        </v-form>

        <v-alert dense outlined type="error" v-if="hasError">
          Something went wrong, please try again.
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onCreate"
          @cancel="onClose"
          :loading="isCreating"
          :confirmText="$t('common.save')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
    dialog: Boolean,
    boligmappaNumber: {
      type: String,
      required: true,
    },
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      boligmappaRoom: {
        valid: false,
        roomType: {
          value: null,
          rules: [(v) => typeof v === "number" || this.$t("common.required")],
        },
        title: {
          value: null,
          rules: [
            (v) => !!v || this.$t("common.required"),
            (v) => (v && !!v.trim()) || this.$t("common.required"),
          ],
        },
        description: {
          value: null,
          rules: [],
        },
      },
    };
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.getRoomTypes();
        }
      },
    },
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapState("boligmappaRoom", {
      roomTypes: (state) => state.roomTypes,
      isLoading: (state) => state.isLoading,
      isCreating: (state) => state.isCreating,
      hasError: (state) => state.hasError,
    }),
  },
  methods: {
    getRoomTypes() {
      this.$store.dispatch("boligmappaRoom/getRoomTypes");
    },
    onClose() {
      this.localDialog = false;
      this.$refs.boligmappaRoom.resetValidation();
    },
    async onCreate() {
      const valid = this.$refs.boligmappaRoom.validate();
      if (valid) {
        const body = {
          boligmappaNumber: this.boligmappaNumber,
          room: {
            roomType: { id: this.boligmappaRoom.roomType.value },
            title: this.boligmappaRoom.title.value,
            description: this.boligmappaRoom.description.value || "",
          },
        };
        const room = await this.$store.dispatch("boligmappaRoom/createRoom", body);
        this.$emit("created", { room });
        this.$refs.boligmappaRoom.reset();
        this.localDialog = false;
      }
    },
  },
  mounted() {},
};
</script>
