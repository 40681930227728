import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import FileSaver from "file-saver";

export async function generateReport({ reportKey, filters, projectId }) {
  //possible keys: project, task, form, employee, client, contact, time-entry

  const params = {
    ...filters,
  };
  if (projectId) {
    params.projectIds = [projectId];
  }

  return axios
    .get(`${apiUrl}report/${reportKey}`, {
      responseType: "blob",
      params,
    })
    .then((res) => {
      let fileName = "";
      const contentDisposition = res.headers["content-disposition"];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
      FileSaver.saveAs(res.data, fileName);
    })
    .catch((error) => {
      throw new Error(error);
    });
}
