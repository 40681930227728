import { shouldUpdateItems } from "@/helpers/util";
import { formatDataTableOptionsToQuery } from "@/helpers/util/dataTable";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let getProductsCancel;

const state = () => ({
  products: [],
  isLoading: false,
  isUpdating: false,
  count: null,
});

const actions = {
  async getProducts({ commit }, { filter, dataTableOptions }) {
    if (getProductsCancel) getProductsCancel();
    commit("loading", true);

    const optionsQuery = formatDataTableOptionsToQuery(dataTableOptions);

    const params = {
      ...filter,
      ...optionsQuery,
    };

    return axios
      .get(`${apiUrl}product`, {
        params,
        cancelToken: new CancelToken(function executor(c) {
          getProductsCancel = c;
        }),
      })
      .then(({ data }) => {
        const { products, count } = data;

        const shouldUpdate = shouldUpdateItems(
          count,
          dataTableOptions?.page,
          dataTableOptions?.itemsPerPage,
        );

        if (shouldUpdate) {
          commit("setCount", count);
        }
        commit("setProducts", products);
        commit("loading", false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("loading", false);
          throw new Error(error);
        }
      });
  },
  async createProduct({ commit, state }, { body }) {
    return axios
      .post(`${apiUrl}product`, { ...body })
      .then(({ data }) => {
        commit("setCount", state.count + 1);
        const { product } = data;
        commit("addProduct", product);
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async updateProduct({ commit }, { body, id }) {
    commit("setIsUpdating", true);
    return axios
      .put(`${apiUrl}product/${id}`, { ...body })
      .then(({ data }) => {
        const { product } = data;
        commit("updateProduct", product);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("setIsUpdating", false);
      });
  },
  async updateProducts({ commit }, { body }) {
    commit("setIsUpdating", true);
    return axios
      .put(`${apiUrl}product`, { ...body })
      .then(({ data }) => {
        const { products } = data;
        for (const product of products) {
          commit("updateProduct", product);
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("setIsUpdating", false);
      });
  },
  async deleteProducts({ commit, state }, { ids }) {
    commit("loading", true);
    const body = { data: { ids } };
    return axios
      .delete(`${apiUrl}product`, body)
      .then(({ data }) => {
        const { products } = data;
        for (const product of products) {
          commit("removeProduct", product);
        }
        const countRemoved = products.length;
        commit("setCount", state.count - countRemoved);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
};

const mutations = {
  setCount(state, count) {
    state.count = count;
  },
  setProducts(state, products) {
    const uniqueProductIds = new Set();
    const uniqueProducts = products.filter((product) => {
      if (uniqueProductIds.has(product.id)) {
        return false;
      } else {
        uniqueProductIds.add(product.id);
        return true;
      }
    });

    state.products = uniqueProducts;
  },
  updateProduct(state, product) {
    const index = state.products.findIndex((oldProduct) => oldProduct.id === product.id);
    if (index !== -1) {
      state.products.splice(index, 1, product);
    }
  },
  addProductsLast(state, products) {
    state.products.push(...products);
  },
  addProduct(state, product) {
    state.products.unshift(product);
  },
  removeProduct(state, product) {
    const index = state.products.findIndex((oldProduct) => oldProduct.id === product.id);
    if (index !== -1) {
      state.products.splice(index, 1);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setIsUpdating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
};

export const products = {
  namespaced: true,
  state,
  actions,
  mutations,
};
