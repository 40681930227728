import { normalizeKartdata } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

export async function kartdataAutocomplete(q) {
  return axios
    .get(`${apiUrl}kartdata/autocomplete`, { params: { q, fuzzy: true } })
    .then(({ data }) => {
      const items = data?.items;
      return normalizeKartdata(items)
    })
    .catch((error) => {
      throw new Error(error);
    });
}
