<template>
  <AppColorChip v-if="hasProgress" :text="text" :color="color" style="min-width: 44px" />
</template>

<script>
export default {
  props: {
    progress: Object,
  },
  computed: {
    hasProgress() {
      return typeof this.progress?.questions === "number" &&
        typeof this.progress?.answers === "number"
        ? true
        : false;
    },
    text() {
      return `${this.progress.answers} / ${this.progress.questions}`;
    },
    color() {
      if (!this.hasProgress) return "warning--text";
      return this.progress.questions > this.progress.answers
        ? "warning--text"
        : "success--text";
    },
  },
};
</script>
