<template>
  <div
    :class="`pb-5 ${isMobile ? 'd-flex flex-column' : 'd-flex justify-space-between'}`"
  >
    <div v-if="company?.logo" :class="`${isMobile ? 'pb-5' : ''}`">
      <img :src="company.logo" :alt="company.name" width="100px" />
    </div>
    <div v-else>{{ company?.name }}</div>

    <div :class="`d-flex flex-column ${!isMobile ? 'align-end' : ''}`">
      <span v-if="company?.name" class="text-body-2 font-weight-bold">{{
        company.name
      }}</span>
      <span v-if="company?.orgNr" class="text-body-2">
        {{ $t("formManagement.signatureSession.company.orgNr") }}: {{ company.orgNr }}
      </span>
      <span v-if="company?.address" class="text-body-2">{{ company.address }}</span>
      <span v-if="company?.zipCode || company?.place" class="text-body-2">
        {{ company?.zipCode }} {{ company?.place }}
      </span>
      <span v-if="company?.phone" class="text-body-2">
        {{ $t("formManagement.signatureSession.company.phone") }}: {{ company?.phone }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    company: {
      type: Object,
      default: () => ({}),
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
