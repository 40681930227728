import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let storageCancel;
let contentCancel;

const state = () => ({
  files: [],
  isLoading: false,
});

const getters = {
  isLoading: (state) => {
    return state.isLoading;
  },
};

const actions = {
  async getFileCollection({ commit, dispatch }, { projectId, type, orderBy, count = 9 }) {
    commit("loading", true);
    if (storageCancel) storageCancel();
    if (contentCancel) contentCancel();

    return axios
      .get(`${apiUrl}storage/files`, {
        cancelToken: new CancelToken(function executor(c) {
          storageCancel = c;
        }),
        params: {
          projectId,
          type,
          orderBy,
          count,
        },
      })
      .then(async ({ data }) => {
        const files = data.storages || [];
        for (let file of files) {
          const blobUrl = await dispatch("getFileContent", file.id);
          file.blobUrl = blobUrl;
        }
        commit("setFiles", files);
        commit("loading", false);
        return files;
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("loading", false);
          throw new Error(error);
        }
      });
  },
  async getFileContent({ commit }, fileId) {
    return axios
      .get(`${apiUrl}storage/file/${fileId}/content`, {
        cancelToken: new CancelToken(function executor(c) {
          contentCancel = c;
        }),
        responseType: "blob",
      })
      .then(({ data }) => {
        const blobUrl = URL.createObjectURL(data);
        //commit("updateFile", { fileId, blobUrl });

        return blobUrl;
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw new Error(error);
        }
      });
  },
  resetState({ commit }) {
    commit("setFiles", []);
    commit("loading", false);
  },
};

const mutations = {
  setFiles(state, folders) {
    state.files = folders;
  },
  updateFile(state, { fileId, blobUrl }) {
    const index = state.files.findIndex((file) => file.id === fileId);
    if (index !== -1) {
      state.files[index] = { ...state.files[index], blobUrl };
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const fileCollection = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
