<template>
  <v-card :disabled="isCompleting">
    <v-card-title>
      <span class="headline">
        {{ $t(`settings.integrations.${integrationName}.configDialog.title`) }}
      </span>
    </v-card-title>
    <v-card-text>
      <div>
        <div class="d-flex">
          <span class="label--small pt-2 required-asterisk">
            {{ $t(`settings.integrations.${integrationName}.configDialog.codeLabel`) }}
          </span>
          <v-spacer />
          <AppStonlyGuideBtn :guideId="guideId" />
        </div>
        <AppDefaultTextarea autofocus rows="1" :rules="$rules.REQUIRED" v-model="code" />
      </div>
      <AppErrorAlert v-if="hasError">
        {{ $t(`settings.integrations.${integrationName}.configDialog.codeError`) }}
      </AppErrorAlert>
    </v-card-text>
    <v-card-actions>
      <AppDialogActionBtnPair
        @confirm="complete"
        @cancel="cancel"
        :confirmText="$t('settings.integrations.activateAndSetup')"
        :loading="isCompleting"
        :disabled="!isValidCode"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    integrationName: {
      type: String,
      required: true,
    },
    guideId: {
      type: String,
      required: true,
    },
    completeMethod: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      code: null,
      hasError: false,
      isCompleting: false,
    };
  },
  computed: {
    isValidCode() {
      if (!this.code) return false;
      if (this.code.trim().length === 0) return false;
      return true;
    },
  },
  methods: {
    complete() {
      if (!this.code) return;
      this.isCompleting = true;
      this.hasError = false;
      const trimmedCode = this.code.trim();
      this.completeMethod({ code: trimmedCode, integrationName: this.integrationName })
        .then(() => {
          this.$emit("complete");
        })
        .catch(() => {
          this.hasError = true;
        })
        .finally(() => {
          this.isCompleting = false;
        });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
