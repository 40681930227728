<template>
  <div
    :class="[
      'drop-area rounded-lg overflow-hidden',
      isDraggingOver ? 'drop-area--dragging-over' : '',
      !hasFiles ? 'drop-area--empty' : 'drop-area--filled',
      loading ? 'drop-area--loading' : '',
    ]"
    @click="handleClick"
    @dragover.prevent="onDragOver"
    @dragleave.prevent="onDragLeave"
    @drop.prevent="onFileDrop"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    isDraggingOver: Boolean,
    hasFiles: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      isClickDisabled: false,
    };
  },
  methods: {
    handleClick(event) {
      if (!this.hasFiles && !this.loading && !this.isClickDisabled) {
        this.$emit("click", event);
      }
    },
    disableClickTemporarily() {
      this.isClickDisabled = true;
      setTimeout(() => {
        this.isClickDisabled = false;
      }, 20);
    },
    onDragOver(event) {
      if (this.loading) return;
      if (!event.dataTransfer?.types?.includes("Files")) return;
      this.$emit("dragOver", event);
    },
    onDragLeave() {
      if (this.loading) return;
      this.$emit("dragLeave");
    },
    onFileDrop(event) {
      if (this.loading) return;
      this.$emit("fileDrop", event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../styles/common/background_colors";
@import "../../../styles/common/common";

.drop-area {
  @extend .border-a;
  position: relative;
  box-sizing: border-box;
  outline: 2px solid transparent;
  outline-offset: -2px;
  @extend .drag-over-transition;

  &--empty {
    cursor: pointer;
  }

  &--filled {
    min-height: 40px;
  }

  &--loading {
    pointer-events: none;
    user-select: none;
    opacity: 0.6;
  }

  &--dragging-over {
    @extend .success-super-light--background;
    outline-color: var(--v-success-base);
  }
}
</style>
