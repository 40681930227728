<script>
import { VSpacer, VBtn, VIcon } from "vuetify/lib";

export default {
  props: {
    item: Object,
  },
  methods: {
    textClass() {
      return this.item?.type === this.$constant.GROUP ? "text-h6" : "text-h5";
    },
    onFormItemAdd() {
      this.$emit("item:itemAdd", { item: this.item });
    },
  },
  render(h) {
    return h("div", { class: "d-flex py-3" }, [
      h(
        "div",
        {
          class: `d-flex align-center font-weight-bold ${this.textClass()}`,
        },
        `${this.item.name}`,
      ),
      h(VSpacer),
      h(
        VBtn,
        {
          on: { click: this.onFormItemAdd },
          props: { color: "primary", text: true, small: true },
        },
        [
          h(VIcon, { props: { small: true } }, this.$icons.LIGHT.ACTION.CIRCLE_PLUS),
          h("span", { class: "pl-2" }, this.$t("formManagement.completion.addQuestion")),
        ],
      ),
    ]);
  },
};
</script>
