import {
  normalizeCmsDocumentTemplateCategories,
  normalizeDocumentCategories,
} from "@/helpers/normalization/document";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  categories: [],
  isLoading: false,
});

const getters = {};

const actions = {
  async getCMSCategories({ commit }, group) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}category`, { params: { group, model: "storage" } })
      .then(({ data }) => {
        const categories = data?.categories;
        const normalizedCategories = normalizeCmsDocumentTemplateCategories(categories);
        commit("setCategories", normalizedCategories);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async getCategories({ commit }, group) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}template/document/category`, { params: { group, model: "storage" } })
      .then(({ data }) => {
        const categories = data?.categories;
        const normalizedCategories = normalizeDocumentCategories(categories);
        commit("setCategories", normalizedCategories);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const documentTemplateLibraryCategory = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
