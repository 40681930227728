<script>
import { VCard } from "vuetify/lib";

export default {
  props: {
    item: Object,
  },
  methods: {
    genNameColumn() {
      const nameDiv = this.$createElement(
        "div",
        {
          class: "d-flex align-center pl-5",
          style: "height: 65px;",
        },
        [this.$createElement("div", this.item?.name)],
      );

      return this.$createElement("div", { class: "w-100" }, [nameDiv]);
    },
    genToDoItemRow() {
      return this.$createElement(
        VCard,
        {
          style: "height: 65px;",
          class: `pa-2 d-flex align-center`,
          props: { flat: true, tile: true },
        },
        [this.genNameColumn()],
      );
    },
  },
  render(h) {
    return this.genToDoItemRow();
  },
};
</script>
