import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
const acceptedTypesToConvert = ["image/heic", "image/heif"];

const state = () => ({
  isLoadingAcceptedFileTypes: false,
  acceptedFileTypes: [],
});

const getters = {
  acceptedFileTypes: (state) => state.acceptedFileTypes,
  isLoadingAcceptedFileTypes: (state) => state.isLoadingAcceptedFileTypes,
};

const actions = {
  async getAcceptedFileTypes({ commit, state }) {
    if (state.acceptedFileTypes.length > 0) return;

    commit("setAcceptedFileTypesLoading", true);
    const url = `${apiUrl}storage/accepted-mime`;

    return axios
      .get(url)
      .then(({ data }) => {
        const types = [...data.mimeTypes, ...acceptedTypesToConvert];
        commit("setAcceptedFileTypes", types);
      })
      .finally(() => {
        commit("setAcceptedFileTypesLoading", false);
      });
  },
};

const mutations = {
  setAcceptedFileTypes: (state, acceptedFileTypes) => {
    state.acceptedFileTypes = acceptedFileTypes;
  },
  setAcceptedFileTypesLoading: (state, isLoading) => {
    state.isLoadingAcceptedFileTypes = isLoading;
  },
};

export const acceptedFileTypes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
