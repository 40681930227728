<script>
import { projectTemplateBuilderMixins } from "@/helpers/mixins";
import { VIcon } from "vuetify/lib";
import CustomButtonTooltip from "./CustomButtonTooltip.vue";
export default {
  mixins: [projectTemplateBuilderMixins],
  props: {
    item: Object,
    open: Boolean,
    storageItems: Array,
    isLastItem: Boolean,
  },
  data() {
    return {
      isHovering: false,
      name: {
        isValid: false,
      },
    };
  },
  methods: {
    hasChild(id) {
      const hasChild = this.storageItems.some((i) => i.parentId === id);
      return hasChild;
    },
    getIcon() {
      const isFolder = this.item.values?.type === "FOLDER";
      const hasChild = this.hasChild(this.item.id);

      if (isFolder && this.open && hasChild) {
        return this.$icons.LIGHT.FILE.FOLDER_OPEN;
      } else if (isFolder) {
        return this.$icons.LIGHT.FILE.FOLDER;
      } else {
        return this.$icons.LIGHT.FILE.FILE;
      }
    },
    genFolderIconButton() {
      if (!this.item && !this.item.values) return null;
      const isFolder = this.item?.values?.type === "FOLDER";
      return isFolder
        ? this.$createElement(CustomButtonTooltip, {
            props: {
              text: this.$t("templateManagement.project.builder.storage.createSubFolder"),
              icon: this.$icons.LIGHT.FILE.FOLDER_TREE,
              disabled: !this.projectTemplateBuilderMixins_isItemSaved(this.item),
            },
          })
        : null;
    },
    borderRadiusIfLastItem() {
      return this.isLastItem ? "rounded-b-lg" : "";
    },
  },
  render(h) {
    return h(
      "div",
      {
        class: `d-flex align-center ${this.borderRadiusIfLastItem()} overflow-hidden`,
        on: {
          mouseover: () => {
            this.isHovering = true;
          },
          mouseleave: () => {
            this.isHovering = false;
          },
        },
      },
      [
        h(VIcon, this.getIcon()),
        h("div", { class: "w-100 pl-2 text-border-reset" }, [this.item.name]),
      ],
    );
  },
};
</script>
