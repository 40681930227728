import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getAllForms({ filters }) {
  const params = { offset: 0, ...filters };
  const config = { params };
  return axios
    .get(`${apiUrl}form`, config)
    .then(({ data }) => {
      const { forms } = data;
      return forms;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
