<template>
  <div class="pt-4 pb-2">
    <v-row no-gutters>
      <v-col cols="4">
        <AppDefaultTextField
          :value="product.name"
          @input="onProductChange({ value: $event, field: 'name' })"
          :placeholder="$t('projectView.products.addProductsDialog.productName')"
          hide-details
          class="pr-3"
        />
      </v-col>
      <v-col>
        <AppFieldMenuDatePicker
          :date="product.addedAt"
          @date:change="onProductChange({ value: $event, field: 'addedAt' })"
          :placeholder="$t('common.productFields.addedDate')"
          hide-details
          :clearable="false"
          class="pr-3"
        />
      </v-col>
      <v-col>
        <AppDefaultTextField
          :value="product.amount"
          @input="onProductChange({ value: $event, field: 'amount' })"
          :placeholder="$t('common.productFields.amount')"
          type="number"
          step="any"
          hide-details
          class="pr-3"
        >
          <template v-slot:append>
            <v-menu offset-y transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="underline-on-hover primary-link--text cursor-pointer text-no-wrap"
                >
                  {{ unitLabel || $t("projectView.products.addProductsDialog.setUnit") }}
                </span>
              </template>
              <div
                class="ui-background d-flex flex-column"
                style="min-width: 70px; max-height: 360px"
                v-if="units"
              >
                <AppMenuItem
                  v-for="(unit, index) in units"
                  :key="index"
                  :text="unit.label"
                  @click="onProductChange({ value: unit, field: 'unit' })"
                />
              </div>
            </v-menu>
          </template>
        </AppDefaultTextField>
      </v-col>

      <v-col>
        <AppCurrencyTextField
          :value="product.unitPrice"
          @input="onProductChange({ value: $event, field: 'unitPrice' })"
          class="pr-3"
          hide-details
          :placeholder="$t('common.productFields.unitPrice')"
          :disabled="hidePrices"
        />
      </v-col>
      <v-col>
        <AppCurrencyTextField disabled :value="total" hide-details />
      </v-col>
      <v-col v-if="!isEditMode" cols="1">
        <v-btn @click="onDelete" class="align-self-center ml-3" text icon color="error">
          <v-icon color="error">
            {{ $icons.LIGHT.ACTION.DELETE }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-2">
      <v-col :cols="isEditMode ? '12' : '11'">
        <AppDefaultTextarea
          :value="product.comment"
          @input="onProductChange({ value: $event, field: 'comment' })"
          hide-details
          :placeholder="$t('projectView.products.addProductsDialog.addCommentHere')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    product: Object,
    units: Array,
    isEditMode: Boolean,
    hidePrices: Boolean,
  },
  computed: {
    unitLabel() {
      return (this.product?.unit?.label ?? "").toUpperCase();
    },
    total() {
      return this.product.amount * this.product.unitPrice;
    },
  },
  methods: {
    onProductChange({ value, field }) {
      if (field === "amount") {
        value = this.formatAmount(value);
      }
      this.$emit("product:change", { ...this.product, [field]: value });
    },
    formatAmount(value) {
      let int = parseFloat(value);
      int = isNaN(value) ? null : value;
      return int;
    },
    onDelete() {
      this.$emit("delete:click");
    },
  },
};
</script>
