<template>
  <div class="d-flex justify-center">
    <v-card
      @click="onClick"
      :class="isSelected ? 'dialog__selected-card' : 'background'"
      :disabled="disabled"
      class="overflow-hidden"
      width="160"
      height="160"
      flat
    >
      <div style="position: absolute; right: 9px; top: 5px">
        <AppSubscriptionPlanStar
          v-if="!integration.canIntegrate"
          :planCode="integration.requiredPlanCode"
        />
        <v-icon
          v-else-if="!integration.isEnabled || integration.requireExternalAccountId"
        >
          {{ $icons.LIGHT.COMMON.WRENCH }}
        </v-icon>
      </div>

      <div class="w-100 h-100 d-flex align-center justify-center">
        <img
          :src="$constant.getIntegrationLogoUrl(integration.name)"
          style="width: 80%"
          :alt="$t(`integration.common.${integration.name}`)"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    isSelected: Boolean,
    integration: Object,
    disabled: Boolean,
  },
  methods: {
    onClick() {
      this.$emit("importCard:click", { integration: this.integration });
    },
  },
};
</script>
