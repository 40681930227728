import { normalizeUsers } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getSearchUsers({ params } = {}) {
  const defaultParams = {
    status: ["ACTIVE", "INVITED", "IMPORTED"],
  };

  // Merge default params with the provided ones, ensuring 'status' has default values if not provided
  const _params = { ...defaultParams, ...params };

  return axios
    .get(`${apiUrl}restricted/user`, { params: _params })
    .then(({ data }) => {
      const { users } = data;
      return normalizeUsers(users);
    })
    .catch((error) => {
      throw new Error(error);
    });
}
