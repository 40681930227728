import i18n from "@/plugins/i18n";
import { capitalize } from "@/helpers/filters";
import {
  CUSTOM_STATUS_CATEGORIES,
  CUSTOM_STATUS_CATEGORY_COLOR,
} from "@/helpers/constants/status";

export const chartTypes = [
  {
    text: i18n.t("chart.chartTypes.area"),
    value: "area",
  },
  {
    text: i18n.t("chart.chartTypes.bar"),
    value: "bar",
  },
  {
    text: i18n.t("chart.chartTypes.line"),
    value: "line",
  },
  {
    text: i18n.t("chart.chartTypes.pie"),
    value: "pie",
  },
  {
    text: i18n.t("chart.chartTypes.donut"),
    value: "donut",
  },
  {
    text: i18n.t("chart.chartTypes.polarArea"),
    value: "polarArea",
  },
  // { //Exclude radar chart for now as it probably wont give much value yet.
  //   text: i18n.t("chart.chartTypes.radar"),
  //   value: "radar",
  // },
  // { //Exclude treemap as it is not working as expected
  //   text: i18n.t("chart.chartTypes.treeMap"),
  //   value: "treemap",
  // },
];

export const chartDatasetOptions = [
  {
    text: capitalize(i18n.t("common.forms")),
    value: "FORM",
  },
  {
    text: capitalize(i18n.t("common.projects")),
    value: "PROJECT",
  },
  {
    text: capitalize(i18n.t("common.tasks")),
    value: "TASK",
  },
];

export const chartGroupingOptions = [
  {
    text: i18n.t("chart.chartGroupingOptions.STATUS_CATEGORY"),
    value: "STATUS_CATEGORY",
    axisType: "category",
  },
  {
    text: i18n.t("chart.chartGroupingOptions.CREATED_AT"),
    value: "CREATED_AT",
    axisType: "datetime",
  },
];

export const createdRangeOptions = [
  { text: i18n.t("chart.timeRanges.allTime"), value: "ALL_TIME" },
  { text: i18n.t("chart.timeRanges.lastYear"), value: "LAST_YEAR" },
  { text: i18n.t("chart.timeRanges.lastQuarter"), value: "LAST_QUARTER" },
  { text: i18n.t("chart.timeRanges.lastMonth"), value: "LAST_MONTH" },
  { text: i18n.t("chart.timeRanges.lastWeek"), value: "LAST_WEEK" },
];

export const dataPointIntervalOptions = [
  { text: i18n.t("chart.intervals.day"), value: "day" },
  { text: i18n.t("chart.intervals.week"), value: "week" },
  { text: i18n.t("chart.intervals.month"), value: "month" },
  { text: i18n.t("chart.intervals.quarter"), value: "quarter" },
  { text: i18n.t("chart.intervals.year"), value: "year" },
];

const chartCategoryGroupOptions = CUSTOM_STATUS_CATEGORIES.map((category) => ({
  text: i18n.t(`statuses.categories.${category}`),
  value: category,
  color: CUSTOM_STATUS_CATEGORY_COLOR[category],
}));

export const chartGroupOptions = {
  STATUS_CATEGORY: chartCategoryGroupOptions,
};
