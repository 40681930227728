<template>
  <div class="d-flex flex-column flex-grow-1 mw-0">
    <AppTextTruncateBlocking
      class="text-subtitle-2 font-weight-medium"
      :text="title"
      :disabled="true"
    />
    <AppTextTruncateBlocking
      class="text-caption"
      :text="subTitle"
      :disabled="disableSubTitleTooltip"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
    disableSubTitleTooltip: Boolean,
  },
};
</script>
