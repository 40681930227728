import {
  CONTACT_USER_ROUTE_NAMES,
  VALID_LANDING_PAGE_ROUTE_NAMES_AND_ICONS,
} from "../constants";
import store from "../../store";
import { ROUTE_NAMES } from "../routeNames";

export const fullname = ({ firstName, lastName }) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName) {
    return `${firstName}`;
  } else if (lastName) {
    return `${lastName}`;
  } else {
    return "";
  }
};

export const adminAccessByKey = ({ adminAccesses, key }) => {
  return (adminAccesses || []).find((f) => f.key === key);
};

export const adminAccessByKeyAndLevel = ({ adminAccesses, key, level }) => {
  return (adminAccesses || []).find((f) => f.key === key && f.level === level);
};

export const adminAccessByKeyLevelAndPartnerName = ({
  adminAccesses,
  key,
  level,
  partnerName,
}) => {
  return (adminAccesses || []).find(
    (f) => f.key === key && f.level === level && f.partnerName === partnerName,
  );
};

export const isAdmin = (user) => {
  if (!user?.currentDomain) return false;
  return user.currentDomain.isAdmin || user.currentDomain.isOwner;
};

export const isCmsAdmin = (adminAccesses) => {
  if (!adminAccesses) return false;
  return !!adminAccessByKeyAndLevel({
    adminAccesses,
    key: "CMS",
    level: "ADMIN",
  });
};

export const isGriprCmsUser = (adminAccesses) => {
  return !!adminAccessByKeyLevelAndPartnerName({
    adminAccesses,
    key: "CMS",
    level: "ADMIN",
    partnerName: "GRIPR",
  });
};

export const currentRootRouteObject = (user) => {
  if (!Array.isArray(user.metaDomainUser)) return null;
  return user.metaDomainUser.find((m) => m.field === "LANDING_PAGE") || null;
};

export const currentRootRouteName = (user) => {
  const routeName = currentRootRouteObject(user)?.value;
  return isValidRootRouteName({ user, routeName }) ? routeName : getFallbackRouteName();
};

const getFallbackRouteName = () =>
  isHighestPriorityRoleContact() ? ROUTE_NAMES.PROJECT.ROOT : ROUTE_NAMES.DASHBOARD.ROOT;

export const isValidRootRouteName = ({ user, routeName }) => {
  if (!user || !routeName) return false;

  let validRouteNames = Object.values(VALID_LANDING_PAGE_ROUTE_NAMES_AND_ICONS).map(
    (f) => f.name,
  );
  if (isHighestPriorityRoleContact()) {
    validRouteNames = CONTACT_USER_ROUTE_NAMES;
  }

  if (!validRouteNames.includes(routeName)) return false;
  if (routeName === ROUTE_NAMES.TEMPLATE.ROOT && !isAdmin(user)) return false;
  return true;
};

export const isHighestPriorityRoleContact = () => {
  const isHighestPriorityRoleContact =
    store?.getters["auth/isHighestPriorityRoleContact"];
  return isHighestPriorityRoleContact;
};
