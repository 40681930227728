import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, contactUserRedirectGuard, userGuard } from "../../auth/authGuard";
import multiguard from "../multiguard";

const { ROOT, CONTACT_PERSON_LIST, CLIENT_LIST } = ROUTE_NAMES.CONTACT;

export const contactRoutes = [
  {
    path: "/contact",
    name: ROOT,
    beforeEnter: multiguard([authGuard, userGuard(), contactUserRedirectGuard]),
    component: () => import(/* webpackChunkName: "contact" */ "../../pages/ContactsPage"),
    redirect: { name: CONTACT_PERSON_LIST },
    children: [
      {
        path: "contact-person-list",
        name: CONTACT_PERSON_LIST,
        component: () =>
          import(
            /* webpackChunkName: "contact" */ "../../pages/ContactsPage/ContactPersonListPage"
          ),
      },
      {
        path: "client-list",
        name: CLIENT_LIST,
        component: () =>
          import(
            /* webpackChunkName: "contact" */ "../../pages/ContactsPage/ClientListPage"
          ),
      },
    ],
  },
];
