<script>
import { VDivider } from "vuetify/lib";

export default {
  props: {
    item: Object,
  },
  render(h) {
    return h(
      "div",
      {
        class:
          "d-flex rounded border-a mt-2 mb-2 input-background--background align-center",
        style: "height: 40px",
      },
      [
        h(
          "div",
          {
            style: "width: 70%;",
            class: "pl-3",
          },
          this.$t("common.question"),
        ),
        h(VDivider, { props: { vertical: true } }),
        h(
          "div",
          {
            class: `pl-3`,
            style: "width: 30%;",
          },
          this.$t("common.responseType"),
        ),
      ],
    );
  },
};
</script>
