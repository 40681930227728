export function normalizeFormItems(items) {
  const normalizedItems = (items || []).map((item) => normalizeFormItem(item));
  return normalizedItems;
}

export function normalizeFormItem(item) {
  return {
    ...item,
    parentId: item?.parentId || null,
    imagesLoading: false,
    imagesUploading: false,
    images: (item?.images || []).map((image) => ({ id: image, url: null })),
    tasks: (item?.tasks || []).map((task) => ({
      ...task,
      isUpdatingStatus: false,
      member: task?.members ? task.members[0] : null,
    })),
  };
}
