import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, userGuard } from "../../auth/authGuard";
import multiguard from "../multiguard";
import { makeBeforeEnter, redirectFromLocalStorage } from "../redirect";

const { ROOT, LIST, CALENDAR } = ROUTE_NAMES.TASK;

const taskLocalStorageKey = "REDIRECT_ROUTE:task";
const beforeEnterTask = makeBeforeEnter({ localStorageKey: taskLocalStorageKey });

export const taskRoutes = [
  {
    path: "/task",
    name: ROOT,
    beforeEnter: multiguard([authGuard, userGuard()]),
    component: () =>
      import(/* webpackChunkName: "task" */ "../../pages/TaskManagement/Tasks"),
    redirect: () =>
      redirectFromLocalStorage({
        localStorageKey: taskLocalStorageKey,
        validRoutes: [LIST.ROOT, CALENDAR.ROOT],
        defaultRoute: LIST.ROOT,
      }),
    children: [
      {
        path: "list",
        name: LIST.ROOT,
        beforeEnter: beforeEnterTask,
        component: () =>
          import(
            /* webpackChunkName: "task" */ "../../pages/TaskManagement/Tasks/TaskList"
          ),
        children: [
          {
            path: "menu/:taskId",
            name: LIST.MENU.ROOT,
            component: () =>
              import(
                /* webpackChunkName: "task" */ "../../pages/TaskManagement/Tasks/MenuPage"
              ),
            children: [
              {
                path: "details",
                name: LIST.MENU.DETAILS,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/DetailsPage"
                  ),
              },
              {
                path: "history",
                name: LIST.MENU.HISTORY,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/HistoryPage"
                  ),
              },
              {
                path: "to-do-list",
                name: LIST.MENU.TO_DO_LIST,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/ToDoList"
                  ),
              },
              {
                path: "storage/:folderId",
                name: LIST.MENU.STORAGE,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/StoragePage"
                  ),
              },
              {
                path: "forms",
                name: LIST.MENU.FORMS,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/FormPage"
                  ),
              },
              {
                path: "product-usage",
                name: LIST.MENU.PRODUCT_USAGE,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/ProductUsagePage"
                  ),
              },
              {
                path: "comments",
                name: LIST.MENU.COMMENTS,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/CommentsPage"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "calendar",
        name: CALENDAR.ROOT,
        beforeEnter: beforeEnterTask,
        component: () =>
          import(
            /* webpackChunkName: "task" */ "../../pages/TaskManagement/Tasks/TaskCalendar"
          ),
        children: [
          {
            path: "menu/:taskId",
            name: CALENDAR.MENU.ROOT,
            component: () =>
              import(
                /* webpackChunkName: "task" */ "../../pages/TaskManagement/Tasks/MenuPage"
              ),
            children: [
              {
                path: "details",
                name: CALENDAR.MENU.DETAILS,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/DetailsPage"
                  ),
              },
              {
                path: "history",
                name: CALENDAR.MENU.HISTORY,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/HistoryPage"
                  ),
              },
              {
                path: "to-do-list",
                name: CALENDAR.MENU.TO_DO_LIST,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/ToDoList"
                  ),
              },
              {
                path: "storage/:folderId",
                name: CALENDAR.MENU.STORAGE,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/StoragePage"
                  ),
              },
              {
                path: "forms",
                name: CALENDAR.MENU.FORMS,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/FormPage"
                  ),
              },
              {
                path: "product-usage",
                name: CALENDAR.MENU.PRODUCT_USAGE,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/ProductUsagePage"
                  ),
              },
              {
                path: "comments",
                name: CALENDAR.MENU.COMMENTS,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/CommentsPage"
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
];
