<template>
  <div>
    <div class="pb-2 pt-6">
      <span class="text-h5 font-weight-bold">
        {{ $t("formManagement.signatureSession.signature.title") }}
      </span>
    </div>

    <v-alert class="mb-4" text dismissible dense color="info">
      <span class="text-body-2">
        {{
          $t("formManagement.signatureSession.signature.info", {
            senderName,
            companyName,
          })
        }}
      </span>
    </v-alert>

    <div class="pb-4">
      <div class="label--small">
        {{ $t("formManagement.signatureSession.signature.yourFullName") }}
      </div>
      <AppDefaultTextField
        :dense="false"
        :value="signerName"
        @input="updateSignerField({ field: 'name', value: $event })"
        hide-details
      />
    </div>

    <div class="pb-4">
      <div class="label--small">
        {{ $t("formManagement.signatureSession.signature.title") }}
      </div>
      <div class="border-a rounded" style="min-height: 140px">
        <div
          class="w-100 input-background d-flex justify-center align-center rounded"
          style="min-height: 140px"
        >
          <span
            class="text-center"
            :class="signerName ? 'signature-text' : 'text-body-1'"
          >
            {{
              signerName || $t("formManagement.signature.signatureFieldPlaceholderText")
            }}
          </span>
        </div>
      </div>
    </div>

    <div class="pb-4">
      <div class="label--small">
        {{ $t("formManagement.signatureSession.signature.date") }}
      </div>
      <SigningDateField
        :date="signerDate"
        @date:change="updateSignerField({ field: 'date', value: $event })"
      />
    </div>

    <div>
      <div class="label--small">
        {{ $t("formManagement.signatureSession.signature.comment") }}
      </div>
      <AppDefaultTextarea
        :dense="false"
        :value="signerComment"
        @input="updateSignerField({ field: 'comment', value: $event })"
        hide-details
      />
    </div>
  </div>
</template>

<script>
import SigningDateField from "../completion/SigningDateField.vue";
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("formSignSessionInProgress", {
      signer: (state) => state.signerData,
    }),
    ...mapGetters("formSignSessionInProgress", {
      company: "company",
      sender: "sender",
    }),
    signerName() {
      return this.signer?.name;
    },
    signerDate() {
      return this.signer?.date;
    },
    signerComment() {
      return this.signer?.comment;
    },
    senderName() {
      return this.sender?.name || this.sender?.email;
    },
    companyName() {
      return this.company?.name;
    },
  },
  methods: {
    updateSignerField({ field, value }) {
      this.$store.commit("formSignSessionInProgress/updateSignerField", { field, value });
    },
  },
  components: { SigningDateField },
};
</script>
