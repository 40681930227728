<template>
  <AppRemindDialog
    v-model="localDialog"
    :items="recipients"
    :getStatusText="getRecipientStatusText"
    :getStatusColor="getRecipientStatusColor"
    :inProgressStatus="$constant.ACTION_REQUEST_STATUS.IN_PROGRESS"
    @submit="handleSubmit"
  />
</template>

<script>
import { remindActionRequest } from "@/services/actionRequest/actionRequest";

export default {
  props: {
    dialog: Boolean,
    actionRequestId: String,
    recipients: Array,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(dialog) {
        this.$emit("dialog:change", dialog);
      },
    },
  },
  methods: {
    getRecipientStatusColor(recipient) {
      const colorMap = {
        [this.$constant.ACTION_REQUEST_STATUS.IN_PROGRESS]: "warning",
        [this.$constant.ACTION_REQUEST_STATUS.COMPLETED]: "success",
        [this.$constant.ACTION_REQUEST_STATUS.DECLINED]: "error",
      };
      return `${colorMap[recipient.status] || "primary"}--text`;
    },
    getRecipientStatusText(recipient) {
      return this.$t(`actionRequest.status.${recipient.status}`);
    },
    handleSubmit({ emails, message }) {
      remindActionRequest({
        id: this.actionRequestId,
        body: {
          emails,
          comment: message,
        },
      })
        .then(() => {
          this.$emit("submit");
          this.localDialog = false;
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
  },
};
</script>
