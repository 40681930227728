var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.displayData.text)?_c('div',{staticClass:"d-flex flex-row pt-1"},[_c('div',{staticClass:"d-flex justify-center",style:({
      width: _vm.highestIconSize + 'px',
    })},[(_vm.isImageIcon)?_c('img',{style:({
        width: _vm.displayData.iconSize + 'px',
        height: _vm.displayData.iconSize + 'px',
        marginRight: '4px',
      }),attrs:{"src":_vm.displayData.icon}}):_c('v-icon',{attrs:{"size":_vm.displayData.iconSize}},[_vm._v(" "+_vm._s(_vm.displayData.icon)+" ")])],1),_c('span',{style:({
      color: 'disabled--text text-truncate',
      marginLeft: '6px',
      fontSize: '12px',
    }),domProps:{"innerHTML":_vm._s(_vm.highlightedText)}})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }