<template>
  <div>
    <div class="label--small" style="padding-bottom: 0px !important">
      {{ label | capitalize }}
    </div>
    <v-menu
      ref="menu"
      v-model="monthMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-layout v-on="on" v-bind="attrs">
          <AppDefaultTextField
            style="width: 220px"
            class="date-field-cursor-pointer text-centered-input"
            :value="
              dateMixins_monthText(localValue) + ' ' + dateMixins_yearText(localValue)
            "
            :prepend-inner-icon="$icons.LIGHT.COMMON.CHEVRON_LEFT"
            @click:prepend-inner="onPrevMonth"
            :append-icon="$icons.LIGHT.COMMON.CHEVRON_RIGHT"
            @click:append="onNextMonth"
            :label="placeholder"
            :clearable="clearable"
            :hide-details="hideDetails"
            readonly
          />
        </v-layout>
      </template>

      <div>
        <v-date-picker
          v-model="localValue"
          type="month"
          no-title
          @input="monthMenu = false"
          :first-day-of-week="dateMixins_firstDayOfWeek"
          show-week
          locale-first-day-of-year="4"
          color="primary"
        />
      </div>
    </v-menu>
  </div>
</template>

<script>
import * as moment from "moment";
import { dateMixins } from "@/helpers/mixins";
export default {
  mixins: [dateMixins],
  props: {
    label: String,
    value: String,
    placeholder: String,
    hideDetails: Boolean,
    disabled: Boolean,
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      monthMenu: false,
    };
  },
  computed: {
    localValue: {
      get() {
        const month = this.value ? moment(this.value).format("YYYY-MM") : null;
        return month;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
  methods: {
    onPrevMonth() {
      this.localValue = this.dateMixins_decrementMonth(this.localValue);
    },
    onNextMonth() {
      this.localValue = this.dateMixins_incrementMonth(this.localValue);
    },
  },
};
</script>
