<template>
  <div class="p-relative" :class="{ 'hide-details-compensation': hideDetails }">
    <div class="label--small d-flex">
      {{ $t(labelKey || "common.task") | capitalize }}
      <v-spacer></v-spacer>
      <AppAutocompleteOpenInNewTabBtn v-if="!multiple" :id="$attrs.value" type="form" />
      <AppDefaultTooltip top v-if="$attrs.showHelper">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small class="pr-1 cursor-pointer">
            {{ $icons.LIGHT.COMMON.CIRCLE_QUESTION }}
          </v-icon>
        </template>
        {{ $t("common.formsFilteredByProjectsHelper") }}
      </AppDefaultTooltip>
    </div>
    <AppFormAutocomplete ref="autocomplete" v-bind="params" v-on="listeners" />
  </div>
</template>

<script>
export default {
  props: {
    labelKey: String,
    hideDetails: Boolean,
    multiple: Boolean,
    type: String,
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
    listeners() {
      const listeners = {
        ...this.$listeners,
      };
      return listeners;
    },
  },
  methods: {
    focus() {
      this.$refs.autocomplete.focus();
    },
    activateMenu() {
      this.$refs.autocomplete.activateMenu();
    },
  },
};
</script>
