<template>
  <v-menu
    style="z-index: 200"
    ref="menu"
    v-model="menu.value"
    :position-x="menu.x"
    :position-y="menu.y"
    absolute
    offset-y
    :top="menuPosition.top"
    :bottom="menuPosition.bottom"
    :left="menuPosition.left"
    :right="menuPosition.right"
    :nudge-top="menuNudge.top"
    :nudge-right="menuNudge.right"
    :nudge-bottom="menuNudge.bottom"
    :nudge-left="menuNudge.left"
    :closeOnContentClick="closeOnContentClick"
    @input="$emit('input', $event)"
    transition="custom-menu-transition"
    v-bind="$attrs"
  >
    <div
      :class="`${hideDefaultPadding ? '' : 'pa-2'} ${backgroundColor}`"
      style="min-width: 70px"
    >
      <div
        :style="{ height: `${height}px`, width: `${width}px`, maxWidth: `${width}px` }"
        class="d-flex flex-column"
      >
        <slot></slot>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    hideDefaultPadding: Boolean,
    backgroundColor: {
      type: String,
      default: "ui-background",
    },
    closeOnContentClick: {
      type: Boolean,
      default: true,
    },
    height: String,
    width: String,
  },
  data() {
    return {
      menu: {
        value: false,
        x: 0,
        y: 0,
        element: null,
      },
      menuPosition: {
        top: false,
        bottom: false,
        left: false,
        right: false,
      },
      menuNudge: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    };
  },
  watch: {
    "menu.value"(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    open({ from, direction = "bottom", nudge = 0 }) {
      //from can be pointerEvent, element or {x, y}, it handles itself automatically based on whats passed
      const x = from?.x ?? from?.clientX;
      const y = from?.y ?? from?.clientY;

      if (x && y) {
        this.openFromCoordinates({ pointerEvent: from, x, y, direction });
      } else if (from instanceof Element) {
        this.openFromElement({ element: from, direction, nudge });
      }
      this.toggleMenu();
    },

    openFromCoordinates({ pointerEvent, x, y, direction = "bottom" }) {
      this.resetMenu();
      pointerEvent?.preventDefault?.();
      const finalX = x ?? pointerEvent?.clientX;
      const finalY = y ?? pointerEvent?.clientY;

      this.menu.x = finalX;
      this.menu.y = finalY;
      this.menuPosition[direction] = true;
    },

    openFromElement({ element, direction = "bottom", nudge = 0 }) {
      this.resetMenu();
      this.menu.element = element;
      const rect = element.getBoundingClientRect();
      const { left, top, right, bottom, width, height } = rect;

      switch (direction) {
        case "top":
          this.menu.x = left;
          this.menu.y = top;
          this.menuPosition.top = true;
          this.menuNudge.top = nudge;
          break;
        case "right":
          this.menu.x = right;
          this.menu.y = top;
          this.menuPosition.right = true;
          this.menuNudge.right = nudge;
          break;
        case "bottom":
          this.menu.x = left;
          this.menu.y = bottom;
          this.menuPosition.bottom = true;
          this.menuNudge.bottom = nudge;
          break;
        case "left":
          this.menu.x = left;
          this.menu.y = top;
          this.menuPosition.left = true;
          this.menuNudge.left = nudge;
          break;
        case "start":
          this.menu.x = left;
          this.menu.y = top;
          break;
      }
    },

    resetMenu() {
      this.menuPosition.top = false;
      this.menuPosition.right = false;
      this.menuPosition.bottom = false;
      this.menuPosition.left = false;
      this.menuNudge.top = 0;
      this.menuNudge.right = 0;
      this.menuNudge.bottom = 0;
      this.menuNudge.left = 0;
    },

    toggleMenu() {
      if (this.menu.value) {
        this.menu.value = false;
        setTimeout(() => {
          this.menu.value = true;
        }, 10);
      } else {
        this.menu.value = true;
      }
    },

    close() {
      this.menu.value = false;
      return { x: this.menu.x, y: this.menu.y, element: this.menu.element };
    },

    updateDimensions() {
      this.$refs.menu.updateDimensions();
    },
  },
};
</script>
