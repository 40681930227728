export const statusMixins = {
  data() {
    return {
      statusMixins_timeEntriesStatusItems: [
        {
          value: this.$constant.TIME_ENTRY_STATUS.APPROVED,
          text: this.$t("statuses.timeEntry.APPROVED"),
          color: "success",
        },
        {
          value: this.$constant.TIME_ENTRY_STATUS.PENDING,
          text: this.$t("statuses.timeEntry.PENDING"),
          color: "warning",
        },
        {
          value: this.$constant.TIME_ENTRY_STATUS.DECLINED,
          text: this.$t("statuses.timeEntry.DECLINED"),
          color: "error",
        },
      ],
      //keep these around for for log
      statusMixins_progressStatusItems: [
        {
          value: this.$constant.PROGRESS_STATUS.NOT_STARTED,
          text: this.$t("statuses.progress.NOT_STARTED"),
          color: "subtitle",
        },
        {
          value: this.$constant.PROGRESS_STATUS.IN_PROGRESS,
          text: this.$t("statuses.progress.IN_PROGRESS"),
          color: "primary-link",
        },
        {
          value: this.$constant.PROGRESS_STATUS.DONE,
          text: this.$t("statuses.progress.DONE"),
          color: "success",
        },
      ],
      statusMixins_categories: this.$constant.CUSTOM_STATUS_CATEGORIES,
    };
  },
  methods: {
    statusMixins_tooltip(model) {
      if (model === "task") return this.$t("statuses.edit.taskTitle");
      if (model === "form") return this.$t("statuses.edit.formTitle");
      if (model === "project") return this.$t("statuses.edit.projectTitle");
    },
    statusMixins_moduleDefinitePlural(model) {
      if (model === "task") return this.$t("common.tasksDefinitePlural");
      if (model === "form") return this.$t("common.formsDefinitePlural");
      if (model === "project") return this.$t("common.projectsDefinitePlural");
    },
    statusMixins_statusTranslation(status, items) {
      const statusItem = this.statusMixins_getItemByValue(status, items);
      if (statusItem === undefined) return this.$t("common.noStatus");
      return statusItem.text;
    },
    statusMixins_statusColor(status, loading, items) {
      if (loading) return "";
      const statusItem = this.statusMixins_getItemByValue(status, items);
      if (statusItem === undefined) return "";
      return statusItem.color;
    },
    statusMixins_getItemByValue(status, items) {
      return items.find((s) => s.value === status);
    },
  },
};
