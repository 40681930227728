import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function copyStorages({ body }) {
  return axios
    .post(`${apiUrl}storage/copy`, body)
    .then(({ storages }) => {})
    .catch((error) => {
      throw new Error(error);
    });
}
