<template>
  <tr
    class="list_tr list_tr-height"
    :class="rowStateClasses"
    @contextmenu="dataTableItemMixins_onRightClick"
    @click="select(!isSelected)"
  >
    <td>
      <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)" />
    </td>
    <td>
      <div class="table-cell-content__breakable-long-text">
        <span
          class="d-inline-block underline-on-hover cursor-pointer"
          @click.stop="onTemplateEditClick"
        >
          {{ template.name }}
        </span>
      </div>
    </td>
    <td>
      <AppColorChip
        :text="$t(template.isPublished ? 'common.published' : 'common.unpublished')"
        :color="template.isPublished ? 'success--text' : 'error--text'"
      />
    </td>
    <td v-if="!hideCategory">
      <AppColorChip
        v-if="template.category"
        :text="
          $te(`formManagement.category.${template.category.name}`)
            ? $t(`formManagement.category.${template.category.name}`)
            : template.category.name
        "
        color="info--text"
      />
    </td>
    <td class="text-start text-no-wrap">
      {{ new Date(template.updatedAt) | dateFormat("DD.MM.YYYY HH:mm") }}
    </td>
    <td class="text-end">
      <v-btn icon @click="dataTableItemMixins_onEllipsisClick" ref="ellipsis">
        <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { dataTableItemMixins } from "@/helpers/mixins";
export default {
  mixins: [dataTableItemMixins],
  props: {
    template: Object,
    isHighlighted: Boolean,
    hideCategory: Boolean,
    isSelected: Boolean,
    select: Function,
    isContextMenuActiveItem: Boolean,
  },
  computed: {
    rowStateClasses() {
      return {
        "list_tr--active": this.isContextMenuActiveItem,
        "list_tr--selected": this.isSelected,
      };
    },
  },
  methods: {
    onTemplateEditClick() {
      this.$emit("edit:click", { items: [this.template] });
    },
    openMenu(params) {
      this.$emit("menu:click", { template: this.template, ...params });
    },
  },
};
</script>
