import axios from "axios";
import { normalizeForm, normalizeForms } from "@/helpers/normalization";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  forms: [],
  isLoading: false,
  isConverting: false,
});

const getters = {};

const actions = {
  async getForms({ commit }, { taskId }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}form`, {
        params: { taskId },
      })
      .then(({ data }) => {
        const forms = data?.forms;
        const normalizedForms = normalizeForms(forms);
        commit("setForms", normalizedForms);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async convertTemplate({ commit, rootState }, { id, body }) {
    commit("converting", true);
    return axios
      .post(`${apiUrl}template/form/${id}/convert`, { ...body })
      .then(({ data }) => {
        const form = data?.form;
        if (form) {
          const normalizedForm = normalizeForm(form);
          commit("addForm", normalizedForm);
        }
        commit("converting", false);
        return data?.form;
      })
      .catch((error) => {
        commit("converting", false);
        throw new Error(error);
      });
  },
  async updateForm({ commit, rootState }, { id, body, filters }) {
    if (typeof body?.statusId === "string") {
      commit("toggleUpdatingStatus", {
        id,
        isUpdatingStatus: true,
      });
    }
    return axios
      .put(`${apiUrl}form/${id}`, { ...body })
      .then(({ data }) => {
        const form = data?.form;
        if (form) {
          const normalizedForm = normalizeForm(form);
          commit("updateForm", { form: normalizedForm, filters });
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("toggleUpdatingStatus", {
          id,
          isUpdatingStatus: false,
        });
      });
  },
  async deleteForm({ commit }, { id }) {
    return axios
      .delete(`${apiUrl}form/${id}`)
      .then(({ data }) => {
        const form = data?.form;
        if (form) {
          commit("removeForm", form);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  resetState({ commit }) {
    commit("setForms", []);
  },
};

const mutations = {
  setForms(state, forms) {
    state.forms = forms;
  },
  addForm(state, form) {
    state.forms.push(form);
  },
  updateForm(state, { form, filters }) {
    const index = state.forms.findIndex((x) => x.id === form.id);
    if (index !== -1) {
      if (filters?.projectId) {
        if (filters.projectId !== form.projectId) {
          state.forms.splice(index, 1);
          return;
        }
      }
      state.forms.splice(index, 1, form);
    }
  },
  removeForm(state, form) {
    const index = state.forms.findIndex((x) => x.id === form.id);
    if (index !== -1) {
      state.forms.splice(index, 1);
    }
  },
  toggleUpdatingStatus(state, { id, isUpdatingStatus }) {
    const index = state.forms.findIndex((x) => x.id === id);
    if (index !== -1) {
      if (state.forms[index]) {
        state.forms.splice(index, 1, { ...state.forms[index], isUpdatingStatus });
      }
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  converting(state, isConverting) {
    state.isConverting = isConverting;
  },
};

export const taskForms = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
