<template>
  <AppContextMenu
    ref="menu"
    v-on="$listeners"
    v-bind="$attrs"
    height="440"
    width="300"
    :closeOnContentClick="false"
  >
    <AppClientAutocomplete
      autofocus
      activateOnFocus
      resetOnSubmit
      isReadyToInitialFetch
      :maxWidthMultiplier="1"
      :type="type"
      :queryParams="queryParams"
      @change="
        (id) => {
          onSubmit({ clientId: id });
        }
      "
      @created="
        ({ client }) => {
          onSubmit({ clientId: client.id });
        }
      "
    />
  </AppContextMenu>
</template>

<script>
export default {
  props: {
    type: String,
    queryParams: Object,
  },
  methods: {
    onSubmit({ clientId }) {
      this.$emit("submit", { clientId });
      this.close();
    },
    close() {
      this.$refs.menu.close();
    },
    open(e) {
      this.$refs.menu.open(e);
    },
  },
};
</script>
