<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ title }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="editFileForm"
          v-model="file.valid"
          v-on:submit.prevent
          @submit="onSubmit"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("fileManagement.fileExplorer.newOfficeFile.label") }}
            </div>
            <AppDefaultTextField
              ref="editFileFormName"
              v-model="file.name.value"
              :rules="$rules.NAME_RULES"
              :counter="$rules.MAX_CHARACTERS"
              autofocus
              :suffix="extension"
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="onClose"
          :confirmText="$t('common.create')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    type: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    title() {
      if (this.type === "DOCX") {
        return this.$t("fileManagement.header.createNewWord");
      } else if (this.type === "XLSX") {
        return this.$t("fileManagement.header.createNewExcel");
      } else if (this.type === "PPTX") {
        return this.$t("fileManagement.header.createNewPowerPoint");
      }
    },
    extension() {
      if (this.type === "DOCX") {
        return ".docx";
      } else if (this.type === "XLSX") {
        return ".xlsx";
      } else if (this.type === "PPTX") {
        return ".pptx";
      }
    },
  },
  data() {
    return {
      file: {
        valid: false,
        name: {
          value: "",
        },
      },
    };
  },
  methods: {
    closeDialog() {
      this.localDialog = false;
    },
    onClose() {
      this.closeDialog();
      this.$refs.editFileForm.resetValidation();
    },
    onSubmit() {
      const valid = this.$refs.editFileForm.validate();
      if (!valid) return;

      const data = {
        name: `${this.file.name.value}${this.extension}`,
      };
      this.$emit("submit", data);
      this.closeDialog();
      this.$refs.editFileForm.reset();
    },
  },
};
</script>
