<template>
  <div class="h-100vh overflow-y-auto">
    <div class="page-container">
      <AppClientDialog
        v-model="dialog.newClient.active"
        :title="$t('contact.client.newClient.title')"
        :confirmText="$t('common.create')"
      />
      <AppContactDialog
        v-model="dialog.newContact.active"
        :title="$t('contact.contactPerson.newContact.title')"
        :confirmText="$t('common.create')"
      />
      <AppAddContactDialog
        v-model="dialog.addContact.active"
        :inData="{
          clientIds: JSON.parse(JSON.stringify(project?.clientIds || [])),
          contactIds: JSON.parse(JSON.stringify(project?.contactIds || [])),
        }"
        @submit="onAddContacts"
      />
      <AppImportDialog
        v-if="showImport"
        v-model="dialog.import.active"
        @import:success="onClientsImportSuccess"
        :importType="importType"
      />
      <AppPageHeader
        :title="$options.filters.capitalize($t('contact.title'))"
        :actionBtnText="
          $t(projectId ? 'contact.addContact.title' : 'contact.client.newClient.button')
        "
        :isActionBtnDisabled="disableActionBtn"
        large
        show-append
        show-action-btn
        @action:click="onCreateNew"
      >
        <template v-slot:append>
          <div class="d-flex align-center">
            <AppFiltersBtn @click="toggleFiltersDrawer" :tableModel="tableModel" />
            <AppToggleMoreBtn>
              <AppMenuItem
                v-if="showImport"
                :icon="$icons.REGULAR.ACTION.DOWNLOAD"
                :text="importBtnText"
                @click="onImportClick"
              />
              <AppMenuItem
                :text="$t('common.downloadExcel')"
                :icon="$icons.REGULAR.FILE.FILE_EXCEL"
                :disabled="isLoadingTableData"
                @click="createContactReport"
              />
            </AppToggleMoreBtn>
          </div>
        </template>
      </AppPageHeader>
      <AppDefaultTabs :tabs="tabs" tabId="contact-page" @click="onTabClick" />
      <router-view :tableModel="tableModel" @data:get="getData" />
      <template v-if="isModelContacts">
        <AppContactsFiltersDrawer v-model="showDrawer" :tableModel="tableModel" />
      </template>
      <template v-if="isModelClients">
        <AppClientsFiltersDrawer v-model="showDrawer" :tableModel="tableModel" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { generateReport } from "@/services/reportGenerator/generateRaport";
import { filtersMixins } from "@/helpers/mixins";

export default {
  mixins: [filtersMixins],
  props: {
    projectId: String,
    tabs: {
      type: Array,
      required: true,
    },
    disableActionBtn: Boolean,
  },
  data() {
    return {
      showDrawer: false,
      searchDebounce: null,
      dialog: {
        newClient: {
          active: false,
        },
        newContact: {
          active: false,
        },

        addContact: {
          active: false,
        },
        import: { active: false },
      },
    };
  },
  watch: {
    projectId: {
      handler(projectId) {
        if (projectId) {
          this.refreshData();
        }
      },
    },
  },
  computed: {
    ...mapState("project", {
      project: (state) => state.project,
    }),
    ...mapState("clients", {
      clients: (state) => state.clients,
      isLoadingClients: (state) => state.isLoading,
    }),
    ...mapState("contacts", {
      isLoadingContacts: (state) => state.isLoading,
    }),
    ...mapGetters("auth", {
      isDomainOwner: "isDomainOwner",
      isDomainAdmin: "isDomainAdmin",
    }),
    tableModel() {
      const currentRouteName = this.$route.name;
      return this.tabs.find((tab) => tab.to.name === currentRouteName).tableModel;
    },
    showImport() {
      return (this.isDomainOwner || this.isDomainAdmin) && !this.projectId;
    },
    isModelContacts() {
      return this.getTabByKey("contacts").tableModel === this.tableModel;
    },
    isModelClients() {
      return this.getTabByKey("clients").tableModel === this.tableModel;
    },
    importBtnText() {
      if (this.isModelContacts) {
        return this.$t("integration.CONTACT.IMPORT.title");
      } else if (this.isModelClients) {
        return this.$t("integration.CLIENT.IMPORT.title");
      }
    },
    importType() {
      if (this.isModelContacts) {
        return this.$constant.IMPORT_TYPES.CONTACT;
      } else if (this.isModelClients) {
        return this.$constant.IMPORT_TYPES.CLIENT;
      }
    },
    isLoadingTableData() {
      if (this.isModelContacts) {
        return this.isLoadingContacts || this.filtersMixins_isLoadingFilters;
      } else if (this.isModelClients) {
        return this.isLoadingClients || this.filtersMixins_isLoadingFilters;
      }
    },
  },
  methods: {
    getTabByKey(key) {
      return this.tabs.find((tab) => tab.key === key);
    },
    onTabClick() {
      if (this.isLoadingTableData) return;
      this.filtersMixins_getFilters();
    },
    onImportClick() {
      this.dialog.import.active = true;
    },
    toggleFiltersDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    createContactReport() {
      const type = this.isModelClients ? "client" : "contact";
      generateReport({ reportKey: type, filters: this.filtersMixins_filters });
    },
    importAccountingUsers({ accounting }) {
      return importAccountingUsers({ accounting });
    },
    getClients() {
      this.$store.dispatch("clients/getClients", {
        filters: this.filtersMixins_filters,
        dataTableOptions: this.filtersMixins_dataTableOptions,
        projectId: this.projectId,
      });
    },
    getContacts() {
      this.$store.dispatch("contacts/getContacts", {
        filters: this.filtersMixins_filters,
        dataTableOptions: this.filtersMixins_dataTableOptions,
        projectId: this.projectId,
      });
    },
    onCreateNew() {
      if (this.projectId) {
        this.openAddContacts();
      } else if (this.isModelClients) {
        this.onNewClient();
      } else if (this.isModelContacts) {
        this.onNewContactPerson();
      }
    },
    openAddContacts() {
      this.dialog.addContact.active = true;
    },
    onNewClient() {
      this.dialog.newClient.active = true;
    },
    onNewContactPerson() {
      this.dialog.newContact.active = true;
    },
    onClientsImportSuccess() {
      this.getData();
    },
    refreshData() {
      this.resetState();
      this.getData();
    },
    getData() {
      if (this.isModelClients) {
        this.getClients();
      } else if (this.isModelContacts) {
        this.getContacts();
      }
    },
    onAddContacts({ data, afterCreation }) {
      this.$store
        .dispatch("project/updateProject", {
          id: this.projectId,
          body: data,
        })
        .then(this.getData)
        .finally(afterCreation);
    },
    resetState() {
      this.$store.dispatch("clients/resetState");
      this.$store.dispatch("contacts/resetState");
    },
  },
  destroyed() {
    this.resetState();
  },
};
</script>
