<template>
  <input
    ref="fileInput"
    class="d-none"
    type="file"
    :accept="acceptedTypes"
    @change="handleFileSelect"
    multiple
  />
</template>

<script>
export default {
  props: {
    acceptedTypes: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleFileSelect(event) {
      const files = event.target.files;
      if (files && files.length > 0) {
        this.$emit("fileSelect", files);
      }
      // Reset the input value to allow selecting the same file again
      this.$refs.fileInput.value = null;
    },
    triggerFileSelect() {
      this.$refs.fileInput.click();
    },
  },
};
</script>
