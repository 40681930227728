<template>
  <div>
    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.deleteForm.active"
      :item="dialog.deleteForm.item"
      :title="
        $tc('formManagement.deleteConfirmation.title', dialog?.deleteForm?.item?.length)
      "
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('fileManagement.fileExplorer.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="formsDelete"
    />
    <AppDataTableServerSidePagination
      :loading="isLoading"
      :headers="_headers"
      :items="forms"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :noDataText="$t('common.noForms')"
      :columnsToFreeze="columnsToFreeze"
      :groupHeaderColumnsToFreeze="2"
      :getAllItems="getAllItems"
      :bulkActionConfigs="bulkActionConfigs"
      removeMutationType="forms/removeForm"
      updateMutationType="forms/updateForm"
      :statsText="statsText"
      :isStatsLoading="isStatsLoading"
      show-select
    >
      <template
        v-slot:group.header="{
          toggle,
          group,
          isOpen,
          headers,
          isGroupIndeterminate,
          isGroupSelected,
          toggleGroup,
        }"
      >
        <td @click="toggle">
          <v-simple-checkbox
            :indeterminate="isGroupIndeterminate"
            :value="isGroupSelected"
            @input="toggleGroup"
          />
        </td>
        <td :colspan="headers.length - 6" @click="toggle">
          <div class="d-flex align-center">
            <v-btn class="mr-4" icon @click.stop="toggle">
              <v-icon>
                {{
                  isOpen
                    ? $icons.LIGHT.COMMON.CHEVRON_DOWN
                    : $icons.LIGHT.COMMON.CHEVRON_UP
                }}
              </v-icon>
            </v-btn>
            <div class="group-header__title">
              {{ headerTitle(group) }}
            </div>
          </div>
        </td>
        <td :colspan="5"></td>
      </template>
      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppFormTableItem
          :form="item"
          :selectedFormId="formId"
          :headers="headers"
          :tableModel="tableModel"
          :select="select"
          :isSelected="isSelected"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :isActiveItem="isActiveItem(item)"
          @form:update="onFormUpdate"
          @name:click="onFormNameClick"
          @menu:click="openContextMenu"
          @status:click="onStatusClick"
          @signature:click="onSignatureClick"
        />
      </template>
    </AppDataTableServerSidePagination>

    <AppContextMenu ref="contextMenu" @input="onContextMenuChange">
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.FILL_OUT_FORM).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.FILL_OUT_FORM).on"
        :selectedItems="[contextMenuActiveItem]"
      />
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.OVERVIEW).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.OVERVIEW).on"
        :selectedItems="[contextMenuActiveItem]"
      />
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.COPY).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.COPY).on"
        :selectedItems="[contextMenuActiveItem]"
      />
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.SEND_TO_SIGN).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.SEND_TO_SIGN).on"
        :selectedItems="[contextMenuActiveItem]"
      />
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.COPY_URL).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.COPY_URL).on"
        :selectedItems="[contextMenuActiveItem]"
      />
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.DELETE).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.DELETE).on"
        :selectedItems="[contextMenuActiveItem]"
      />
    </AppContextMenu>
    <AppCustomStatusPickerMenu ref="statusMenu" @submit="onFormsUpdate" model="form" />
    <AppUserPickerMenu
      ref="responsibleMenu"
      @submit="(id) => onFormsUpdate({ ownerId: id })"
    />
    <AppProjectPickerMenu ref="projectMenu" @submit="onFormsUpdate" />
    <AppDatePickerMenu
      ref="dueDateMenu"
      @submit="({ date }) => onFormsUpdate({ dueDate: date })"
    />
    <AppTaskPickerMenu ref="taskMenu" @submit="onFormsUpdate" />
    <AppFormCategoryPickerMenu ref="categoryMenu" @submit="onFormsUpdate" />
    <AppIsActivePickerMenu
      ref="isSignableMenu"
      @submit="({ isActive }) => onFormsUpdate({ isSignable: isActive })"
    />
    <AppAccessLevelPickerMenu
      ref="isPrivateMenu"
      :allowedLevels="['PUBLIC', 'PRIVATE']"
      @submit="
        ({ permissionLevel }) =>
          onFormsUpdate({ isPrivate: permissionLevel === 'PRIVATE' })
      "
    />
    <AppCopyItemsDialog
      v-model="dialog.copyDialog.active"
      :inItems="dialog.copyDialog.items"
      type="FORM"
      @submit="onCopy"
    />
  </div>
</template>

<script>
import { groupByMixins } from "@/helpers/mixins";
import { getAllForms } from "@/services/form/allForms";

export default {
  mixins: [groupByMixins],

  props: {
    forms: Array,
    serverItemsLength: Number,
    isLoading: Boolean,
    formId: String,
    tableModel: String,
    statsText: String,
    isStatsLoading: Boolean,
  },
  data() {
    return {
      contextMenuActiveItem: null,
      dialog: {
        deleteForm: {
          active: false,
          item: null,
        },
        copyDialog: {
          active: false,
          items: null,
        },
      },
      isContextMenuOpen: false,
      contextMenuActiveItem: null,
      itemsToEdit: null,
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    bulkActionConfigs() {
      return this.$constant.getBulkActionConfigs(this.actionConfigs);
    },
    actionConfigs() {
      const {
        FILL_OUT_FORM,
        OVERVIEW,
        COPY,
        SEND_TO_SIGN,
        COPY_URL,
        DELETE,
        EDIT_STATUS,
        EDIT_RESPONSIBLE,
        EDIT_DUE_DATE,
        EDIT_TASK,
        EDIT_PROJECT,
        EDIT_FORM_CATEGORY,
        EDIT_IS_SIGNABLE,
        EDIT_PERMISSION_LEVEL,
      } = this.$constant.ACTION_KEYS;

      const _openEditMenu = (e, menuRef) => {
        this.openEditMenu({ ...e, menuRef, direction: "top" });
      };

      const customActions = [
        {
          key: FILL_OUT_FORM,
          on: { click: this.onFormFillOutClick },
        },
        {
          key: OVERVIEW,
          on: { click: this.onFormDetailsClick },
        },
        {
          key: COPY,
          on: { click: this.onCopyClick },
        },
        {
          key: SEND_TO_SIGN,
          props: {
            labelKeyParams: { isSent: this.isSentToSign },
          },
          on: { click: this.onSendToSignClick },
        },
        {
          key: COPY_URL,
          on: { click: this.onCopyUrl },
        },
        {
          key: DELETE,
          props: {
            disabled: ({ items }) => !this.canEditForms({ items }),
          },
          on: { click: this.onFormDelete },
        },
        {
          key: EDIT_STATUS,
          on: { click: (e) => _openEditMenu(e, "statusMenu") },
        },
        {
          key: EDIT_RESPONSIBLE,
          on: { click: (e) => _openEditMenu(e, "responsibleMenu") },
        },
        {
          key: EDIT_DUE_DATE,
          on: { click: (e) => _openEditMenu(e, "dueDateMenu") },
        },
        {
          key: EDIT_TASK,
          on: { click: (e) => _openEditMenu(e, "taskMenu") },
        },
        {
          key: EDIT_PROJECT,
          on: { click: (e) => _openEditMenu(e, "projectMenu") },
        },
        {
          key: EDIT_FORM_CATEGORY,
          on: { click: (e) => _openEditMenu(e, "categoryMenu") },
        },
        {
          key: EDIT_IS_SIGNABLE,
          on: { click: (e) => _openEditMenu(e, "isSignableMenu") },
        },
        {
          key: EDIT_PERMISSION_LEVEL,
          on: { click: (e) => _openEditMenu(e, "isPrivateMenu") },
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.FORM_ACTIONS,
      });

      return config;
    },
    isSentToSign() {
      return !!this.contextMenuActiveItem?.signatureSession;
    },
    columnsToFreeze() {
      let columns = 3;

      const isGroupByStatus = this.filtersMixins_dataTableOptions.groupBy?.includes(
        this.filtersMixins_dataFields.STATUS,
      );

      if (isGroupByStatus) {
        columns -= 1;
      }
      return columns;
    },
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          value: this.filtersMixins_dataFields.STATUS,
          sortable: false,
          show: true,
        },
        {
          text: this.$t("formManagement.list.formName"),
          align: "start",
          value: this.filtersMixins_dataFields.NAME,
          show: true,
        },
        {
          text: this.$t("formManagement.list.signatureStatus"),
          value: this.filtersMixins_dataFields.SIGNATURE_STATUS,
          show: true,
        },
        {
          text: this.$t("common.progress"),
          value: this.filtersMixins_dataFields.PROGRESS,
          show: true,
        },
        {
          text: this.$t("formManagement.list.responsible"),
          value: this.filtersMixins_dataFields.RESPONSIBLE,
          show: true,
        },
        {
          text: this.$t("common.project"),
          value: this.filtersMixins_dataFields.PROJECT,
          show: !this.projectId,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("formManagement.list.belongsToTask"),
          ),
          value: this.filtersMixins_dataFields.TASK,
          show: true,
        },
        {
          text: this.$t("common.category"),
          value: this.filtersMixins_dataFields.CATEGORY,
          show: true,
        },
        {
          text: this.$t("taskManagement.list.dueDate"),
          value: this.filtersMixins_dataFields.DUE_DATE,
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
  },
  methods: {
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      this.itemsToEdit = items;
    },
    async getAllItems() {
      return await getAllForms({
        filters: {
          ...this.filtersMixins_filters,
          ...(this.projectId ? { projectId: this.projectId } : {}),
        },
      });
    },
    canEditForms({ items }) {
      return items?.every((item) => item?.permissions?.canEdit);
    },
    onCopyUrl({ items }) {
      const form = items[0];
      const formUrl = this.$router.resolve({
        name: this.$routeNames.FORM_VIEW.ROOT,
        params: { formId: form?.id },
      }).href;

      const fullPath = `${window.location.origin}${formUrl}`;

      navigator.clipboard.writeText(fullPath);

      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text: this.$t("common.copied"),
        color: "success",
      });
    },
    actionConfigByKey(key) {
      return this.actionConfigs.find((action) => action.key === key) || {};
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem === item;
    },
    isActiveItem(item) {
      return item.id === this.formId;
    },
    onCopyClick({ items }) {
      this.dialog.copyDialog.active = true;
      this.dialog.copyDialog.items = items;
    },
    onCopy(e) {
      this.$emit("forms:copy", { forms: e });
    },
    onSignatureClick({ form }) {
      this.$emit("signature:click", { form });
    },
    onSendToSignClick({ items }) {
      const form = items[0];
      this.$emit("form:sendToSign", { form });
    },
    headerTitle(group) {
      const commonHeaderTitle = this.groupByMixins_commonHeaderTitle(group);
      if (commonHeaderTitle) return commonHeaderTitle;
      if (this.groupByMixins_isGroupSelected(this.filtersMixins_dataFields.CATEGORY)) {
        return this.translatedCategory(group);
      }

      return group;
    },
    onStatusChange({ statusId }) {
      const body = { statusId };

      this.onFormUpdate({ body, form: this.itemsToEdit[0] });
    },
    onStatusClick(e) {
      const { items } = e;
      this.$refs.statusMenu.open(e);
      this.itemsToEdit = items;
    },
    translatedCategory(key) {
      return this.$te(`formManagement.category.${key}`)
        ? this.$t(`formManagement.category.${key}`)
        : key;
    },
    openContextMenu({ from, direction, form }) {
      this.$refs.contextMenu.open({ direction, from });
      this.$nextTick(() => {
        this.contextMenuActiveItem = form;
      });
    },
    onContextMenuChange(isOpen) {
      this.isContextMenuOpen = isOpen;
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    onFormDetailsClick({ items }) {
      const form = items[0];
      this.$emit("form:details", { item: form });
    },
    onFormNameClick({ form }) {
      this.contextMenuActiveItem = form;
      this.onFormFillOutClick({ items: [form] });
    },
    onFormFillOutClick({ items }) {
      const form = items[0];
      this.$emit("form:fillOut", { item: form });
    },
    onFormUpdate(e) {
      this.$emit("form:update", e);
    },
    onFormsUpdate(value) {
      const body = { ...value, ids: this.itemsToEdit.map((item) => item.id) };
      this.$emit("forms:update", { body });
    },
    onFormDelete({ items }) {
      this.dialog.deleteForm.active = true;
      this.dialog.deleteForm.item = items;
    },
    formsDelete({ item }) {
      this.$emit("forms:delete", { forms: item });
    },
  },
};
</script>
