import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, userGuard } from "../../auth/authGuard";
import multiguard from "../multiguard";

const { ROOT, LIST } = ROUTE_NAMES.FORM;

export const formRoutes = [
  {
    path: "/form",
    name: ROOT,
    beforeEnter: multiguard([authGuard, userGuard()]),
    component: () =>
      import(/* webpackChunkName: "form" */ "../../pages/FormManagement/Forms"),
    redirect: { name: LIST.ROOT },
    children: [
      {
        path: "list",
        name: LIST.ROOT,
        component: () =>
          import(
            /* webpackChunkName: "form" */ "../../pages/FormManagement/Forms/FormList"
          ),
        children: [
          {
            path: "menu/:formId",
            name: LIST.MENU.ROOT,
            component: () =>
              import(
                /* webpackChunkName: "form" */ "../../pages/FormManagement/Forms/MenuPage"
              ),
            children: [
              {
                path: "details",
                name: LIST.MENU.DETAILS,
                component: () =>
                  import(
                    /* webpackChunkName: "form" */ "../../pages/FormManagement/MenuPages/DetailsPage"
                  ),
              },
              {
                path: "comments",
                name: LIST.MENU.COMMENTS,
                component: () =>
                  import(
                    /* webpackChunkName: "form" */ "../../pages/FormManagement/MenuPages/CommentsPage"
                  ),
              },
              {
                path: "storage/:folderId",
                name: LIST.MENU.STORAGE,
                component: () =>
                  import(
                    /* webpackChunkName: "form" */ "../../pages/FormManagement/MenuPages/StoragePage"
                  ),
              },
              {
                path: "signature",
                name: LIST.MENU.SIGNATURE,
                component: () =>
                  import(
                    /* webpackChunkName: "form" */ "../../pages/FormManagement/MenuPages/SignaturePage"
                  ),
              },
              {
                path: "history",
                name: LIST.MENU.HISTORY,
                component: () =>
                  import(
                    /* webpackChunkName: "form" */ "../../pages/FormManagement/MenuPages/HistoryPage"
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
];
