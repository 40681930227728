import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

export async function getAccountingTimeEntryProducts() {
  return axios
    .get(`${apiUrl}accounting/time-entry/product`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
}
