<template>
  <div class="file_storage_node list_tr" :class="rowStateClasses" v-ripple="itemClick">
    <v-hover v-slot="{ hover }">
      <div
        class="file_storage_node__root"
        :class="[{ 'cursor-pointer': itemClick }]"
        @click="onClick(item)"
      >
        <div class="file_storage_node__prepend">
          <slot
            name="prepend"
            :item="item"
            :state="{ isSelected: computedIsSelected, hover }"
          >
          </slot>
        </div>
        <div class="file_storage_node__label">
          <slot
            name="label"
            :item="item"
            :isAddedToMerge="isAddedToMerge"
            :state="{ isSelected: computedIsSelected, hover }"
          >
            <span class="hyphenate">
              {{ item.name }}
            </span>
          </slot>
        </div>
        <div class="file_storage_node__append">
          <slot
            name="append"
            :item="item"
            :state="{ isSelected: computedIsSelected, hover }"
          >
          </slot>
        </div>
      </div>
    </v-hover>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AppStorageItem",
  props: {
    item: Object,
    itemClick: Boolean,
    isActive: Boolean,
    isSelected: Boolean,
  },
  computed: {
    ...mapState("storageMergeToPdf", {
      selectedFilesToMerge: (state) => state.selectedFilesToMerge,
    }),
    rowStateClasses() {
      return {
        "list_tr--success": this.isAddedToMerge,
        "list_tr--selected": this.computedIsSelected,
        "list_tr--active": this.isActive,
      };
    },
    computedIsSelected() {
      return this.isSelected || this.item?.isSelected;
    },
    isAddedToMerge() {
      return this.selectedFilesToMerge.some((file) => file.id === this.item.id);
    },
  },
  methods: {
    onClick(item) {
      if (this.itemClick) {
        this.$emit("item:click", item);
      }
    },
  },
};
</script>
