import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  units: [],
  isLoading: false,
});

const actions = {
  async getProductUnits({ commit }) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}product-unit`)
      .then(({ data }) => {
        const { units } = data;
        commit("setUnits", units);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
};

const mutations = {
  setUnits(state, stats) {
    state.units = stats;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const productUnits = {
  namespaced: true,
  state,
  actions,
  mutations,
};
