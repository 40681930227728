<template>
  <AppContextMenu ref="contextMenu" @input="$emit('input', $event)">
    <AppContextMoreMenu
      offset-x
      v-bind="actionConfigByKey($constant.ACTION_KEYS.OPEN_WITH).props"
      :selectedItems="[contextMenuActiveItem]"
    >
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.PREVIEW).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.PREVIEW).on"
        :selectedItems="[contextMenuActiveItem]"
      />
      <div class="open-with-more">
        <AppMenuItemOpenInOffice :item="contextMenuActiveItem" />
      </div>
    </AppContextMoreMenu>

    <v-divider
      class="my-2"
      v-if="
        !actionConfigByKey($constant.ACTION_KEYS.OPEN_WITH).props.hidden({
          items: [contextMenuActiveItem],
        })
      "
    />

    <AppMenuActionBtn
      v-bind="actionConfigByKey($constant.ACTION_KEYS.RENAME).props"
      v-on="actionConfigByKey($constant.ACTION_KEYS.RENAME).on"
      :selectedItems="[contextMenuActiveItem]"
    />

    <AppMenuActionBtn
      v-bind="actionConfigByKey($constant.ACTION_KEYS.DOWNLOAD).props"
      v-on="actionConfigByKey($constant.ACTION_KEYS.DOWNLOAD).on"
      :selectedItems="[contextMenuActiveItem]"
    />

    <AppMenuActionBtn
      v-bind="
        actionConfigByKey($constant.ACTION_KEYS.DOWNLOAD_WITHOUT_CONVERTED_MERGE_WORDS)
          .props
      "
      v-on="
        actionConfigByKey($constant.ACTION_KEYS.DOWNLOAD_WITHOUT_CONVERTED_MERGE_WORDS).on
      "
      :selectedItems="[contextMenuActiveItem]"
    />

    <v-divider class="my-2" />

    <AppMenuActionBtn
      v-bind="actionConfigByKey($constant.ACTION_KEYS.MOVE).props"
      v-on="actionConfigByKey($constant.ACTION_KEYS.MOVE).on"
      :selectedItems="[contextMenuActiveItem]"
    />

    <AppMenuActionBtn
      v-bind="actionConfigByKey($constant.ACTION_KEYS.COPY).props"
      v-on="actionConfigByKey($constant.ACTION_KEYS.COPY).on"
      :selectedItems="[contextMenuActiveItem]"
    />

    <AppMenuActionBtn
      v-bind="actionConfigByKey($constant.ACTION_KEYS.LINK_FILE_TO_FOLDER).props"
      v-on="actionConfigByKey($constant.ACTION_KEYS.LINK_FILE_TO_FOLDER).on"
      :selectedItems="[contextMenuActiveItem]"
    />

    <AppMenuActionBtn
      v-bind="actionConfigByKey($constant.ACTION_KEYS.ADD_TO_MERGE).props"
      v-on="actionConfigByKey($constant.ACTION_KEYS.ADD_TO_MERGE).on"
      :selectedItems="[contextMenuActiveItem]"
    />

    <AppMenuActionBtn
      v-bind="actionConfigByKey($constant.ACTION_KEYS.SEND_TO_SIGN).props"
      v-on="actionConfigByKey($constant.ACTION_KEYS.SEND_TO_SIGN).on"
      :selectedItems="[contextMenuActiveItem]"
    />

    <AppMenuActionBtn
      v-bind="actionConfigByKey($constant.ACTION_KEYS.REQUEST_READ_CONFIRMATION).props"
      v-on="actionConfigByKey($constant.ACTION_KEYS.REQUEST_READ_CONFIRMATION).on"
      :selectedItems="[contextMenuActiveItem]"
    />

    <AppMenuActionBtn
      v-bind="actionConfigByKey($constant.ACTION_KEYS.COPY_URL).props"
      v-on="actionConfigByKey($constant.ACTION_KEYS.COPY_URL).on"
      :selectedItems="[contextMenuActiveItem]"
    />

    <AppMenuActionBtn
      v-if="showBoligmappa"
      v-bind="actionConfigByKey($constant.ACTION_KEYS.UPLOAD_TO_BOLIGMAPPA).props"
      v-on="actionConfigByKey($constant.ACTION_KEYS.UPLOAD_TO_BOLIGMAPPA).on"
      :selectedItems="[contextMenuActiveItem]"
    />

    <v-divider class="my-2" />

    <AppMenuActionBtn
      v-bind="actionConfigByKey($constant.ACTION_KEYS.DELETE).props"
      v-on="actionConfigByKey($constant.ACTION_KEYS.DELETE).on"
      :selectedItems="[contextMenuActiveItem]"
    />
  </AppContextMenu>
</template>

<script>
import { planRestrictionsMixins } from "@/helpers/mixins";
import { storageActionMixins } from "@/helpers/mixins/tableActionMixins/storageActionMixins";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [planRestrictionsMixins, storageActionMixins],
  props: {
    contextMenuActiveItem: Object,
    showBoligmappa: Boolean,
    folderRouterName: String,
    rootId: String,
    contextMenuItemsToHide: Object,
    actionConfigs: Array,
  },
  methods: {
    open(e) {
      this.$refs.contextMenu.open(e);
    },
    actionConfigByKey(key) {
      return this.actionConfigs.find((action) => action.key === key) || {};
    },
  },
};
</script>
