<template>
  <tr
    class="list_tr list_tr-height"
    :class="rowStateClasses"
    @contextmenu="dataTableItemMixins_onRightClick"
    @click="select(!isSelected)"
  >
    <td>
      <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)" />
    </td>
    <td><AppUserAvatar :user="user" show-name :truncate="70" /></td>
    <td>
      <AppLinkText :link="`mailto:${user.email}`" underline-on-hover>
        <AppTextTruncateValue :text="user.email" :truncate="30" />
      </AppLinkText>
    </td>
    <td>
      <AppPhoneLink :phone="phone" />
    </td>
    <td>
      <AppLabelBtn
        ref="jobTitleLabelBtn"
        @click="
          (e) =>
            $emit('jobTitleLabel:click', {
              from: $refs.jobTitleLabelBtn.$el,
              item: user,
            })
        "
        :title="jobTitle"
        :isUpdating="user.isUpdatingLabel"
        :clickable="canEditLabels"
      />
    </td>
    <td>
      <div class="d-flex align-center justify-center">
        <v-checkbox
          :inputValue="user.isAdmin"
          @change="onRoleChange($event, 'ADMIN')"
          @click.stop
          :disabled="!canEdit"
        />
      </div>
    </td>
    <td>
      <div class="d-flex align-center justify-center">
        <v-checkbox
          :inputValue="user.isHrAdmin"
          @change="onRoleChange($event, 'HR_ADMIN')"
          @click.stop
          :disabled="!canEdit"
        />
      </div>
    </td>

    <td>
      <AppColorChip
        :text="
          $te(`common.userStatus.${user.status.toLowerCase()}`)
            ? $t(`common.userStatus.${user.status.toLowerCase()}`)
            : user.status
        "
        :color="userStatusColor"
      />
    </td>
    <td>{{ user.number }}</td>
    <td>
      <v-btn icon @click="dataTableItemMixins_onEllipsisClick" ref="ellipsis">
        <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { dataTableItemMixins } from "@/helpers/mixins";

export default {
  mixins: [dataTableItemMixins],
  props: {
    user: Object,
    canEdit: Boolean,
    isHighlighted: Boolean,
    labels: Array,
    canEditLabels: Boolean,
    jobTitleLabel: Object,
    select: Function,
    isSelected: Boolean,
    isContextMenuActiveItem: Boolean,
  },
  data() {
    return {
      isEditingWorkTitle: false,
      menu: {
        show: false,
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    rowStateClasses() {
      return {
        "list_tr--active": this.isContextMenuActiveItem,
        "list_tr--selected": this.isSelected,
      };
    },
    phone() {
      return this.getMetaValueByField("PHONE");
    },
    jobTitle() {
      return this.jobTitleLabel?.name;
    },
    phoneRules() {
      return [
        this.form.phone.valid
          ? true
          : this.$t("settings.account.details.invalidPhoneNumber"),
      ];
    },

    userStatusColor() {
      let colorClass = "";
      switch (this.user.status) {
        case this.$constant.ACTIVE:
          colorClass = "success--text";
          break;
        case this.$constant.INVITED:
          colorClass = "pink--text";
          break;
        case this.$constant.INACTIVE:
          colorClass = "warning--text";
          break;
        case this.$constant.DELETED:
          colorClass = "error--text";
          break;
        case this.$constant.IMPORTED:
          colorClass = "purple--text";
          break;
      }
      return colorClass;
    },
  },
  methods: {
    onOpenEditWorkTitleDialog() {
      this.isEditingWorkTitle = true;
    },
    onCloseEditWorkTitleDialog() {
      this.isEditingWorkTitle = false;
    },
    openMenu(params) {
      this.$emit("menu:click", {
        user: this.user,
        ...params,
      });
    },
    getMetaValueByField(field) {
      return this.user.meta.find((meta) => {
        return meta.field === field;
      })?.value;
    },
    onRoleChange(isAdmin, role) {
      this.$emit("user:onRoleChange", { user: this.user, isAdmin, role });
    },
    onDomainUserUpdate({ field, value }) {
      this.$emit("user:onDomainUserUpdate", { id: this.user.id, [field]: value });
    },
  },
};
</script>
