<template>
  <v-list :expand="true" class="pt-0">
    <v-list-group
      v-for="(section, sectionIndex) in sections"
      :key="sectionIndex"
      v-model="isExpanded[sectionIndex]"
      class="app-section-list-border-t"
      :class="showBorderB(expanded(sectionIndex), sectionIndex, section)"
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>
            {{ section.title }} ({{ itemsLength(section) }})
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="(item, index) in section.data"
        :key="index"
        class="list_tr ma-0"
        :class="rowStateClasses(item)"
      >
        <div class="w-100">
          <slot
            name="item"
            :item="item"
            :isSelected="isSelected(item)"
            :select="(event) => select(item, event)"
          ></slot>
          <v-divider v-if="showDivider(section, index, sectionIndex)" />
        </div>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
export default {
  props: {
    sections: {
      type: Array,
      required: true,
    },
    isSectionsExpanded: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    contextMenuActiveItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isExpanded: [],
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    sectionsLength() {
      return this.sections?.length;
    },
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("change", newValue);
      },
    },
  },
  methods: {
    rowStateClasses(item) {
      return {
        "list_tr--selected": this.isSelected(item),
        "list_tr--active": this.isContextMenuActiveItem(item),
      };
    },
    isSelected(item) {
      return this.localValue.some((selectedItem) => selectedItem.id === item.id);
    },
    select(item, shouldAdd) {
      const newSelection = shouldAdd
        ? [...this.localValue, item]
        : this.localValue.filter((selected) => selected.id !== item.id);

      this.localValue = newSelection;
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item.id;
    },
    expanded(sectionIndex) {
      return this.isExpanded[sectionIndex];
    },
    showDivider(section, index, sectionIndex) {
      const isLastSection = this.isLastSection(sectionIndex);
      const isLastItemInSection = index === this.itemsLength(section) - 1;

      return !(isLastItemInSection && !isLastSection);
    },
    showBorderB(isExpanded, sectionIndex, section) {
      const isLastSection = this.isLastSection(sectionIndex);
      const isNotLastAndHasNoItems = !isLastSection && this.itemsLength(section) === 0;
      if (isNotLastAndHasNoItems) {
        return "";
      }
      return isExpanded || isLastSection ? "app-section-list-border-b" : "";
    },
    itemsLength(items) {
      return items?.data?.length || 0;
    },
    isLastSection(sectionIndex) {
      return sectionIndex === this.sectionsLength - 1;
    },
  },
  beforeMount() {
    this.isExpanded = this.isSectionsExpanded;
  },
};
</script>
