<template>
  <div ref="toDoList">
    <Draggable
      v-if="!isLoading"
      :value="todos"
      :preventOnFilter="false"
      :forceFallback="true"
      :scrollSensativity="200"
      draggable=".draggable-item"
      :animation="$constant.DRAG_SETTINGS.ANIMATION_DURATION"
      :ghostClass="$constant.DRAG_SETTINGS.GHOST_CLASS_HIDDEN"
      :dragClass="$constant.DRAG_SETTINGS.DRAG_CLASS"
      filter=".block-draggable"
      @change="onDraggableChange"
      @start="isDragging = true"
      @end="isDragging = false"
    >
      <AppToDoListItem
        v-for="toDo of todos"
        :key="toDo.id"
        :toDo="toDo"
        @toDo:update="updateToDo($event)"
        @toDo:delete="deleteToDo($event)"
        @toDo:saveUnsaved="onSaveUnsavedToDo"
      />
    </Draggable>
    <div
      v-if="showCreateBtn"
      :class="showCreateBtnTopBorder ? 'border-t' : ''"
      class="to-do-list_create-btn-container"
    >
      <v-btn
        :class="isDragging ? 'overwrite-btn-opacity' : ''"
        :height="44"
        @click="onCreateUnsavedToDo"
        depressed
        block
      >
        <v-icon>{{ $icons.LIGHT.ACTION.PLUS }}</v-icon>
        <span class="pl-2">{{ $t("widget.toDo.createBtn") }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Draggable from "vuedraggable";

export default {
  props: {
    modelId: String,
    model: String,
  },
  watch: {
    modelId: {
      handler(newItemId, oldItemId) {
        if (newItemId !== oldItemId) {
          this.getTodos();
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      isDragging: false,
    };
  },
  computed: {
    hasTodos() {
      return this.todos?.length > 0;
    },
    showCreateBtnTopBorder() {
      return this.hasTodos && !this.isDragging;
    },
    showCreateBtn() {
      return !this.hasUnsavedToDo;
    },
    ...mapState("todos", {
      todos: (state) => state.todos,
      isLoading: (state) => state.isLoading,
    }),
    ...mapGetters("todos", {
      hasUnsavedToDo: "hasUnsavedToDo",
    }),
  },
  methods: {
    getNextIndex() {
      let nextIndex = 0;
      if (this.todos?.length > 0) {
        const maxIndex = Math.max(...this.todos.map((x) => x.index));
        nextIndex = maxIndex + 1;
      }
      return nextIndex;
    },
    onCreateUnsavedToDo() {
      const nextIndex = this.getNextIndex();
      this.createUnSavedToDo({ nextIndex });
    },
    createUnSavedToDo({ nextIndex }) {
      this.$store.commit("todos/addUnsavedToDo", { nextIndex });
      setTimeout(() => {
        this.focusToDoInput(nextIndex);
        this.scrollToBottomOfList();
      }, 100);
    },
    onSaveUnsavedToDo({ body, createNext }) {
      this.saveUnsavedToDo({ body });
      if (createNext) {
        this.onCreateUnsavedToDo();
      }
    },
    async saveUnsavedToDo({ body }) {
      if (body.name) {
        await this.$store.dispatch("todos/createToDo", {
          body: {
            modelId: this.modelId,
            model: this.model,
            ...body,
          },
        });
        this.scrollToBottomOfList();
      } else {
        this.$store.commit("todos/removeToDo", { isUnsaved: true });
      }
    },
    focusToDoInput(id) {
      const input = document.getElementById(`to-do-list-item-name-text-field-${id}`);
      if (input) {
        input.focus();
      }
    },
    scrollToBottomOfList() {
      const toDoList = this.$refs.toDoList;
      if (toDoList) {
        toDoList.scrollTop = toDoList.scrollHeight;
      }
    },
    onDraggableChange(e) {
      if (e.moved) {
        const toDo = e.moved?.element;
        const newIndex = e.moved?.newIndex;

        this.moveToDo({
          toDo,
          body: { index: newIndex },
        });
      }
    },
    getTodos() {
      this.$store.dispatch("todos/getTodos", {
        modelId: this.modelId,
        model: this.model,
      });
    },
    moveToDo({ toDo, body }) {
      this.$store.dispatch("todos/moveToDo", {
        id: toDo.id,
        toDo,
        body,
      });
    },
    updateToDo({ body, toDo }) {
      this.$store.dispatch("todos/updateToDo", {
        id: toDo.id,
        body,
      });
    },
    deleteToDo({ toDo }) {
      this.$store.dispatch("todos/deleteToDo", {
        id: toDo.id,
      });
    },
  },
  components: { Draggable },
};
</script>
