var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"drawer-menu__toggle-button d-flex align-center justify-center cursor-pointer",class:{
    'drawer-menu__toggle-button--mini': _vm.isMini,
    'drawer-menu__toggle-button--full': !_vm.isMini,
  },on:{"click":_vm.onDrawerVariantToggle}},[_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"width":"2rem"}},[_c('div',{staticClass:"d-flex flex-column justify-center align-center pr-1",staticStyle:{"width":"1.5rem","height":"1.5rem"}},[_c('div',{class:[
          'drawer-menu__toggle-handle-1',
          _vm.isMini
            ? 'drawer-menu__toggle-handle-1--mini'
            : 'drawer-menu__toggle-handle-1--full',
        ]}),_c('div',{class:[
          'drawer-menu__toggle-handle-2',
          _vm.isMini
            ? 'drawer-menu__toggle-handle-2--mini'
            : 'drawer-menu__toggle-handle-2--full',
        ]})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }