import { normalizeLayout, normalizeWidgetItem } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  widgetLayout: {
    widgetItems: [],
  },
  isLoading: false,
  isSaving: false,
  isUpdating: false,
  isEditMode: false,
});

const getters = {};

const actions = {
  async getWidgetLayout({ commit }, { model, modelId }) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}widget/layout`, { params: { model, modelId } })
      .then(({ data }) => {
        const layout = data?.widgetLayout;
        const normalizedLayout = normalizeLayout(layout);

        commit("setWidgetLayout", normalizedLayout);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async updateWidgetLayout({ commit }, { layout }) {
    const newLayout = { ...layout, widgetItems: layout.widgetItems };

    commit("saving", true);
    return axios
      .put(`${apiUrl}widget/layout/${newLayout?.id}`, { ...newLayout })
      .then(({ data }) => {
        const layout = data?.widgetLayout;
        const normalizedLayout = normalizeLayout(layout);

        commit("setWidgetLayout", normalizedLayout);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("saving", false);
      });
  },
  async updateWidgetLayoutItem({ commit, state }, { id, body }) {
    commit("updating", false);
    const oldItem = state.widgetLayout.widgetItems.find((x) => x.id === id);
    const updatedItem = { ...oldItem, ...body };
    commit("updateItem", updatedItem);

    return axios
      .put(`${apiUrl}widget/layout-item/${id}`, body)
      .then(({ data }) => {
        const widgetItem = data?.widgetItem;

        if (widgetItem) {
          const normalizedWidgetItem = normalizeWidgetItem(widgetItem);
          commit("updateItem", normalizedWidgetItem);
        }
      })
      .catch((error) => {
        commit("updateItem", oldItem);
        throw new Error(error);
      })
      .finally(() => {
        commit("updating", false);
      });
  },
};

const mutations = {
  setWidgetLayout(state, widgetLayout) {
    state.widgetLayout = widgetLayout;
  },
  addWidget(state, widgetLibraryItem) {
    state.widgetLayout.widgets.push(widgetLibraryItem);
  },
  updateItem(state, widget) {
    const index = state.widgetLayout.widgetItems.findIndex((x) => x.id === widget.id);
    if (index !== -1) {
      state.widgetLayout.widgetItems.splice(index, 1, widget);
    }
  },
  setIsEditMode(state, isEditMode) {
    state.isEditMode = isEditMode;
  },
  saving(state, isSaving) {
    state.isSaving = isSaving;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  updating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
};

export const widgetLayout = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
