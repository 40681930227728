const state = () => ({
  snackbar: {
    show: false,
    text: "",
    timeout: 2000,
    color: null,
    vHtml: false,
    action: {
      text: null,
      callback: null,
    },
  },
});

const getters = {};

const actions = {
  snackbar(
    { commit },
    snackbar = {
      show: false,
      text: "",
      timeout: 2000,
      color: null,
      vHtml: false,
      action: {
        text: null,
        callback: () => {},
      },
    },
  ) {
    commit("setSnackbarVHtml", snackbar.vHtml);
    commit("toggleSnackbar", snackbar.show);
    commit("setSnackbarText", snackbar.text);
    commit("setSnackbarTimeout", snackbar.timeout);
    commit("setSnackbarColor", snackbar.color);
    commit("setSnackbarAction", snackbar.action);
  },
};

const mutations = {
  setSnackbar(state, value) {
    state.snackbar = value;
  },
  toggleSnackbar(state, value) {
    state.snackbar.show = value;
  },
  setSnackbarText(state, value) {
    state.snackbar.text = value;
  },
  setSnackbarTimeout(state, value) {
    state.snackbar.timeout = value;
  },
  setSnackbarColor(state, value) {
    state.snackbar.color = value;
  },
  setSnackbarVHtml(state, value) {
    state.snackbar.vHtml = value;
  },
  setSnackbarAction(state, value) {
    state.snackbar.action = value;
  },
};

export const snackbar = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
