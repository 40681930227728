function escapeSearchString(search) {
  return search.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}

function createHighlightRegex(escapedSearch) {
  return new RegExp(`(${escapedSearch})`, "gi");
}

function applyHighlight(text, regex) {
  return text.replace(regex, '<span class="text-highlight">$1</span>');
}

function isExactMatch(text, search) {
  return text.toLowerCase() === search.toLowerCase();
}

export function highlightText(text, search, isSelected) {
  if (!search) return text;

  const escapedSearch = escapeSearchString(search);
  const regex = createHighlightRegex(escapedSearch);

  const highlightedText = applyHighlight(text, regex);

  const finalText = isSelected && isExactMatch(text, search) ? text : highlightedText;

  return `<span class="text-pre-wrap">${finalText}</span>`;
}
