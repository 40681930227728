<script>
import ResponseItem from "./ResponseItem.vue";
import { formTemplateBuilderMixins } from "@/helpers/mixins";
import GroupItem from "./GroupItem.vue";
import ResponseItemHeader from "./ResponseItemHeader.vue";

export default {
  mixins: [formTemplateBuilderMixins],
  props: {
    item: Object,
    items: Array,
    sectionIndex: Number,
  },
  data() {
    return {};
  },
  methods: {
    genGroup(item, groupIndex) {
      return this.$createElement("div", { class: "d-flex w-100 justify-center py-12" }, [
        this.$createElement("div", {
          class: "d-flex align-center flex-column",
          style: "min-width: 32px",
        }),
        this.$createElement("div", { class: "w-100" }, [
          this.$createElement(GroupItem, {
            props: { item },
          }),
          this.$createElement(ResponseItemHeader),
          this.genHierarchy(item.children, groupIndex),
        ]),
      ]);
    },
    genHierarchy(items, parentGroupIndex) {
      const hierarchy = [];
      let responseItemIndex = 0;
      let groupIndex = 0;
      for (let [index, item] of items.entries()) {
        if (item.type === this.$constant.GROUP) {
          hierarchy.push(this.genGroup(item, groupIndex));
          groupIndex++;
        } else {
          hierarchy.push(
            this.$createElement(
              "div",
              { key: item.id, class: "mt-2 ui-background--background" },
              [
                this.$createElement(ResponseItem, {
                  props: {
                    item,
                    responseItemIndex,
                    sectionIndex: this.sectionIndex,
                    parentGroupIndex,
                  },
                }),
              ],
            ),
          );
          responseItemIndex++;
        }
      }
      return hierarchy;
    },
  },
  render(h) {
    const items = this.formTemplateBuilderMixins_getHierarchyByParentId(
      this.items,
      this.item.id,
    );
    // const hasResponseItem = !!items.find((item) => item.type !== this.$constant.GROUP);
    // const hasItems = items.length > 0;
    return h("div", [h(ResponseItemHeader), this.genHierarchy(items)]);
  },
};
</script>
