import { MODULE_FIELD_CONFIG } from "@/helpers/constants";
import { formatItemWithSearchStr } from "@/helpers/util/moduleItem";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getSearchProjects({ params } = {}) {
  return axios
    .get(`${apiUrl}search/project`, {
      params: {
        ...params,
        //Since projectAutocomplete is reliant on this param, add by default, might need to be implemented as params later if we use this endpoint outside of autocompletes
        fields: MODULE_FIELD_CONFIG.PROJECT.FIELDS_QUERY_PARAM,
      },
    })
    .then(({ data }) => {
      const projects = formatItemWithSearchStr(data?.projects, "PROJECT");
      return projects;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
