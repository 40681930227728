<template>
  <div class="information_drawer">
    <v-toolbar class="border-b" dense flat fixed height="68">
      <v-toolbar-title>
        {{ $t("formManagement.signatureSession.navMenu.title") }}
      </v-toolbar-title>
      <v-spacer />

      <v-btn icon @click="refresh">
        <v-icon>{{ $icons.LIGHT.ACTION.REFRESH }}</v-icon>
      </v-btn>
      <AppToggleMenuBtn>
        <AppMenuItem
          :disabled="isAddMoreRecipientsDisabled"
          :text="$t('formManagement.signatureSession.navMenu.addMoreRecipients')"
          :icon="$icons.REGULAR.ACTION.ADD"
          @click="dialog.addRecipients = true"
        />
        <AppMenuItem
          :disabled="isRemindSignersDisabled"
          :text="$t('formManagement.signatureSession.navMenu.sendReminder')"
          :icon="$icons.REGULAR.ACTION.NOTIFICATION"
          @click="dialog.remindSigners = true"
        />
        <AppMenuItem
          :disabled="isSendCompletionMailDisabled"
          :text="$t('formManagement.signatureSession.navMenu.sendCompletionMail')"
          :icon="$icons.REGULAR.COMMON.ENVELOPE"
          @click="onSendCompletionMail"
        />
        <AppMenuItem
          :disabled="isResetSessionDisabled"
          :text="$t('formManagement.signatureSession.navMenu.reset')"
          :icon="$icons.REGULAR.ROUTE.EVENT_LOG"
          @click="dialog.resetSession = true"
        />
        <AppMenuItem
          :disabled="isRevokeSessionDisabled"
          :text="$t('formManagement.signatureSession.navMenu.revoke')"
          :icon="$icons.REGULAR.COMMON.SIGNATURE_SLASH"
          color="error"
          @click="dialog.revokeSession = true"
        />
      </AppToggleMenuBtn>
    </v-toolbar>

    <AppCenterLoader
      class="ma-0"
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    >
    </AppCenterLoader>

    <template v-if="!isLoading && form">
      <template v-if="status === $constant.FORM_SIGN_SESSION_STATUS.NOT_STARTED">
        <NotStartedDrawer :formId="form.id" @afterSubmit="refresh" />
      </template>

      <template v-else-if="status === $constant.FORM_SIGN_SESSION_STATUS.IN_PROGRESS">
        <InProgressDrawer :signatureSession="signatureSession" />
      </template>

      <template v-else-if="status === $constant.FORM_SIGN_SESSION_STATUS.COMPLETED">
        <CompletedDrawer :signatureSession="signatureSession" />
      </template>

      <template v-else-if="status === $constant.FORM_SIGN_SESSION_STATUS.DECLINED">
        <InProgressDrawer :signatureSession="signatureSession" />
      </template>

      <template v-else> something went wrong... </template>

      <AppSignFormAddRecipientsDialog
        :formId="form.id"
        v-model="dialog.addRecipients"
        @submit="refresh"
      />
      <AppFormSignatureRemindDialog
        v-model="dialog.remindSigners"
        :formId="form.id"
        :signers="signers"
        @submit="refresh"
      />
      <AppConfirmationTextareaDialog
        v-model="dialog.resetSession"
        @confirm="onResetSession"
        :title="$t('formManagement.signatureSession.resetDialog.title')"
        :subTitle="$t('formManagement.signatureSession.resetDialog.subTitle')"
        :textareaLabel="$t('formManagement.signatureSession.resetDialog.textareaLabel')"
        :alertText="$t('formManagement.signatureSession.resetDialog.alertText')"
        :confirmText="$t('formManagement.signatureSession.resetDialog.confirmText')"
      />
      <AppConfirmationTextareaDialog
        v-model="dialog.revokeSession"
        @confirm="onRevokeSession"
        :title="$t('formManagement.signatureSession.revokeDialog.title')"
        :subTitle="$t('formManagement.signatureSession.revokeDialog.subTitle')"
        :textareaLabel="$t('formManagement.signatureSession.revokeDialog.textareaLabel')"
        :alertText="$t('formManagement.signatureSession.revokeDialog.alertText')"
        :confirmText="$t('formManagement.signatureSession.revokeDialog.confirmText')"
      />
    </template>
  </div>
</template>

<script>
import {
  resetFormSignSession,
  revokeFormSignSession,
  sendCompletionEmail,
} from "../../../../services/form/formSignSession";
import InProgressDrawer from "./status/InProgressDrawer";
import NotStartedDrawer from "./status/NotStartedDrawer";
import CompletedDrawer from "./status/CompletedDrawer";

export default {
  props: {
    form: Object,
    isLoading: Boolean,
  },
  data() {
    return {
      hasSentCompletionEmail: false,
      dialog: {
        addRecipients: false,
        remindSigners: false,
        resetSession: false,
        revokeSession: false,
      },
    };
  },
  watch: {
    form: {
      deep: true,
      handler(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
          this.autoOpenRecipientsDialog();
        }
      },
    },
  },
  computed: {
    signatureSession() {
      return this.form?.signatureSession;
    },
    signers() {
      return this.signatureSession?.signers || [];
    },
    status() {
      return this.signatureSession?.status || "NOT_STARTED";
    },
    isAddMoreRecipientsDisabled() {
      return this.status === this.$constant.FORM_SIGN_SESSION_STATUS.NOT_STARTED;
    },
    isRemindSignersDisabled() {
      return (
        this.status === this.$constant.FORM_SIGN_SESSION_STATUS.NOT_STARTED ||
        this.status === this.$constant.FORM_SIGN_SESSION_STATUS.COMPLETED
      );
    },
    isSendCompletionMailDisabled() {
      return (
        this.status !== this.$constant.FORM_SIGN_SESSION_STATUS.COMPLETED ||
        this.hasSentCompletionEmail
      );
    },
    isResetSessionDisabled() {
      return (
        this.status === this.$constant.FORM_SIGN_SESSION_STATUS.NOT_STARTED ||
        this.status === this.$constant.FORM_SIGN_SESSION_STATUS.COMPLETED
      );
    },
    isRevokeSessionDisabled() {
      return (
        this.status === this.$constant.FORM_SIGN_SESSION_STATUS.NOT_STARTED ||
        this.status === this.$constant.FORM_SIGN_SESSION_STATUS.COMPLETED
      );
    },
  },
  methods: {
    autoOpenRecipientsDialog() {
      const shouldAutoOpenDialog =
        this.$route.query?.openAddRecipients === "true" ||
        this.$route.query?.openAddRecipients === true;

      if (shouldAutoOpenDialog) {
        this.dialog.addRecipients = true;
        this.$router.replace({
          query: { ...this.$route.query, openAddRecipients: undefined },
        });
      }
    },
    refresh() {
      this.$emit("form:refresh");
    },
    onResetSession({ comment }) {
      const body = {
        comment,
        formId: this.form.id,
      };

      resetFormSignSession({ body })
        .then(() => {
          this.refresh();
        })
        .catch(() => {
          throw new Error(err);
        });
    },
    onRevokeSession({ comment }) {
      const body = {
        comment,
        formId: this.form.id,
      };

      revokeFormSignSession({ body })
        .then(() => {
          this.refresh();
        })
        .catch(() => {
          throw new Error(err);
        });
    },
    onSendCompletionMail() {
      const body = {
        formId: this.form.id,
      };

      sendCompletionEmail({ body })
        .then(() => {
          this.hasSentCompletionEmail = true;
        })
        .catch(() => {
          throw new Error(err);
        });
    },
  },
  mounted() {
    this.autoOpenRecipientsDialog();
  },
  components: {
    NotStartedDrawer,
    InProgressDrawer,
    CompletedDrawer,
  },
};
</script>
