<script>
import { VCard } from "vuetify/lib";
import { formTemplateBuilderMixins } from "@/helpers/mixins";
import QuestionItem from "./QuestionItem.vue";
import SectionHeader from "./SectionHeader.vue";

export default {
  mixins: [formTemplateBuilderMixins],
  props: {
    item: Object,
    items: Array,
    assignableUsers: Array,
  },
  data() {
    return {};
  },
  methods: {
    genGroup(item) {
      return this.$createElement(
        VCard,
        {
          class: "my-5 pa-5 background",
          props: { flat: true },
        },
        [
          this.$createElement(SectionHeader, {
            props: { item },
            on: { "item:itemAdd": (e) => this.$emit("item:itemAdd", e) },
          }),
          this.$createElement("div", this.genHierarchy(item.children)),
        ],
      );
    },
    genHierarchy(items) {
      const hierarchy = [];
      for (let item of items) {
        if (item.type === this.$constant.GROUP) {
          hierarchy.push(this.genGroup(item));
        } else {
          hierarchy.push(
            this.$createElement(QuestionItem, {
              props: {
                item,
                observer: this.observer,
                assignableUsers: this.assignableUsers,
              },
              on: {
                "item:update": (e) => this.formTemplateBuilderMixins_updateItem(e),
                "item:commitUpdate": (e) => this.$emit("item:commitUpdate", e),
                "item:delete": (e) => this.formTemplateBuilderMixins_deleteItem(e),
                "item:imageDelete": (e) => this.$emit("item:imageDelete", e),
                "item:imageUpload": (e) => this.$emit("item:imageUpload", e),
                "item:imagePreview": (e) => this.$emit("item:imagePreview", e),
                "item:taskUpdate": (e) => this.$emit("item:taskUpdate", e),
                "item:taskDelete": (e) => this.$emit("item:taskDelete", e),
                "optionsMenu:open": this.formTemplateBuilderMixins_openOptionsMenu,
              },
            }),
          );
        }
      }
      return hierarchy;
    },
  },
  render(h) {
    return h("div", [
      h(
        "div",
        this.genHierarchy(
          this.formTemplateBuilderMixins_getHierarchyByParentId(this.items, this.item.id),
        ),
      ),
    ]);
  },
};
</script>
