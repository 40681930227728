import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let getTasksCancel;

export async function getTasks({
  projectId,
  limit,
  offset,
  groupBy,
  groupDesc,
  statusIds,
}) {
  if (getTasksCancel) getTasksCancel();

  const params = {
    projectId,
    offset,
    limit,
    groupBy,
    groupDesc,
    statusIds,
  };

  return axios
    .get(`${apiUrl}task`, {
      params,
      cancelToken: new CancelToken(function executor(c) {
        getTasksCancel = c;
      }),
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        throw new Error(error);
      }
      return { isCanceled: true };
    });
}
