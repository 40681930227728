<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        {{ $t("common.selectFiles") }}
      </v-card-title>
      <v-card-text>
        <AppFilePicker
          v-model="selectedFiles"
          :acceptedTypes="fileAccepts"
          :modes="modes"
          maxHeight="600px"
          :disableNameEdit="disableNameEdit"
        />
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onConfirm"
          @cancel="onCancel"
          :disabled="isSubmitDisabled"
          :confirmText="$t('common.upload')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    dialog: Boolean,
    modes: Array,
    //mimeTypes supports wildcards like image/* or video/*
    disableNameEdit: Boolean,
    mimeTypes: Array,
  },
  model: {
    prop: "dialog",
    event: "change",
  },
  data() {
    return {
      selectedFiles: [],
    };
  },
  watch: {
    dialog: {
      handler(val) {
        if (val) {
          this.getAcceptedFileTypes();
        }
      },
    },
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    isSubmitDisabled() {
      return this.selectedFiles.length === 0;
    },
    ...mapGetters("auth", {
      activeDomainId: "activeDomainId",
    }),
    ...mapGetters("acceptedFileTypes", {
      acceptedFileTypes: "acceptedFileTypes",
    }),
    fileAccepts() {
      let filteredMimeTypes = this.mimeTypes?.flatMap((type) => {
        if (type.includes("/*")) {
          let baseType = type.split("/")[0];
          return this.acceptedFileTypes.filter((acceptedType) =>
            acceptedType.startsWith(baseType + "/"),
          );
        } else {
          return this.acceptedFileTypes.includes(type) ? [type] : [];
        }
      });

      let accepted =
        filteredMimeTypes?.length > 0
          ? filteredMimeTypes.join(",")
          : this.acceptedFileTypes.join(",");

      return accepted;
    },
  },
  methods: {
    onConfirm() {
      if (this.selectedFiles.length > 0) {
        this.$emit("submit", this.selectedFiles);
        this.selectedFiles = [];
      }
      this.localDialog = false;
    },
    onCancel() {
      this.localDialog = false;
    },
    async getAcceptedFileTypes() {
      await this.$store.dispatch("acceptedFileTypes/getAcceptedFileTypes");
    },
  },
};
</script>
