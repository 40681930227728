<template>
  <div class="rounded" :style="badgeStyle">
    <div :style="{ color: textColor }" class="font-weight-medium text-body-2 text-center">
      {{ text }}
    </div>
  </div>
</template>

<script>
import { getContrastYIQ } from "@/helpers/util";

export default {
  props: {
    backgroundColor: String,
    text: String,
  },
  computed: {
    badgeStyle() {
      return {
        backgroundColor: this.backgroundColor,
        paddingTop: "6px",
        paddingBottom: "6px",
        paddingLeft: "10px",
        paddingRight: "10px",
        minWidth: "84px",
        minHeight: "32px",
      };
    },
    textColor() {
      return getContrastYIQ(this.backgroundColor);
    },
  },
};
</script>
