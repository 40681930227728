<template>
  <div>
    <template v-if="showPreview">
      <AppPdfPreview v-if="isPdf" :name="name" :src="src" />
      <AppImagePreview
        v-else-if="isImage"
        :src="src"
        :fileId="fileId"
        :mime="mime"
        @preview:update="$emit('preview:update', $event)"
        :canEditFile="canEditFile"
        :isLinkedFile="isLinkedFile"
      />
      <AppVideoPreview v-else-if="isVideo" :mime="mime" :src="src" />
    </template>
    <template v-else>
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="subtitle-1 text-center" cols="12">
          <h2>{{ $t("fileManagement.preview.notSupported.title") }}</h2>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    fileId: String,
    mime: String,
    src: String,
    canEditFile: Boolean,
    isLinkedFile: Boolean,
    name: String,
  },
  computed: {
    isImage() {
      return this.mime?.match("image/");
    },
    isVideo() {
      return this.mime?.match("video/");
    },
    isPdf() {
      return !this.isVideo && !this.isImage;
    },
    isValid() {
      return this.isPdf || this.isImage || this.isVideo;
    },
    showPreview() {
      return this.isValid;
    },
    invalidPreview() {
      return !this.isValid;
    },
  },
};
</script>
