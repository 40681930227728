<template>
  <AppContextMenu ref="menu" :closeOnContentClick="false">
    <AppMenuItem
      v-for="level in permissionLevels.filter((p) => allowedLevels.includes(p.value))"
      :key="level.value"
      :text="level.text"
      :icon="level.icon"
      @click="onSubmit({ permissionLevel: level.value })"
    />
  </AppContextMenu>
</template>

<script>
import { REGULAR } from "@/helpers/icons";

export default {
  props: {
    allowedLevels: {
      type: Array,
      default: () => ["PUBLIC", "RESTRICTED", "PRIVATE"],
    },
  },
  data() {
    return {
      permissionLevels: [
        {
          text: this.$t("common.permissionLevel.public"),
          value: "PUBLIC",
          icon: REGULAR.COMMON.USERS,
        },
        {
          text: this.$t("common.permissionLevel.restricted"),
          value: "RESTRICTED",
          icon: REGULAR.COMMON.USER_GROUP,
        },
        {
          text: this.$t("common.permissionLevel.private"),
          value: "PRIVATE",
          icon: REGULAR.COMMON.USER,
        },
      ],
    };
  },
  methods: {
    onSubmit({ permissionLevel }) {
      this.$emit("submit", { permissionLevel });
      this.close();
    },
    close() {
      this.$refs.menu.close();
    },
    open(e) {
      this.$refs.menu.open(e);
    },
  },
};
</script>
