import { normalizeUsers } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  users: [],
  isLoading: false,
});

const getters = {};

const actions = {
  async getUsers({ state, commit }, filter) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}user`, { params: { status: ["ACTIVE", "INVITED"] } })
      .then(({ data }) => {
        const normalizedUsers = normalizeUsers(data);
        commit("setUsers", normalizedUsers);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const selectableUsers = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
