<template>
  <div>
    <v-card flat class="border-a">
      <v-expansion-panels accordion flat class="p-unset" v-model="panelIndex">
        <v-expansion-panel ref="expansionPanel">
          <AppExpansionPanelHeader buttonClass="mt-6 mr-6 mb-6">
            <AppWideCardContent :title="title" :subTitle="subTitle" :faIcon="faIcon">
              <slot name="header"></slot>
            </AppWideCardContent>
          </AppExpansionPanelHeader>

          <v-expansion-panel-content>
            <slot></slot>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    faIcon: String,
    title: String,
    subTitle: String,
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    panelIndex: {
      get() {
        return this.value ? 0 : -1; // Convert boolean to index
      },
      set(index) {
        this.$emit("input", index === 0); // Convert index back to boolean
      },
    },
  },
  watch: {
    value(newVal) {
      if (newVal !== (this.panelIndex === 0)) {
        this.panelIndex = newVal ? 0 : -1;
      }
    },
  },
  methods: {
    // scrollToElement(element, offset) {
    //   const elementRect = element.getBoundingClientRect();
    //   const elementBottomDocument = elementRect.bottom + window.scrollY;
    //   const viewportHeight = window.innerHeight;
    //   const scrollPosition = elementBottomDocument - viewportHeight + offset;
    //   window.scrollTo({
    //     top: scrollPosition,
    //     behavior: "smooth",
    //   });
    // },
    // scrollPanelIntoView() {
    //   if (!this.value) {
    //     return;
    //   }
    //   this.$nextTick(() => {
    //     setTimeout(() => {
    //       const element = this.$refs.expansionPanel.$el;
    //       if (element) {
    //         const rect = element.getBoundingClientRect();
    //         const isVisible =
    //           rect.top >= 0 &&
    //           rect.left >= 0 &&
    //           rect.bottom <=
    //             (window.innerHeight || document.documentElement.clientHeight) &&
    //           rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    //         if (!isVisible) {
    //           this.scrollToElement(element, 60);
    //         }
    //       }
    //     }, 300);
    //   });
    // },
  },
};
</script>
