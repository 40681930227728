<template>
  <AppFiltersDrawer
    v-if="localShowDrawer"
    @close="closeFilters"
    :tableModel="tableModel"
    :children="children"
  />
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { filtersItemsMixins } from "@/helpers/mixins";
import { filtersMixins } from "@/helpers/mixins";
import { filtersWatchMixins } from "@/helpers/mixins";

export default {
  mixins: [filtersItemsMixins, filtersMixins, filtersWatchMixins],
  props: {
    showDrawer: Boolean,
    tableModel: String,
  },
  model: {
    prop: "showDrawer",
    event: "change",
  },
  methods: {
    getData() {
      this.getUnits();
    },
    closeFilters() {
      this.localShowDrawer = false;
    },
    async getUnits() {
      await this.$store.dispatch("productUnits/getProductUnits");
    },
  },
  computed: {
    localShowDrawer: {
      get() {
        return this.showDrawer;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    ...mapGetters("activeIntegrations", {
      hasProductIntegrationEnabled: "hasProductIntegrationEnabled",
    }),
    ...mapState("productUnits", {
      units: (state) => state.units,
      isUnitsLoading: (state) => state.isLoading,
    }),
    clientIds() {
      return this.filtersMixins_filterByKey("clientIds") ?? [];
    },
    children() {
      return [
        {
          is: "AppFiltersClientMultiAutocomplete",
          labelKey: "common.productFields.supplier",
          filterValueKey: "clientIds",
          itemValue: "id",
          itemText: "name",
          isReadyToInitialFetch: this.isReadyToInitialFetch,
          type: this.$constant.SUPPLIER,
          queryParams: {
            alwaysIncludeIds: this.clientIds,
          },
        },
        {
          is: "AppFiltersMultiAutocomplete",
          labelKey: "common.productFields.unit",
          filterValueKey: "units",
          itemValue: "value",
          itemText: "label",
          items: this.units ?? null,
          loading: this.isUnitsLoading ?? null,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "common.productFields.status",
          filterValueKey: "isActive",
          "item-value": "value",
          "item-text": "text",
          items: this.filtersItemsMixins_isActiveItems,
        },
        ...(this.hasProductIntegrationEnabled
          ? [
              {
                is: "AppFiltersSelect",
                labelKey: "filters.productFilters.source",
                filterValueKey: "source",
                "item-value": "value",
                "item-text": "text",
                items: this.filtersItemsMixins_productCreatedSource,
              },
              {
                is: "AppFiltersSelect",
                labelKey: "filters.productFilters.isSynchronized",
                filterValueKey: "isSynchronized",
                "item-value": "value",
                "item-text": "text",
                items: this.filtersItemsMixins_yesOrNoItems,
              },
            ]
          : []),
      ];
    },
  },
};
</script>
