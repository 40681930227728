<template>
  <AppAddRecipientsDialog
    v-model="localDialog"
    :confirmButtonText="
      $t('fileManagement.preview.navMenu.e-signature.prepDocumentDialog.completeButton')
    "
    :genEmptyRecipient="genEmptyRecipient"
    :validateRecipients="validateRecipients"
    :submitAction="submitAction"
    :languages="languages"
    @submit="onSubmit"
  >
    <template v-slot:additional-fields="{ recipient }">
      <AppDefaultSelect
        style="width: 150px"
        class="pl-3"
        :items="signerTypes"
        v-model="recipient.signerType"
        :clearable="false"
      >
        <template v-slot:selection="{ item }">
          <v-icon>{{ item.icon }}</v-icon>
          <div class="pl-2">
            {{ item.text }}
          </div>
        </template>
        <template v-slot:item="{ item }">
          <v-icon>{{ item.icon }}</v-icon>
          <div class="pl-2">{{ item.text }}</div>
        </template>
      </AppDefaultSelect>
    </template>
    <template v-slot:additional-content>
      <AppErrorAlert class="mb-2" v-if="showNeedsAtLeastOneSignerError">
        {{
          $t(
            "fileManagement.preview.navMenu.e-signature.prepDocumentDialog.errorMessages.needAtLeastOneSigner",
          )
        }}
      </AppErrorAlert>
    </template>
  </AppAddRecipientsDialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      showNeedsAtLeastOneSignerError: false,
      signerTypes: [
        {
          value: "SIGNER",
          text: this.$t(
            "fileManagement.preview.navMenu.e-signature.prepDocumentDialog.signerType.signer",
          ),
          icon: this.$icons.LIGHT.FILE.FILE_SIGNATURE,
        },
        {
          value: "REVIEWER",
          text: this.$t(
            "fileManagement.preview.navMenu.e-signature.prepDocumentDialog.signerType.reviewer",
          ),
          icon: this.$icons.LIGHT.COMMON.CIRCLE_CHECK,
        },
      ],
      languages: [
        {
          value: "EN",
          text: this.$t("common.languages.fromLanguageCodes.en"),
        },
        {
          value: "ES",
          text: this.$t("common.languages.fromLanguageCodes.es"),
        },
        {
          value: "DE",
          text: this.$t("common.languages.fromLanguageCodes.de"),
        },
        {
          value: "FR",
          text: this.$t("common.languages.fromLanguageCodes.fr"),
        },
        {
          value: "RO",
          text: this.$t("common.languages.fromLanguageCodes.ro"),
        },
        {
          value: "NO",
          text: this.$t("common.languages.fromLanguageCodes.no"),
        },
      ],
    };
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  props: {
    dialog: Boolean,
    storageId: String,
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapState("storageESign", {
      isSendingDocumentToESign: (state) => state.isSendingDocumentToESign,
    }),
  },
  methods: {
    genEmptyRecipient() {
      return {
        name: "",
        email: "",
        signerType: "SIGNER",
        language: "NO",
        userId: null,
      };
    },
    validateRecipients(recipients) {
      const hasAtLeastOneSigner = recipients.some(
        (signer) => signer.signerType === "SIGNER",
      );
      this.showNeedsAtLeastOneSignerError = !hasAtLeastOneSigner;
      return hasAtLeastOneSigner;
    },
    submitAction(recipients) {
      return this.$store.dispatch("storageESign/sendDocumentToESign", {
        storageId: this.storageId,
        body: { signers: recipients },
      });
    },
    onSubmit(data) {
      this.$emit("submit", data.embeddedUrl);
    },
  },
};
</script>
