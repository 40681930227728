import { normalizeCmsDocumentTemplate } from "@/helpers/normalization";
import axios from "axios";
import FileSaver from "file-saver";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  isLoading: false,
  isDownloading: false,
  item: null,
});

const getters = {};

const actions = {
  async getItem({ commit }, id) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}cms/document/${id}`)
      .then((res) => {
        const template = res.data?.template;
        const normalizedTemplate = normalizeCmsDocumentTemplate(template);

        commit("setItem", normalizedTemplate);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async downloadItem({ commit }, item) {
    commit("downloading", true);
    return axios
      .get(`${apiUrl}cms/document/${item.id}/download`, {
        responseType: "blob",
      })
      .then((res) => {
        commit("downloading", false);
        const fileName = `${item.name.replace(/\.[^/.]+$/, "")}`;
        FileSaver.saveAs(res.data, fileName);
      })
      .catch((error) => {
        commit("downloading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setItem(state, item) {
    state.item = item;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  downloading(state, isDownloading) {
    state.isDownloading = isDownloading;
  },
};

export const cmsTemplateDetails = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
