<template>
  <v-dialog v-model="localDialog" scrollable max-width="600px">
    <v-card>
      <v-card-title>{{ $t("common.details") }}</v-card-title>
      <v-card-text class="primary-text--text" v-if="product">
        <AppProductDetailsDialogItem
          :label="$t('common.productFields.productNumber')"
          :value="product.code"
        />
        <AppProductDetailsDialogItem
          :label="$t('common.productFields.name')"
          :value="product.name"
        />
        <AppProductDetailsDialogItem
          :label="$t('common.productFields.supplier')"
          :value="clientName"
        />
        <AppProductDetailsDialogItem
          :label="$t('common.productFields.unit')"
          :value="unitLabel"
        />
        <AppProductDetailsDialogItem
          :label="$t('common.productFields.unitPrice')"
          :value="product.unitPrice"
          isCurrency
        />
        <AppProductDetailsDialogItem
          :label="$t('common.productFields.status')"
          :value="$t(product.isActive ? 'common.active' : 'common.inactive')"
        />
        <AppProductDetailsDialogItem
          :label="$t('common.productFields.createdDate')"
          :value="formatDate(product.createdAt)"
        />
        <AppProductDetailsDialogItem
          :label="$t('common.productFields.createdBy')"
          :value="creatorName"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn text color="primary" @click="onClose">
          {{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  props: {
    dialog: Boolean,
    product: Object,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    unitLabel() {
      return this.product?.unit.label;
    },
    creatorName() {
      return this.product?.creator?.fullName;
    },
    clientName() {
      return this.product?.client?.name;
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    onClose() {
      this.localDialog = false;
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD.MM.YYYY");
    },
  },
};
</script>
