<template>
  <div class="information_drawer">
    <v-toolbar class="border-b" dense flat fixed height="68">
      <v-toolbar-title class="font-weight-bold">{{
        $t("common.forms") | capitalize
      }}</v-toolbar-title>
      <v-spacer />
      <AppDefaultTooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="onNewForm" v-bind="attrs" v-on="on">
            <v-icon>{{ $icons.LIGHT.ACTION.CIRCLE_PLUS }}</v-icon>
          </v-btn>
        </template>
        <span class="break-word">
          {{ $t("taskManagement.menu.forms.addFormTooltip") }}
        </span>
      </AppDefaultTooltip>
    </v-toolbar>
    <AppCenterLoader
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />
    <div class="overflow-y-auto secondary-height" v-if="!isLoading">
      <AppFormDrawerItem
        v-for="form in forms"
        :key="form.id"
        :item="form"
        @item:nameClick="onFormNameClick"
        @item:update="onFormUpdate"
        @item:delete="onFormDelete"
      />
      <template v-if="forms.length === 0">
        <v-row class="fill-height w-100 ma-0" align-content="center" justify="center">
          <v-col class="subtitle-1 text-center pb-0" cols="12">
            <h3>
              {{ $t("formManagement.noForms.title") }}
            </h3>
          </v-col>
        </v-row>
      </template>
    </div>
    <AppFormTemplatePickerDialog v-model="dialog.newFormDialog" @select="onFormSelect" />
    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.deleteForm.active"
      :item="dialog.deleteForm.item"
      :title="$tc('formManagement.deleteConfirmation.title', 1)"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('fileManagement.fileExplorer.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="deleteForm"
    />
  </div>
</template>

<script>
export default {
  props: {
    forms: Array,
    isLoading: Boolean,
  },
  data() {
    return {
      dialog: {
        newFormDialog: false,
        deleteForm: {
          active: false,
          item: null,
        },
      },
    };
  },
  methods: {
    onFormNameClick({ item }) {
      this.$emit("form:nameClick", { item });
    },
    onFormUpdate({ body, form }) {
      this.$emit("form:update", { body, form });
    },
    onFormDelete({ form }) {
      this.dialog.deleteForm.item = form;
      this.dialog.deleteForm.active = true;
    },
    deleteForm({ item }) {
      this.$emit("form:delete", { form: item });
    },
    onNewForm() {
      this.dialog.newFormDialog = true;
    },
    onFormSelect(params) {
      this.$emit("form:select", params);
    },
  },
  mounted() {},
};
</script>
