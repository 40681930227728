export function normalizeStorage(storage) {
  if (!storage) return storage;

  const normalized = {
    ...storage,
    name: storage.name || "undefined",
    isLoading: false,
    isSelected: false,
  };

  return normalized;
}

export function normalizeStorages(storages) {
  return (storages || []).map((storage) => normalizeStorage(storage));
}
