export const billingMixins = {
  methods: {
    billingMixins_stringToFormattedDate(date) {
      return this.$options.filters.dateFormat(new Date(date), this.$constant.DATE_FORMAT);
    },
    billingMixins_priceFormat(price, currency) {
      return this.$options.filters.currencyFormat(price, currency);
    },
    billingMixins_pendingChangeAlertText({
      currentQuantity,
      currentPlanCode,
      currentPlanName,
      pendingQuantity,
      pendingPlanCode,
      pendingPlanName,
      applyDate,
    }) {
      let text = this.$t(
        "settings.billing.subscription.common.subscriptionScheduleAlert",
        {
          applyDate: this.billingMixins_stringToFormattedDate(applyDate),
        },
      );
      if (pendingQuantity !== currentQuantity) {
        const pendingChangeUnit = this.$tc(
          `settings.billing.subscription.lowercasePlanUnits.${currentPlanCode}`,
          pendingQuantity,
        );
        const currentUnit = this.$tc(
          `settings.billing.subscription.lowercasePlanUnits.${currentPlanCode}`,
          currentQuantity,
        );
        text += this.$t("settings.billing.subscription.common.seatsWillBeChangeTo", {
          currentQuantity,
          pendingQuantity,
          currentPlanCode,
          currentUnit,
          pendingChangeUnit,
        });
      }
      if (pendingPlanCode !== currentPlanCode) {
        text +=
          " " +
          this.$t("settings.billing.subscription.planCard.pendingPlanChange", {
            currentPlanName,
            pendingPlanName,
          });
      }
      return text;
    },
  },
};
