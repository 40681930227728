import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import i18n from "@/plugins/i18n";

export async function getIndustries() {
  return axios
    .get(`${apiUrl}resources/industries`)
    .then(({ data }) => {
      const mappedIndustries = mapIndustries(data);
      return mappedIndustries;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

function mapIndustries(industries) {
  if (!industries) return [];
  const mappedIndustries = industries.map((industry) => {
    return { value: industry, text: i18n.t(`industries.${industry}`) };
  });
  return mappedIndustries;
}
