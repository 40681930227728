<template>
  <AppMenuItem
    :text="text || $t('common.edit')"
    :icon="$icons.REGULAR.ACTION.EDIT"
    :disabled="disabled"
    @click="click"
  />
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    text: String,
  },
  methods: {
    click(e) {
      this.$emit("click", e);
    },
  },
};
</script>
