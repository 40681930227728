<template>
  <div>
    <v-menu
      ref="menu"
      v-model="contextMenu.show"
      :position-x="contextMenu.x"
      :position-y="contextMenu.y"
      :absolute="!useActivator"
      :offsetX="useActivator"
      :openOnHover="useActivator"
      :right="useActivator"
      :closeOnContentClick="false"
      :closeOnClick="!editDefaultValueTemplatesDialog.active"
      :disabled="disabled"
      :transition="`${useActivator ? ' slide-y-transition' : 'custom-menu-transition'}`"
      @input="$emit('input', $event)"
    >
      <template v-slot:activator="{ on }">
        <slot name="activator" :on="{ ...on, click: (e) => e.stopPropagation() }"></slot>
      </template>
      <v-card :loading="isLoading">
        <div class="pa-3 ui-background d-flex flex-column" style="min-width: 240px">
          <div class="d-flex flex-row">
            <div
              style="
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.10000000149011612px;
              "
              class="mb-1"
            >
              {{ $t("answerTemplates.title") }}
            </div>
            <v-spacer />
            <AppSettingsBtn
              v-if="canEdit"
              @click.stop="onEditDefaultValueTemplatesClick"
              :tooltip="editDialogTitle"
            />
          </div>
          <div v-if="!isLoading">
            <AppDefaultTextField
              autofocus
              class="py-1"
              hide-details
              v-model="search"
              :append-icon="$icons.LIGHT.ACTION.SEARCH"
              :label="$t('common.search')"
              clearable
            />
            <div style="max-height: 360px" class="overflow-y-auto">
              <AppMenuItem
                v-for="defaultValueTemplate in filteredDefaultValueTemplates"
                :key="defaultValueTemplate.id"
                :text="defaultValueTemplate.name"
                :subText="defaultValueTemplate.labelName"
                :icon="$icons.SOLID.ACTION.STATUS"
                :iconColor="defaultValueTemplate.labelColor"
                :isSelected="isSelected(defaultValueTemplate)"
                @click="selectItem(defaultValueTemplate)"
                block
                large-icon
              />
            </div>
          </div>

          <v-divider class="my-1" />
          <v-list-item @click="onToggleLabelItemClick">
            <AppDefaultSwitch
              ref="toggleLabel"
              class="pa-0 ma-0"
              :label="$t('defaultAnswer.showLabel')"
              @change="toggleShowLabel"
              @click="(e) => e.stopPropagation()"
              :input-value="!selectedItem?.hideDefaultValueBadge"
            />
          </v-list-item>
        </div>
      </v-card>
    </v-menu>
    <AppEditDefaultValueTemplatesDialog
      :title="editDialogTitle"
      v-model="editDefaultValueTemplatesDialog.active"
      :categoryId="categoryId"
      :model="model"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    editDialogTitle: String,
    canEdit: {
      type: Boolean,
      default: false,
    },
    useActivator: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      model: null,
      categoryId: null,
      currentDefaultValueTemplate: null,
      selectedItem: null,
      contextMenu: {
        show: false,
        x: 0,
        y: 0,
      },
      editDefaultValueTemplatesDialog: {
        active: false,
      },
    };
  },
  watch: {
    ["filteredDefaultValueTemplates.length"](newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateDimensions();
      }
    },
  },
  computed: {
    ...mapState("defaultValueTemplates", {
      isLoading: (state) => state.isLoading,
    }),
    ...mapGetters("defaultValueTemplates", {
      getDefaultValueTemplates: "getDefaultValueTemplates",
      hasFetchedDefaultValueTemplates: "hasFetchedDefaultValueTemplates",
    }),
    filteredDefaultValueTemplates() {
      return this.defaultValueTemplates.filter((defaultValueTemplate) => {
        const searchLower = this.search?.toLowerCase() ?? "";
        return (
          defaultValueTemplate?.name?.toLowerCase().includes(searchLower) ||
          defaultValueTemplate?.labelName?.toLowerCase().includes(searchLower) ||
          defaultValueTemplate?.value?.toLowerCase().includes(searchLower)
        );
      });
    },
    defaultValueTemplates() {
      const defaultValueTemplates = this.getDefaultValueTemplates({
        model: this.model,
        categoryId: this.categoryId,
      });

      return defaultValueTemplates;
    },
    hasFetched() {
      const hasFetched = this.hasFetchedDefaultValueTemplates({
        model: this.model,
        categoryId: this.categoryId,
      });

      return hasFetched;
    },
  },
  methods: {
    isSelected(defaultValueTemplate) {
      return this.currentDefaultValueTemplate?.id === defaultValueTemplate.id;
    },
    onEditDefaultValueTemplatesClick() {
      this.editDefaultValueTemplatesDialog = {
        active: true,
      };
    },
    selectItem(defaultValueTemplate) {
      this.$emit("item:select", { defaultValueTemplate, item: this.selectedItem });
      this.close();
    },
    onToggleLabelItemClick(e) {
      e.stopPropagation();
      this.$refs.toggleLabel.onChange();
    },
    toggleShowLabel(e) {
      this.$emit("badge:toggle", {
        hideDefaultValueBadge: !e,
        item: this.selectedItem,
      });
    },
    fetchDefaultValueTemplates() {
      this.$store.dispatch("defaultValueTemplates/fetchDefaultValueTemplates", {
        model: this.model,
        categoryId: this.categoryId,
      });
    },
    updateDimensions() {
      this.$refs.menu.updateDimensions();
    },
    close() {
      this.contextMenu.show = false;
    },
    open({ pointerEvent, data }) {
      pointerEvent.stopPropagation();
      this.openMenu(pointerEvent);
      this.onAfterOpen(data);
    },
    onAfterOpen(data) {
      this.setData(data);

      if (this.hasFetched) return;
      this.fetchDefaultValueTemplates();
    },
    setData(data) {
      const { model, categoryId, currentDefaultValueTemplate, selectedItem } = data;

      this.model = model;
      this.categoryId = categoryId;
      this.currentDefaultValueTemplate = currentDefaultValueTemplate;
      this.selectedItem = selectedItem;
    },
    openMenu(e) {
      this.contextMenu.show = false;
      this.contextMenu.x = e.x || e.clientX;
      this.contextMenu.y = e.y || e.clientY;
      setTimeout(() => {
        this.contextMenu.show = true;
      }, 50);
    },
  },
};
</script>
