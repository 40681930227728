import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, contactUserRedirectGuard, userGuard } from "../../auth/authGuard";
import multiguard from "../multiguard";
import { makeBeforeEnter, redirectFromLocalStorage } from "../redirect";

const {
  ROOT,
  LIST,
  CALENDAR,
  VIEW,
  INFO,
  INFO_DETAILS,
  TASK,
  STORAGE,
  ALL_FILES,
  VIEW_MEMBERS,
  MEMBERS_EMPLOYEE_LIST,
  MEMBERS_CONTACT_LIST,
  VIEW_CONTACTS,
  CONTACT_PERSON_LIST,
  CLIENT_LIST,
  VIEW_COMMENTS,
  FORM,
  PRODUCTS,
  E_BYGGESOK,
  SUB_PROJECTS,
  SUB_PROJECTS_LIST,
  SUB_PROJECTS_CALENDAR,
  TIME_REGISTRATION,
  TIME_REGISTRATION_ALL_HOURS,
} = ROUTE_NAMES.PROJECT;

//project redirect
const projectLocalStorageKey = `REDIRECT_ROUTE:${ROOT}`;
const beforeEnterProject = makeBeforeEnter({ localStorageKey: projectLocalStorageKey });

//projectViewTask redirect
const projectViewTaskLocalStorageKey = `REDIRECT_ROUTE:${TASK.ROOT}`;
const beforeEnterProjectViewTask = makeBeforeEnter({
  localStorageKey: projectViewTaskLocalStorageKey,
});

//projectMembers redirect
const projectMembersLocalStorageKey = `REDIRECT_ROUTE:${VIEW_MEMBERS}`;
const beforeEnterProjectMembers = makeBeforeEnter({
  localStorageKey: projectMembersLocalStorageKey,
});

//projectSubProjects redirect
const projectSubProjectsLocalStorageKey = `REDIRECT_ROUTE:${SUB_PROJECTS}`;
const beforeEnterProjectSubProjects = makeBeforeEnter({
  localStorageKey: projectSubProjectsLocalStorageKey,
});

export const projectRoutes = [
  {
    path: "/project",
    name: ROOT,
    beforeEnter: multiguard([authGuard, userGuard()]),
    component: () => import(/* webpackChunkName: "project" */ "../../pages/ProjectPage"),
    redirect: () =>
      redirectFromLocalStorage({
        localStorageKey: projectLocalStorageKey,
        validRoutes: [LIST, CALENDAR],
        defaultRoute: LIST,
      }),
    children: [
      {
        path: "list",
        name: LIST,
        beforeEnter: beforeEnterProject,
        component: () =>
          import(
            /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectListPage"
          ),
      },
      {
        path: "calendar",
        name: CALENDAR,
        beforeEnter: beforeEnterProject,
        component: () =>
          import(
            /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectCalendarPage"
          ),
      },
    ],
  },
  {
    path: "/project/:projectId",
    name: VIEW,
    beforeEnter: multiguard([authGuard, userGuard()]),
    component: () =>
      import(/* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectViewPage"),
    redirect: { name: INFO },
    children: [
      {
        path: "info",
        name: INFO,
        component: () =>
          import(
            /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectViewPage/projectInfoPage"
          ),
        children: [
          {
            path: "details",
            name: INFO_DETAILS,
            component: () =>
              import(
                /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectViewPage/projectInfoPage/MenuPage"
              ),
          },
        ],
      },
      {
        path: "task",
        name: TASK.ROOT,
        component: () =>
          import(/* webpackChunkName: "project" */ "../../pages/TaskManagement/Tasks"),
        redirect: () =>
          redirectFromLocalStorage({
            localStorageKey: projectViewTaskLocalStorageKey,
            validRoutes: [TASK.LIST.ROOT, TASK.CALENDAR.ROOT],
            defaultRoute: TASK.LIST.ROOT,
          }),
        children: [
          {
            path: "list",
            name: TASK.LIST.ROOT,
            beforeEnter: beforeEnterProjectViewTask,
            component: () =>
              import(
                /* webpackChunkName: "project" */ "../../pages/TaskManagement/Tasks/TaskList"
              ),
            children: [
              {
                path: "menu/:taskId",
                name: TASK.LIST.MENU.ROOT,
                component: () =>
                  import(
                    /* webpackChunkName: "project" */ "../../pages/TaskManagement/Tasks/MenuPage"
                  ),
                children: [
                  {
                    path: "details",
                    name: TASK.LIST.MENU.DETAILS,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/TaskManagement/MenuPages/DetailsPage"
                      ),
                  },
                  {
                    path: "history",
                    name: TASK.LIST.MENU.HISTORY,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/TaskManagement/MenuPages/HistoryPage"
                      ),
                  },
                  {
                    path: "to-do-list",
                    name: TASK.LIST.MENU.TO_DO_LIST,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/TaskManagement/MenuPages/ToDoList"
                      ),
                  },
                  {
                    path: "storage/:folderId",
                    name: TASK.LIST.MENU.STORAGE,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/TaskManagement/MenuPages/StoragePage"
                      ),
                  },
                  {
                    path: "forms",
                    name: TASK.LIST.MENU.FORMS,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/TaskManagement/MenuPages/FormPage"
                      ),
                  },
                  {
                    path: "product-usage",
                    name: TASK.LIST.MENU.PRODUCT_USAGE,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/TaskManagement/MenuPages/ProductUsagePage"
                      ),
                  },
                  {
                    path: "comments",
                    name: TASK.LIST.MENU.COMMENTS,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/TaskManagement/MenuPages/CommentsPage"
                      ),
                  },
                ],
              },
            ],
          },
          {
            path: "calendar",
            name: TASK.CALENDAR.ROOT,
            beforeEnter: beforeEnterProjectViewTask,
            component: () =>
              import(
                /* webpackChunkName: "project" */ "../../pages/TaskManagement/Tasks/TaskCalendar"
              ),
            children: [
              {
                path: "menu/:taskId",
                name: TASK.CALENDAR.MENU,
                component: () =>
                  import(
                    /* webpackChunkName: "project" */ "../../pages/TaskManagement/Tasks/MenuPage"
                  ),
                children: [
                  {
                    path: "details",
                    name: TASK.CALENDAR.MENU.DETAILS,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/TaskManagement/MenuPages/DetailsPage"
                      ),
                  },
                  {
                    path: "history",
                    name: TASK.CALENDAR.MENU.HISTORY,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/TaskManagement/MenuPages/HistoryPage"
                      ),
                  },
                  {
                    path: "to-do-list",
                    name: TASK.CALENDAR.MENU.TO_DO_LIST,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/TaskManagement/MenuPages/ToDoList"
                      ),
                  },
                  {
                    path: "storage/:folderId",
                    name: TASK.CALENDAR.MENU.STORAGE,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/TaskManagement/MenuPages/StoragePage"
                      ),
                  },
                  {
                    path: "forms",
                    name: TASK.CALENDAR.MENU.FORMS,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/TaskManagement/MenuPages/FormPage"
                      ),
                  },
                  {
                    path: "product-usage",
                    name: TASK.CALENDAR.MENU.PRODUCT_USAGE,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/TaskManagement/MenuPages/ProductUsagePage"
                      ),
                  },
                  {
                    path: "comments",
                    name: TASK.CALENDAR.MENU.COMMENTS,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/TaskManagement/MenuPages/CommentsPage"
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "storage",
        name: STORAGE,
        component: () =>
          import(
            /* webpackChunkName: "project" */ "../../pages/FileManagement/ProjectStoragePage"
          ),
        children: [
          {
            path: "all-files/:rootId/:folderId",
            name: ALL_FILES,
            component: () =>
              import(
                /* webpackChunkName: "project" */ "../../pages/FileManagement/ProjectStoragePage/AllFiles"
              ),
          },
        ],
      },
      {
        path: "members",
        name: VIEW_MEMBERS,
        component: () =>
          import(
            /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectViewPage/ProjectMembersPage"
          ),
        redirect: () => {
          return redirectFromLocalStorage({
            localStorageKey: projectMembersLocalStorageKey,
            validRoutes: [MEMBERS_EMPLOYEE_LIST, MEMBERS_CONTACT_LIST],
            defaultRoute: MEMBERS_EMPLOYEE_LIST,
          });
        },
        children: [
          {
            path: "employees",
            name: MEMBERS_EMPLOYEE_LIST,
            beforeEnter: beforeEnterProjectMembers,
            component: () =>
              import(
                /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectViewPage/ProjectMembersPage/projectEmployeeMembersList"
              ),
          },
          {
            path: "contacts",
            name: MEMBERS_CONTACT_LIST,
            beforeEnter: beforeEnterProjectMembers,
            component: () =>
              import(
                /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectViewPage/ProjectMembersPage/projectContactMembersList"
              ),
          },
        ],
      },
      {
        path: "contacts",
        name: VIEW_CONTACTS,
        beforeEnter: multiguard([authGuard, userGuard()]),
        component: () =>
          import(
            /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectViewPage/projectContactsPage"
          ),
        redirect: { name: CONTACT_PERSON_LIST },
        children: [
          {
            path: "contact-person-list",
            name: CONTACT_PERSON_LIST,
            component: () =>
              import(
                /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectViewPage/projectContactsPage/ContactPersonListPage"
              ),
          },
          {
            path: "client-list",
            name: CLIENT_LIST,
            component: () =>
              import(
                /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectViewPage/projectContactsPage/ClientListPage"
              ),
          },
        ],
      },
      {
        path: "comments",
        name: VIEW_COMMENTS,
        component: () =>
          import(
            /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectViewPage/projectCommentsPage"
          ),
      },
      {
        path: "form",
        name: FORM.ROOT,
        component: () =>
          import(
            /* webpackChunkName: "project" */ "../../pages/FormManagement/ProjectForms"
          ),
        redirect: { name: FORM.LIST.ROOT },
        children: [
          {
            path: "list",
            name: FORM.LIST.ROOT,
            component: () =>
              import(
                /* webpackChunkName: "project" */ "../../pages/FormManagement/Forms/FormList"
              ),
            children: [
              {
                path: "menu/:formId",
                name: FORM.LIST.MENU.ROOT,
                component: () =>
                  import(
                    /* webpackChunkName: "project" */ "../../pages/FormManagement/Forms/MenuPage"
                  ),
                children: [
                  {
                    path: "details",
                    name: FORM.LIST.MENU.DETAILS,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/FormManagement/MenuPages/DetailsPage"
                      ),
                  },
                  {
                    path: "comments",
                    name: FORM.LIST.MENU.COMMENTS,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/FormManagement/MenuPages/CommentsPage"
                      ),
                  },
                  {
                    path: "storage/:folderId",
                    name: FORM.LIST.MENU.STORAGE,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/FormManagement/MenuPages/StoragePage"
                      ),
                  },
                  {
                    path: "signature",
                    name: FORM.LIST.MENU.SIGNATURE,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/FormManagement/MenuPages/SignaturePage"
                      ),
                  },
                  {
                    path: "history",
                    name: FORM.LIST.MENU.HISTORY,
                    component: () =>
                      import(
                        /* webpackChunkName: "project" */ "../../pages/FormManagement/MenuPages/HistoryPage"
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "product-usage",
        name: PRODUCTS,
        component: () =>
          import(
            /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectViewPage/projectProductsPage"
          ),
      },
      {
        path: "ebyggesok",
        name: E_BYGGESOK,
        beforeEnter: contactUserRedirectGuard,
        component: () =>
          import(
            /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectViewPage/projectEByggesokPage"
          ),
      },
      {
        path: "sub-projects",
        name: SUB_PROJECTS,
        beforeEnter: multiguard([authGuard, userGuard()]),
        component: () =>
          import(
            /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectViewPage/projectSubProjectsPage"
          ),
        redirect: () =>
          redirectFromLocalStorage({
            localStorageKey: projectSubProjectsLocalStorageKey,
            validRoutes: [SUB_PROJECTS_LIST, SUB_PROJECTS_CALENDAR],
            defaultRoute: SUB_PROJECTS_LIST,
          }),
        children: [
          {
            path: "list",
            name: SUB_PROJECTS_LIST,
            beforeEnter: beforeEnterProjectSubProjects,
            component: () =>
              import(
                /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectListPage"
              ),
          },
          {
            path: "calendar",
            name: SUB_PROJECTS_CALENDAR,
            beforeEnter: beforeEnterProjectSubProjects,
            component: () =>
              import(
                /* webpackChunkName: "project" */ "../../pages/ProjectPage/ProjectCalendarPage"
              ),
          },
        ],
      },
      {
        path: "time-registration",
        name: TIME_REGISTRATION,
        beforeEnter: multiguard([authGuard, userGuard()]),
        component: () =>
          import(
            /* webpackChunkName: "timeRegistration" */ "../../pages/TimeRegistrationPage"
          ),
        redirect: { name: TIME_REGISTRATION_ALL_HOURS },
        children: [
          {
            path: "all-hours",
            name: TIME_REGISTRATION_ALL_HOURS,
            component: () =>
              import(
                /* webpackChunkName: "timeRegistration" */ "../../pages/TimeRegistrationPage/AllHoursPage"
              ),
          },
        ],
      },
    ],
  },
];
