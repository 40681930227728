export const numberMixins = {
  methods: {
    numberMixins_preventNonNumericInput(event, allowFloat = false) {
      //Code allows for multiple decimal points and pasting in non-numeric values but these are minor issues
      if (event.ctrlKey || event.metaKey) return true;

      const validKeys = [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        allowFloat ? "Comma" : "",
        allowFloat ? "Period" : "",
      ];
      if (validKeys.includes(event.code)) {
        return true;
      }
      const key = event.key;
      const isNumeric = !isNaN(Number(key));
      const isValidInput = isNumeric && key !== " ";
      if (!isValidInput) {
        event.preventDefault();
      }
    },
  },
};
