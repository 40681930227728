<template>
  <v-dialog v-model="localDialog" scrollable max-width="400px">
    <v-card v-if="dialog" color="secondary">
      <v-card-title>{{ $t("common.note") }}</v-card-title>
      <v-card-text class="primary-text--text">
        <div v-html="$t('signUp.step2.companyAlreadyExists')"></div>
      </v-card-text>
      <v-card-actions>
        <v-btn depressed style="width: 38%" color="primary" @click="logout">
          {{ $t("common.logout") }}
        </v-btn>
        <v-spacer />
        <v-btn depressed style="width: 38%" color="primary" @click="close">
          {{ $t("common.continue") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    domainCount: Number,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    close() {
      this.localDialog = false;
    },
    logout() {
      this.$auth.logout({ logoutParams: { returnTo: window.location.origin } });
    },
  },
};
</script>
