<template>
  <AppWidgetLibraryItemTemplate
    :title="$t('widgetLibrary.latestImages.title')"
    :subTitle="$t('widgetLibrary.latestImages.subTitle')"
    :planCode="planCode"
    :showStar="showStar"
    :icon="$icons.LIGHT.COMMON.IMAGE"
    @add="onAdd"
  />
</template>

<script>
export default {
  props: {
    planCode: String,
    showStar: Boolean,
  },
  methods: {
    onAdd() {
      this.$emit("add");
    },
  },
};
</script>
