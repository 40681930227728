import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import { v4 } from "uuid";

const state = () => ({
  isLoading: false,
  hourTypes: [],
});

const getters = {};

const actions = {
  async getHourTypes({ commit }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}hour-type`)
      .then(({ data }) => {
        const hourTypes = data.hourTypes;
        commit("setHourTypes", hourTypes);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  async createHourType({ commit }, { body }) {
    return axios
      .post(`${apiUrl}hour-type`, body)
      .then(({ data }) => {
        const hourType = data.hourType;
        if (hourType) {
          commit("updateHourType", { hourType, tempId: body.tempId });
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  },

  async updateHourType({ commit, state }, { id, body }) {
    const oldHourType = state.hourTypes.find((x) => x.id === id);

    commit("updateHourType", { id, hourType: { ...oldHourType, ...body } });

    return axios
      .put(`${apiUrl}hour-type/${id}`, { ...body })
      .then(({ data }) => {
        const hourType = data.hourType;
        commit("updateHourType", { hourType, id: hourType.id });
      })
      .catch((error) => {
        commit("updateHourType", { hourType: oldHourType, id: oldHourType.id });
        throw new Error(error);
      });
  },
  async deleteHourType({ commit, state }, { id }) {
    const oldHourType = state.hourTypes.find((x) => x.id === id);
    commit("removeHourType", { id: oldHourType.id });

    return axios
      .delete(`${apiUrl}hour-type/${id}`)
      .then(() => {
        commit("removeHourType", { id });
      })
      .catch((error) => {
        commit("addHourType", oldHourType);
        throw new Error(error);
      });
  },
};

const mutations = {
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setHourTypes(state, hourTypes) {
    state.hourTypes = hourTypes;
  },
  addHourType(state, hourType) {
    state.hourTypes.push(hourType);
  },
  removeHourType(state, { id, tempId }) {
    const index = state.hourTypes.findIndex((ht) =>
      !!tempId ? ht.tempId === tempId : ht.id === id,
    );

    if (index !== -1) {
      state.hourTypes.splice(index, 1);
    }
  },
  updateHourType(state, { hourType, id, tempId }) {
    const index = state.hourTypes.findIndex((ht) =>
      !!tempId ? ht.tempId === tempId : ht.id === id,
    );

    if (index === -1) return;
    state.hourTypes.splice(index, 1, hourType);
  },
};

export const hourTypes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
