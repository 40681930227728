<script>
import { VSwitch } from "vuetify/lib";
export default {
  extends: VSwitch,
  props: {
    inset: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "success",
    },
  },
};
</script>
