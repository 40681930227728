export function formatDataTableOptionsToQuery(options) {
  if (!options) return {};
  const detachedCopy = JSON.parse(JSON.stringify(options));

  const offset = (options.page - 1) * options.itemsPerPage;
  const limit = options.itemsPerPage;

  const fieldsToDelete = [
    "multiSort",
    "mustSort",
    "page",
    "itemsPerPage",
    "pageCount",
    "stickyCols",
  ];

  fieldsToDelete.forEach((field) => delete detachedCopy[field]);

  const query = {
    ...detachedCopy,
    ...(!!limit ? { limit } : {}),
    offset: !!offset ? offset : 0,
  };

  return query;
}
