<script>
import { VIcon } from "vuetify/lib";
import { VBtn } from "vuetify/lib";

export default {
  props: {
    values: Array,
    id: String,
  },
  data() {
    return {
      localValues: [],
    };
  },
  watch: {
    values: {
      handler(values) {
        if (this.localValues.length > 0) return;
        this.localValues = [...values];
      },
      immediate: true,
    },
  },
  methods: {
    addChoice() {
      this.localValues = this.localValues.concat("");
      this.focusField(this.localValues.length - 1);
    },
    deleteChoice(index) {
      this.localValues = [
        ...this.localValues.slice(0, index),
        ...this.localValues.slice(index + 1),
      ];
      this.updateValues();
    },
    updateChoice({ value, index }) {
      this.localValues[index] = value;
    },
    fieldBlur({ value, index }) {
      if (!value || value?.trim() === "") {
        this.deleteChoice(index);
      } else {
        this.updateValues();
      }
    },
    updateValues() {
      this.$emit("values:update", { values: this.localValues });
    },
    focusField(index) {
      this.$nextTick(() => {
        const field = document.getElementById(`formItemValuesChoice_${this.id}_${index}`);
        field?.focus();
      });
    },
    genChoice({ value, index }) {
      return this.$createElement("div", { class: "d-flex align-center pb-2" }, [
        this.$createElement(
          "div",
          { class: "font-weight-bold d-flex justify-center", style: "width: 32px;" },
          [this.$createElement("span", index + 1)],
        ),
        this.genChoiceInput({ value, index }),
        this.$createElement(
          VBtn,
          {
            class: "ml-2 mr-2",
            props: { icon: true, color: "error" },
            on: { click: () => this.deleteChoice(index) },
          },
          [this.$createElement(VIcon, this.$icons.LIGHT.ACTION.DELETE)],
        ),
      ]);
    },
    genChoiceInput({ value, index }) {
      return this.$createElement("AppDefaultTextField", {
        props: {
          id: `formItemValuesChoice_${this.id}_${index}`,
          label: this.$t("templateManagement.form.builder.item.choiceTextPlaceholder"),
          hideDetails: true,
          value: value,
        },
        on: {
          input: (e) => this.updateChoice({ value: e, index }),
          blur: (e) => this.fieldBlur({ value: e.target.value, index }),
          keydown: (e) => this.onkeydownPress(e),
        },
      });
    },
    onkeydownPress(e) {
      if (e.key === "Enter") {
        const value = e.target.value;
        if (!value || value?.trim() === "") return;
        this.addChoice();
      }
    },
    genChoices() {
      return this.localValues.map((value, index) => {
        return this.genChoice({ value, index });
      });
    },
    genAddChoice() {
      return this.$createElement(
        VBtn,
        { props: { text: true, block: true }, on: { click: this.addChoice } },
        this.$t("templateManagement.form.builder.item.addChoice"),
      );
    },
  },
  render(h) {
    return h("div", { class: "w-100 py-2" }, [this.genChoices(), this.genAddChoice()]);
  },
};
</script>
