<template>
  <v-divider v-if="isMini" />
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("appDrawer", {
      isMini: "isDrawerMiniVariant",
    }),
  },
};
</script>
