<script>
import { projectTemplateBuilderMixins } from "@/helpers/mixins";
import { VCard, VForm } from "vuetify/lib";
import CustomButtonTooltip from "./CustomButtonTooltip.vue";

export default {
  mixins: [projectTemplateBuilderMixins],
  props: {
    item: Object,
    isDragging: Boolean,
  },
  data() {
    return {
      showNameTextField: false,
      name: {
        isValid: false,
      },
      isHovering: false,
    };
  },
  computed: {
    isSelected() {
      return this.isHovering && !this.isDragging;
    },
  },
  methods: {
    updateItem({ value, fieldPath }) {
      const oldValue = this.getItemOldValue({ fieldPath });
      const hasId = !!this.item.id;
      if (value !== oldValue || !hasId) {
        const body = this.getItemBody({ value, fieldPath });
        this.$emit("item:update", { item: this.item, body });
      }
    },
    getItemOldValue({ fieldPath }) {
      const fieldPathArr = fieldPath.split(".");
      const oldValue = fieldPathArr.reduce((prevValue, currentValue) => {
        if (!prevValue) return null;
        return prevValue[currentValue];
      }, this.item);

      return oldValue;
    },
    getItemBody({ value, fieldPath }) {
      const fieldPathArr = fieldPath.split(".");
      const body = {};
      fieldPathArr.reduce((prevValue, currentValue, index) => {
        const isLastIteration = fieldPathArr.length - 1 === index;
        const currentBody = (prevValue[currentValue] = isLastIteration ? value : {});
        return currentBody;
      }, body);

      return body;
    },
    vaidateAndUpdateFormField({ value, fieldName, fieldPath }) {
      const hasId = !!this.item.id;
      const isFormValid = this[fieldName].isValid;
      if (isFormValid || !hasId) {
        this.updateItem({ value, fieldPath });
      }
    },
    deleteToDo() {
      this.$emit("item:delete", { item: this.item });
    },
    onToDoNameChangeEnter() {
      setTimeout(() => {
        document.getElementById(`toDoItemTextField_${this.item?.refId}`)?.blur();
      }, 20);
    },
    getHoverBorderColor() {
      const isDark = this.$vuetify.theme.isDark;
      const darkBorder = this.$vuetify.theme.defaults.dark["primary-border"];
      const lightBorder = this.$vuetify.theme.defaults.light["primary-border"];
      return isDark ? darkBorder : lightBorder;
    },
    getBorderOnHover() {
      const hoverBorderColor = this.getHoverBorderColor();
      const color = this.isSelected ? hoverBorderColor : "transparent";
      return `border-top: 1px solid ${color}; border-right: 1px solid ${color}; border-bottom: 1px solid ${color};border-left: 1px solid ${color};`;
    },
    genNameColumn() {
      const nameDiv = this.$createElement(
        "div",
        {
          class: "d-flex align-center pl-5",
          style: "height: 65px;",
          on: {
            click: (e) => {
              this.showNameTextField = true;
              setTimeout(
                () =>
                  document
                    .getElementById(`toDoItemTextField_${this.item?.refId}`)
                    ?.focus(),
                20,
              );
            },
          },
        },
        [this.$createElement("div", this.item?.name)],
      );

      const textField = this.$createElement("AppDefaultTextField", {
        class: "px-2 block-draggable",
        props: {
          id: `toDoItemTextField_${this.item?.refId}`,
          hideDetails: true,
          value: this.item?.name,
          rules: this.projectTemplateBuilderMixins_nameRules,
          backgroundColor: "transparent",
        },
        on: {
          blur: (e) => {
            this.showNameTextField = false;
            const value = e?.target?.value;
            this.vaidateAndUpdateFormField({
              value,
              fieldName: "name",
              fieldPath: "name",
            });
          },
          keydown: (e) => (e.key === "Enter" ? this.onToDoNameChangeEnter() : ""),
        },
      });

      const nameForm = this.$createElement(
        VForm,
        {
          props: { value: this.name.isValid },
          on: {
            submit: (e) => e.preventDefault(),
            input: (e) => (this.name.isValid = e),
          },
        },
        [textField],
      );

      return this.$createElement("div", { style: `width: 95%;` }, [
        this.showNameTextField || !this.item.id ? nameForm : nameDiv,
      ]);
    },
    genActionColumn() {
      const deleteBtn = this.$createElement(CustomButtonTooltip, {
        class: "ml-2 block-draggable",
        props: {
          text: this.$t("common.delete"),
          icon: this.$icons.LIGHT.ACTION.DELETE,
          disabled: !this.projectTemplateBuilderMixins_isItemSaved(this.item),
        },
        on: {
          "button:click": () => this.deleteToDo(),
        },
      });

      return this.$createElement("div", { style: `width: 5%;` }, [
        this.isSelected ? deleteBtn : this.$createElement("div", ""),
      ]);
    },
    genToDoItemRow() {
      return this.$createElement(
        VCard,
        {
          style: `${this.getBorderOnHover()} height: 65px; ${
            this.isDragging ? "" : "border-radius: 0px"
          }`,
          class: `pa-2 draggable-item d-flex align-center`,
          props: { flat: true },
          on: {
            mouseover: (e) => (this.isHovering = true),
            mouseleave: (e) => (this.isHovering = false),
          },
        },
        [
          this.isSelected
            ? this.$createElement("span", {
                style: `background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGUlEQVQI12NgAIJz507/Z4AxVqxYAuEgAwD26QnAz+WLlgAAAABJRU5ErkJggg==);
                  background-repeat:repeat;
                  position:absolute;
                  left:-3px;
                  width: 13px;
                  height:100%;
                  background-color:#fff;
                  border-width:0 0 0 2px;
                  border-style:solid;
                  border-color:#fff;
                  cursor: move`,
              })
            : null,
          this.genNameColumn(),
          this.genActionColumn(),
        ],
      );
    },
  },
  render(h) {
    return this.genToDoItemRow();
  },
};
</script>
