import { CDN_BASE_URL } from "./cdn";

export const CDN_LOGIN_PLATFORM_ICONS_URL = `${CDN_BASE_URL}/assets/login_platform_icons`;
export const CDN_LOGIN_PLATFORM_ICONS_URLS = Object.freeze({
  GRIPR: `${CDN_LOGIN_PLATFORM_ICONS_URL}/gripr.png`,
  GOOGLE: `${CDN_LOGIN_PLATFORM_ICONS_URL}/google.png`,
  APPLE: `${CDN_LOGIN_PLATFORM_ICONS_URL}/apple.png`,
  MICROSOFT: `${CDN_LOGIN_PLATFORM_ICONS_URL}/microsoft.png`,
});

export const LOGIN_METHOD_KEYS = Object.freeze({
  AUTH0: "auth0",
  GOOGLE: "google-oauth2",
  MICROSOFT: "windowslive",
  APPLE: "apple",
});

export const LOGIN_METHOD_NAMES = Object.freeze({
  AUTH0: "Auth0",
  GOOGLE: "Google",
  MICROSOFT: "Microsoft",
  APPLE: "Apple",
});

export const LOGIN_METHODS_ALLOWED_TO_ADD = Object.freeze(
  Object.entries(LOGIN_METHOD_KEYS)
    .filter(([key]) => key !== "AUTH0")
    .map(([key, value]) => ({
      key: value,
      name: LOGIN_METHOD_NAMES[key],
    })),
);
