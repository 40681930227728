var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.hideHeader)?_c('AppPageHeader',{staticClass:"mx-2",attrs:{"title":_vm.title,"large":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('AppWidgetButtons',{attrs:{"background":"background"},on:{"save":_vm.save,"cancel":_vm.cancel,"enableEditMode":_vm.enableEditMode}})]},proxy:true}],null,false,2455461961)}):_vm._e(),(_vm.isLoadingLayout || !_vm.localLayout)?_c('div',{staticClass:"h-100"},[_c('AppWidgetsSkeletonLoaders')],1):_c('dashboard',{attrs:{"id":"dashboard"}},[_c('dash-layout',{key:_vm.localLayout.breakpoint,ref:"dashLayout",attrs:{"breakpoint":_vm.localLayout.breakpoint,"numberOfCols":_vm.localLayout.numberOfCols,"debug":false}},_vm._l((_vm.localLayout.widgetItems),function(widget){return _c('dash-item',_vm._b({key:widget.id,staticStyle:{"user-select":"unset !important"},attrs:{"draggable":_vm.isEditMode,"resizable":_vm.isEditMode},on:{"moveStart":function($event){return _vm.onTransform($event, true)},"resizeStart":function($event){return _vm.onTransform($event, true)},"moveEnd":function($event){return _vm.onTransform($event, false)},"resizeEnd":function($event){return _vm.onTransform($event, false)}}},'dash-item',widget,false,true),[_c(widget.component.widget,_vm._b({tag:"component",staticClass:"h-100",attrs:{"templateProps":{
            //isTransforming is used to set transparency when resizing or moving widget
            isTransforming: _vm.isTransforming,
            isEditMode: _vm.isEditMode,
            id: widget.id,
            //whether the user has the required roles to use the widget
            hasRequiredRoles: widget.hasRequiredRoles,
            //add to this funcion if we implement more widgets for specific plans
            showStar: !_vm.planRestrictionsMixins_canAddWidget(widget.name),
            planCode: widget.minPlanCode,
          }},on:{"delete:click":function($event){return _vm.onDeleteClick(widget.id)},"item:update":function($event){return _vm.onItemUpdate({ widget: widget, propsToUpdate: $event })},"editChart:click":function($event){return _vm.onEditChartClick({ id: widget.id, props: widget.props })}}},'component',widget.props,false))],1)}),1)],1),_c('AppWidgetLibraryDrawer',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEditMode),expression:"isEditMode"}],attrs:{"addedWidgets":_vm.localWidgetItems,"model":_vm.model,"modelId":_vm.modelId},on:{"add":_vm.addWidget}}),_c('AppConfigureWidgetsDialog',{attrs:{"data":_vm.editChart.data},on:{"save":_vm.onEditChartSave},model:{value:(_vm.editChart.dialog),callback:function ($$v) {_vm.$set(_vm.editChart, "dialog", $$v)},expression:"editChart.dialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }