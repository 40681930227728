export async function base64ToBlob(base64, contentType) {
  const response = await fetch(`data:${contentType};base64,${base64}`);
  const blob = await response.blob();
  return blob;
}

export async function base64ToBlobObjectUrl(base64, contentType) {
  const blob = await base64ToBlob(base64, contentType);
  return URL.createObjectURL(blob);
}

export function isBase64(base64) {
  try {
    atob(base64);
    return true;
  } catch (error) {
    return false;
  }
}
