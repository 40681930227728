import { normalizeFormTask } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  task: null,
  isLoading: false,
  isUpdating: false,
});

const getters = {};

const actions = {
  async getTask({ state, commit }, { id }) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}task/${id}`)
      .then(async ({ data }) => {
        const task = data?.task;
        if (task) {
          const normalizedTask = normalizeFormTask(task);
          commit("setTask", normalizedTask);
        }
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async updateTask({ commit }, { id, data }) {
    commit("updating", true);
    if (typeof data?.status === "string") {
      commit("updatingStatus", true);
    }
    return axios
      .put(`${apiUrl}task/${id}`, { ...data })
      .then(async ({ data }) => {
        const task = data?.task;
        if (task) {
          const normalizedTask = normalizeFormTask(task);
          commit("updateTask", normalizedTask);
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("updatingStatus", false);
        commit("updating", false);
      });
  },
  async deleteTask({ commit }, { id }) {
    return axios
      .delete(`${apiUrl}task/${id}`)
      .then(async (res) => {
        commit("setTask", null);
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
};

const mutations = {
  setTask(state, task) {
    state.task = task;
  },
  updateTask(state, task) {
    state.task = task;
  },
  updating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  updatingStatus(state, isUpdatingStatus) {
    if (state.task) {
      state.task.isUpdatingStatus = isUpdatingStatus;
    }
  },
};

export const formTask = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
