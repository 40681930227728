export const FORM_SIGN_SESSION_STATUS = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  DECLINED: "DECLINED",
  COMPLETED: "COMPLETED",
};

export const VALID_FORM_SIGN_SESSION_STATUSES = [
  FORM_SIGN_SESSION_STATUS.IN_PROGRESS,
  FORM_SIGN_SESSION_STATUS.COMPLETED,
  FORM_SIGN_SESSION_STATUS.DECLINED,
];
