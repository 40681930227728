<template>
  <div class="p-relative">
    <div class="label--small">
      {{ $t(labelKey) | capitalize }}
    </div>
    <AppDefaultSelect
      v-model="localValue"
      :items="items"
      :loading="loading"
      :noDataText="$t('common.noData')"
    />
  </div>
</template>

<script>
export default {
  props: {
    labelKey: String,
    items: Array,
    loading: Boolean,
    userAvatar: Boolean,
    value: [Boolean, String],
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
};
</script>
