import { LIGHT } from "../icons";
import heic2any from "heic2any";

export const storageMixins = {
  methods: {
    storageMixins_getStorageIconName(item) {
      const { mime, meta, type, isLocked } = item;

      if (type === this.$constant.FOLDER) {
        if (isLocked) {
          return LIGHT.FILE.FOLDER_LOCK;
        }
        return LIGHT.FILE.FOLDER;
      }
      let iconName = LIGHT.FILE.FILE;
      if (mime) {
        if (mime.match("image/*")) {
          iconName = LIGHT.FILE.FILE_IMAGE;
        }

        if (mime.match("video/*")) {
          iconName = LIGHT.FILE.FILE_VIDEO;
        }

        if (mime.match("application/pdf")) {
          iconName = LIGHT.FILE.FILE_PDF;
        }

        // is word
        if (this.$constant.OFFICE_WORD_MIMETYPES.includes(mime)) {
          iconName = "$docx";
        }

        // is odt
        if (this.$constant.ODT_MIMETYPE === mime) {
          iconName = "$odt";
        }

        // is excel
        if (this.$constant.OFFICE_EXCEL_MIMETYPES.includes(mime)) {
          iconName = "$xlsx";
        }

        // is ods
        if (this.$constant.ODS_MIMETYPE === mime) {
          iconName = "$ods";
        }

        // is powerpoint
        if (this.$constant.OFFICE_POWERPOINT_MIMETYPES.includes(mime)) {
          iconName = "$pptx";
        }

        // is odp
        if (this.$constant.ODP_MIMETYPE === mime) {
          iconName = "$odp";
        }

        if (mime.match("text/plain")) {
          iconName = LIGHT.FILE.FILE_LINES;
        }
      }
      if (Array.isArray(meta)) {
        const metaItem = meta.find(
          (item) => item.field === this.$constant.E_SIGN_META_FIELD,
        );
        if (metaItem) {
          iconName = LIGHT.FILE.FILE_SIGNATURE;
        }
      }
      return iconName;
    },
    storageMixins_getPermissionLevelIconName(permissionLevel) {
      if (permissionLevel === "RESTRICTED") {
        return LIGHT.COMMON.USER_GROUP;
      }
      if (permissionLevel === "PRIVATE") {
        return LIGHT.COMMON.USER;
      }
      if (permissionLevel === "PUBLIC") {
        return LIGHT.COMMON.USERS;
      }
    },
    storageMixins_getStorageCount(storage) {
      const fileCount = parseInt(storage.countFiles || 0);
      const folderCount = parseInt(storage.countFolders || 0);
      const totalCount = fileCount + folderCount;
      return totalCount >= 99 ? "99+" : `${totalCount}`;
    },
    async storageMixins_getFiles(e) {
      const files = Array.from(e.target.files ? e.target.files : e.dataTransfer.files);
      return await this.storageMixins_filesConvetion(files);
    },
    async storageMixins_filesConvetion(files) {
      const convertedFiles = await Promise.all(
        files.map(async (file) => {
          const extension = file.name?.split(".").pop()?.toLowerCase();
          if (extension === "heic" || extension === "heif") {
            return await this.storageMixins_convertHEICToJPG(file);
          }
          return file;
        }),
      );
      return convertedFiles;
    },
    async storageMixins_convertHEICToJPG(file) {
      const blobUrl = window.URL.createObjectURL(file);
      const blobRes = await fetch(blobUrl);
      const blob = await blobRes.blob();
      return heic2any({
        blob,
        toType: "image/jpeg",
        quality: 1, // Adjust quality from 0 to 1 as needed
      })
        .then((conversionResult) => {
          return new File([conversionResult], file.name.replace(/\..+$/, ".jpg"), {
            type: "image/jpeg",
          });
        })
        .catch((error) => {
          console.error("Error converting HEIC to JPEG:", error);
          return file;
        });
    },
    storageMixins_getReadableFileType(type) {
      const translationKey = `fileManagement.fileExplorer.readableFileTypes.${type}`;
      return this.$te(translationKey) ? this.$t(translationKey) : type;
    },
  },
};
