import { normalizeNotifications } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  notifications: [],
  isLoading: false,
});

const getters = {
  getNotificationsByCategory: (state, getters) => (type) => {
    const notifications = getters.getNotificationsByType(type);
    const notificationsByCategory = getters.notificationsByCategory(notifications, type);
    return notificationsByCategory;
  },
  getNotificationsByType: (state) => (type) => {
    return state.notifications.find((n) => n.type === type);
  },
  notificationsByCategory: (state) => (notifications, type) => {
    const categories = [];
    for (const trigger of notifications?.triggers || []) {
      const category = categories.find((n) => n.category === trigger.category);
      const channel = { type, ...trigger };
      const triggers = [{ key: trigger.key, channels: [channel] }];
      if (!category) {
        categories.push({ category: trigger.category, triggers });
        continue;
      }
      const catTrigger = category.triggers.find((c) => c.key === trigger.key);
      if (!catTrigger) {
        category.triggers.push(triggers[0]);
        continue;
      }
      catTrigger.channels.unshift(channel);
    }
    return categories;
  },
};

const actions = {
  async getNotifications({ commit }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}notification`)
      .then(({ data }) => {
        const notifications = data?.notifications;
        const normalizedNotifications = normalizeNotifications(notifications);
        commit("setNotifications", normalizedNotifications);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async updateTrigger({ commit }, { isEnabled, triggerKey, type }) {
    commit("toggleUpdatingDone", {
      triggerKey,
      type,
      isLoading: true,
    });
    return axios
      .put(`${apiUrl}notification`, {
        isEnabled,
        triggerKey,
        type,
      })
      .then(({ data }) => {
        const trigger = data.trigger;
        trigger.isLoading = false;
        commit("updateTrigger", { trigger, type });
        return trigger;
      })
      .catch((error) => {
        commit("toggleUpdatingDone", {
          triggerKey,
          type,
          isLoading: false,
        });
        throw new Error(error);
      });
  },
};

const mutations = {
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
  updateTrigger(state, { trigger, type }) {
    const notificationIdx = state.notifications.findIndex((x) => x.type === type);
    if (notificationIdx !== -1) {
      const triggerIdx = state.notifications[notificationIdx].triggers.findIndex(
        (x) => x.key === trigger.key,
      );
      if (triggerIdx !== -1) {
        state.notifications[notificationIdx].triggers.splice(triggerIdx, 1, trigger);
      }
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  toggleUpdatingDone(state, { triggerKey, type, isLoading }) {
    const notificationIdx = state.notifications.findIndex((x) => x.type === type);
    if (notificationIdx !== -1) {
      const triggerIdx = state.notifications[notificationIdx].triggers.findIndex(
        (x) => x.key === triggerKey,
      );
      if (triggerIdx !== -1) {
        state.notifications[notificationIdx].triggers.splice(triggerIdx, 1, {
          ...state.notifications[notificationIdx].triggers[triggerIdx],
          isLoading,
        });
      }
    }
  },
};

export const settingsNotifications = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
