<script>
import AppDefaultTooltip from "@/components/common/default/AppDefaultTooltip.vue";
import { VIcon } from "vuetify/lib";
export default {
  props: {
    text: String,
    icon: String,
  },

  render(h) {
    return h(
      AppDefaultTooltip,
      {
        props: {
          top: true,
        },
        scopedSlots: {
          activator: ({ on }) => {
            return h(
              VIcon,
              {
                class: "ml-2 cursor-pointer",
                props: { small: true },
                on: on,
              },
              this.icon,
            );
          },
        },
      },
      this.text,
    );
  },
};
</script>
