<template>
  <tr
    class="list_tr list_tr-height user-select-none"
    :class="rowStateClasses"
    @contextmenu="dataTableItemMixins_onRightClick"
    @click="isSelectable && select(!isSelected)"
  >
    <td>
      <v-simple-checkbox
        :class="{ 'v-card--disabled': !isSelectable }"
        :disabled="!isSelectable"
        color="primary"
        :value="isSelected"
        @input="select($event)"
      />
    </td>
    <td class="p-relative">
      <div class="d-flex align-center justify-start">
        <AppStorageThumbnail
          :item="storage"
          class="mr-4 cursor-pointer"
          @click.stop="onNameClick"
          top
        />
        <div class="table-cell-content__breakable-long-text">
          <span
            class="cursor-pointer underline-on-hover d-inline-block"
            @click.stop="onNameClick"
          >
            {{ storage.name }}
            <span v-if="storage.type === $constant.FOLDER" class="font-weight-bold">
              ({{ storageMixins_getStorageCount(storage) }})
            </span>
            <v-icon
              v-if="showIsAddedToMergeIndicator"
              size="16"
              color="success"
              class="ml-2"
            >
              {{ $icons.REGULAR.COMMON.MERGE }}
            </v-icon>
          </span>
          <div class="d-flex">
            <AppStorageItemInfoRow :storage="storage" />
          </div>
        </div>
      </div>
    </td>
    <td>
      <template v-if="owner">
        <AppUserAvatar :user="owner" show-name :truncate="20" />
      </template>
    </td>
    <td>{{ lastModified }}</td>
    <td>
      <v-btn
        icon
        @click="dataTableItemMixins_onEllipsisClick"
        v-if="isSelectable"
        ref="ellipsis"
      >
        <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import moment from "moment";
import { storageMixins } from "@/helpers/mixins";
import { dataTableItemMixins } from "@/helpers/mixins";
import { mapState } from "vuex";

export default {
  mixins: [storageMixins, dataTableItemMixins],
  props: {
    storage: Object,
    isSelected: Boolean,
    isSelectable: Boolean,
    isContextMenuActiveItem: Boolean,
    contextMenuItemsToHide: Object,
  },
  computed: {
    ...mapState("storageMergeToPdf", {
      selectedFilesToMerge: (state) => state.selectedFilesToMerge,
    }),
    isAddedToMerge() {
      return this.selectedFilesToMerge.some((file) => file.id === this.storage.id);
    },
    showIsAddedToMergeIndicator() {
      return this.isAddedToMerge && !this.contextMenuItemsToHide?.merge;
    },
    rowStateClasses() {
      return {
        "list_tr--active": this.isContextMenuActiveItem,
        "list_tr--selected": this.isSelected,
        "list_tr--success": this.isAddedToMerge,
      };
    },
    lastModified() {
      if (!this.storage.updatedAt) return "";
      return moment(this.storage.updatedAt).format("ll");
    },
    ownerName() {
      const owner = this.storage?.storageOwner;
      if (!owner) return "";
      let name = owner.email;
      if (owner.firstName && owner.lastName) {
        name = `${owner.firstName} ${owner.lastName}`;
      } else if (owner.firstName) {
        name = `${owner.firstName}`;
      } else if (owner.lastName) {
        name = `${owner.lastName}`;
      }

      return name;
    },
    owner() {
      const owner = this.storage?.storageOwner;
      if (!owner) return null;
      return { ...owner, name: this.ownerName };
    },
    isSentToBoligmappa() {
      return this.getStorageLookupByIntegrationId(this.$constant.BOLIGMAPPA);
    },
  },
  methods: {
    onNameClick() {
      this.$emit("name:click", { storage: this.storage });
    },
    openMenu(params) {
      this.$emit("menu:click", { storage: this.storage, ...params });
    },
    select(isSelected) {
      this.$emit("isSelected:change", isSelected);
    },
    getStorageLookupByIntegrationId(integrationId) {
      if (!Array.isArray(this.storage.lookup)) return null;
      const lookup = this.storage.lookup.find((i) => (i.integrationId = integrationId));
      if (!lookup || !lookup?.integrationId) return null;
      return lookup.integrationId;
    },
  },
};
</script>
