<template>
  <AppDefaultTextField
    ref="inputRef"
    :value="formattedValue"
    :hide-details="hideDetails"
    :placeholder="placeholder"
    :disabled="disabled"
    :autofocus="autofocus"
    @keydown.enter="onSubmit"
    @blur="() => $emit('blur', formattedValue)"
  />
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";
import { watch } from "vue";
export default {
  props: {
    value: [Number],
    currencyDisplay: {
      type: String,
      default: "code",
    },
    autofocus: Boolean,
    hideDetails: Boolean,
    placeholder: String,
    disabled: Boolean,
    resetOnSubmit: Boolean,
  },
  methods: {
    focus() {
      this.$refs.inputRef.focus();
    },
    reset() {
      this.$refs.inputRef.reset();
    },
    onSubmit() {
      this.$emit("submit");
    },
  },
  setup(props) {
    const options = {
      //hard coded locale and currency
      currency: "NOK",
      locale: "nb",
      currencyDisplay: props.currencyDisplay,
    };
    const { inputRef, formattedValue, setValue } = useCurrencyInput(options);
    watch(
      () => props.value,
      (value) => {
        setValue(value);
      },
    );
    return { inputRef, formattedValue };
  },
};
</script>
