<script>
export default {
  props: {
    title: String,
  },
  render(h) {
    return h("div", { class: "pb-1 text-caption" }, this.title);
  },
};
</script>
