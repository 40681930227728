<template>
  <div>
    <v-carousel
      :cycle="!hover && !overlay.show"
      hide-delimiter-background
      :delimiter-icon="$icons.SOLID.COMMON.CIRCLE"
      show-arrows-on-hover
      class="rounded-lg"
      height="auto"
      :interval="6000"
      v-model="carouselIndex"
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn icon v-on="on" v-bind="attrs">
          <v-icon color="ui-background ">
            {{ $icons.REGULAR.COMMON.ANGLE_LEFT }}
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn icon v-on="on" v-bind="attrs">
          <v-icon color="ui-background">
            {{ $icons.REGULAR.COMMON.ANGLE_RIGHT }}
          </v-icon>
        </v-btn>
      </template>
      <v-carousel-item v-for="(data, i) in carouselData" :key="i" eager>
        <img
          class="cursor-pointer"
          style="width: 100%; max-height: 50vh; object-fit: contain"
          :src="data.src"
          @click="showFullscreenOverlay"
        />
      </v-carousel-item>
    </v-carousel>
    <v-overlay
      v-model="overlay.show"
      @click.stop="closeFullscreenOverlay"
      class="v-overlay--resizable-content"
    >
      <div class="w-100 h-100 d-flex justify-center">
        <div style="width: 70% !important">
          <v-carousel
            ref="overlayCarousel"
            hide-delimiter-background
            :delimiter-icon="$icons.SOLID.COMMON.CIRCLE"
            height="auto"
            class="rounded-lg"
            :interval="6000"
            v-model="carouselIndex"
          >
            <template v-slot:prev="{ on, attrs }">
              <v-btn icon v-on="on" v-bind="attrs">
                <v-icon color="ui-background ">
                  {{ $icons.REGULAR.COMMON.ANGLE_LEFT }}
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:next="{ on, attrs }">
              <v-btn icon v-on="on" v-bind="attrs">
                <v-icon color="ui-background">
                  {{ $icons.REGULAR.COMMON.ANGLE_RIGHT }}
                </v-icon>
              </v-btn>
            </template>
            <v-carousel-item v-for="(data, i) in carouselData" :key="i" eager>
              <v-img :src="data.src" height="100%" eager />
            </v-carousel-item>
            <v-btn icon style="top: 5px; right: 5px; position: absolute">
              <v-icon>{{ $icons.REGULAR.ACTION.CLOSE }}</v-icon>
            </v-btn>
          </v-carousel>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
export default {
  props: {
    carouselData: {
      type: Array,
      required: true,
    },
    hover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      overlay: {
        show: false,
      },
      carouselIndex: 0,
      delimiterClickHandler: () => {},
      handleArrowKeys: (event) => {
        if (event.key === "ArrowLeft") {
          this.carouselIndex =
            this.carouselIndex > 0
              ? this.carouselIndex - 1
              : this.carouselData.length - 1;
        } else if (event.key === "ArrowRight") {
          this.carouselIndex =
            this.carouselIndex < this.carouselData.length - 1
              ? this.carouselIndex + 1
              : 0;
        }
      },
    };
  },
  watch: {
    ["overlay.show"]: {
      handler(val) {
        if (val) {
          this.addDelimiterClickEvent();
          this.addArrowKeysEventListener();
        } else {
          this.removeDelimitedEventListener();
          this.removeArrowKeysEventListener();
        }
      },
    },
  },
  computed: {
    currentCarouselData() {
      return this.carouselData[this.carouselIndex];
    },
  },
  methods: {
    showFullscreenOverlay() {
      this.overlay.show = true;
    },
    closeFullscreenOverlay() {
      this.overlay.show = false;
    },
    getDelimitedElements() {
      if (!this.$refs.overlayCarousel) return [];
      return this.$refs.overlayCarousel.$el.querySelectorAll(
        ".v-carousel__controls__item",
      );
    },
    addDelimiterClickEvent() {
      this.$nextTick(() => {
        const delimiters = this.getDelimitedElements();
        this.delimiterClickHandler = (event) => this.handleDelimiterClick(event);

        delimiters.forEach((delimiter) => {
          delimiter.addEventListener("click", this.delimiterClickHandler);
        });
      });
    },
    removeDelimitedEventListener() {
      const delimiters = this.getDelimitedElements();
      delimiters.forEach((delimiter) => {
        delimiter.removeEventListener("click", this.delimiterClickHandler);
      });
    },
    addArrowKeysEventListener() {
      window.addEventListener("keydown", this.handleArrowKeys);
    },
    removeArrowKeysEventListener() {
      window.removeEventListener("keydown", this.handleArrowKeys);
    },
    handleDelimiterClick(event) {
      event.stopPropagation();
    },
  },
  beforeDestroy() {
    this.removeDelimitedEventListener();
    this.removeArrowKeysEventListener();
  },
};
</script>
