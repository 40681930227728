<script>
import Title from "./Title.vue";
import ToDoItem from "./ToDoItem.vue";

export default {
  props: {
    toDoItems: Array,
  },
  data() {
    return {
      isAssigningTimeLimit: false,
      isDragging: false,
    };
  },
  methods: {
    removeBorderOnChildrenIfNotDraggingChild() {
      return !this.isDragging ? "project-template__item-container" : "";
    },
    removeBorderBotIfNoRows() {
      return this.toDoItems.length === 0 ? "" : "border-b";
    },
    genToDoItem() {
      const toDoRows = [];
      for (const item of this.toDoItems) {
        const toDoRow = this.$createElement(ToDoItem, {
          props: {
            item,
            isDragging: this.isDragging,
          },
        });
        toDoRows.push(toDoRow);
      }
      return this.$createElement(
        "div",
        {
          class: `border-l border-r ${this.removeBorderBotIfNoRows()} rounded-b-lg overflow-hidden project-template__item-container`,
        },
        toDoRows,
      );
    },
    showBorderAndBorderRadiusIfNoItems() {
      return this.toDoItems.length === 0 ? "rounded-b-lg" : "";
    },
  },
  render(h) {
    return h("div", { class: "pb-8" }, [
      h(Title, {
        props: { title: this.$t("templateManagement.project.builder.toDo.title") },
      }),
      h("div", [
        h(
          "div",
          {
            class: `d-flex rounded-t-lg ${this.showBorderAndBorderRadiusIfNoItems()} pa-2 px-4 border-a text-subtitle-2`,
          },
          [h("div", this.$t("templateManagement.project.builder.toDo.name"))],
        ),
        this.genToDoItem(),
      ]),
    ]);
  },
};
</script>
