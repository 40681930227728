<script>
import { VIcon, VBtn, VTooltip } from "vuetify/lib";
export default {
  props: {
    text: String,
    maxTooltipWidth: {
      type: Number,
      default: 200,
    },
    icon: String,
    disabled: Boolean,
  },
  methods: {
    onButtonClick(event) {
      event.stopPropagation();
    },
  },
  render(h) {
    return h("div", [
      h(
        VTooltip,
        {
          props: {
            top: true,
            maxWidth: this.maxTooltipWidth,
            contentClass: "text-caption",
          },
          scopedSlots: {
            activator: ({ on }) => {
              return h(
                VBtn,
                {
                  props: { icon: true, disabled: this.disabled },
                  on: {
                    ...on,
                    click: (event) => this.onButtonClick(event),
                  },
                },
                [h(VIcon, this.icon)],
              );
            },
          },
        },
        this.text,
      ),
    ]);
  },
};
</script>
