import axios from "axios";
import _ from "lodash";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let updateCancel;

const state = () => ({
  items: [],
  isLoading: false,
  isCreating: false,
});

const getters = {
  getDefaultValueTemplates:
    (state) =>
    ({ model, categoryId }) => {
      const item = state.items.find(
        (item) => item.model === model && item.categoryId === categoryId,
      );
      return item?.defaultValueTemplates ?? [];
    },
  hasFetchedDefaultValueTemplates:
    (state) =>
    ({ model, categoryId }) => {
      const hasFetchedDefaultValueTemplates = state.items.some(
        (item) => item.model === model && item.categoryId === categoryId,
      );
      return hasFetchedDefaultValueTemplates;
    },
};

const actions = {
  async fetchDefaultValueTemplates({ commit }, { model, categoryId }) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}template/default-value-template`, {
        params: { model, categoryId },
      })
      .then(({ data }) => {
        commit("setDefaultValueTemplates", data);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },

  async createDefaultValueTemplate({ commit }, { defaultValueTemplate }) {
    if (updateCancel) updateCancel();

    commit("creating", true);
    commit("addDefaultValueTemplate", {
      defaultValueTemplate,
      model: defaultValueTemplate.model,
      categoryId: defaultValueTemplate.categoryId,
    });
    return axios
      .post(
        `${apiUrl}template/default-value-template`,
        { ...defaultValueTemplate },
        {
          cancelToken: new CancelToken(function executor(c) {
            updateCancel = c;
          }),
        },
      )
      .then(({ data }) => {
        commit("setDefaultValueTemplates", data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw new Error(error);
        }
      })
      .finally(() => {
        if (!updateCancel) {
          commit("removeUnsavedDefaultValueTemplate", {
            model: defaultValueTemplate.model,
            categoryId: defaultValueTemplate.categoryId,
          });
          commit("creating", false);
        }
      });
  },
  async updateDefaultValueTemplate(
    { commit, getters },
    { categoryId, defaultValueTemplate, model },
  ) {
    if (updateCancel) updateCancel();
    const oldValues = getters.getDefaultValueTemplates({ model, categoryId });
    commit("updateDefaultValueTemplate", { model, categoryId, defaultValueTemplate });
    const newValues = getters.getDefaultValueTemplates({ model, categoryId });

    return axios
      .put(
        `${apiUrl}template/default-value-template`,
        {
          model,
          categoryId,
          defaultValueTemplates: newValues,
        },
        {
          cancelToken: new CancelToken(function executor(c) {
            updateCancel = c;
          }),
        },
      )
      .then(({ data }) => {
        commit("setDefaultValueTemplates", data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("setDefaultValueTemplates", oldValues);
          throw new Error(error);
        }
      });
  },
  async updateDefaultValueTemplates(
    { commit, getters },
    { model, categoryId, defaultValueTemplates },
  ) {
    if (updateCancel) updateCancel();

    const oldValues = getters.getDefaultValueTemplates({ model, categoryId });

    commit("setDefaultValueTemplates", { defaultValueTemplates, model, categoryId });

    return axios
      .put(
        `${apiUrl}template/default-value-template`,
        { model, categoryId, defaultValueTemplates },
        {
          cancelToken: new CancelToken(function executor(c) {
            updateCancel = c;
          }),
        },
      )
      .then(({ data }) => {
        commit("setDefaultValueTemplates", data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("setDefaultValueTemplates", oldValues);
          throw new Error(error);
        }
      });
  },
  async deleteDefaultValueTemplate(
    { commit, getters },
    { defaultValueTemplate, categoryId, model },
  ) {
    if (updateCancel) updateCancel();

    commit("removeDefaultValue", { model, categoryId, defaultValueTemplate });
    return axios
      .put(
        `${apiUrl}template/default-value-template`,
        {
          model,
          categoryId,
          defaultValueTemplates: getters.getDefaultValueTemplates({ model, categoryId }),
        },
        {
          cancelToken: new CancelToken(function executor(c) {
            updateCancel = c;
          }),
        },
      )
      .then(async ({ data }) => {
        commit("setDefaultValueTemplates", data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw new Error(error);
        }
      });
  },
};

const mutations = {
  setDefaultValueTemplates(state, { defaultValueTemplates, model, categoryId }) {
    const indexToUpdate = state.items.findIndex(
      (item) => item.model === model && item.categoryId === categoryId,
    );

    if (indexToUpdate !== -1) {
      state.items[indexToUpdate].defaultValueTemplates = defaultValueTemplates;
    } else {
      state.items.push({ model, categoryId, defaultValueTemplates });
    }
    return state;
  },
  addDefaultValueTemplate(state, { defaultValueTemplate, model, categoryId }) {
    const modelIndex = state.items.findIndex(
      (item) => item.model === model && item.categoryId === categoryId,
    );

    if (modelIndex !== -1) {
      const defaultValueTemplates = state.items[modelIndex].defaultValueTemplates;
      if (defaultValueTemplates) {
        defaultValueTemplates.push(defaultValueTemplate);
      } else {
        state.items[modelIndex].defaultValueTemplates = [defaultValueTemplate];
      }
    } else {
      const newItem = {
        model,
        categoryId,
        defaultValueTemplates: [defaultValueTemplate],
      };
      state.items.push(newItem);
    }
  },

  removeUnsavedDefaultValueTemplate(state, { model, categoryId }) {
    const itemToUpdate = state.items.find(
      (item) => item.model === model && item.categoryId === categoryId,
    );

    if (itemToUpdate) {
      itemToUpdate.defaultValueTemplates = itemToUpdate.defaultValueTemplates.filter(
        (s) => s.id,
      );
    }
  },
  updateDefaultValueTemplate(state, { model, categoryId, defaultValueTemplate }) {
    if (!defaultValueTemplate) throw new Error("DefaultValue not found");

    const itemToUpdate = state.items.find(
      (item) => item.model === model && item.categoryId === categoryId,
    );

    if (itemToUpdate) {
      const index = itemToUpdate.defaultValueTemplates.findIndex(
        (x) => x.id === defaultValueTemplate.id,
      );

      if (index !== -1) {
        itemToUpdate.defaultValueTemplates.splice(index, 1, defaultValueTemplate);
      }
    }
  },
  removeDefaultValue(state, { model, categoryId, defaultValueTemplate }) {
    if (!defaultValueTemplate) throw new Error("DefaultValue not found");

    const itemToUpdate = state.items.find(
      (item) => item.model === model && item.categoryId === categoryId,
    );

    if (itemToUpdate) {
      const index = itemToUpdate.defaultValueTemplates.findIndex(
        (x) => x.id === defaultValueTemplate.id,
      );

      if (index !== -1) {
        itemToUpdate.defaultValueTemplates.splice(index, 1);
      }
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  creating(state, isCreating) {
    state.isCreating = isCreating;
  },
};

export const defaultValueTemplates = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
