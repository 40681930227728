<template>
  <AppContextMenu ref="menu" :closeOnContentClick="false" height="440">
    <AppUnitAutocomplete
      autofocus
      activateOnFocus
      resetOnSubmit
      isReadyToInitialFetch
      :maxWidthMultiplier="1"
      @change="onSubmit({ id: $event })"
    />
  </AppContextMenu>
</template>

<script>
export default {
  methods: {
    onSubmit({ id }) {
      this.$emit("submit", { unit: id });
      this.close();
    },
    close() {
      this.$refs.menu.close();
    },
    open(e) {
      this.$refs.menu.open(e);
    },
  },
};
</script>
