<script>
export default {
  props: {
    item: Object,
  },
  render(h) {
    return h(
      "div",
      { class: "d-flex align-center py-3 text-h5 font-weight-bold" },
      `${this.item.name}`,
    );
  },
};
</script>
