import i18n from "@/plugins/i18n";

export function normalizePathData(rawPath) {
  const lastPath = rawPath[rawPath.length - 1];
  const path = rawPath.map((x) => {
    const isLastLevel = lastPath.level === x.level;
    const text = x.level === 0 && x.name === "root" ? i18n.t("common.home") : x.name;
    return {
      ...x,
      disabled: false,
      exact: true,
      text,
      isLastLevel,
    };
  });

  let truncatedPath = path;

  const penultimatePath = rawPath[rawPath.length - 2];
  truncatedPath = rawPath
    .map((x) => {
      const text = x.level === 0 && x.name === "root" ? i18n.t("common.home") : x.name;
      const isLastLevel = lastPath.level === x.level;
      const isPenultimatePath = penultimatePath
        ? penultimatePath
          ? penultimatePath.level === x.level
          : false
        : false;
      if (x.level === 0 || isLastLevel || isPenultimatePath) {
        return {
          ...x,
          disabled: false,
          exact: true,
          text,
          isLastLevel,
          isPenultimatePath,
        };
      }
      return null;
    })
    .filter((x) => x !== null);

  let prependPath = [];

  if (rawPath.length > 2) {
    prependPath = [
      ...rawPath
        .filter((x) => {
          return x.id !== penultimatePath.id && x.id !== lastPath.id;
        })
        .map((x) => {
          const text =
            x.level === 0 && x.name === "root" ? i18n.t("common.home") : x.name;
          return {
            ...x,
            disabled: false,
            exact: true,
            text: text,
          };
        })
        .sort((a, b) => b.level - a.level),
    ];
  }

  return { path, prependPath, truncatedPath };
}
