<template>
  <v-btn text @click="$emit('click')" class="add-file-btn" color="primary">
    <v-icon class="pr-3">{{ $icons.LIGHT.ACTION.ADD }}</v-icon>
    <span class="font-weight-bold">
      {{ $t(translationKey) }}
    </span>
  </v-btn>
</template>

<script>
export default {
  props: {
    translationKey: String,
  },
};
</script>

<style scoped>
.add-file-btn {
  margin-top: 8px;
  margin-right: 4px;
}
</style>
