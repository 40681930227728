<template>
  <div>
    <AppMiniContentSelectionDivider />
    <template v-if="activeDomainId">
      <AppNavigationList
        v-if="hasVisibleItemsForSection($constant.DOMAIN_DRAWER_SECTIONS.DASHBOARD)"
      >
        <AppNavigationItem
          v-for="item in getItemsForSection($constant.DOMAIN_DRAWER_SECTIONS.DASHBOARD)"
          v-if="showRoute(item)"
          :key="item.routeName"
          :to="{ name: item.routeName }"
          :prependIcon="item.icon"
          :title="$t(item.title)"
          prepend-icon-small
          replace
        />
      </AppNavigationList>

      <AppMiniContentSelectionDivider />

      <!-- Manage Section -->
      <AppNavigationList
        v-if="hasVisibleItemsForSection($constant.DOMAIN_DRAWER_SECTIONS.MANAGE)"
      >
        <AppContentSectionHeader>
          {{ $t("drawer.routeLabel.manage") }}
        </AppContentSectionHeader>
        <AppNavigationItem
          v-for="item in getItemsForSection($constant.DOMAIN_DRAWER_SECTIONS.MANAGE)"
          v-if="showRoute(item)"
          :key="item.routeName"
          :to="{ name: item.routeName }"
          :prependIcon="item.icon"
          :title="$t(item.title)"
          prepend-icon-small
          replace
        />
      </AppNavigationList>

      <!-- Company Section -->
      <AppNavigationList
        v-if="hasVisibleItemsForSection($constant.DOMAIN_DRAWER_SECTIONS.COMPANY)"
      >
        <AppContentSectionHeader>
          {{ $t("drawer.routeLabel.company") }}
        </AppContentSectionHeader>
        <AppNavigationItem
          v-for="item in getItemsForSection($constant.DOMAIN_DRAWER_SECTIONS.COMPANY)"
          v-if="showRoute(item)"
          :key="item.routeName"
          :to="{ name: item.routeName }"
          :prependIcon="item.icon"
          :title="$t(item.title)"
          :appendIcon="
            item.showAppendIcon ? $icons.SOLID.ACTION.GO_NEXT_LEVEL : undefined
          "
          prepend-icon-small
          :show-append-icon-only-on-hover="item.showAppendIcon"
          replace
        />
      </AppNavigationList>

      <!-- Pin Navigation Menu -->
      <AppNavigationList>
        <div ref="pinMenuBtn">
          <AppNavigationItem
            :title="$t('common.more')"
            :prependIcon="$icons.REGULAR.ACTION.CIRCLE_MENU"
            prepend-icon-small
            @click="openPinMenu"
          />
        </div>
      </AppNavigationList>

      <!-- Pin Menu -->
      <AppContextMenu ref="pinMenu">
        <v-list dense class="pa-0">
          <v-list-item
            v-for="item in navigationItems"
            v-if="item.show"
            :key="item.routeName"
            class="px-2"
            :to="{ name: item.routeName }"
          >
            <v-list-item-icon class="ml-2 mr-2">
              <v-icon small>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-body-2">{{
                $t(item.title)
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="my-0">
              <AppDefaultTooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    style="min-width: 40px"
                    depressed
                    small
                    color="transparent"
                    @click.stop.prevent="togglePin(item.routeName)"
                  >
                    <v-icon small v-bind="attrs" v-on="on" color="primary">
                      {{
                        isPinned(item.routeName)
                          ? $icons.SOLID.ACTION.PIN
                          : $icons.REGULAR.ACTION.PIN
                      }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{
                  isPinned(item.routeName)
                    ? $t("drawer.pinMenu.unpin")
                    : $t("drawer.pinMenu.pin")
                }}</span>
              </AppDefaultTooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </AppContextMenu>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      pinnedItems: [],
    };
  },
  watch: {
    activeDomainId: {
      immediate: true,
      handler(newVal) {
        const pinnedItems = JSON.parse(
          localStorage.getItem(`domain_drawer_pins_${newVal}`),
        );
        const allPins = JSON.stringify(
          this.navigationItems.map((item) => item.routeName),
        );

        //First time when nothing is saved in local storage, set all pins as pinned
        //Need to handle this when new menu items are added :)
        if (pinnedItems === null) {
          localStorage.setItem(`domain_drawer_pins_${newVal}`, allPins);
        }
        this.pinnedItems = pinnedItems || JSON.parse(allPins);
      },
    },
  },
  computed: {
    navigationItems() {
      return [
        {
          routeName: this.$routeNames.DASHBOARD.ROOT,
          title: "drawer.route.dashboard",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.DASHBOARD,
          icon: this.$icons.REGULAR.ROUTE.DASHBOARD,
          show: !this.isHighestPriorityRoleContact,
        },
        {
          routeName: this.$routeNames.TASK.ROOT,
          title: "drawer.route.tasks",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.MANAGE,
          icon: this.$icons.REGULAR.ROUTE.TASK,
          show: true,
        },
        {
          routeName: this.$routeNames.FORM.ROOT,
          title: "drawer.route.forms",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.MANAGE,
          icon: this.$icons.REGULAR.ROUTE.FORM,
          show: true,
        },
        {
          routeName: this.$routeNames.TIME_REGISTRATION.ROOT,
          title: "drawer.route.timeRegistration",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.MANAGE,
          icon: this.$icons.REGULAR.ROUTE.TIME_REGISTRATION,
          show: !this.isHighestPriorityRoleContact && this.isTimeEntryFeatureEnabled,
        },
        {
          routeName: this.$routeNames.PROJECT.ROOT,
          title: "drawer.route.projects",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.MANAGE,
          icon: this.$icons.REGULAR.ROUTE.PROJECT,
          show: true,
        },
        {
          routeName: this.$routeNames.STORAGE.ROOT,
          title: "drawer.route.files",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.MANAGE,
          icon: this.$icons.REGULAR.ROUTE.STORAGE,
          show: !this.isHighestPriorityRoleContact,
        },
        {
          routeName: this.$routeNames.CONTACT.ROOT,
          title: "drawer.route.contacts",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.COMPANY,
          icon: this.$icons.REGULAR.ROUTE.CONTACT,
          show: !this.isHighestPriorityRoleContact,
        },
        {
          routeName: this.$routeNames.EMPLOYEE.ROOT,
          title: "drawer.route.employees",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.COMPANY,
          icon: this.$icons.REGULAR.ROUTE.USER,
          show: !this.isHighestPriorityRoleContact,
        },
        {
          routeName: this.$routeNames.TEMPLATE.ROOT,
          title: "drawer.route.templates",
          section: this.$constant.DOMAIN_DRAWER_SECTIONS.COMPANY,
          icon: this.$icons.REGULAR.ROUTE.TEMPLATE,
          showAppendIcon: true,
          show: !this.isHighestPriorityRoleContact && this.isOwnerOrAdmin,
        },
      ];
    },
    storageKey() {
      return `domain_drawer_pins_${this.activeDomainId}`;
    },
    isOwnerOrAdmin() {
      return this.isDomainOwner || this.isDomainAdmin;
    },
    ...mapGetters("auth", {
      activeDomainId: "activeDomainId",
      isDomainOwner: "isDomainOwner",
      isDomainAdmin: "isDomainAdmin",
      isHighestPriorityRoleContact: "isHighestPriorityRoleContact",
      isTimeEntryFeatureEnabled: "isTimeEntryFeatureEnabled",
    }),
  },
  methods: {
    openPinMenu() {
      const from = this.$refs.pinMenuBtn;

      this.$refs.pinMenu.open({
        from,
        direction: "right",
      });
    },
    showRoute(item) {
      const { routeName, show } = item;

      return show && (this.isPinned(routeName) || this.isUnderRoute(routeName));
    },
    hasVisibleItemsForSection(section) {
      return this.navigationItems
        .filter((item) => item.section === section)
        .some((item) => this.showRoute(item));
    },
    isPinned(routeName) {
      return this.pinnedItems.includes(routeName);
    },
    isUnderRoute(routeName) {
      return this.$route.matched.some((m) => m.name === routeName);
    },
    togglePin(routeName) {
      const newPinnedItems = this.isPinned(routeName)
        ? this.pinnedItems.filter((item) => item !== routeName)
        : [...this.pinnedItems, routeName];

      this.pinnedItems = newPinnedItems;
      localStorage.setItem(this.storageKey, JSON.stringify(newPinnedItems));
    },
    getItemsForSection(section) {
      return this.navigationItems.filter((item) => item.section === section);
    },
  },
};
</script>
