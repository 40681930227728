<template>
  <v-dialog v-model="localDialog" max-width="290px">
    <v-card style="overflow: hidden">
      <v-date-picker
        height="200px"
        v-model="dates"
        range
        color="primary"
        :first-day-of-week="dateMixins_firstDayOfWeek"
        locale-first-day-of-year="4"
      />
      <div style="height: 72px">
        <v-scroll-x-transition>
          <div v-if="hasSelectedMultipleDates">
            <div class="px-5 pb-6">
              <AppDayPicker v-model="days" />
            </div>
          </div>
        </v-scroll-x-transition>
      </div>
      <div class="pb-3 pl-3">
        <AppDialogActionBtnPair
          :confirmText="$t('common.confirm')"
          :cancelText="$t('common.cancel')"
          :disabled="isConfirmDisabled"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { dateMixins } from "@/helpers/mixins";

const allDays = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

export default {
  mixins: [dateMixins],
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    selectedItems: Array,
  },
  data() {
    return {
      dates: [],
      days: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"],
    };
  },
  model: {
    prop: "dialog",
    event: "input",
  },
  computed: {
    hasSelectedMultipleDates() {
      return this.dates.length > 1;
    },
    isConfirmDisabled() {
      const hasPickedNoDays = !this.dates?.length;
      const hasPickedNoDates = !this.dates?.length;

      return hasPickedNoDays || hasPickedNoDates;
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    selectedDates: {
      get() {
        return this.dates;
      },
      set(value) {
        this.$emit("update:dates", value);
      },
    },
    selectedDays: {
      get() {
        return this.days;
      },
      set(value) {
        this.$emit("update:days", value);
      },
    },
  },
  methods: {
    reset() {
      this.dates = [];
      this.days = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"];
    },
    onConfirm() {
      this.$emit("confirm", {
        dates: this.dates,
        days: this.hasSelectedMultipleDates ? this.days : allDays,
        items: this.selectedItems,
      });
      this.localDialog = false;
      this.reset();
    },
    onCancel() {
      this.localDialog = false;
    },
  },
};
</script>
