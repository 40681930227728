export function summonStonlyGuide(guideId) {
  window.StonlyWidget("openGuide", {
    guideId,
    widgetOptions: {
      widgetPlacement: "MODAL",
      widgetFormat: "CLASSIC",
      widgetSizeType: "MEDIUM",
    },
  });
}
