<template>
  <div>
    <file-pond
      name="avatar"
      ref="pond"
      acceptedFileTypes="image/png,image/jpeg,image/bmp"
      imageCropAspectRatio="1:1"
      :imageResizeTargetWidth="200"
      :imageResizeTargetHeight="200"
      :imagePreviewHeight="170"
      stylePanelLayout="compact circle"
      styleLoadIndicatorPosition="right bottom"
      styleProgressIndicatorPosition="right bottom"
      styleButtonRemoveItemPosition="right bottom"
      styleButtonProcessItemPosition="right bottom"
      :instantUpload="true"
      :allowImagePreview="true"
      :allowImageTransform="true"
      :allowImageCrop="true"
      :allowImageResize="true"
      :files="myFiles"
      :credits="[]"
    />
  </div>
</template>

<script>
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import en from "filepond/locale/en-en";
import nb from "filepond/locale/no_nb";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit,
  FilePondPluginImageResize,
);

export default {
  props: {
    avatarPath: String,
  },
  components: {
    FilePond,
  },
  data() {
    return {};
  },
  computed: {
    myFiles() {
      return this.avatarPath
        ? [
            {
              source: this.avatarPath,
              options: {
                type: "local",
              },
            },
          ]
        : [];
    },
  },
  methods: {},
  async mounted() {
    if (this.$i18n.locale === "en") {
      setOptions(en);
    } else {
      setOptions(nb);
    }
    setOptions({
      server: {
        process: async (
          fieldName,
          file,
          metadata,
          load,
          error,
          progress,
          abort,
          transfer,
          options,
        ) => {
          const formData = new FormData();
          formData.append(fieldName, file, file.name);
          await this.$store.dispatch("profile/uploadAvatar", { formData });
          this.$store.dispatch("auth/getUser");
          this.$emit("avatar:upload");
          load();
          return {
            abort: () => {
              abort();
            },
          };
        },
        load: async (source, load, error, progress, abort, headers) => {
          const blob = await this.$store.dispatch("profile/getAvatar");
          load(blob);
        },
        remove: async (source, load, error) => {
          await this.$store.dispatch("profile/deleteAvatar");
          this.$store.dispatch("auth/getUser");
          this.$emit("avatar:delete");
          load();
        },
        revert: async (uniqueFileId, load, error) => {
          await this.$store.dispatch("profile/deleteAvatar");
          this.$emit("avatar:delete");
          this.$store.dispatch("auth/getUser");
          load();
        },
      },
    });
  },
};
</script>
