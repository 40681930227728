import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import { v4 } from "uuid";
import { normalizePathData } from "@/helpers/normalization";
const pathTrackId = v4();

const state = () => ({
  items: [],
  path: [],
  truncatedPath: [],
  prependPath: [],
  isLoading: false,
  isLinking: false,
  isPathLoading: false,
});

const getters = {
  isLoading: (state) => {
    return state.isPathLoading || state.isLoading;
  },
};

const actions = {
  async getFolders({ commit }, id) {
    commit("foldersLoading", true);
    return axios
      .get(`${apiUrl}storage/folder/${id}/children`, {
        params: {
          type: "FOLDER",
        },
      })
      .then(({ data }) => {
        const items = data?.storages.map((item) => ({
          ...item,
          isLoading: false,
          isSelected: false,
        }));
        commit("setFolders", items);
        commit("foldersLoading", false);
      })
      .catch((error) => {
        commit("foldersLoading", false);
        throw new Error(error);
      });
  },
  async getPath({ commit }, id) {
    commit("pathLoading", true);
    return axios
      .get(`${apiUrl}storage/folder/${id}/path`, { headers: { trackId: pathTrackId } })
      .then(({ data }) => {
        const { path, prependPath, truncatedPath } = normalizePathData(data?.path || []);
        commit("setPrependPath", prependPath);
        commit("setTruncatedStoragePath", truncatedPath);
        commit("setPath", path);
        commit("pathLoading", false);
      })
      .catch((error) => {
        commit("pathLoading", false);
        throw new Error(error);
      });
  },
  async linkFilesToFolders({ commit, dispatch }, { files, folders }) {
    commit("linking", true);
    return axios
      .post(`${apiUrl}storage/link`, { files, folders }, { skipErrorHandling: true })
      .then(({ data }) => {
        commit("linking", false);
        return data;
      })
      .catch((error) => {
        commit("linking", false);
        const response = error.response;
        if (response && response.data.statusCode === 409) {
          dispatch(
            "snackbar/snackbar",
            {
              show: true,
              text: "Conflict",
              timeout: 5000,
              color: "error",
            },
            { root: true },
          );
        } else {
          dispatch(
            "snackbar/snackbar",
            {
              show: true,
              text: "Something went wrong...",
              timeout: 5000,
              color: "error",
            },
            { root: true },
          );
        }

        return null;
      });
  },
};

const mutations = {
  setFolders(state, folders) {
    state.items = folders;
  },
  resetPaths(state) {
    state.path = [];
    state.truncatedPath = [];
    state.prependPath = [];
  },
  setPath(state, path) {
    state.path = path;
  },
  setTruncatedStoragePath(state, path) {
    state.truncatedPath = path;
  },
  setPrependPath(state, path) {
    state.prependPath = path;
  },
  foldersLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  linking(state, isLinking) {
    state.isLinking = isLinking;
  },
  pathLoading(state, isLoading) {
    state.isPathLoading = isLoading;
  },
};

export const linkableFolders = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
