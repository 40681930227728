<template>
  <v-dialog v-model="localDialog" max-width="450" v-if="content" scrollable>
    <v-card>
      <v-card-title>{{ $t("repeatOption.task.copyContentDialog.title") }}</v-card-title>
      <v-card-text class="pb-2">
        <v-checkbox
          class="pb-3 mt-0 pt-0"
          hide-details
          :input-value="localContent.every((lc) => lc.value)"
          @change="
            localContent.forEach((l) => {
              l.value = $event;
            })
          "
        >
          <template v-slot:label>
            <span class="primary-text--text text-no-wrap">
              {{ $t("repeatOption.task.copyContentDialog.toggleEverything") }}
            </span>
          </template>
        </v-checkbox>
        <v-divider class="pb-1" />
        <v-row no-gutters>
          <v-col cols="6" v-for="localCont in localContent" :key="localCont.field">
            <v-checkbox
              class="mt-0 pt-0 pt-3"
              hide-details
              @change="localCont.value = $event"
              :input-value="localCont.value"
            >
              <template v-slot:label>
                <span class="primary-text--text text-no-wrap">
                  {{
                    $options.filters.capitalize(
                      $t(`repeatOption.task.copyContentDialog.${localCont.field}`),
                    )
                  }}
                </span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <AppDialogActionBtnPair @confirm="onConfirm" @cancel="localDialog = false" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      localContent: [],
    };
  },
  props: {
    content: Array,
    dialog: Boolean,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  watch: {
    dialog: {
      handler(val) {
        if (val) {
          this.localContent = JSON.parse(JSON.stringify(this.content));
        }
      },
    },
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("dialog:change", val);
      },
    },
  },
  methods: {
    onConfirm() {
      this.$emit("content:change", this.localContent);
      this.localDialog = false;
    },
  },
  mounted() {},
};
</script>
