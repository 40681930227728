<template>
  <span v-if="value">{{ formatCurrency(value) }}</span>
  <span v-else-if="showZeroIfNoValue">{{ formatCurrency(0) }}</span>
  <span v-else>-</span>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    showZeroIfNoValue: Boolean,
    maximumFractionDigits: {
      type: Number,
      default: 2,
    },
  },
  methods: {
    formatCurrency(value) {
      const { locale, numberFormats } = this.$i18n;
      //hard code nb for now, can be changed to use locale from i18n
      const currencyData = numberFormats["nb"]?.currency;

      const formatter = new Intl.NumberFormat("nb", {
        ...currencyData,
        maximumFractionDigits: this.maximumFractionDigits,
      });
      return formatter.format(value);
    },
  },
};
</script>
