<template>
  <AppAddRecipientsDialog
    v-model="localDialog"
    :confirmButtonText="
      $t('formManagement.signatureSession.sendToSignDialog.completeButton')
    "
    :genEmptyRecipient="genEmptyRecipient"
    :validateRecipients="validateRecipients"
    :submitAction="submitAction"
    :languages="languages"
    @submit="onSubmit"
    @error="onError"
  >
    <template v-slot:additional-content>
      <AppInfoAlert class="mb-2" v-if="!isFormStatusCategoryDone">
        {{
          $t(
            "formManagement.signatureSession.sendToSignDialog.infoMessages.youCantFillOutAfterSigning",
          )
        }}
      </AppInfoAlert>
      <AppErrorAlert class="mb-2" v-if="showNeedsAtLeastOneSignerError">
        {{
          $t(
            "formManagement.signatureSession.sendToSignDialog.errorMessages.needAtLeastOneSigner",
          )
        }}
      </AppErrorAlert>
    </template>
  </AppAddRecipientsDialog>
</template>

<script>
import { mapGetters } from "vuex";
import { sendFormToSign } from "@/services/form/formSignSession";

export default {
  data() {
    return {
      showNeedsAtLeastOneSignerError: false,
      languages: [
        {
          value: "nb",
          text: this.$t("common.languages.fromLanguageCodes.nb"),
        },
        {
          value: "en",
          text: this.$t("common.languages.fromLanguageCodes.en"),
        },
      ],
    };
  },
  props: {
    dialog: Boolean,
    formId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    ...mapGetters("form", {
      isFormStatusCategoryDone: "isFormStatusCategoryDone",
    }),
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    genEmptyRecipient() {
      return { name: "", email: "", language: "nb", userId: null };
    },
    validateRecipients(recipients) {
      const hasAtLeastOneSigner = recipients.length > 0;
      this.showNeedsAtLeastOneSignerError = !hasAtLeastOneSigner;
      return hasAtLeastOneSigner;
    },
    submitAction(recipients) {
      const body = {
        formId: this.formId,
        signers: recipients.map(({ name, email, language }) => ({
          name,
          email,
          language,
        })),
      };
      return sendFormToSign({ body });
    },
    onSubmit() {
      this.$emit("submit");
    },
    onError(err) {
      console.error("Error in submitting:", err);
      // Handle the error as needed
    },
  },
};
</script>
