<template>
  <v-expand-transition>
    <div v-if="showAlert">
      <AppInfoAlert class="mb-2">
        {{
          $t(
            "formManagement.signatureSession.sendToSignDialog.infoMessages.formIsSentToSign",
          )
        }}
      </AppInfoAlert>
    </div>
  </v-expand-transition>
</template>

<script>
export default {
  props: {
    showAlert: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
