<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <span class="font-weight-bold text-body-2" v-html="highlightedTitle"></span>
    </div>
    <ModuleInfoRows
      :search="search"
      :item="item"
      :isSelected="isSelected"
      :fields="subTitleFields"
    />
  </div>
</template>

<script>
import _ from "lodash";
import ModuleInfoRows from "./ModuleInfoRows.vue";
import { getDisplayData } from "@/helpers/util/moduleItem";

export default {
  props: {
    item: Object,
    isSelected: Boolean,
    search: String,
    titleField: Object,
    subTitleFields: Array,
  },
  components: {
    ModuleInfoRows,
  },
  computed: {
    displayData() {
      return getDisplayData(this.item, {
        dataKey: this.titleField.dataKey,
        pathToModule: this.titleField.pathToModule,
      });
    },
    highlightedTitle() {
      const title = this.displayData.text;
      return this.$textHelpers.highlightText(title, this.search, this.isSelected);
    },
  },
};
</script>
