export function normalizeForms(forms) {
  return (forms || []).map((form) => normalizeForm(form));
}

export function normalizeForm(form) {
  if (!form) return form;
  const isUpdatingStatus = false;
  const isUpdatingIsSignable = false;
  const isUpdatingIsPrivate = false;

  const normalized = {
    ...form,
    isUpdatingStatus,
    isUpdatingIsSignable,
    isUpdatingIsPrivate,
  };

  return normalized;
}
