import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import i18n from "../../../../plugins/i18n";
const categoriesTranslation = i18n.t("cms.template.document.category");

const state = () => ({
  categories: [],
  isLoading: false,
});

const getters = {};

const actions = {
  async getCategories({ commit }, { group }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}template/document/category`, { params: { group } })
      .then((res) => {
        let categories = res.data.categories.map((item) => {
          const name = item?.name;
          const translatedName = categoriesTranslation[name?.toLowerCase()];
          return {
            ...item,
            value: item?.id,
            text: translatedName ? translatedName : name ? name : "undefined",
          };
        });
        commit("setCategories", categories);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const templateDocumentGroup = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
