import axios from "axios";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { getInstance } from "@/auth";
const BASE_URL = `https://${process.env.VUE_APP_AUTH0_DOMAIN}/api/v2`;

const state = () => ({
  auth0User: null,
  isLoading: false,
});

const getters = {
  identities(state) {
    const authService = getInstance();
    const { sub } = authService.user;
    const primaryProvider = sub.split("|")[0];
    const primaryUserId = sub.split("|")[1];

    const identitiesExcludingPrimary = (state.auth0User?.identities || []).map(
      (identity) => {
        const isPrimary =
          identity.provider === primaryProvider && identity.user_id === primaryUserId;
        return {
          isPrimary,
          ...(isPrimary ? { profileData: state.auth0User } : {}),
          ...identity,
        };
      },
    );

    return identitiesExcludingPrimary;
  },
  canAuth0UserChangePassword(state) {
    const auth0User = state.auth0User;
    if (!auth0User) return false;
    const provider = auth0User.user_id.split("|")[0];
    return provider === "auth0";
  },
};

const actions = {
  async getAuth0User({ commit }) {
    const authService = getInstance();
    const token = await authService.getTokenSilently();
    const { sub } = authService.user;

    // create new axios instance to prevent CORS error
    const axiosInstance = axios.create({ headers: { Authorization: `Bearer ${token}` } });
    return axiosInstance
      .get(`${BASE_URL}/users/${sub}`)
      .then(({ data }) => {
        commit("setAuth0User", data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async linkAuth0Identity({ dispatch }, { connection }) {
    const userToLink = await authenticateUser({ connection });
    if (!userToLink) return;
    const targetUserIdToken = userToLink.__raw;
    const authService = getInstance();
    const token = await authService.getTokenSilently();
    const { sub } = authService.user;

    const axiosInstance = axios.create({ headers: { Authorization: `Bearer ${token}` } });
    return axiosInstance
      .post(`${BASE_URL}/users/${sub}/identities`, {
        link_with: targetUserIdToken,
      })
      .finally(() => {
        dispatch("getAuth0User");
      });
  },
  async unlinkAuth0Identity({ dispatch }, { provider, userId }) {
    const authService = getInstance();
    const token = await authService.getTokenSilently();
    const { sub } = authService.user;

    const axiosInstance = axios.create({ headers: { Authorization: `Bearer ${token}` } });
    return axiosInstance
      .delete(`${BASE_URL}/users/${sub}/identities/${provider}/${userId}`)
      .finally(() => {
        dispatch("getAuth0User");
      });
  },
};

const authenticateUser = async ({ connection }) => {
  try {
    const client = await new Auth0Client({
      domain: process.env.VUE_APP_AUTH0_DOMAIN,
      clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    });

    await client.loginWithPopup({
      authorizationParams: {
        max_age: 0,
        scope: "openid",
        connection,
      },
    });

    return await client.getIdTokenClaims();
  } catch (error) {}
};

const mutations = {
  setAuth0User(state, user) {
    state.auth0User = user;
  },
  setLoading(state, loading) {
    state.isLoading = loading;
  },
};

export const auth0User = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
