<template>
  <AppFiltersDrawer
    v-if="localShowDrawer"
    @close="closeFilters"
    :tableModel="tableModel"
    :children="children"
  />
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { getSearchUsers } from "@/services/search/users";
import { filtersItemsMixins } from "@/helpers/mixins";
import { filtersMixins } from "@/helpers/mixins";
import { filtersWatchMixins } from "@/helpers/mixins";

export default {
  mixins: [filtersItemsMixins, filtersMixins, filtersWatchMixins],
  props: {
    showDrawer: Boolean,
    tableModel: String,
  },
  model: {
    prop: "showDrawer",
    event: "change",
  },
  data() {
    return {
      users: [],
      isUsersLoading: false,
    };
  },
  methods: {
    getData() {
      this.getUnits();
      this.getUsers();
    },
    closeFilters() {
      this.localShowDrawer = false;
    },
    async getUsers() {
      this.isUsersLoading = true;
      const params = {
        alwaysIncludeIds: this.userIds,
      };
      getSearchUsers({ params })
        .then((users) => {
          this.users = users;
        })
        .finally(() => {
          this.isUsersLoading = false;
        });
    },
    async getUnits() {
      await this.$store.dispatch("productUnits/getProductUnits");
    },
  },
  computed: {
    localShowDrawer: {
      get() {
        return this.showDrawer;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    ...mapGetters("activeIntegrations", {
      hasProductIntegrationEnabled: "hasProductIntegrationEnabled",
    }),
    ...mapState("productUnits", {
      units: (state) => state.units,
      isUnitsLoading: (state) => state.isLoading,
    }),
    projectId() {
      return this.$route.params.projectId;
    },
    userIds() {
      return this.filtersMixins_filterByKey("userIds") ?? [];
    },
    clientIds() {
      return this.filtersMixins_filterByKey("clientIds") ?? [];
    },
    taskIds() {
      return this.filtersMixins_filterByKey("taskIds") ?? [];
    },
    children() {
      const children = [
        {
          is: "AppFiltersClientMultiAutocomplete",
          labelKey: "common.productFields.supplier",
          filterValueKey: "clientIds",
          itemValue: "id",
          itemText: "name",
          isReadyToInitialFetch: this.isReadyToInitialFetch,
          type: this.$constant.SUPPLIER,
          queryParams: {
            alwaysIncludeIds: this.clientIds,
          },
        },
        {
          is: "AppFiltersMultiAutocomplete",
          labelKey: "common.productFields.unit",
          filterValueKey: "units",
          itemValue: "value",
          itemText: "label",
          items: this.units ?? null,
          loading: this.isUnitsLoading ?? null,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "common.productFields.added",
          itemValue: "value",
          itemText: "text",
          filterValueKey: "createdDate",
          items: this.filtersItemsMixins_dateItems,
        },
        {
          is: "AppFiltersClientGroupedUserAndContactAutocomplete",
          labelKey: "common.productFields.addedBy",
          filterValueKey: "userIds",
          "item-value": "id",
          "item-text": "name",
          items: this.users ?? null,
          loading: this.isUsersLoading ?? null,
          "user-avatar": true,
        },
        {
          is: "AppFiltersTaskMultiAutocomplete",
          labelKey: "common.productFields.task",
          filterValueKey: "taskIds",
          "item-value": "id",
          "item-text": "name",
          isReadyToInitialFetch: this.isReadyToInitialFetch,
          refetchOnQueryParamsChange: true,
          dialogData: { projectId: this.projectId },
          queryParams: {
            alwaysIncludeIds: this.taskIds,
            projectId: this.projectId,
          },
        },
        ...(this.hasProductIntegrationEnabled
          ? [
              {
                is: "AppFiltersSelect",
                labelKey: "projectView.products.sentToAccounting",
                filterValueKey: "status",
                "item-value": "value",
                "item-text": "text",
                items: this.filtersItemsMixins_sentOrNotItems,
              },
              {
                is: "AppFilterSearch",
                labelKey: "common.productFields.reference",
                filterValueKey: "reference",
              },
            ]
          : []),
      ];

      return children;
    },
  },
};
</script>
