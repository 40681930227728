<script>
import { VCol, VRow } from "vuetify/lib";

export default {
  props: {
    signatures: Array,
  },
  data() {
    return {};
  },
  methods: {
    genSignatures() {
      return this.$createElement("div", { class: "py-6 d-flex flex-column" }, [
        this.$createElement(
          "div",
          { class: "text-h5 font-weight-bold pb-5" },
          this.$t("formManagement.signatureSession.title"),
        ),
        this.genSignatureRows(),
      ]);
    },
    genSignatureRows() {
      return this.signatures.map((signature) => this.genSignatureRow(signature));
    },
    genSignatureRow(item) {
      const isLast = this.signatures.indexOf(item) === this.signatures.length - 1;
      return this.$createElement("div", { class: `${!isLast && "mb-8"}` }, [
        this.genInfoRows(item),
        this.genSignatureField(item),
      ]);
    },
    genInfoRows(item) {
      return [
        this.$createElement(VRow, [
          this.$createElement(VCol, { props: { cols: 8 } }, [
            this.$createElement(
              "div",
              { class: "pb-1" },
              this.$t("formManagement.signature.fullName"),
            ),
            this.$createElement("div", { class: "font-weight-bold" }, item.name || "-"),
          ]),
          this.$createElement(VCol, [
            this.$createElement(
              "div",
              { class: "pb-1" },
              this.$t("formManagement.signature.date"),
            ),
            this.$createElement(
              "div",
              { class: "font-weight-bold" },
              this.$options.filters.dateFormat(new Date(item.date), "DD.MM.YYYY") || "-",
            ),
          ]),
        ]),
        item.comment &&
          this.$createElement(VRow, [
            this.$createElement(VCol, [
              this.$createElement(
                "div",
                { class: "pb-1" },
                this.$t("formManagement.signature.comment"),
              ),
              this.$createElement("div", { class: "font-weight-bold" }, item.comment),
            ]),
          ]),
      ];
    },
    genSignatureField(item) {
      if (!item.signature && !item.name) return;
      return this.$createElement(VRow, [
        this.$createElement(VCol, [
          this.$createElement(
            "span",
            { class: "text-decoration-underline signature-text" },
            //user name || signature because .signatureSession.signers has .signature has .signatures only has .name
            item?.signature || item.name,
          ),
        ]),
      ]);
    },
    genb64SignatureField(item) {
      return this.genTextSignatureField(item);
    },
  },
  render(h) {
    return this.genSignatures();
  },
};
</script>
