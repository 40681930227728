import _ from "lodash";
import { isValidUUID } from "../util/uuid";

export const dataTableBulkActionMixin = {
  props: {
    getAllItems: {
      type: Function,
    },
    removeMutationType: {
      type: String,
    },
    updateMutationType: {
      type: String,
    },
  },
  data() {
    return {
      dataTableBulkActionMixin_selectedItems: [],
      dataTableBulkActionMixin_isSelectingAll: false,
      dataTableBulkActionMixin_unsubscribe: null,
    };
  },
  methods: {
    async dataTableBulkActionMixin_selectAllItems() {
      this.dataTableBulkActionMixin_isSelectingAll = true;
      try {
        const items = await this.getAllItems();
        this.dataTableBulkActionMixin_selectedItems = items;
      } finally {
        this.dataTableBulkActionMixin_isSelectingAll = false;
      }
    },
    dataTableBulkActionMixin_clearSelection() {
      this.dataTableBulkActionMixin_selectedItems = [];
    },
    dataTableBulkActionMixin_removeItemFromSelection(removedItem) {
      const isUuid = isValidUUID(removedItem);
      const removedId = isUuid ? removedItem : removedItem.id;
      this.dataTableBulkActionMixin_selectedItems =
        this.dataTableBulkActionMixin_selectedItems.filter(
          (selectedItem) => selectedItem.id !== removedId,
        );
    },
    dataTableBulkActionMixin_updateItemInSelection(updatedItem) {
      this.dataTableBulkActionMixin_selectedItems =
        this.dataTableBulkActionMixin_selectedItems.map((item) =>
          item.id === updatedItem.id ? updatedItem : item,
        );
    },
    dataTableBulkActionMixin_handleMutation(mutation, state) {
      if (mutation.type === this.removeMutationType) {
        this.dataTableBulkActionMixin_removeItemFromSelection(mutation.payload);
      } else if (mutation.type === this.updateMutationType) {
        this.dataTableBulkActionMixin_updateItemInSelection(mutation.payload);
      }
    },
    dataTableBulkActionMixin_setUpMutationListener() {
      this.dataTableBulkActionMixin_unsubscribe = this.$store.subscribe(
        (mutation, state) => {
          this.dataTableBulkActionMixin_handleMutation(mutation, state);
        },
      );
    },
    dataTableBulkActionMixin_mergeItems({ primaryItems, secondaryItems }) {
      return primaryItems.map((primaryItem) => {
        const secondaryItem = secondaryItems.find((item) => item.id === primaryItem.id);
        if (secondaryItem) {
          return _.merge({}, primaryItem, secondaryItem);
        }
        return primaryItem;
      });
    },
    dataTableBulkActionMixin_syncSelectedItems() {
      this.dataTableBulkActionMixin_selectedItems =
        this.dataTableBulkActionMixin_mergeItems({
          primaryItems: this.dataTableBulkActionMixin_selectedItems,
          secondaryItems: this.items,
        });
    },
  },
  watch: {
    items: {
      handler() {
        //Handle syncing when the items are paginated or updated outside of bulk action
        this.dataTableBulkActionMixin_syncSelectedItems();
      },
      deep: true,
    },
  },
  mounted() {
    //Handle syncing when items are removed/deleted
    this.dataTableBulkActionMixin_setUpMutationListener();
  },
  beforeDestroy() {
    this.dataTableBulkActionMixin_unsubscribe?.();
  },
};
