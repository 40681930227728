<script>
import DateField from "./answerTypes/DateField.vue";
import RadioGroup from "./answerTypes/RadioGroup.vue";
import MultiChoice from "./answerTypes/MultiChoice.vue";
import Textarea from "./answerTypes/Textarea.vue";
import ItemNote from "./ItemNote.vue";
import ItemImages from "./ItemImages.vue";
import ItemTasks from "./ItemTasks.vue";

export default {
  props: {
    item: Object,
    observer: IntersectionObserver,
  },
  methods: {
    genNameRow() {
      return this.$createElement("AppQuestionItemNameRow", {
        props: { item: this.item },
        on: {
          "questionName:mounted": (e) => this.handleQuestionNameMounted(e),
        },
      });
    },
    genExplanationRow() {
      const explanation = this.item?.explanation || "";
      if (!explanation.trim()) return;
      return this.$createElement("div", this.item.explanation);
    },
    genAnswerInput() {
      let answer = this.$createElement("div", "invalid type");

      if (this.item.type === this.$constant.DATE_FIELD) {
        answer = this.$createElement(DateField, { props: { item: this.item } });
      } else if (this.item.type === this.$constant.TEXTAREA) {
        answer = this.$createElement(Textarea, { props: { item: this.item } });
      } else if (this.item.type === this.$constant.RADIO_GROUP) {
        answer = this.$createElement(RadioGroup, { props: { item: this.item } });
      } else if (this.item.type === this.$constant.MULTI_CHOICE) {
        answer = this.$createElement(MultiChoice, { props: { item: this.item } });
      }

      return this.$createElement(
        "div",
        { class: "py-2 d-flex flex-column align-start" },
        [
          this.$createElement(
            "span",
            { class: "text-body-2 font-weight-bold" },
            this.$t("formManagement.preview.answer"),
          ),
          [answer],
        ],
      );
    },
    genImages() {
      if (this.item.images.length <= 0) return null;
      return this.$createElement(ItemImages, {
        props: { item: this.item },
        on: {
          "item:imagePreview": (e) => this.$emit("item:imagePreview", e),
        },
      });
    },
    genNote() {
      if (!this.item?.note) return;
      return this.$createElement(ItemNote, {
        props: { note: this.item.note, item: this.item },
      });
    },
    genTasks() {
      if (this.item.tasks.length <= 0) return;
      return this.$createElement(ItemTasks, {
        props: { item: this.item },
        on: { "item:taskClick": (e) => this.$emit("item:taskClick", e) },
      });
    },
    handleQuestionNameMounted(ref) {
      if (this.item?.hasOptions && this.observer && ref) {
        this.observer.observe(ref);
      }
    },
  },
  render(h) {
    return h("div", { class: "d-flex flex-column py-3" }, [
      this.$createElement("div", { class: "py-2" }, [
        this.genNameRow(),
        !this.item.isNotRelevant ? this.genExplanationRow() : null,
      ]),
      !this.item.isNotRelevant ? this.genAnswerInput() : null,
      ...[
        this.item.hasOptions && !this.item.isNotRelevant
          ? [this.genNote(), this.genImages(), this.genTasks()]
          : [],
      ],
    ]);
  },
};
</script>
