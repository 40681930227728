<template>
  <div>
    <AppPlanInfoItem
      v-for="(limit, index) in limits"
      :key="index"
      :text="limit.text"
      :isLast="index === limits.length - 1"
      :stonlyGuideId="limit.guideId"
    />
  </div>
</template>

<script>
export default {
  props: {
    limits: Array,
  },
  methods: {
    storageFormatText(storage) {
      return storage < 1 ? `${storage * 1000} MB` : `${storage} GB`;
    },
    getLimitById(id) {
      return this.limits.find((limit) => limit.id === id);
    },
  },
};
</script>
