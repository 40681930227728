import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, userGuard } from "../../auth/authGuard";
import multiguard from "../multiguard";

const {
  ROOT,
  FORM_TEMPLATES,
  PROJECT_TEMPLATES,
  FORM_TEMPLATE_BUILDER,
  PROJECT_TEMPLATE_BUILDER,
  FORM_TEMPLATE_BUILDER_DETAILS,
  FORM_TEMPLATE_BUILDER_HISTORY,
  PROJECT_TEMPLATE_BUILDER_DETAILS,
  PROJECT_TEMPLATE_BUILDER_HISTORY,
} = ROUTE_NAMES.TEMPLATE;

export const templateRoutes = [
  {
    path: "/template",
    name: ROOT,
    beforeEnter: multiguard([authGuard, userGuard({ admin: true, owner: true })]),
    component: () =>
      import(/* webpackChunkName: "template" */ "../../pages/TemplateManagement"),
    redirect: { name: FORM_TEMPLATES },
    children: [
      {
        path: "forms",
        name: FORM_TEMPLATES,
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../../pages/TemplateManagement/FormTemplatePage"
          ),
      },
      {
        path: "projects",
        name: PROJECT_TEMPLATES,
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../../pages/TemplateManagement/ProjectTemplatePage"
          ),
      },
    ],
  },
  {
    path: "/template/form/:templateId",
    name: FORM_TEMPLATE_BUILDER,
    beforeEnter: multiguard([authGuard, userGuard({ admin: true, owner: true })]),
    component: () =>
      import(
        /* webpackChunkName: "template" */ "../../pages/TemplateManagement/FormTemplatePage/FormTemplateBuilderPage"
      ),
    children: [
      {
        path: "details",
        name: FORM_TEMPLATE_BUILDER_DETAILS,
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../../pages/TemplateManagement/FormTemplatePage/FormTemplateBuilderPage/MenuPages/DetailsPage"
          ),
      },
      {
        path: "history",
        name: FORM_TEMPLATE_BUILDER_HISTORY,
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../../pages/TemplateManagement/FormTemplatePage/FormTemplateBuilderPage/MenuPages/HistoryPage"
          ),
      },
    ],
  },
  {
    path: "/template/project/:templateId",
    name: PROJECT_TEMPLATE_BUILDER,
    beforeEnter: multiguard([authGuard, userGuard({ admin: true, owner: true })]),
    component: () =>
      import(
        /* webpackChunkName: "template" */ "../../pages/TemplateManagement/ProjectTemplatePage/ProjectTemplateBuilderPage"
      ),
    children: [
      {
        path: "details",
        name: PROJECT_TEMPLATE_BUILDER_DETAILS,
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../../pages/TemplateManagement/ProjectTemplatePage/ProjectTemplateBuilderPage/Menu/DetailsPage"
          ),
      },
      {
        path: "history",
        name: PROJECT_TEMPLATE_BUILDER_HISTORY,
        component: () =>
          import(
            /* webpackChunkName: "template" */ "../../pages/TemplateManagement/ProjectTemplatePage/MenuPages/HistoryPage"
          ),
      },
    ],
  },
];
