import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getAllTasks({ filters }) {
  const params = { offset: 0, ...filters };
  const config = { params };
  return axios
    .get(`${apiUrl}task`, config)
    .then(({ data }) => {
      const { tasks } = data;
      return tasks;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
