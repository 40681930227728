import { normalizeCmsDocumentTemplates } from "@/helpers/normalization";
import axios from "axios";
import FileSaver from "file-saver";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  isLoading: false,
  isUploading: false,
  isDownloading: false,
  items: [],
  filter: { search: undefined, category: undefined, published: undefined },
});

const getters = {
  canUpload: (state) => {
    return !state.isLoading && !state.isUploading;
  },
  canDownload: (state, getters) => {
    return getters?.selectedItems?.length === 1;
  },
  selectedItems: (state) => {
    return state.items.filter((i) => i.isSelected === true);
  },
  isAllSelected: (state, getters) => {
    return state.items.length > 0
      ? getters.selectedItems.length === state.items.length
      : false;
  },
  isIndeterminate: (state, getters) => {
    return getters.selectedItems.length > 0 &&
      getters.selectedItems.length !== state.items.length
      ? true
      : false;
  },
};

const actions = {
  async getItems({ state, commit }, filter) {
    commit("loading", true);
    commit("setFilter", filter);
    return axios
      .get(`${apiUrl}cms/document`, {
        params: { ...state.filter },
      })
      .then(({ data }) => {
        const templates = data?.templates;
        const normalizedTemplates = normalizeCmsDocumentTemplates(templates);
        commit("setItems", normalizedTemplates);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async uploadItems({ commit, dispatch, state }, formData) {
    commit("uploading", true);
    return axios
      .post(`${apiUrl}cms/document`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        dispatch("getItems", state.filter);
        commit("uploading", false);
      })
      .catch((error) => {
        commit("uploading", false);
        throw new Error(error);
      });
  },
  async updateItem({ commit, dispatch, state }, { id, data, refresh }) {
    const item = { id };
    commit("toggleItemLoading", { item, isLoading: true });
    return axios
      .put(`${apiUrl}cms/document/${item.id}`, {
        ...data,
      })
      .then((res) => {
        if (refresh) {
          dispatch("getItems", state.filter);
        } else {
          commit("updateItem", { item: res.data.template });
        }
        commit("toggleItemLoading", { item, isLoading: false });
      })
      .catch((error) => {
        commit("toggleItemLoading", { item, isLoading: false });
        throw new Error(error);
      });
  },
  async deleteItems({ commit, dispatch, state }, items) {
    for (let item of items) {
      commit("toggleItemLoading", { item, isLoading: true });
    }
    const ids = items.map((i) => i.id);
    return axios
      .delete(`${apiUrl}cms/document`, { data: { ids } })
      .then((res) => {
        dispatch("getItems", state.filter);
      })
      .catch((error) => {
        for (let item of items) {
          commit("toggleItemLoading", { item, isLoading: false });
        }
        throw new Error(error);
      });
  },
  async downloadItem({ commit }, item) {
    commit("downloading", true);
    return axios
      .get(`${apiUrl}cms/document/${item.id}/download`, {
        responseType: "blob",
      })
      .then((res) => {
        commit("downloading", false);
        const fileName = `${item.name.replace(/\.[^/.]+$/, "")}`;
        FileSaver.saveAs(res.data, fileName);
      })
      .catch((error) => {
        commit("downloading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setItems(state, items) {
    state.items = items;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  updateItem(state, { item }) {
    const filter = state.filter;
    if (!item) throw new Error("No item");
    const index = state.items.findIndex((x) => x.id === item.id);
    if (index !== -1) {
      let remove = false;
      if (filter) {
        if (
          typeof filter.isPublished === "boolean" &&
          filter.isPublished !== item.isPublished
        ) {
          remove = true;
        } else if (filter.category && filter.category !== item.category) {
          remove = true;
        }
      }
      if (remove) {
        state.items.splice(index, 1);
      } else {
        state.items[index].name = item.name;
        state.items[index].isPublished = item.isPublished;
        state.items[index].categoryId = item.categoryId;
        state.items[index].category = item.category;
      }
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  uploading(state, isUploading) {
    state.isUploading = isUploading;
  },
  downloading(state, isDownloading) {
    state.isDownloading = isDownloading;
  },
  toggleItemLoading(state, payload = { item: null, isLoading: false }) {
    if (!payload.item) throw new Error("No item");
    const index = state.items.findIndex((x) => x.id === payload.item.id);
    if (index !== -1) {
      state.items[index].isLoading = payload.isLoading;
    }
  },
  toggleItemsSelected(state, { items, isSelected }) {
    for (const item of items) {
      const index = state.items.findIndex((x) => x.id == item.id);

      if (index !== -1) {
        state.items[index].isSelected = isSelected;
      }
    }
  },
};

export const cmsTemplates = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
