<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("cms.template.document.editTemplate.title") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="editTemplateForm"
          v-model="item.valid"
          v-on:submit.prevent
          @submit="onSave"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("cms.template.document.editTemplate.nameLabel") }}
            </div>
            <AppDefaultTextField
              v-model="item.name.value"
              :rules="$rules.NAME_RULES"
              :counter="nameMaxInput"
              autofocus
            />
          </div>

          <div class="p-relative">
            <div class="label--small">
              {{ $t("cms.template.document.editTemplate.categoryLabel") }}
            </div>

            <AppDefaultSelect
              v-model="item.category.value"
              :items="categories"
              item-text="text"
              item-value="id"
            />
          </div>

          <div class="p-relative">
            <div class="label--small">
              {{ $t("common.status") }}
            </div>

            <v-btn-toggle mandatory color="primary" v-model="item.isPublished.value">
              <v-btn
                :value="false"
                height="40"
                width="125"
                class="text-transform-none text-body-1"
              >
                <template v-slot>
                  <div class="d-flex flex-column">
                    <span class="subtitle-1">{{
                      $t("cms.template.document.status.unpublished")
                    }}</span>
                  </div>
                </template>
              </v-btn>
              <v-btn :value="true" style="text-transform: none" height="40" width="125">
                <template v-slot>
                  <div class="d-flex flex-column">
                    <span class="subtitle-1">{{
                      $t("cms.template.document.status.published")
                    }}</span>
                  </div>
                </template>
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSave"
          @cancel="onClose"
          :confirmText="$t('common.save')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    dialog: Boolean,
    data: Object,
    nameMaxInput: {
      type: Number,
      default: 255,
    },
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapState("cmsTemplateCategory", {
      isLoading: (state) => state.isLoading,
      categories: (state) => state.items,
    }),
  },
  watch: {
    data: {
      immediate: true,
      handler(data) {
        if (data) {
          this.item.name.value = data.name ?? "";
          this.item.category.value = data.categoryId ?? "uncategorized";
          this.item.isPublished.value = data.isPublished ?? "";
        }
      },
    },
  },
  data() {
    return {
      item: {
        valid: false,
        name: {
          value: "",
        },
        category: {
          value: "",
        },
        isPublished: {
          value: "",
        },
      },
    };
  },
  methods: {
    closeDialog() {
      this.localDialog = false;
    },
    onClose() {
      this.closeDialog();
      this.$refs.editTemplateForm.resetValidation();
    },
    onSave() {
      const valid = this.$refs.editTemplateForm.validate();
      if (valid && this.data) {
        const data = {
          name: this.item.name.value,
          categoryId:
            this.item.category.value !== "uncategorized"
              ? this.item.category.value
              : null,
          isPublished: this.item.isPublished.value,
        };
        this.$emit("submit:form", { id: this.data.id, data });
        this.$refs.editTemplateForm.reset();
        this.closeDialog();
      }
    },
    getCategories() {
      this.$store.dispatch("cmsTemplateCategory/getItems");
    },
  },
  mounted() {
    this.getCategories();
  },
};
</script>
