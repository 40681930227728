<template>
  <v-snackbar
    v-model="snackbarActive"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    :content-class="snackbarTextColor"
  >
    <span class="text-pre-wrap" v-if="snackbar.vHtml" v-html="snackbar.text"></span>
    <span class="text-pre-wrap" v-else>
      {{ snackbar.text }}
    </span>

    <template v-slot:action="{ attrs }">
      <v-btn
        v-if="snackbar?.action?.callback"
        depressed
        outlined
        class="mr-2 border-a"
        v-bind="attrs"
        @click="
          () => {
            snackbar.action.callback();
            $store.commit('snackbar/toggleSnackbar', false);
          }
        "
      >
        {{ snackbar.action.text }}
      </v-btn>
      <v-btn
        outlined
        class="border-a"
        v-bind="attrs"
        @click="$store.commit('snackbar/toggleSnackbar', false)"
      >
        {{ $t("common.close") }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("snackbar", {
      snackbar: (state) => state.snackbar,
    }),
    snackbarActive: {
      get() {
        return this.snackbar.show;
      },
      set(value) {
        this.$store.commit("snackbar/toggleSnackbar", value);
      },
    },
    snackbarTextColor() {
      return this.snackbar.color === "warning" ? "primary-text--text" : "";
    },
  },
};
</script>
