import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  template: null,
  isLoading: false,
  isUpdating: false,
});

const getters = {};

const actions = {
  async getTemplate({ commit }, id) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}template/project/${id}`)
      .then(({ data }) => {
        const template = data.template;

        commit("setTemplate", template);
        commit("loading", false);
      })
      .catch(error => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async updateTemplate({ commit, state }, { id, body }) {
    commit("updating", true);
    return axios
      .put(`${apiUrl}template/project/${id}`, {
        ...body,
      })
      .then(({ data }) => {
        const template = data?.template;
        if (template) {
          commit("updateTemplate", template);
        }
        commit("updating", false);
      })
      .catch(error => {
        commit("updating", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setTemplate(state, template) {
    state.template = template;
  },
  updateTemplate(state, template) {
    state.template = template;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  updating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
};

export const projectTemplate = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
