import { ROUTE_NAMES } from "@/helpers/routeNames";
import { redirectToSignupGuard } from "../../auth/authGuard";

const { ROOT } = ROUTE_NAMES.REFERRAL;

export const referralRoutes = [
  {
    path: "/referral/:campaignCode",
    name: ROOT,
    beforeEnter: redirectToSignupGuard,
  },
];
