<template>
  <tr
    class="list_tr list_tr-height"
    :class="rowStateClasses"
    @contextmenu="dataTableItemMixins_onRightClick"
    @click="select(!isSelected)"
  >
    <td>
      <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)" />
    </td>
    <td>
      <div class="table-cell-content__breakable-long-text">
        <span class="d-inline-block">
          {{ client.name }}
        </span>
      </div>
    </td>
    <td>{{ client.organizationNumber }}</td>
    <td>
      <template v-if="client.owner">
        <AppUserAvatar :user="client.owner" show-name :truncate="15" />
      </template>
    </td>
    <td>
      <div class="table-cell-content__breakable--margin">
        <template v-if="client.type">
          {{ $t(`contact.client.type.${client.type.toLowerCase()}`) }}
          <template v-if="client.isPrivate">
            ({{ $t("contact.client.customerType.private") }})
          </template>
        </template>
      </div>
    </td>
    <td>
      <AppPhoneLink :phone="client?.phone" />
    </td>
    <td>
      <AppLinkText :link="`mailto:${client.email}`" underline-on-hover>
        {{ client.email }}
      </AppLinkText>
    </td>
    <td>
      <div class="d-flex align-center">
        <v-checkbox
          :disabled="!!projectId"
          :inputValue="client.active"
          @click.stop
          @change="updateClient({ id: client.id, body: { active: $event }, snack: true })"
        />
      </div>
    </td>
    <td class="text-end">
      <v-btn icon @click="dataTableItemMixins_onEllipsisClick" ref="ellipsis">
        <v-icon>
          {{ $icons.REGULAR.ACTION.MENU }}
        </v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { dataTableItemMixins } from "@/helpers/mixins";

export default {
  mixins: [dataTableItemMixins],
  props: {
    client: Object,
    isContextMenuActiveItem: Boolean,
    isSelected: Boolean,
    select: Function,
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    rowStateClasses() {
      return {
        "list_tr--active": this.isContextMenuActiveItem,
        "list_tr--selected": this.isSelected,
      };
    },
  },
  methods: {
    openMenu(params) {
      this.$emit("menu:click", { client: this.client, ...params });
    },
    updateClient(e) {
      this.$emit("client:update", e);
    },
  },
};
</script>
