export const ACTION_REQUEST_STATUS = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
};

export const VALID_STORAGE_READ_CONFIRMATION_STATUSES = [
  ACTION_REQUEST_STATUS.IN_PROGRESS,
  ACTION_REQUEST_STATUS.COMPLETED,
];
