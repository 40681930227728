<template>
  <div class="d-flex align-center w-100" style="height: 40px">
    <div v-if="avatarItem">
      <AppUserAvatar :user="avatarItem" medium-large class="pr-3" />
    </div>
    <slot name="prepend"> </slot>
    <AppDrawerHeader
      v-if="!isMini"
      :title="title"
      :subTitle="subTitle"
      :disableSubTitleTooltip="disableSubTitleTooltip"
    />
    <div class="d-flex align-center" v-if="!isMini">
      <img style="width: 24px; height: 24px" :src="menuArrow" />
    </div>
  </div>
</template>

<script>
import menuArrow from "../../../assets/menu_arrow.svg";
import { mapGetters } from "vuex";

export default {
  props: {
    avatarItem: Object,
    title: String,
    subTitle: String,
    disableSubTitleTooltip: Boolean,
    showMenuIcon: Boolean,
  },
  computed: {
    menuArrow() {
      return menuArrow;
    },
    ...mapGetters("appDrawer", {
      isMini: "isDrawerMiniVariant",
    }),
  },
};
</script>
