import axios from "axios";
import { normalizeForm } from "@/helpers/normalization";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  form: null,
  isLoading: false,
  isUpdating: false,
  isCreatingSignature: false,
});

const getters = {
  getFormFolderId: (state) => {
    return state.form?.folderId || null;
  },
  isSignable: (state) => {
    return state.form?.isSignable === "TRUE";
  },
  isFormStatusCategoryDone: (state) => {
    return state.form?.statusObject?.category === "DONE";
  },
};

const actions = {
  async getForm({ commit }, id) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}form/${id}`)
      .then(({ data }) => {
        const form = data?.form;
        const normalizedForm = normalizeForm(form);
        commit("setForm", normalizedForm);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async updateForm({ state, commit }, { id, body }) {
    commit("updating", true);
    let prevStatusId;
    if (typeof body?.statusId === "string") {
      prevStatusId = state.form?.statusId;
      commit("updatingStatus", true);
      commit("updateForm", { ...state.form, statusId: body?.statusId });
    }
    if (typeof body?.isSignable === "boolean") {
      commit("updatingIsSignable", true);
    }
    if (typeof body?.isPrivate === "boolean") {
      commit("updatingIsPrivate", true);
    }

    return axios
      .put(`${apiUrl}form/${id}`, {
        ...body,
      })
      .then(({ data }) => {
        const form = data?.form;
        const normalizedForm = normalizeForm(form);
        commit("updateForm", normalizedForm);
      })
      .catch((error) => {
        if (typeof body?.statusId === "string") {
          commit("updateForm", { ...state.form, statusId: prevStatusId });
        }
        throw new Error(error);
      })
      .finally(() => {
        commit("updating", false);
        commit("updatingStatus", false);
        commit("updatingIsSignable", false);
        commit("updatingIsPrivate", false);
      });
  },
  async deleteForm({ commit }, { id }) {
    return axios
      .delete(`${apiUrl}form/${id}`)
      .then(({ data }) => {
        commit("setForm", null);
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async createFormSignature({ commit }, formId) {
    commit("setIsCreatingSignature", true);
    return axios
      .post(`${apiUrl}form-signature`, { formId })
      .then(({ data }) => {
        commit("addSignature", data.signature);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("setIsCreatingSignature", false);
      });
  },
  async updateFormSignature({ commit, dispatch }, { id, body }) {
    return axios
      .put(`${apiUrl}form-signature/${id}`, body)
      .then(({ data }) => {
        commit("updateSignature", data.signature);
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async deleteFormSignature({ commit, dispatch, state }, id) {
    commit("removeSignature", id);

    return axios
      .delete(`${apiUrl}form-signature/${id}`)
      .then(({ data }) => {})
      .catch((error) => {
        const formId = state.form.id;
        dispatch("getForm", formId);
        throw new Error(error);
      });
  },
  removeFormTaskId({ commit, state }) {
    commit("updateForm", { ...state.form, taskId: null });
  },
};

const mutations = {
  setForm(state, form) {
    state.form = form;
  },
  addSignature(state, signature) {
    state.form.signatures.push(signature);
  },
  updateSignature(state, signature) {
    const index = state.form.signatures.findIndex((x) => x.id === signature.id);
    if (index === -1) return;

    state.form.signatures.splice(index, 1, signature);
  },
  removeSignature(state, id) {
    const index = state.form.signatures.findIndex((i) => i.id === id);
    if (index === -1) return;

    state.form.signatures.splice(index, 1);
  },
  updateForm(state, form) {
    state.form = form;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  updating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
  setIsCreatingSignature(state, isCreating) {
    state.isCreatingSignature = isCreating;
  },
  updatingStatus(state, isUpdatingStatus) {
    if (state.form) {
      state.form.isUpdatingStatus = isUpdatingStatus;
    }
  },
  updatingIsSignable(state, isUpdatingIsSignable) {
    if (state.form) {
      state.form.isUpdatingIsSignable = isUpdatingIsSignable;
    }
  },
  updatingIsPrivate(state, isUpdatingIsPrivate) {
    if (state.form) {
      state.form.isUpdatingIsPrivate = isUpdatingIsPrivate;
    }
  },
};

export const form = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
