<template>
  <AppRemindDialog
    v-model="localDialog"
    :items="signers"
    :isLoading="isSignersLoading"
    :getStatusText="getStatusText"
    :getStatusColor="getStatusColor"
    :inProgressStatus="$constant.E_SIGN_STATUS.NOT_COMPLETED"
    @submit="handleSubmit"
  />
</template>

<script>
import { getDocumentProperties } from "@/services/storage/esign/documentProperties";

export default {
  props: {
    dialog: Boolean,
    storageId: String,
  },
  data() {
    return {
      signers: [],
      isSignersLoading: false,
      status: null,
    };
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(dialog) {
        this.$emit("dialog:change", dialog);
      },
    },
  },
  watch: {
    dialog(dialog) {
      if (dialog) {
        this.getSigners();
      }
    },
  },
  methods: {
    getSigners() {
      this.isSignersLoading = true;
      getDocumentProperties(this.storageId)
        .then((data) => {
          this.signers = data.signers;
          this.status = data.status;
        })
        .finally(() => {
          this.isSignersLoading = false;
        });
    },
    getStatusColor(signer) {
      const colorMap = {
        [this.$constant.E_SIGN_STATUS.NOT_VIEWED]: "warning",
        [this.$constant.E_SIGN_STATUS.COMPLETED]: "success",
      };
      return `${colorMap[signer.status] || "primary"}--text`;
    },
    getStatusText(signer) {
      const status = signer.isViewed
        ? signer.status
        : this.$constant.E_SIGN_STATUS.NOT_VIEWED;
      return this.$t(`fileManagement.preview.navMenu.e-signature.status.${status}`);
    },
    handleSubmit({ emails, message }) {
      this.$emit("submit", { emails, message });
      this.localDialog = false;
    },
  },
};
</script>
