<template>
  <div>
    <v-btn
      v-if="show"
      depressed
      :color="confirmColor"
      class="mr-2"
      @click="confirm"
      :loading="loading"
      :disabled="disabled"
    >
      {{ confirmText }}
    </v-btn>
    <v-btn :color="cancelColor" text @click="cancel">
      {{ cancelText }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    confirmText: {
      default: function () {
        return this.$t("common.confirm");
      },
      type: String,
    },
    cancelText: {
      default: function () {
        return this.$t("common.cancel");
      },
      type: String,
    },
    confirmColor: {
      default: "primary",
      type: String,
    },
    cancelColor: {
      default: "",
      type: String,
    },
    show: {
      type: Boolean,
      default: true,
    },
    loading: Boolean,
    disabled: Boolean,
  },

  methods: {
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
