import { TIME_ENTRY_ACCOUNTING_HOUR_TYPES } from "@/helpers/constants";
import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

const state = () => ({
  isLoading: false,
  hourTypes: [],
});

const getters = {
  getAccountingHourTypesByType: (state) => (isInternal) => {
    if (
      state.hourTypes.every(
        (hourType) => hourType.type === TIME_ENTRY_ACCOUNTING_HOUR_TYPES.BOTH,
      )
    ) {
      return state.hourTypes;
    }
    return state.hourTypes.filter((hourType) => {
      return isInternal
        ? hourType.type === TIME_ENTRY_ACCOUNTING_HOUR_TYPES.INTERNAL
        : hourType.type === TIME_ENTRY_ACCOUNTING_HOUR_TYPES.EXTERNAL;
    });
  },
};

const actions = {
  async getHourTypes({ commit }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}accounting/time-entry/hour-type`)
      .then(({ data }) => {
        commit("setHourTypes", data);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
};

const mutations = {
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setHourTypes(state, hourTypes) {
    state.hourTypes = hourTypes;
  },
};

export const accountingHourTypes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
