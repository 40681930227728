<template>
  <AppSelectItemsDialog
    v-model="localDialog"
    :title="$t('projectView.products.addProductsDialog.findAndAddDialog.title')"
    :availableItemsText="
      $tc(
        'projectView.products.addProductsDialog.findAndAddDialog.availableProducts',
        countAll,
      )
    "
    :searchPlaceholder="
      $t('projectView.products.addProductsDialog.findAndAddDialog.searchPlaceholder')
    "
    :confirmText="$t('projectView.products.actionBtnText')"
    :selectedItemsText="
      $tc(
        'projectView.products.addProductsDialog.findAndAddDialog.productsSelected',
        selectedProducts.length,
      )
    "
    :isLoadingStats="isLoadingStats"
    :isLoadingItems="isLoadingProducts || filtersMixins_isLoadingFilters"
    :items="products"
    :count="count"
    :selectedItems="selectedProducts"
    :search="filtersMixins_filterByKey('search')"
    @searchInput="filtersMixins_debounceSearch"
    @load-more="getProducts(true)"
    @submit="addProducts"
  >
    <template #item-list="{ items }">
      <AppSelectProductsItem
        v-for="(item, index) in items"
        :key="index"
        :product="item"
        :isChecked="selectedProducts.some((p) => p?.id === item.id)"
        @toggleChecked="toggleChecked"
      />
    </template>
  </AppSelectItemsDialog>
</template>

<script>
import { mapState } from "vuex";
import { getProductStatistics } from "@/services/product/productStatistics";
import * as moment from "moment";
import { filtersMixins } from "@/helpers/mixins";

export default {
  mixins: [filtersMixins],
  props: {
    dialog: Boolean,
    projectId: String,
  },
  data() {
    return {
      selectedProducts: [],
      isLoadingStats: false,
      countAll: null,
      tableModel: this.$constant.PROJECT_FIND_PRODUCTS,
    };
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  watch: {
    filtersMixins_filters: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.onFiltersChanged();
        }
      },
      deep: true,
    },
    dialog: {
      handler(val) {
        if (val) {
          this.filtersMixins_getFilters();
        }
      },
    },
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapState("productsInfinityList", {
      products: (state) => state.products,
      isLoadingProducts: (state) => state.isLoading,
      count: (state) => state.count,
    }),
  },
  methods: {
    onFiltersChanged() {
      this.resetState();
      this.getProducts();
    },
    resetSelectedProducts() {
      this.selectedProducts = [];
    },
    toggleChecked({ product }) {
      const isSelected = this.selectedProducts.some((p) => p?.id === product.id);
      if (isSelected) {
        this.selectedProducts = this.selectedProducts.filter((p) => p?.id !== product.id);
      } else {
        this.selectedProducts = [...this.selectedProducts, product];
      }
    },
    addProducts() {
      const newProducts = this.selectedProducts.map((product) => ({
        addedAt: moment(new Date()).format("YYYY-MM-DD"),
        name: null,
        amount: null,
        unitPrice: null,
        comment: null,
        ...product,
      }));
      this.$emit("submit:click", newProducts);
      this.resetSelectedProducts();
    },
    resetState() {
      this.$store.commit("productsInfinityList/resetState");
    },
    async getProducts(shouldPushItems) {
      await this.$store.dispatch("productsInfinityList/getProducts", {
        filter: this.filtersMixins_filters,
        projectId: this.projectId,
        shouldPushItems,
      });
    },
  },
  mounted() {
    this.isLoadingStats = true;
    getProductStatistics()
      .then((res) => {
        const { products } = res;
        const { active } = products || {};
        this.countAll = active || 0;
      })
      .finally(() => {
        this.isLoadingStats = false;
      });
  },
};
</script>
