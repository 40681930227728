<template>
  <AppDefaultTooltip top>
    <template v-slot:activator="{ on, attrs }">
      <div class="cursor-pointer pa-1 d-flex" @click="onClick" v-bind="attrs" v-on="on">
        <v-icon class="pr-1" x-small>{{ icon }}</v-icon>
        <div class="text-caption">{{ count || 0 }}</div>
      </div>
    </template>
    <span>
      {{ text }}
    </span>
  </AppDefaultTooltip>
</template>

<script>
export default {
  props: {
    text: String,
    icon: String,
    count: [Number, String],
  },
  methods: {
    onClick(e) {
      e.stopPropagation();
      this.$emit("click");
    },
  },
};
</script>
