import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  support: null,
  isLoading: false,
  isUpdating: false,
});

const getters = {
  supportValue: state => {
    return state.support?.value || false;
  },
  supportName: state => {
    return state.support?.name || "";
  },
};

const actions = {
  async getSupportSecurity({ commit }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}security/support`)
      .then(({ data }) => {
        commit("setSupport", data.support);
      })
      .catch(error => {
        throw new Error(error);
      })
      .finally(() => commit("loading", false));
  },
  async updateSupportSecurity({ commit }, body) {
    commit("updating", true);
    return axios
      .put(`${apiUrl}security/support`, body)
      .then(({ data }) => {
        commit("setSupport", data.support);
      })
      .catch(error => {
        throw new Error(error);
      })
      .finally(() => commit("updating", false));
  },
};

const mutations = {
  setSupport(state, support) {
    state.support = support;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  updating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
};

export const settingsSupportSecurity = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
