<template>
  <AppDefaultCombobox
    ref="combobox"
    v-model="localValue"
    :loading="isLoading"
    :items="items"
    :search-input.sync="search"
    item-value="orgnr"
    item-text="name"
    required
    return-object
    :dense="dense"
    :autofocus="autofocus"
    :disabled="disabled"
    :backgroundColor="backgroundColor"
    :no-data-text="$t('integration.brreg.lookup.noData')"
    :placeholder="getPlaceholder"
    :rules="rules"
    :menuProps="{ maxHeight: 350 }"
  >
    <template v-slot:prepend-item>
      <v-list-item>
        <div class="pb-2 primary--text">
          {{ $t("integration.brreg.lookup.resultsFromBrreg") }}
        </div>
      </v-list-item>
      <v-divider />
    </template>
    <template v-slot:item="{ item: organization, on, attrs }">
      <AppOrganizationListItem
        :organization="organization"
        :isSelected="isSelected(organization)"
        v-bind="attrs"
        v-on="on"
      />
    </template>
  </AppDefaultCombobox>
</template>

<script>
import { getOrganizations } from "../../../services/integration/brreg/BronnoysundRegisterService";

export default {
  props: {
    value: [String, Object],
    rules: Array,
    autofocus: Boolean,
    dense: Boolean,
    disabled: Boolean,
    placeholder: String,
    backgroundColor: { default: "input-background", type: String },
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      search: null,
      items: [],
      isLoading: false,
      searchDebounce: null,
    };
  },
  computed: {
    getPlaceholder() {
      return !!this.placeholder
        ? this.placeholder
        : this.$t("integration.brreg.lookup.placeholder");
    },
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
  watch: {
    search: {
      handler(search) {
        if (!search) {
          clearTimeout(this.searchDebounce);
          this.items = [];
          return;
        }

        this.debounceSearch(search);
      },
    },
  },
  methods: {
    blur() {
      this.$refs.combobox.blur();
    },
    isSelected(item) {
      return item?.orgnr === this.value?.orgnr;
    },
    onInput(e) {
      this.localValue = e.srcElement.value;
    },
    // onChange(e) {
    //   const item = this.items.find((item) => item?.orgnr === e);
    //   this.$emit("returnObject:change", { item });
    // },
    getOrganizations(search) {
      this.isLoading = true;
      getOrganizations(search)
        .then((organizations) => {
          this.items = organizations;
        })
        .finally(() => (this.isLoading = false));
    },
    debounceSearch(event) {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.getOrganizations(event);
      }, 600);
    },
  },
};
</script>
