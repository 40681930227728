export default {
  //integration icons
  boligmappa: {
    component: "AppBoligmappaIcon",
  },
  docx: {
    component: "AppDocxIcon",
  },
  xlsx: {
    component: "AppXlsxIcon",
  },
  pptx: {
    component: "AppPptxIcon",
  },
  word: {
    component: "AppWordIcon",
  },
  excel: {
    component: "AppExcelIcon",
  },
  powerpoint: {
    component: "AppPowerpointIcon",
  },
  odp: {
    component: "AppOdpIcon",
  },
  ods: {
    component: "AppOdsIcon",
  },
  odt: {
    component: "AppOdtIcon",
  },
  autogear: {
    component: "AppAutogearLogoIcon",
  },
  //Fontawesome custom icons
  folderLock: {
    component: "AppFolderLockIcon",
  },
  solidSquareSlash: {
    component: "AppSolidSquareSlashIcon",
  },
  regularCalendarDaySlash: {
    component: "AppRegularCalendarDaySlashIcon",
  },
};
