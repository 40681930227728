import { actions, mutations, getters } from "@/store/modules/storage/storage";

//Should be a copy pasted state from storage.js store
const state = () => ({
  items: [],
  parent: {
    item: null,
    isRoot: false,
    canUpload: false,
    isLoading: false,
  },
  path: [],
  truncatedPath: [],
  prependPath: [],
  isItemsLoading: false,
  isPathLoading: false,
  isDownloading: false,
  isDeleting: false,
  file: {
    isUploading: false,
    acceptedTypes: [],
  },
  folder: {
    isUploading: false,
  },
});

export const storageDialog = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
