<script>
import { VTooltip } from "vuetify/lib";
import { TOOLTIP_OPEN_DELAY, TOOLTIP_MAX_WIDTH } from "../../../helpers/constants";
export default {
  extends: VTooltip,
  props: {
    maxWidth: {
      type: Number,
      default: TOOLTIP_MAX_WIDTH,
    },
    openDelay: {
      type: Number,
      default: TOOLTIP_OPEN_DELAY,
    },
    color: {
      type: String,
      default: "ui-background-black",
    },
    contentClass: {
      type: String,
      default: "text-caption",
    },
  },
};
</script>
