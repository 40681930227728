<template>
  <AppContextMenu ref="menu" :closeOnContentClick="false">
    <AppCurrencyTextField v-model="value" autofocus hide-details @submit="onSubmit" />
  </AppContextMenu>
</template>

<script>
export default {
  data() {
    return {
      value: null,
    };
  },
  methods: {
    onSubmit() {
      this.$emit("submit", { value: this.value });
      this.value = null;
      this.close();
    },
    close() {
      this.$refs.menu.close();
    },
    open(e) {
      this.$refs.menu.open(e);
    },
  },
};
</script>
