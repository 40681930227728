<template>
  <AppWidgetTemplate
    @delete:click="$emit('delete:click')"
    :isLoading="loading"
    :title="title"
    :guideId="guideId"
    v-bind="templateProps"
  >
    <template v-slot:append-header>
      <v-btn small @click="$emit('actionBtn:click')" depressed color="primary">
        {{ actionBtnText }}
      </v-btn>
    </template>
    <div>
      <div class="pl-5 pr-5">
        <v-chip class="my-2" outlined ref="groupByMenuBtn" @click="openGroupByMenu">
          <v-icon style="margin-left: 0px" color="subtitle" small left>{{
            $icons.LIGHT.COMMON.LAYER_GROUP
          }}</v-icon>
          <div class="subtitle--text">{{ $t("common.groupBy") }} ({{ groupByName }})</div>
        </v-chip>
      </div>
      <AppDefaultTabs
        :value="selectedTabName"
        :tabs="tabs"
        @click="$emit('tab:click')"
        @change="onTabChange"
        :tabId="tabId"
        backgroundColor="transparent"
        hide-padding-bottom
        style="cursor: unset !important"
      />
    </div>
    <div class="pl-5 pb-3 pr-5 overflow-y-auto" style="height: calc(100% - 88px)">
      <div v-for="(group, categoryName) of groupedItems(groupBy)" :key="categoryName">
        <div
          v-if="categoryName !== 'undefined'"
          class="text-caption pt-3 pb-1 d-flex align-center"
        >
          <div class="font-weight-medium">
            <slot name="groupHeaderTitle" :categoryName="categoryName"></slot>
          </div>
          <div class="pl-1 font-weight-bold subtitle--text">({{ group.length }})</div>
        </div>

        <div v-for="(item, index) of group" :key="item.id">
          <slot name="item" :item="item"></slot>
        </div>
      </div>
      <div
        class="w-100 d-flex align-center justify-center pt-2"
        v-if="isMoreItems"
        v-intersect="onIntersect"
      >
        <AppCircularLoader />
      </div>
    </div>
    <AppContextMenu ref="groupByMenu">
      <slot name="groupByItems"></slot>
    </AppContextMenu>
  </AppWidgetTemplate>
</template>

<script>
export default {
  props: {
    items: Array,
    templateProps: Object,
    title: String,
    actionBtnText: String,
    groupByName: String,
    tabs: Array,
    loading: Boolean,
    isMoreItems: Boolean,
    groupBy: String,
    tabId: String,
    guideId: String,
    selectedTabName: String,
  },
  watch: {
    groupBy: {
      handler(val) {
        this.$emit("items:get");
      },
    },
  },
  methods: {
    openGroupByMenu() {
      const element = this.$refs.groupByMenuBtn.$el;
      this.$refs.groupByMenu.open({ from: element });
    },
    onTabChange(tab) {
      this.$emit("tab:change", tab);
    },
    groupedItems(groupBy) {
      const grouped = {};
      this.items?.forEach((item) => {
        const groupName = this.getPropertyByDotNotation(item, groupBy);

        if (!grouped[groupName]) {
          grouped[groupName] = [];
        }

        grouped[groupName].push(item);
      });

      return grouped;
    },
    getPropertyByDotNotation(obj, dotNotation) {
      if (!obj || !dotNotation) {
        return; // or any default value you prefer
      }

      const keys = dotNotation.split(".");
      return keys.reduce((acc, key) => (acc && acc[key] ? acc[key] : ""), obj);
    },
    onIntersect(e) {
      if (this.loading || !this.isMoreItems) return;
      const isIntersecting = e[0].isIntersecting;
      if (isIntersecting) {
        this.$emit("scroll:bottom");
      }
    },
  },
};
</script>
