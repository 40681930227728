import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

const state = () => ({
  roomTypes: [],
  isLoading: false,
  isCreating: false,
  hasError: false,
});

const getters = {};

const actions = {
  async getRoomTypes({ commit }) {
    commit("error", false);
    commit("loading", true);
    return axios
      .get(`${apiUrl}boligmappa/room-type`)
      .then(({ data }) => {
        commit("setRoomTypes", data.response);
      })
      .catch(error => {
        commit("error", true);
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },

  async createRoom({ commit }, { boligmappaNumber, room }) {
    commit("error", false);
    commit("creating", true);
    return axios
      .post(`${apiUrl}boligmappa/plant/${boligmappaNumber}/room`, room)
      .then(({ data }) => {
        const room = data.response;
        return room;
      })
      .catch(error => {
        commit("error", true);
        throw new Error(error);
      })
      .finally(() => {
        commit("creating", false);
      });
  },

  resetState({ commit }) {},
};

const mutations = {
  setRoomTypes(state, roomTypes) {
    state.roomTypes = roomTypes;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  creating(state, isCreating) {
    state.isCreating = isCreating;
  },
  error(state, hasError) {
    state.hasError = hasError;
  },
};

export const boligmappaRoom = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
