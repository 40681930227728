export function signupEvent({ orgnr, employeecount = 1 }) {
  const data = {
    event: "signup",
    signuptype: "company",
    orgnr,
    employeecount,
  };

  if (process.env.NODE_ENV === "production") {
    window.dataLayer.push(data);
  }
}

export function storeUtmParametersInLocalStorage(query) {
  if (Object.keys(query).length === 0) {
    return;
  }

  const utmQueryParams = Object.entries(query).reduce((acc, [key, value]) => {
    if (key.startsWith("utm_")) {
      acc[key] = value;
    }
    return acc;
  }, {});

  if (Object.keys(utmQueryParams).length > 0) {
    localStorage.setItem("utmParams", JSON.stringify(utmQueryParams));
  }
}
