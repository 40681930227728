<template>
  <div
    class="action_bar elevation-1 ui-background-black d-flex flex-column pa-2 rounded"
    v-if="show"
  >
    <slot name="actions"> </slot>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
  model: {
    prop: "show",
    event: "show:change",
  },
};
</script>
