<template>
  <v-menu offset-y transition="slide-y-transition" origin="top right">
    <template v-slot:activator="{ on, attrs }">
      <AppBtnWithIndicator
        v-bind="attrs"
        v-on="on"
        :indicatorText="selectedText"
        :text="$t('common.basedOn')"
        show-indicator
      />
    </template>
    <div class="pa-2 ui-background d-flex flex-column" style="min-width: 70px">
      <AppMenuItem
        v-for="item in basedOnItems"
        :key="item.key"
        :text="item.text"
        :icon="item.icon"
        :isSelected="basedOn === item.key"
        @click="$emit('basedOn:change', item.key)"
      />
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    basedOn: String,
    model: String,
  },
  data() {
    return {
      basedOnItems: [
        {
          text: this.$t("common.both"),
          icon: this.$icons.REGULAR.COMMON.RIGHT_LONG,
          key: this.$constant.BASED_ON_TYPES.BOTH,
        },
        {
          text: this.$t("common.start"),
          icon: this.$icons.REGULAR.ACTION.START_DATE,
          key: this.$constant.BASED_ON_TYPES.START,
        },
        {
          text: this.$t("common.end"),
          icon: this.$icons.REGULAR.ACTION.DUE_DATE,
          key: this.$constant.BASED_ON_TYPES.END,
        },
      ],
    };
  },
  computed: {
    selectedText() {
      return this.basedOnItems.find((item) => item.key === this.basedOn)?.text || "";
    },
  },
  methods: {},
};
</script>
