<template>
  <div class="information_drawer">
    <v-toolbar class="border-b" dense flat fixed height="68">
      <v-toolbar-title>
        {{ $t("fileManagement.preview.navMenu.readConfirmation.title") }}
      </v-toolbar-title>
      <v-spacer />

      <v-btn icon @click="refresh">
        <v-icon>{{ $icons.LIGHT.ACTION.REFRESH }}</v-icon>
      </v-btn>
      <AppToggleMenuBtn>
        <AppMenuItem
          :text="$t('fileManagement.preview.navMenu.readConfirmation.addMoreRecipients')"
          :icon="$icons.REGULAR.ACTION.ADD"
          @click="addRecipients"
        />

        <AppMenuItem
          :disabled="isDownloadDisabled"
          :text="$t('fileManagement.preview.navMenu.readConfirmation.download')"
          :icon="$icons.REGULAR.ACTION.DOWNLOAD"
          @click="downloadReadConfirmation"
        />

        <AppMenuItem
          :disabled="isRemindRecipientsDisabled"
          :text="$t('fileManagement.preview.navMenu.readConfirmation.sendReminder')"
          :icon="$icons.REGULAR.ACTION.NOTIFICATION"
          @click="dialog.remindRecipients = true"
        />

        <AppMenuItem
          :disabled="isResetActionRequestDisabled"
          :text="$t('fileManagement.preview.navMenu.readConfirmation.reset')"
          :icon="$icons.REGULAR.ROUTE.EVENT_LOG"
          @click="dialog.resetActionRequest = true"
        />

        <AppMenuItem
          :disabled="isRevokeActionRequestDisabled"
          :text="$t('fileManagement.preview.navMenu.readConfirmation.revoke')"
          :icon="$icons.REGULAR.COMMON.SIGNATURE_SLASH"
          color="error"
          @click="dialog.revokeActionRequest = true"
        />
      </AppToggleMenuBtn>
    </v-toolbar>

    <AppCenterLoader
      class="ma-0"
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />

    <template v-if="!isLoading && storage">
      <template v-if="isStatusNotStarted">
        <NotStartedDrawer :storageId="storageId" @addRecipients="addRecipients" />
      </template>

      <template v-else-if="isStatusInProgress">
        <InProgressDrawer :actionRequest="actionRequest" />
      </template>

      <template v-else-if="isStatusCompleted">
        <CompletedDrawer :actionRequest="actionRequest" />
      </template>

      <template v-else> something went wrong... </template>

      <AppActionRequestAddRecipientsDialog
        model="storage"
        type="READ_CONFIRMATION"
        :modelId="storageId"
        :actionRequestId="actionRequestId"
        v-model="dialog.addRecipients"
        @submit="refresh"
      />

      <AppActionRequestRemindDialog
        v-if="actionRequestId"
        :actionRequestId="actionRequestId"
        :recipients="recipients"
        v-model="dialog.remindRecipients"
        @submit="refresh"
      />

      <AppConfirmationTextareaDialog
        v-model="dialog.resetActionRequest"
        @confirm="onResetActionRequest"
        :title="$t('fileManagement.preview.navMenu.readConfirmation.resetDialog.title')"
        :subTitle="
          $t('fileManagement.preview.navMenu.readConfirmation.resetDialog.subTitle')
        "
        :textareaLabel="
          $t('fileManagement.preview.navMenu.readConfirmation.resetDialog.textareaLabel')
        "
        :alertText="
          $t('fileManagement.preview.navMenu.readConfirmation.resetDialog.alertText')
        "
        :confirmText="
          $t('fileManagement.preview.navMenu.readConfirmation.resetDialog.confirmText')
        "
      />

      <AppConfirmationTextareaDialog
        v-model="dialog.revokeActionRequest"
        @confirm="onRevokeActionRequest"
        :title="$t('fileManagement.preview.navMenu.readConfirmation.revokeDialog.title')"
        :subTitle="
          $t('fileManagement.preview.navMenu.readConfirmation.revokeDialog.subTitle')
        "
        :textareaLabel="
          $t('fileManagement.preview.navMenu.readConfirmation.revokeDialog.textareaLabel')
        "
        :alertText="
          $t('fileManagement.preview.navMenu.readConfirmation.revokeDialog.alertText')
        "
        :confirmText="
          $t('fileManagement.preview.navMenu.readConfirmation.revokeDialog.confirmText')
        "
      />
    </template>
  </div>
</template>

<script>
import {
  resetActionRequest,
  revokeActionRequest,
  downloadActionRequest,
} from "@/services/actionRequest/actionRequest";

import CompletedDrawer from "./status/CompletedDrawer";
import InProgressDrawer from "./status/InProgressDrawer";
import NotStartedDrawer from "./status/NotStartedDrawer";

export default {
  props: {
    storage: Object,
    isLoading: Boolean,
  },
  data() {
    return {
      isDownloading: false,
      dialog: {
        addRecipients: false,
        remindRecipients: false,
        resetActionRequest: false,
        revokeActionRequest: false,
      },
    };
  },
  computed: {
    storageId() {
      return this.storage?.id;
    },
    actionRequest() {
      const actionRequests = this.storage?.actionRequests || [];
      return actionRequests.find((ar) => ar.type === "READ_CONFIRMATION");
    },
    actionRequestId() {
      return this.actionRequest?.id;
    },
    status() {
      return (
        this.actionRequest?.status || this.$constant.ACTION_REQUEST_STATUS.NOT_STARTED
      );
    },
    recipients() {
      return this.actionRequest?.recipients || [];
    },
    isStatusNotStarted() {
      return this.status === this.$constant.ACTION_REQUEST_STATUS.NOT_STARTED;
    },
    isStatusInProgress() {
      return this.status === this.$constant.ACTION_REQUEST_STATUS.IN_PROGRESS;
    },
    isStatusCompleted() {
      return this.status === this.$constant.ACTION_REQUEST_STATUS.COMPLETED;
    },
    isRemindRecipientsDisabled() {
      return this.isStatusNotStarted || this.isStatusCompleted;
    },
    isResetActionRequestDisabled() {
      return this.isStatusNotStarted || this.isStatusCompleted;
    },
    isRevokeActionRequestDisabled() {
      return this.isStatusNotStarted || this.isStatusCompleted;
    },
    isDownloadDisabled() {
      return (!this.isStatusCompleted && !this.isStatusInProgress) || this.isDownloading;
    },
  },
  methods: {
    refresh() {
      this.$emit("storage:refresh");
    },
    addRecipients() {
      this.dialog.addRecipients = true;
    },
    onResetActionRequest({ comment }) {
      resetActionRequest({ id: this.actionRequestId, body: { comment } })
        .then(() => {
          this.refresh();
        })
        .catch((error) => {
          throw error;
        });
    },
    onRevokeActionRequest({ comment }) {
      revokeActionRequest({ id: this.actionRequestId, body: { comment } })
        .then(() => {
          this.refresh();
        })
        .catch((error) => {
          throw error;
        });
    },
    downloadReadConfirmation() {
      this.isDownloading = true;
      downloadActionRequest({ id: this.actionRequestId })
        .then(() => {
          this.refresh();
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
    autoOpenRecipientsDialog() {
      const shouldAutoOpenDialog =
        this.$route.query?.openAddRecipients === "true" ||
        this.$route.query?.openAddRecipients === true;

      if (shouldAutoOpenDialog) {
        this.addRecipients();
        // Remove query parameter
        this.$router.replace({
          query: { ...this.$route.query, openAddRecipients: undefined },
        });
      }
    },
  },
  mounted() {
    this.autoOpenRecipientsDialog();
  },
  components: {
    NotStartedDrawer,
    InProgressDrawer,
    CompletedDrawer,
  },
};
</script>
