<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("cms.template.document.editStatus.title") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="editTemplateStatusForm"
          v-model="item.valid"
          v-on:submit.prevent
          @submit="onSave"
        >
          <v-row>
            <v-col align="center">
              <v-btn-toggle mandatory color="primary" v-model="item.isPublished.value">
                <v-btn
                  :value="false"
                  height="100"
                  width="175"
                  class="text-transform-none text-body-1"
                >
                  <template v-slot>
                    <div class="d-flex flex-column">
                      <span class="subtitle-1">{{
                        $t("cms.template.document.status.unpublished")
                      }}</span>
                    </div>
                  </template>
                </v-btn>
                <v-btn
                  :value="true"
                  style="text-transform: none"
                  height="100"
                  width="175"
                >
                  <template v-slot>
                    <div class="d-flex flex-column">
                      <span class="subtitle-1">{{
                        $t("cms.template.document.status.published")
                      }}</span>
                    </div>
                  </template>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSave"
          @cancel="localDialog = false"
          :confirmText="$t('common.save')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  data() {
    return {
      item: {
        valid: false,
        isPublished: {
          value: false,
        },
      },
    };
  },
  methods: {
    onSave() {
      const valid = this.$refs.editTemplateStatusForm.validate();
      if (valid) {
        const data = {
          isPublished: this.item.isPublished.value,
        };
        this.$emit("submit:form", { data });
        this.$refs.editTemplateStatusForm.reset();
        this.localDialog = false;
      }
    },
  },
};
</script>
