<script>
import { VAlert } from "vuetify/lib";
export default {
  extends: VAlert,
  props: {
    text: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
