var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AppSelectItemsDialog',{attrs:{"title":_vm.$t('projectView.products.addProductsDialog.findAndAddDialog.title'),"availableItemsText":_vm.$tc(
      'projectView.products.addProductsDialog.findAndAddDialog.availableProducts',
      _vm.countAll,
    ),"searchPlaceholder":_vm.$t('projectView.products.addProductsDialog.findAndAddDialog.searchPlaceholder'),"confirmText":_vm.$t('projectView.products.actionBtnText'),"selectedItemsText":_vm.$tc(
      'projectView.products.addProductsDialog.findAndAddDialog.productsSelected',
      _vm.selectedProducts.length,
    ),"isLoadingStats":_vm.isLoadingStats,"isLoadingItems":_vm.isLoadingProducts || _vm.filtersMixins_isLoadingFilters,"items":_vm.products,"count":_vm.count,"selectedItems":_vm.selectedProducts,"search":_vm.filtersMixins_filterByKey('search')},on:{"searchInput":_vm.filtersMixins_debounceSearch,"load-more":function($event){return _vm.getProducts(true)},"submit":_vm.addProducts},scopedSlots:_vm._u([{key:"item-list",fn:function({ items }){return _vm._l((items),function(item,index){return _c('AppSelectProductsItem',{key:index,attrs:{"product":item,"isChecked":_vm.selectedProducts.some((p) => p?.id === item.id)},on:{"toggleChecked":_vm.toggleChecked}})})}}]),model:{value:(_vm.localDialog),callback:function ($$v) {_vm.localDialog=$$v},expression:"localDialog"}})
}
var staticRenderFns = []

export { render, staticRenderFns }