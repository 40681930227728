<template>
  <div v-if="!isLoading" class="d-flex align-center" style="width: 25%">
    <v-progress-linear
      :value="donePercentage"
      :color="progressionColor"
      height="20"
      rounded
    >
      <span class="white--text body-2">{{ progressionText }}</span>
    </v-progress-linear>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState("todos", {
      isLoading: (state) => state.isLoading,
    }),
    ...mapGetters("todos", {
      doneCount: "doneCount",
      totalCount: "totalCount",
      donePercentage: "donePercentage",
    }),
    progressionText() {
      return `${this.doneCount} / ${this.totalCount}`;
    },
    progressionColor() {
      if (this.totalCount === 0) return "grey";
      return this.doneCount >= this.totalCount ? "success" : "info";
    },
  },
};
</script>
