<template>
  <div>
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteDialog.active"
      :item="dialog.deleteDialog.item"
      :title="$tc('cms.template.project.delete.title', dialog.deleteDialog.item.length)"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('fileManagement.fileExplorer.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="templateDelete"
    />
    <AppCopyItemsDialog
      v-model="dialog.copyDialog.active"
      :inItems="dialog.copyDialog.items"
      type="PROJECT_TEMPLATE"
      @submit="onTemplateCopy"
    />
    <AppDataTable
      :loading="isLoading"
      :headers="_headers"
      :items="templates"
      :noDataText="$t('common.noTemplates')"
      :bulkActionConfigs="bulkActionConfigs"
      :removeMutationType="removeMutationType"
      :updateMutationType="updateMutationType"
      show-select
      :statsText="statsText"
      :isStatsLoading="isStatsLoading"
    >
      <template v-slot:item="{ item, select, isSelected }">
        <AppFormTemplateTableItem
          :template="item"
          :isSelected="isSelected"
          :select="select"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          hide-category
          @edit:click="onTemplateEditClick"
          @menu:click="openContextMenu"
        />
      </template>
    </AppDataTable>
    <AppContextMenu ref="contextMenu" @input="onContextMenuChange">
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.EDIT).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.EDIT).on"
        :selectedItems="[contextMenuActiveItem]"
      />
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.COPY).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.COPY).on"
        :selectedItems="[contextMenuActiveItem]"
      />
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.DELETE).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.DELETE).on"
        :selectedItems="[contextMenuActiveItem]"
      />
    </AppContextMenu>
  </div>
</template>

<script>
export default {
  props: {
    templates: Array,
    isLoading: Boolean,
    isCms: Boolean,
    statsText: String,
    isStatsLoading: Boolean,
  },
  data() {
    return {
      assignableUsers: [],
      isAssignableUsersLoading: false,
      assignableProjects: [],
      isAssignableProjectsLoading: false,
      dialog: {
        deleteDialog: {
          active: false,
          item: [],
        },
        copyDialog: {
          active: false,
          items: [],
        },
      },
      contextMenuActiveItem: null,
    };
  },
  computed: {
    removeMutationType() {
      return this.isCms
        ? "cmsProjectTemplates/removeTemplate"
        : "projectTemplates/removeTemplate";
    },
    updateMutationType() {
      return this.isCms
        ? "cmsProjectTemplates/updateTemplate"
        : "projectTemplates/updateTemplate";
    },
    bulkActionConfigs() {
      return this.$constant.getBulkActionConfigs(this.actionConfigs);
    },
    actionConfigs() {
      const { EDIT, DELETE, COPY } = this.$constant.ACTION_KEYS;

      const customActions = [
        {
          key: EDIT,
          on: {
            click: this.onTemplateEditClick,
          },
        },
        {
          key: COPY,
          props: {
            hidden: this.isCms,
          },
          on: {
            click: this.onCopyClick,
          },
        },

        {
          key: DELETE,
          on: {
            click: this.onTemplateDelete,
          },
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.FORM_TEMPLATE_ACTIONS,
      });

      return config;
    },
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          text: this.$t("cms.template.project.name"),
          value: "name",
          show: true,
        },
        {
          text: this.$t("common.status"),
          value: "isPublished",
          show: true,
        },
        // {
        //   text: this.$t("common.category"),
        //   value: "category.name",
        //   show: true,
        // },
        {
          text: this.$t("common.lastUpdated"),
          value: "updatedAt",
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
  },
  methods: {
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    actionConfigByKey(key) {
      return this.actionConfigs.find((action) => action.key === key) || {};
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    openContextMenu({ from, template }) {
      this.$refs.contextMenu.open({ from });
      this.$nextTick(() => {
        this.contextMenuActiveItem = template;
      });
    },
    onTemplateEditClick({ items }) {
      const template = items?.[0];
      this.$emit("template:editClick", { template });
    },
    onTemplateDelete({ items }) {
      this.dialog.deleteDialog.active = true;
      this.dialog.deleteDialog.item = items;
    },
    onCopyClick({ items }) {
      this.dialog.copyDialog.active = true;
      this.dialog.copyDialog.items = items;
    },
    onTemplateCopy(items) {
      this.$emit("template:copy", { items });
    },
    templateDelete({ item }) {
      this.$emit("template:delete", { items: item });
    },
  },
};
</script>
