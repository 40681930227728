<script>
import { projectTemplateBuilderMixins } from "@/helpers/mixins";
import { VIcon, VSpacer, VForm } from "vuetify/lib";
import CustomButtonTooltip from "./CustomButtonTooltip.vue";
export default {
  mixins: [projectTemplateBuilderMixins],
  props: {
    item: Object,
    open: Boolean,
    storageItems: Array,
  },
  data() {
    return {
      isHovering: false,
      name: {
        isValid: false,
      },
    };
  },
  methods: {
    updateItem({ value, fieldPath }) {
      const oldValue = this.getItemOldValue({ fieldPath });
      const hasId = !!this.item.id;
      if (value !== oldValue || !hasId) {
        const body = this.getItemBody({ value, fieldPath });
        this.$emit("item:update", { item: this.item, body });
      }
    },
    getItemOldValue({ fieldPath }) {
      const fieldPathArr = fieldPath.split(".");
      const oldValue = fieldPathArr.reduce((prevValue, currentValue) => {
        if (!prevValue) return null;
        return prevValue[currentValue];
      }, this.item);

      return oldValue;
    },
    getItemBody({ value, fieldPath }) {
      const fieldPathArr = fieldPath.split(".");
      const body = {};
      fieldPathArr.reduce((prevValue, currentValue, index) => {
        const isLastIteration = fieldPathArr.length - 1 === index;
        const currentBody = (prevValue[currentValue] = isLastIteration ? value : {});
        return currentBody;
      }, body);

      return body;
    },
    onStorageNameChangeEnter() {
      setTimeout(() => {
        document.getElementById(`storageItemTextField_${this.item?.refId}`)?.blur();
      }, 20);
    },
    hasChild(id) {
      const hasChild = this.storageItems.some((i) => i.parentId === id);
      return hasChild;
    },
    getIcon() {
      const isFolder = this.item.values?.type === "FOLDER";
      const hasChild = this.hasChild(this.item.id);

      if (isFolder && this.open && hasChild) {
        return this.$icons.LIGHT.FILE.FOLDER_OPEN;
      } else if (isFolder) {
        return this.$icons.LIGHT.FILE.FOLDER;
      } else {
        return this.$icons.LIGHT.FILE.FILE;
      }
    },
    onCreateSubFolderClick() {
      this.$emit("item:createSubFolder", {
        item: this.item,
        type: "FOLDER",
        parentId: this.item.id,
      });
    },
    deleteStorage() {
      this.$emit("item:delete", { item: this.item });
    },
    genFolderIconButton() {
      if (!this.item && !this.item.values) return null;
      const isFolder = this.item?.values?.type === "FOLDER";
      return isFolder
        ? this.$createElement(CustomButtonTooltip, {
            props: {
              text: this.$t("templateManagement.project.builder.storage.createSubFolder"),
              icon: this.$icons.LIGHT.FILE.FOLDER_TREE,
              disabled: !this.projectTemplateBuilderMixins_isItemSaved(this.item),
            },
            on: {
              "button:click": () => this.onCreateSubFolderClick(),
            },
          })
        : null;
    },
    onNameChange(e) {
      const hasId = !!this.item.id;
      if (this.name.isValid || !hasId) {
        this.updateItem({
          value: e?.target?.value,
          fieldPath: "name",
        });
      }
    },
    genActionButtons() {
      return this.isHovering
        ? [
            this.genFolderIconButton(),
            this.$createElement(CustomButtonTooltip, {
              class: "ml-2",
              props: {
                text: this.$t("common.delete"),
                icon: this.$icons.LIGHT.ACTION.DELETE,
                disabled: !this.projectTemplateBuilderMixins_isItemSaved(this.item),
              },
              on: {
                "button:click": () => this.deleteStorage(),
              },
            }),
          ]
        : [];
    },
  },
  render(h) {
    return h(
      "div",
      {
        class: "d-flex align-center",
        on: {
          mouseover: () => {
            this.isHovering = true;
          },
          mouseleave: () => {
            this.isHovering = false;
          },
        },
      },
      [
        h(VIcon, this.getIcon()),
        h("div", { class: "w-100 pl-2 text-border-reset" }, [
          this.$createElement(
            VForm,
            {
              props: { value: this.name.isValid },
              on: {
                submit: (e) => e.preventDefault(),
                input: (e) => (this.name.isValid = e),
              },
            },
            [
              h("AppDefaultTextField", {
                class: "py-2",
                props: {
                  id: `storageItemTextField_${this.item.refId}`,
                  hideDetails: true,
                  value: this.item?.name,
                  rules: this.projectTemplateBuilderMixins_nameRules,
                  backgroundColor: "transparent",
                },
                on: {
                  click: (e) => {
                    e.stopPropagation();
                  },
                  blur: (e) => this.onNameChange(e),
                  keydown: (e) =>
                    e.key === "Enter" ? this.onStorageNameChangeEnter() : "",
                },
              }),
            ],
          ),
        ]),
        h(VSpacer),
        ...this.genActionButtons(),
      ],
    );
  },
};
</script>
