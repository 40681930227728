<template>
  <div>
    <AppDataTable
      ref="dataTable"
      :loading="isLoading"
      :headers="_headers"
      :items="storages"
      :noDataText="$t('common.noStorage')"
      :height="dataTableHeight"
      :bulkActionConfigs="bulkActionConfigs"
      :isItemSelectable="isItemSelectable"
      :removeMutationType="removeMutationType"
      :updateMutationType="updateMutationType"
      @input="$emit('input', $event)"
      disable-pagination
      show-select
    >
      <template v-slot:item="{ item, isSelected, isSelectable, select }">
        <AppStorageTableItem
          :storage="item"
          :isSelected="isSelected"
          :isSelectable="isSelectable"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :contextMenuItemsToHide="contextMenuItemsToHide"
          @isSelected:change="select"
          @name:click="onNameClick"
          @menu:click="openContextMenu"
        />
      </template>
    </AppDataTable>
    <AppStorageContextMenu
      ref="contextMenu"
      :contextMenuActiveItem="contextMenuActiveItem"
      :showBoligmappa="showBoligmappa"
      :folderRouterName="folderRouterName"
      :rootId="rootId"
      :contextMenuItemsToHide="contextMenuItemsToHide"
      :actionConfigs="actionConfigs"
      @input="onContextMenuChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    storages: Array,
    isLoading: Boolean,
    selectedStorages: Array,
    showBoligmappa: Boolean,
    folderRouterName: String,
    rootId: String,
    dataTableHeight: String,
    contextMenuItemsToHide: Object,
    storageTypesNotAllowedToSelect: Array,
    actionConfigs: Array,
    removeMutationType: String,
    updateMutationType: String,
  },
  model: {
    prop: "selectedStorages",
    event: "selectedStorages:change",
  },
  data() {
    return {
      contextMenuActiveItem: null,
    };
  },
  computed: {
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          text: this.$t("fileManagement.list.name"),
          align: "start",
          value: "name",
          show: true,
        },
        {
          text: this.$t("fileManagement.list.owner"),
          align: "start",
          value: "storageOwner.firstName",
          show: true,
        },
        {
          text: this.$t("fileManagement.list.lastModified"),
          align: "start",
          value: "updatedAt",
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
    selectableStorages() {
      return this.storages.filter((s) => this.isSelectableStorage(s));
    },
    isAllSelectableStoragesSelected() {
      return (
        this.isStoragesSelected(this.selectableStorages) &&
        !!this.selectableStorages?.length
      );
    },
    bulkActionConfigs() {
      return this.$constant.getBulkActionConfigs(this.actionConfigs);
    },
  },
  methods: {
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    isStoragesSelected(storages) {
      return storages.every((s) =>
        this.localSelectedStorages.some((file) => file.id === s.id),
      );
    },
    isSelectableStorage(storage) {
      return !this.storageTypesNotAllowedToSelect?.includes(storage.type);
    },
    openContextMenu({ from, storage }) {
      if (!this.isSelectableStorage(storage)) return;

      this.$refs.contextMenu.open({ from });

      this.$nextTick(() => {
        this.contextMenuActiveItem = storage;
      });
    },
    onNameClick({ storage }) {
      this.$emit("storage:click", { storage });
    },
    isItemSelectable(item) {
      return !this.storageTypesNotAllowedToSelect?.includes(item.type);
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    clearSelection() {
      this.$refs.dataTable.dataTableBulkActionMixin_clearSelection();
    },
  },
};
</script>
