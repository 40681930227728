<template>
  <AppDefaultChart
    :chartData="chartData_itemsByStatusCategory_includeStatuses"
    :chartOptions="chartOptions"
  />
</template>

<script>
export default {
  data() {
    return {
      widgetData: {
        chart: {
          //widget props
          //working types: bar, horizontal bar, line,
          //area, pie, donut, radialBar,
          //treemap, scatter, radar, polarArea,
          //radialBar values are %
          type: "bar",
          stacked: true,
          xaxisType: "category", //decided by by (category or datetime)
          dataSets: [{ name: "TASK" }, { name: "PROJECT" }, { name: "FORM" }],
          //start: "123212312321213" //put in new date to format dato format (start and end isnt saved on widget props)
          //end: "1232132198172387232" //put in new date to format dato format
          //projectId: "ABC123"
          by: "STATUS_CATEGORY", //each by can have a predefined subchart?
          includeSubChart: "STATUSES", //can be boolean if prefedined?
          //names and labels can come translated from backend?
        },
      },
      chartData_itemsByStatusCategory_includeStatuses: {
        datasets: [
          {
            name: "TASK",
            color: "#C7E3E4",
            dataPoints: [
              {
                id: "081aa5c8-5984-4fa8-adc0-1d86ea446f78",
                label: "NOT_STARTED",
                value: 20,
                subChart: {
                  labels: ["Til vurdering", "Ikke startet"],
                  xaxis: {
                    type: "category",
                  },
                  datasets: [
                    {
                      name: "Statuses",
                      dataPoints: [
                        {
                          id: "59fbb002-004c-480f-9208-ce62c78a74d3",
                          label: "Til vurdering",
                          value: 7,
                          color: "#A7C7E4",
                        },
                        {
                          id: "bd526c16-5bdc-439d-822b-cda051329de2",
                          label: "Ikke startet",
                          value: 13,
                          color: "#E4D7C7",
                        },
                      ],
                    },
                  ],
                },
              },
              {
                id: "9aaea9a0-5c63-4498-9817-bfa949fb862b",
                label: "IN_PROGRESS",
                value: 29,
              },
              {
                id: "b3f1a1c2-8f5b-4c7d-9a93-2a3d00a3b8ff",
                label: "COMPLETED",
                value: 15,
                subChart: {
                  labels: ["Quality Check", "Final Review"],
                  datasets: [
                    {
                      name: "Completion Statuses",
                      dataPoints: [
                        {
                          id: "a1e76e88-71f4-4d10-b77d-bc8a76f5b506",
                          label: "Quality Check",
                          value: 5,
                          color: "#C7E4A7",
                        },
                        {
                          id: "f2c10e96-4f5b-411b-a888-6b4955a2f5ef",
                          label: "Final Review",
                          value: 10,
                          color: "#E4C7A7",
                        },
                      ],
                    },
                  ],
                },
              },
              {
                id: "c4d5e5e2-ea3e-4f5b-9d67-7b521584a0b2",
                label: "CLOSED",
                value: 8,
              },
            ],
          },
          {
            name: "PROJECT",
            color: "#FEC9A4",
            dataPoints: [
              {
                id: "a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p6",
                label: "NOT_STARTED",
                value: 45,
                subChart: {
                  labels: ["Ikke startet", "Startet"],
                  datasets: [
                    {
                      name: "Statuses",
                      dataPoints: [
                        {
                          id: "q1w2e3r4-t5y6-u7i8-o9p0-a1s2d3f4g5h6",
                          label: "Ikke startet",
                          value: 30,
                          color: "#A4E4C7",
                        },
                        {
                          id: "z7x8c9v0-b1n2-m3a4-s5d6-f7g8h9j0k1l2",
                          label: "Startet",
                          value: 15,
                          color: "#E4A7C7",
                        },
                      ],
                    },
                  ],
                },
              },
              {
                id: "m5n6b7v8-c9x0-z1l2-k3j4-h5g6f7d8s9a0",
                label: "IN_PROGRESS",
                value: 25,
              },
              {
                id: "p0o9i8u7-y6t5-r4e3-w2q1-a1s2d3f4g5h6",
                label: "COMPLETED",
                value: 20,
              },
              {
                id: "l1k2j3h4-g5f6-d7s8-a9q0-z1x2c3v4b5n6",
                label: "CLOSED",
                value: 10,
              },
            ],
          },
        ],
      },
      chartData_itemsByDate: {
        datasets: [
          {
            name: "TASK",
            color: "#C7E3E4",
            dataPoints: [
              { label: "2023-01-01", value: 3 },
              { label: "2023-01-02", value: 2 },
              { label: "2023-01-03", value: 10 },
              { label: "2023-01-04", value: 5 },
              { label: "2023-01-05", value: 3 },
            ],
          },
          {
            name: "PROJECT",
            color: "#FEC9A4",
            dataPoints: [
              { label: "2023-01-01", value: 5 },
              { label: "2023-01-02", value: 3 },
              { label: "2023-01-03", value: 8 },
              { label: "2023-01-04", value: 7 },
              { label: "2023-01-05", value: 4 },
            ],
          },
          {
            name: "FORM",
            color: "#DFD1EF",
            dataPoints: [
              { label: "2023-01-01", value: 2 },
              { label: "2023-01-02", value: 2 },
              { label: "2023-01-03", value: 5 },
              { label: "2023-01-04", value: 3 },
              { label: "2023-01-05", value: 2 },
            ],
          },
        ],
      },
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: this.widgetData.chart.type,
          stacked: this.widgetData.chart.stacked,
        },
        xaxis: {
          type: this.widgetData.chart.xaxisType,
        },
      };
    },
  },
};
</script>
