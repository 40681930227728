<template>
  <AppFiltersDrawer
    v-if="localShowDrawer"
    @close="closeFilters"
    :tableModel="tableModel"
    :children="children"
  />
</template>

<script>
import { filtersItemsMixins } from "@/helpers/mixins";
import { getApplicationTypes } from "@/services/integration/eByggesok/applicationTypes";
import { getConstructionCases } from "@/services/integration/eByggesok/constructionCases";
import { filtersWatchMixins } from "@/helpers/mixins";

export default {
  mixins: [filtersItemsMixins, filtersWatchMixins],
  props: {
    showDrawer: Boolean,
    tableModel: String,
  },
  model: {
    prop: "showDrawer",
    event: "change",
  },
  data() {
    return {
      applicationTypes: null,
      isLoadingApplicationTypes: false,
      constructionCases: null,
      isLoadingConstructionCases: false,
    };
  },
  methods: {
    getData() {
      this.getApplicationTypes();
      this.getConstructionCases();
    },
    closeFilters() {
      this.localShowDrawer = false;
    },
    getApplicationTypes() {
      this.isLoadingApplicationTypes = true;
      getApplicationTypes()
        .then((applicationTypes) => {
          this.applicationTypes = applicationTypes;
        })
        .finally(() => {
          this.isLoadingApplicationTypes = false;
        });
    },
    getConstructionCases() {
      this.isLoadingConstructionCases = true;
      getConstructionCases(this.projectId)
        .then((applicationTypes) => {
          this.constructionCases = applicationTypes;
        })
        .finally(() => {
          this.isLoadingConstructionCases = false;
        });
    },
  },
  computed: {
    localShowDrawer: {
      get() {
        return this.showDrawer;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    projectId() {
      return this.$route.params.projectId;
    },
    children() {
      const children = [
        {
          is: "AppFiltersSelect",
          labelKey: "filters.eByggesokFilters.createdDate",
          filterValueKey: "createdDate",
          itemValue: "id",
          itemText: "name",
          items: this.filtersItemsMixins_dateItems,
        },
        {
          is: "AppFiltersMultiAutocomplete",
          labelKey: "filters.eByggesokFilters.constructionCase",
          filterValueKey: "caseIds",
          itemValue: "id",
          itemText: "name",
          items: this.constructionCases ?? null,
          loading: this.isLoadingConstructionCases,
        },
        {
          is: "AppFiltersMultiAutocomplete",
          labelKey: "filters.eByggesokFilters.applications",
          filterValueKey: "types",
          "item-value": "type",
          "item-text": "type",
          items: this.applicationTypes ?? null,
          loading: this.isLoadingApplicationTypes,
        },
      ];

      return children;
    },
  },
};
</script>
