import Vue from "vue";
import VueI18n from "vue-i18n";
import nb from "@/i18n/nb";
import en from "@/i18n/en";

Vue.use(VueI18n);

const language = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "nb";

const numberFormats = {
  nb: {
    currency: {
      style: "currency",
      currency: "NOK",
      currencyDisplay: "code",
    },
  },
  en: {
    currency: {
      style: "currency",
      currency: "GBP",
    },
  },
};

export default new VueI18n({
  numberFormats,
  locale: language,
  fallbackLocale: "en",
  messages: {
    en,
    nb,
  },
});
