<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <template v-if="showSettings">
      <IntegrationInstanceSettings
        :integration="integration"
        @complete="complete"
        @cancel="cancel"
      />
    </template>
  </v-dialog>
</template>

<script>
import IntegrationInstanceSettings from "./IntegrationInstanceSettings.vue";
export default {
  components: { IntegrationInstanceSettings },
  props: {
    dialog: Boolean,
    integration: Object,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    integrationName() {
      return this.integration?.name;
    },
    // isTripletexSettings() {
    //   return this.integrationName === this.$constant.TRIPLETEX;
    // },
    // isPowerofficeGo() {
    //   return this.integrationName === this.$constant.POWEROFFICE;
    // },
    // isFiken() {
    //   return this.integrationName === this.$constant.FIKEN;
    // },
    // isDuett() {
    //   return this.integrationName === this.$constant.DUETT;
    // },
    // isSparebank1Regnskap() {
    //   return this.integrationName === this.$constant.SPAREBANK1_REGNSKAP;
    // },
    // isDNBRegnskap() {
    //   return this.integrationName === this.$constant.DNB_REGNSKAP;
    // },
    // isUnimicro() {
    //   return this.integrationName === this.$constant.UNIMICRO;
    // },
    showSettings() {
      const isDialogOpen = this.dialog;
      const isAccountingIntegration = this.$constant.ACCOUNTING_INTEGRATIONS.includes(
        this.integrationName,
      );
      return isDialogOpen && isAccountingIntegration;
    },
  },
  methods: {
    closeDialog() {
      this.localDialog = false;
    },
    complete() {
      this.closeDialog();
      this.$emit("complete");
    },
    cancel() {
      this.closeDialog();
    },
  },
};
</script>
