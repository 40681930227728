<template>
  <AppBtnWithIndicator
    :showIndicator="hasFilters"
    :icon="$icons.SOLID.ACTION.ADD"
    :indicatorText="filtersMixins_appliedFiltersCount"
    @click="click"
    :text="$t('common.filters')"
  />
</template>

<script>
import { filtersMixins } from "@/helpers/mixins";
import AppBtnWithIndicator from "./AppBtnWithIndicator.vue";
export default {
  mixins: [filtersMixins],
  props: {
    tableModel: String,
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
  computed: {
    hasFilters() {
      return this.filtersMixins_appliedFiltersCount > 0;
    },
  },
  components: { AppBtnWithIndicator },
};
</script>
