<template>
  <v-list-item :input-value="isSelected">
    <div class="d-flex flex-column py-2">
      <div class="font-weight-bold text-body-2">
        {{ organization.name }}
      </div>
      <div class="text-caption">Org. {{ organization.orgnr }}</div>
      <div class="text-caption">
        {{ organization.fullAddress }}
      </div>
    </div>
  </v-list-item>
</template>

<script>
export default {
  props: {
    organization: Object,
    isSelected: Boolean,
  },
};
</script>
