<template>
  <div class="d-flex">
    <span class="text-body-1 font-weight-bold pb-2" style="width: 280px">
      {{ label }}:
    </span>
    <span class="text-body-1">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: [String, Object],
  },
};
</script>
