<template>
  <div :class="childClass">
    <div class="text-h6 font-weight-bold">
      {{ title }}
    </div>
    <div class="pb-4">
      {{ subTitle }}
    </div>
    <div>
      <v-btn
        color="background"
        depressed
        :href="`mailto:${$constant.CONTACT_EMAIL}?subject=${emailSubject}`"
        target="_blank"
      >
        {{ buttonText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    childClass: String,
    title: String,
    subTitle: String,
    buttonText: String,
    emailSubject: String,
  },
};
</script>
