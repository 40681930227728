import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  stats: [],
  isLoading: false,
});

const getters = {
  statsCountInProgress: (state, getters) => {
    const inProgressProjects = getters.countByStatus("IN_PROGRESS");
    return inProgressProjects;
  },
  countByStatus: (state) => (status) => {
    return state.stats.find((project) => project.status === status)?.count || 0;
  },
};

const actions = {
  async getStats({ commit }, filter) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}statistics/project`, {
        params: { ...filter },
      })
      .then(({ data }) => {
        const stats = data?.statistics || [];
        commit("setStats", stats);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setStats(state, stats) {
    state.stats = stats;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const projectStats = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
