import {
  IMPORT_TYPES,
  INTEGRATION_NAMES_SUPPORTING_IMPORT_TYPES,
} from "@/helpers/constants/integration";

export const filtersItemsMixins = {
  data() {
    return {
      filtersItemsMixins_sentOrNotItems: [
        { text: this.$t("common.sent"), value: "SENT" },
        { text: this.$t("common.notSent"), value: "NOT_SENT" },
      ],
      filtersItemsMixins_isActiveItems: [
        { text: this.$t("common.active"), value: true },
        { text: this.$t("common.inactive"), value: false },
      ],
      filtersItemsMixins_clientTypeItems: [
        {
          text: this.$t("filters.clientFilters.types.customerCompany"),
          value: this.$constant.CUSTOMER_COMPANY,
        },
        {
          text: this.$t("filters.clientFilters.types.supplierCompany"),
          value: this.$constant.SUPPLIER_COMPANY,
        },
        {
          text: this.$t("filters.clientFilters.types.customerPrivate"),
          value: this.$constant.CUSTOMER_PRIVATE,
        },
        {
          text: this.$t("filters.clientFilters.types.supplierPrivate"),
          value: this.$constant.SUPPLIER_PRIVATE,
        },
      ],
      filtersItemsMixins_yesOrNoItems: [
        { text: this.$t("common.yes"), value: true },
        { text: this.$t("common.no"), value: false },
      ],
      filtersItemsMixins_dateItems: [
        {
          text: this.$t("filters.dateItems.today"),
          value: "TODAY",
        },
        {
          text: this.$t("filters.dateItems.yesterday"),
          value: "YESTERDAY",
        },
        {
          text: this.$t("filters.dateItems.thisWeek"),
          value: "THIS_WEEK",
        },
        {
          text: this.$t("filters.dateItems.lastWeek"),
          value: "LAST_WEEK",
        },
        {
          text: this.$t("filters.dateItems.thisMonth"),
          value: "THIS_MONTH",
        },
        {
          text: this.$t("filters.dateItems.lastMonth"),
          value: "LAST_MONTH",
        },
      ],
      filtersItemsMixins_dateItemsWithFutureDates: [
        {
          text: this.$t("filters.dateItems.today"),
          value: "TODAY",
        },
        {
          text: this.$t("filters.dateItems.yesterday"),
          value: "YESTERDAY",
        },
        {
          text: this.$t("filters.dateItems.tomorrow"),
          value: "TOMORROW",
        },
        {
          text: this.$t("filters.dateItems.thisWeek"),
          value: "THIS_WEEK",
        },
        {
          text: this.$t("filters.dateItems.thisWeekSoFar"),
          value: "THIS_WEEK_SO_FAR",
        },
        {
          text: this.$t("filters.dateItems.lastWeek"),
          value: "LAST_WEEK",
        },
        {
          text: this.$t("filters.dateItems.nextWeek"),
          value: "NEXT_WEEK",
        },
        {
          text: this.$t("filters.dateItems.thisMonth"),
          value: "THIS_MONTH",
        },
        {
          text: this.$t("filters.dateItems.thisMonthSoFar"),
          value: "THIS_MONTH_SO_FAR",
        },
        {
          text: this.$t("filters.dateItems.lastMonth"),
          value: "LAST_MONTH",
        },
        {
          text: this.$t("filters.dateItems.nextMonth"),
          value: "NEXT_MONTH",
        },
      ],
      filtersItemsMixins_projectPermissionLevels: [
        { text: this.$t("common.permissionLevel.public"), value: "PUBLIC" },
        { text: this.$t("common.permissionLevel.restricted"), value: "RESTRICTED" },
        { text: this.$t("common.permissionLevel.private"), value: "PRIVATE" },
      ],
      filtersItemsMixins_projectLevels: [
        { text: this.$t("filters.projectLevels.mainProject"), value: "MAIN" },
        { text: this.$t("filters.projectLevels.subProject"), value: "SUB" },
      ],
      filtersItemsMixins_userStatusItems: [
        { text: this.$t("filters.userStatuses.active"), value: "ACTIVE" },
        { text: this.$t("filters.userStatuses.Inactive"), value: "INACTIVE" },
        { text: this.$t("filters.userStatuses.invited"), value: "INVITED" },
        { text: this.$t("filters.userStatuses.imported"), value: "IMPORTED" },
        { text: this.$t("filters.userStatuses.deleted"), value: "DELETED" },
      ],
      filtersItemsMixins_userRolesItems: [
        { text: this.$t("filters.userRoles.ownerOfWorkspace"), value: "DOMAIN_OWNER" },
        { text: this.$t("filters.userRoles.admin"), value: "ADMIN" },
        { text: this.$t("filters.userRoles.hrAdmin"), value: "HR_ADMIN" },
        { text: this.$t("filters.userRoles.user"), value: "USER" },
      ],
      filtersItemsMixins_productCreatedSource: [
        {
          text: this.$t("filters.productCreatedSource.manually"),
          value: "MANUALLY",
        },
        ...INTEGRATION_NAMES_SUPPORTING_IMPORT_TYPES[IMPORT_TYPES.PRODUCT].map(
          (name) => ({
            text: this.$options.filters.capitalize(this.$t(`integration.common.${name}`)),
            value: name,
          }),
        ),
      ],
      filtersItemsMixins_signatureSessionStatus: [
        {
          text: this.$t("formManagement.list.signStatus.IN_PROGRESS"),
          value: "IN_PROGRESS",
        },
        { text: this.$t("formManagement.list.signStatus.COMPLETED"), value: "COMPLETED" },
        { text: this.$t("formManagement.list.signStatus.DECLINED"), value: "DECLINED" },
      ],
    };
  },
};
