import i18n from "@/plugins/i18n";

const categoriesTranslation = i18n.t("formManagement.category");

export function normalizeCmsFormTemplateCategories(categories) {
  return (categories || []).map((category) => {
    const name = category.name;
    const translatedName = categoriesTranslation[name?.toLowerCase()];
    return {
      ...category,
      text: translatedName ?? name,
    };
  });
}
