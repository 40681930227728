import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import moment from "moment";

const state = () => ({
  comments: [],
  isLoading: false,
  isCreatingComment: false,
  hasMore: false,
});

const getters = {
  dates: state => {
    const dates = [];
    for (const comment of state.comments) {
      const day = moment(comment.createdAt).format("DD.MM.YYYY");
      const date = dates.find(d => d.day === day);
      if (!date) {
        dates.push({ day, comments: [comment] });
      } else {
        date.comments.push(comment);
      }
    }
    return dates;
  },
};

const actions = {
  async getComments({ commit }, { params }) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}comment`, {
        params,
      })
      .then(({ data }) => {
        const comments = data?.comments || [];
        if (params.offsetId) {
          commit("addComments", { comments, method: "unshift" });
        } else {
          commit("setComments", comments.reverse());
        }
        commit("setHasMore", data.hasMore);
        commit("loading", false);
      })
      .catch(error => {
        commit("loading", false);
        throw new Error(error);
      });
  },

  async createComment({ commit }, { body }) {
    commit("creatingComment", true);
    return axios
      .post(`${apiUrl}comment`, { ...body })
      .then(({ data }) => {
        const comment = data?.comment;
        commit("addComments", { comments: [comment], method: "push" });
        commit("creatingComment", false);
      })
      .catch(error => {
        commit("creatingComment", false);
        throw new Error(error);
      });
  },
  async deleteComment({ commit }, id) {
    return axios
      .delete(`${apiUrl}comment/${id}`)
      .then(({ data }) => {
        commit("removeComment", id);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  async updateComment({ commit }, { id, content }) {
    return axios
      .put(`${apiUrl}comment/${id}`, { content })
      .then(({ data }) => {
        const comment = data?.comment;
        commit("updateComment", comment);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  async resetState({ commit }) {
    commit("setComments", []);
    commit("setHasMore", false);
  },
};

const mutations = {
  setComments(state, comments) {
    state.comments = comments;
  },
  setHasMore(state, hasMore) {
    state.hasMore = hasMore;
  },
  addComments(state, { comments, method }) {
    for (const comment of comments) {
      const existingComment = state.comments.find(c => c.id === comment.id);
      if (!existingComment) {
        state.comments[method](comment);
      }
    }
  },
  removeComment(state, commentId) {
    const index = state.comments.findIndex(c => c.id === commentId);
    if (index !== -1) {
      state.comments.splice(index, 1);
    }
  },
  updateComment(state, comment) {
    const index = state.comments.findIndex(c => c.id === comment.id);
    if (index !== -1) {
      state.comments.splice(index, 1, comment);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  creatingComment(state, isCreatingComment) {
    state.isCreatingComment = isCreatingComment;
  },
};

export const comments = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
