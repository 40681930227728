<script>
import moment from "moment";
import { VDatePicker, VForm, VMenu } from "vuetify/lib";
import { formTemplateBuilderMixins, dateMixins } from "@/helpers/mixins";
export default {
  mixins: [formTemplateBuilderMixins, dateMixins],
  props: {
    item: Object,
  },
  data() {
    return {
      value: {
        isValid: false,
      },
    };
  },
  computed: {
    rules() {
      let rules = [];
      if (this.item?.isRequired) {
        rules = this.$rules.REQUIRED;
      }
      return rules;
    },
    inputValue() {
      if (!this.item?.value) return null;
      return moment(this.item?.value).format("DD.MM.YYYY");
    },
    formattedValue() {
      if (!this.item?.value) return null;
      return moment(this.item?.value).format("YYYY-MM-DD");
    },
  },
  methods: {
    onChange(e) {
      const newVal = typeof e === "object" && e !== null ? e?.target?.value : e;
      const oldVal = this.item.value;

      const isNewValue = newVal !== oldVal;

      if (typeof newVal !== "undefined" && isNewValue) {
        this.formTemplateBuilderMixins_updateItem({
          item: this.item,
          body: { value: newVal },
        });
      }
    },
    genForm() {
      return this.$createElement(
        VForm,
        {
          props: { value: this.value.isValid },
          on: {
            submit: (e) => e.preventDefault(),
            input: (e) => (this.value.isValid = e),
          },
        },
        [
          this.$createElement(VMenu, {
            props: {
              offsetY: true,
              closeOnContentClick: false,
              transition: "scale-transition",
              maxWidth: "290px",
              minWidth: "auto",
            },
            scopedSlots: {
              activator: ({ on, attrs }) =>
                this.$createElement("AppDefaultTextField", {
                  attrs,
                  on: { ...on, "click:clear": (e) => this.onChange(e) },
                  props: {
                    label: this.$t(
                      "templateManagement.form.builder.item.type.DATE_FIELD",
                    ),
                    value: this.inputValue,
                    rules: this.rules,
                    clearable: true,
                    readonly: true,
                    dense: false,
                    hideDetails: true,
                  },
                }),
              default: () =>
                this.$createElement(VDatePicker, {
                  props: {
                    noTitle: true,
                    scrollable: true,
                    value: this.formattedValue,
                    firstDayOfWeek: this.dateMixins_firstDayOfWeek,
                    showWeek: true,
                    localeFirstDayOfYear: 4,
                  },
                  on: { change: (e) => this.onChange(e) },
                }),
            },
          }),
        ],
      );
    },
  },
  render(h) {
    return h("div", [this.genForm()]);
  },
};
</script>
