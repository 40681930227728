<script>
import { VImg } from "vuetify/lib";
import { mapGetters } from "vuex";
const API_URL = process.env.VUE_APP_API_URL;

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      authToken: null,
    };
  },
  computed: {
    ...mapGetters("auth", {
      activeDomainId: "activeDomainId",
    }),
  },
  methods: {
    async getTokenSilently() {
      try {
        const token = await this.$auth.getTokenSilently();
        this.authToken = token;
      } catch (error) {
        console.error("Error getting token:", error);
        this.errorMessage = "Error loading video: Unable to authenticate";
      }
    },
    getImageSrc(id) {
      if (!this.authToken) return;
      return `${API_URL}storage/file/${id}/thumbnail?authToken=${this.authToken}&domain=${this.activeDomainId}`;
    },
    genImages() {
      const elms = [];
      if (Array.isArray(this.item.images)) {
        for (let image of this.item.images) {
          elms.push(
            this.$createElement(
              "div",
              {
                class:
                  "form-item__image_container__completion border-a d-flex align-center justify-center cursor-pointer",
                on: {
                  click: (e) =>
                    this.$emit("item:imagePreview", {
                      item: this.item,
                      imageId: image.id,
                      imageIds: this.item.images.map((image) => {
                        return { id: image.id };
                      }),
                    }),
                },
              },
              [
                this.$createElement("AppImageDeleteBtn", {
                  on: {
                    click: (e) => {
                      e.stopPropagation();
                      this.$emit("item:imageDelete", {
                        item: this.item,
                        id: image.id,
                      });
                    },
                  },
                }),
                this.$createElement(VImg, {
                  props: { src: this.getImageSrc(image.id), contain: true, lazy: true },
                  class: "form-item__image",
                }),
              ],
            ),
          );
        }
      }
      return elms;
    },
  },
  render(h) {
    return h("div", { class: "py-2" }, [
      h(
        "div",
        { class: "text-body-2 font-weight-bold pb-1" },
        this.$t("formManagement.preview.images"),
      ),
      h(
        "div",
        { class: "form-item__images d-flex flex-wrap w-100", style: { gap: "8px" } },
        [this.genImages()],
      ),
    ]);
  },
  mounted() {
    this.getTokenSilently();
  },
};
</script>
