export const chartMixins = {
  methods: {
    chartMixins_formatSeries({ chartData }) {
      const series = chartData?.datasets?.map((dataSet) => {
        const data = dataSet?.dataPoints?.map((dataPoint) => ({
          ...dataPoint,
          x: dataPoint?.label,
          y: dataPoint?.value,
          // fillColor: dataPoint?.color ?? null,
          subChart: dataPoint?.subChart ?? null,
        }));
        return {
          ...dataSet,
          name: dataSet?.name,
          color: dataSet?.color,
          data,
        };
      });
      return series;
    },
  },
};
