<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card v-if="dialog">
      <v-card-title>
        <span class="headline">
          {{ title ? title : $t("team.newTeam.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="newTeamForm"
          v-model="team.valid"
          v-on:submit.prevent
          @submit="onCreate"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("team.list.teamName") }}
            </div>
            <AppDefaultTextField
              v-model="team.name.value"
              :rules="$rules.NAME_RULES"
              :counter="$rules.MAX_CHARACTERS"
              autofocus
            />
          </div>

          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("team.list.teamOwner") }}
            </div>
            <AppClientGroupedUserAndContactAutocomplete
              v-model="team.owner.value"
              :items="assignableOwners"
              :loading="isAssignableOwnersLoading"
              :rules="$rules.REQUIRED"
              :noDataText="$t('common.noUsers')"
              :labelPaths="['workTitleLabel']"
              user-avatar
            />
          </div>

          <div class="p-relative">
            <div class="label--small">
              {{ $t("common.members") | capitalize }}
            </div>
            <AppClientGroupedUserAndContactAutocomplete
              v-model="team.members.values"
              :items="assignableMembers"
              :loading="isAssignableMembersLoading"
              :labelPaths="['workTitleLabel']"
              user-avatar
              multiple
              clearable
              :noDataText="$t('common.noUsers')"
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onCreate"
          @cancel="onClose"
          :confirmText="buttonText"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getSearchUsers } from "@/services/search/users";
import { normalizeUsers } from "@/helpers/normalization";

export default {
  props: {
    dialog: Boolean,
    title: String,
    data: Object,
    buttonText: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      assignableMembers: [],
      isAssignableMembersLoading: false,
      assignableOwners: [],
      isAssignableOwnersLoading: false,
      team: {
        valid: false,
        name: {
          value: "",
        },
        owner: {
          value: null,
        },
        members: {
          values: [],
        },
      },
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapGetters("auth", {
      currentUserId: "currentUserId",
    }),
    isEditMode() {
      return !!this.data;
    },
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.fillFormWithData(this.data);
          this.getAssignableMembers();
          this.getAssignableOwners();
        }
      },
    },
  },
  methods: {
    fillFormWithData(data) {
      if (data) {
        this.team.name.value = data?.name;
        this.team.owner.value = data?.ownerId;
        this.team.members.values = Array.isArray(data?.members)
          ? data.members.map((member) => member.id)
          : [];
      } else {
        if (!this.team.owner.value) {
          this.team.owner.value = this.currentUserId;
        }
      }
    },
    getAssignableMembers() {
      this.isAssignableMembersLoading = true;

      this.setInitialMembers();

      const alwaysIncludeIds = this.team.members.values.map((user) => user.id);
      const params = {
        alwaysIncludeIds,
      };
      getSearchUsers({ params })
        .then((users) => {
          this.assignableMembers = users;
        })
        .finally(() => (this.isAssignableMembersLoading = false));
    },
    getAssignableOwners() {
      this.isAssignableOwnersLoading = true;

      this.setInitialOwner();

      const alwaysIncludeIds = [this.team.owner.value];
      const params = {
        alwaysIncludeIds,
      };
      getSearchUsers({ params })
        .then((users) => {
          this.assignableOwners = users;
        })
        .finally(() => (this.isAssignableOwnersLoading = false));
    },
    setInitialMembers() {
      if (this.assignableMembers.length) return;
      const members = normalizeUsers([...(this.data?.members || [])]);
      this.assignableMembers = members;
    },
    setInitialOwner() {
      if (this.assignableOwners.length) return;
      const owners = this.data?.owner ? normalizeUsers([this.data?.owner]) : [];
      this.assignableOwners = owners;
    },
    closeDialog() {
      this.localDialog = false;
    },
    onClose() {
      this.closeDialog();
      this.$refs.newTeamForm.resetValidation();
    },
    onCreate() {
      const valid = this.$refs.newTeamForm.validate();
      if (valid) {
        const data = {
          body: {
            name: this.team.name.value,
            ownerId: this.team.owner.value,
            members: this.team.members.values,
          },
        };
        if (this.isEditMode) {
          data.id = this.data.id;
        }
        this.$emit("submit:form", data);
        this.closeDialog();
        this.$refs.newTeamForm.reset();
      }
    },
    removeOwner(item) {
      this.team.owner.value = null;
    },
    removeMember(item) {
      const index = this.team.members.values.indexOf(item.id);
      if (index >= 0) this.team.members.values.splice(index, 1);
    },
  },
};
</script>
