<template>
  <div class="video-player">
    <video
      ref="videoPlayer"
      controls
      autoplay
      :src="src"
      :type="mime"
      class="video-fit"
      @error="handleVideoError"
      @volumechange="handleVolumeChange"
      disablePictureInPicture
      controlsList="nodownload"
    ></video>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
const API_URL = process.env.VUE_APP_API_URL;

export default {
  props: {
    mime: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errorMessage: "",
    };
  },
  mounted() {
    this.applyStoredAudioSettings();
  },
  methods: {
    handleVideoError(e) {
      this.errorMessage = `Error playing video: ${e.target.error.message}`;
      console.error("Video error:", e);
    },
    handleVolumeChange(e) {
      const video = e.target;
      localStorage.setItem("videoVolume", video.volume);
      localStorage.setItem("videoMuted", video.muted);
    },
    applyStoredAudioSettings() {
      const video = this.$refs.videoPlayer;
      if (video) {
        const storedVolume = localStorage.getItem("videoVolume");
        const storedMuted = localStorage.getItem("videoMuted");

        if (storedVolume !== null) {
          video.volume = parseFloat(storedVolume);
        }
        if (storedMuted !== null) {
          video.muted = storedMuted === "true";
        }
      }
    },
    stopVideo() {
      const video = this.$refs.videoPlayer;
      if (video) {
        video.pause();
        video.src = "";
        video.load();
      }
    },
  },
  beforeDestroy() {
    this.stopVideo();
  },
};
</script>

<style scoped>
.video-player {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.video-fit {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}

.error {
  text-align: center;
  padding: 20px;
  color: red;
}
</style>
