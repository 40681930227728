import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

export async function getIntegrations({ type }) {
  return axios
    .get(`${apiUrl}integration`, { params: { type } })
    .then(({ data }) => {
      const integrations = data?.integrations;
      return integrations;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function updateIntegration({ id, body }) {
  return axios
    .put(`${apiUrl}integration-instance/${id}`, body)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
