import i18n from "@/plugins/i18n";
const categoriesTranslation = i18n.t("formManagement.category");

export function normalizeFormCategories(categories) {
  const normalizedCategories = (categories || []).map((category) => {
    const name = category.name;
    const translatedName = categoriesTranslation[name?.toLowerCase()];
    return {
      ...category,
      text: translatedName ?? name,
    };
  });
  return normalizedCategories;
}
