<template>
  <tr class="select-all--background" :style="stickyStyles">
    <td :colspan="headersLength">
      <div class="select-all-banner">
        {{ $tc("dataTable.allRowsHasBeenSelected", itemsLength) }}
        <v-btn
          :loading="isSelectingAll"
          :disabled="disabled"
          @click="onSelectAllClick"
          depressed
          small
          text
          color="primary-link"
          class="text-transform-none"
        >
          {{ $tc("dataTable.selectAll", totalItems) | capitalize }}
        </v-btn>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "AppDataTableSelectAllBanner",
  props: {
    headersLength: Number,
    itemsLength: Number,
    totalItems: Number,
    isSelectingAll: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      stickyStyles: {
        position: "sticky",
        left: 0,
        top: "48px",
        zIndex: 3,
      },
    };
  },
  methods: {
    onSelectAllClick() {
      this.$emit("select-all");
    },
  },
};
</script>

<style scoped>
.select-all-banner {
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--select-all--background);
  font-size: var(--text-body-2);
}
</style>
