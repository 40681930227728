<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <div class="label--small" :class="required ? 'required-asterisk' : ''">
        {{ label }}
      </div>
      <div>
        <AppDurationChip
          v-for="(duration, index) in durationShortcuts"
          :key="index"
          color="helper"
          :duration="duration"
          class="mr-2 mb-1"
          @click="localValue = $event"
        />
      </div>
    </div>
    <AppDefaultTextField
      type="number"
      :rules="rules"
      v-model="localValue"
      @keydown="numberMixins_preventNonNumericInput($event, true)"
      :hide-details="hideDetails"
    >
      <template v-slot:append>
        <span> {{ $t("timeRegistration.common.hours") | capitalize }}</span>
      </template>
    </AppDefaultTextField>
  </div>
</template>

<script>
import { timeMixins, numberMixins } from "@/helpers/mixins";

export default {
  mixins: [timeMixins, numberMixins],
  props: {
    value: Number,
    rules: Array,
    durationShortcuts: Array,
    hideDetails: Boolean,
    label: String,
    required: Boolean,
  },
  model: {
    prop: "value",
    event: "input",
  },
  computed: {
    localValue: {
      get() {
        const hours = this.timeMixins_secondsToHours(this.value);
        return hours;
      },
      set(value) {
        const seconds = this.timeMixins_hoursToSeconds(value);
        this.$emit("input", seconds);
      },
    },
  },
};
</script>
