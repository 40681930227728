<template>
  <div v-if="show">
    <div class="c-log-list_node">
      <div class="c-log-list_node__root">
        <span class="break-word" v-html="generateText(node)"></span>
        <span class="text--secondary pt-2">
          {{ new Date(node.createdAt) | dateFormat("DD.MM.YYYY HH:mm") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { statusMixins } from "@/helpers/mixins";

export default {
  mixins: [statusMixins],
  props: {
    node: Object,
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    handleOpenFilePreview(e) {
      const clickedId = e.target.id;
      const idsCanOpenFilePreview = [
        "UPLOAD_FILE_ID",
        "DOWNLOAD_FILE_ID",
        "UPDATE_FILE_ID",
        "MOVE_FILE_ID",
        "UPDATE_FILE_ID",
      ];
      if (idsCanOpenFilePreview.includes(clickedId)) {
        this.openFilePreview();
      }
    },
    generateText(node) {
      let text = "";
      if (!node) {
        this.show = false;
        return text;
      }

      switch (node.model) {
        case "storage": {
          if (this.hasAction("MOVE")) {
            text = `
              ${this.$t("historicalLog.theFile")}
              <span class="" id="MOVE_FILE_ID">${node.modelName}</span>
              ${this.$t("historicalLog.movedFrom")}
              <span class="font-weight-bold">
              ${this.getFolderName(node.oldValues)}
              </span> ${this.$t("historicalLog.common.to")}
              <span class="font-weight-bold">
              ${this.getFolderName(node.newValues)}
              </span> ${this.$t("historicalLog.common.by")}
              ${this.getUserName(node)}
            `;
          } else if (this.hasAction("UPLOAD")) {
            text = `
              ${this.$t("historicalLog.theFile")}
              <span class="" id="UPLOAD_FILE_ID">${node.modelName}</span>
              ${this.$t("historicalLog.uploadedBy")}
              ${this.getUserName(node)}
            `;
          } else if (this.hasAction("DOWNLOAD")) {
            text = `
              <span class="" id="DOWNLOAD_FILE_ID">${node.modelName}</span>
              ${this.$t("historicalLog.downloadedBy")}
              ${this.getUserName(node)}
            `;
          } else if (this.hasAction("UPDATE_FILE")) {
            text = `
              ${this.$t("historicalLog.newFileUploaded")}
              <span class="" id="UPDATE_FILE_ID">${node.modelName}</span>
              ${this.$t("historicalLog.common.by")}
              ${this.getUserName(node)}
            `;
          } else if (this.hasAction("UPDATE_NAME")) {
            text = this.$t("historicalLog.theFile") + " " + this.updateNameText(node);
          } else if (this.hasAction("UPLOAD_BOLIGMAPPA")) {
            text = `${this.getUserName(node)}
            ${this.$t("historicalLog.uploadedToBoligmappa", { name: node.modelName })}`;
          } else if (this.hasAction("CREATE_SIGN_STORAGE")) {
            text = `${this.$t("historicalLog.esign.created", {
              user: this.getUserName(node),
            })}`;
          } else if (this.hasAction("UPDATE_SIGN_STATUS")) {
            const oldValues = node.oldValues;
            const newValues = node.newValues;
            const user = this.getUserName(node);
            if (oldValues?.status === "NOT_STARTED" && newValues?.status === "DRAFT") {
              text = `${this.$t("historicalLog.esign.notStartedToDraft", { user })}`;
            } else if (
              oldValues?.status === "DRAFT" &&
              newValues?.status === "IN_PROGRESS"
            ) {
              text = `${this.$t("historicalLog.esign.draftToInProgress")}`;
            } else if (
              oldValues?.status === "IN_PROGRESS" &&
              newValues?.status === "REVOKED"
            ) {
              text = `${this.$t("historicalLog.esign.inProgressToRevoked", { user })}`;
            } else if (
              oldValues?.status === "IN_PROGRESS" &&
              newValues?.status === "DECLINED"
            ) {
              text = `${this.$t("historicalLog.esign.inProgressToDeclined")}`;
            } else if (
              oldValues?.status === "IN_PROGRESS" &&
              newValues?.status === "EXPIRED"
            ) {
              text = `${this.$t("historicalLog.esign.inProgressToExpired")}`;
            } else if (
              oldValues?.status === "IN_PROGRESS" &&
              newValues?.status === "COMPLETED"
            ) {
              text = `${this.$t("historicalLog.esign.inProgressToCompleted")}`;
            }
          } else {
            this.show = false;
          }
          break;
        }
        case "relation": {
          if (this.hasAction("LINK")) {
            text = `
              ${node.modelName}
              ${this.$t("historicalLog.linkedTo")}
              <span class="font-weight-bold">
              ${this.getFolderName(node.newValues)}
              </span> ${this.$t("historicalLog.common.by")}
              ${this.getUserName(node)}
            `;
          } else if (this.hasAction("MOVE")) {
            text = `
              ${node.modelName}
              ${this.$t("historicalLog.movedFrom")}
              <span class="font-weight-bold">
              ${this.getFolderName(node.oldValues)}
              </span> ${this.$t("historicalLog.common.to")}
              <span class="font-weight-bold">
              ${this.getFolderName(node.newValues)}
              </span> ${this.$t("historicalLog.common.by")}
              ${this.getUserName(node)}
            `;
          } else if (this.hasAction("DELETE")) {
            text = `
              ${this.$t("historicalLog.linkDeletedFrom")}
              <span class="font-weight-bold">
              ${this.getFolderName(node.oldValues)}
              </span> ${this.$t("historicalLog.common.by")}
              ${this.getUserName(node)}
            `;
          } else if (this.hasAction("UPDATE_MEMBER")) {
            let textSet = false;
            const newValues = node.newValues;
            const oldValues = node.oldValues;
            if (newValues) {
              if (newValues.childType === "user" && newValues.parentType === "task") {
                text = `
                  ${this.getUserName(node)}
                  ${this.$t("historicalLog.assignedTaskTo")}
                  ${this.getChildUserName(node)}
                `;
                textSet = true;
              } else if (
                newValues.childType === "user" &&
                newValues.parentType === "form"
              ) {
                text = `
                  ${this.getUserName(node)}
                  ${this.$t("historicalLog.assignedFormTo")}
                  ${this.getChildUserName(node)}
                `;
                textSet = true;
              } else if (
                newValues.childType === "user" &&
                newValues.parentType === "project"
              ) {
                text = `
                  ${this.getUserName(node)}
                  ${this.$t("historicalLog.addedUserToProject", {
                    user: this.getChildUserName(node),
                  })}
                `;
                textSet = true;
              }
            } else if (oldValues) {
              if (oldValues.childType === "user" && oldValues.parentType === "task") {
                text = `
                  ${this.getUserName(node)}
                  ${this.$t("historicalLog.removedfromTask", {
                    user: this.getChildUserName(node),
                  })}
                `;
                textSet = true;
              } else if (
                oldValues.childType === "user" &&
                oldValues.parentType === "form"
              ) {
                text = `
                  ${this.getUserName(node)}
                  ${this.$t("historicalLog.removedfromForm", {
                    user: this.getChildUserName(node),
                  })}
                `;
                textSet = true;
              } else if (
                oldValues.childType === "user" &&
                oldValues.parentType === "project"
              ) {
                text = `
                  ${this.getUserName(node)}
                  ${this.$t("historicalLog.removedfromProject", {
                    user: this.getChildUserName(node),
                  })}
                `;
                textSet = true;
              }
            }

            if (!textSet) {
              this.show = false;
            }
          } else {
            this.show = false;
          }
          break;
        }
        case "share": {
          if (this.hasAction("CREATE_SHARE")) {
            text = `
              ${this.$t("historicalLog.permalinkCreatedFor")}
              ${node.modelName}
              ${this.$t("historicalLog.common.by")}
              ${this.getUserName(node)}
            `;
          } else if (this.hasAction("UPDATE_SHARE")) {
            text = `
              ${this.$t("historicalLog.permalinkReplacedFor")}
              ${node.modelName}
              ${this.$t("historicalLog.common.by")}
              ${this.getUserName(node)}
            `;
          } else if (this.hasAction("DELETE_SHARE")) {
            text = `
              ${this.$t("historicalLog.permalinkDeletedFor")}
              ${node.modelName}
              ${this.$t("historicalLog.common.by")}
              ${this.getUserName(node)}
            `;
          } else {
            this.show = false;
          }
          break;
        }
        case "template":
        case "cms_template": {
          if (this.hasAction("CREATE")) {
            text = this.createdByText(node);
          } else if (this.hasAction("UPDATE_STATUS")) {
            if (
              node.oldValues?.isPublished === "false" &&
              node.newValues?.isPublished === "true"
            ) {
              text = `
                ${node.modelName}
                ${this.$t("historicalLog.publishedBy")}
                ${this.getUserName(node)}
            `;
            } else {
              text = `
                ${node.modelName}
                ${this.$t("historicalLog.unpublishedBy")}
                ${this.getUserName(node)}
            `;
            }
          } else if (this.hasAction("UPDATE_CATEGORY")) {
            const fromT = `cms.template.document.category.${node.oldValues?.categoryName}`;
            const toT = `cms.template.document.category.${node.newValues?.categoryName}`;
            const fromCategoryName = this.$te(fromT)
              ? this.$t(fromT)
              : node.oldValues?.categoryName;
            const toCategoryName = this.$te(toT)
              ? this.$t(toT)
              : node.newValues?.categoryName;

            text = `
              ${this.$t("historicalLog.changedCategory", {
                user: this.getUserName(node),
                name: node.modelName,
              })}
              <span class="font-weight-bold">
                ${fromCategoryName}
              </span>
              ${this.$t("historicalLog.common.to")}
              <span class="font-weight-bold">
                ${toCategoryName}
              </span>

            `;
          } else if (this.hasAction("DOWNLOAD")) {
            text = `
              ${node.modelName}
              ${this.$t("historicalLog.downloadedBy")}
              ${this.getUserName(node)}
            `;
          } else if (this.hasAction("UPDATE_RESTRICTED_TO_TEMPLATEOWNER")) {
            text = `
            ${node.modelName}
             was made available to everyone by
             ${this.getUserName(node)}\n`;
          } else if (this.hasAction("UPDATE_NAME")) {
            text = this.updateNameText(node);
          } else if (this.hasAction("UPDATE_DESCRIPTION")) {
            text = this.descriptionText(node);
          } else {
            this.show = false;
          }
          break;
        }
        case "task": {
          if (this.hasAction("CREATE")) {
            text = this.$t("historicalLog.theTask") + " " + this.createdByText(node);
          } else if (this.hasAction("UPDATE_NAME")) {
            text = this.updateNameText(node);
          } else if (this.hasAction("UPDATE_DESCRIPTION")) {
            text = this.descriptionText(node);
          } else if (this.hasAction("UPDATE_DUE_DATE")) {
            text = this.dueDateText(node);
          } else if (this.hasAction("UPDATE_DONE")) {
            text = this.doneText(node);
          } else if (this.hasAction("UPDATE_STATUS")) {
            text = this.progressStatusText(node);
          } else if (this.hasAction("UPDATE_PROJECT")) {
            if (!node.oldValues?.projectId) {
              text = `
              ${this.getUserName(node)}
              ${this.$t("historicalLog.addedProject")}
              ${this.$t("common.theTask")}
            `;
            } else if (!node.newValues?.projectId) {
              text = `
              ${this.getUserName(node)}
              ${this.$t("historicalLog.removedProject")}
              ${this.$t("common.theTask")}
            `;
            } else {
              text = `
              ${this.getUserName(node)}
              ${this.$t("historicalLog.changedProject")}
              ${this.$t("common.theTask")}
            `;
            }
          } else {
            this.show = false;
          }
          break;
        }
        case "comment": {
          if (this.hasAction("CREATE")) {
            text = this.commentText("CREATE", node);
          } else if (this.hasAction("UPDATE")) {
            text = this.commentText("UPDATE", node);
          } else if (this.hasAction("DELETE")) {
            text = this.commentText("DELETE", node);
          } else {
            this.show = false;
          }
          break;
        }
        case "form": {
          if (this.hasAction("CREATE")) {
            text = this.$t("historicalLog.theForm") + " " + this.createdByText(node);
          } else if (this.hasAction("UPDATE_NAME")) {
            text = this.updateNameText(node);
          } else if (this.hasAction("UPDATE_DESCRIPTION")) {
            text = this.descriptionText(node);
          } else if (this.hasAction("UPDATE_DUE_DATE")) {
            text = this.dueDateText(node);
          } else if (this.hasAction("UPDATE_DONE")) {
            text = this.doneText(node);
          } else if (this.hasAction("UPDATE_STATUS")) {
            text = this.progressStatusText(node);
          } else if (this.hasAction("UPDATE_PROJECT")) {
            if (!node.oldValues?.projectId) {
              text = `
              ${this.getUserName(node)}
              ${this.$t("historicalLog.addedProject")}
              ${this.$t("common.form")}
            `;
            } else if (!node.newValues?.projectId) {
              text = `
              ${this.getUserName(node)}
              ${this.$t("historicalLog.removedProject")}
              ${this.$t("common.form")}
            `;
            } else {
              text = `
              ${this.getUserName(node)}
              ${this.$t("historicalLog.changedProject")}
              ${this.$t("common.form")}
            `;
            }
          } else {
            this.show = false;
          }
          break;
        }
        case "form_item": {
          if (this.hasAction("CREATE")) {
            text = `
            ${this.$t("historicalLog.theQuestion")}
            <span class="font-weight-bold">
            ${node.newValues?.name}
            </span>
            ${this.createdByText(node, true)}`;
          } else if (this.hasAction("DELETE")) {
            text = `
            ${this.$t("historicalLog.theQuestion")}
            <span class="font-weight-bold">
            ${node.oldValues?.name}
            </span>
            ${this.deletedByText(node, true)}`;
          } else {
            this.show = false;
          }
          break;
        }
        case "project": {
          if (this.hasAction("CREATE")) {
            text = this.$t("historicalLog.theProject") + " " + this.createdByText(node);
          } else if (this.hasAction("UPDATE_NAME")) {
            text = `
            ${this.$t("historicalLog.theProject")} ${this.updateNameText(node)}`;
          } else if (this.hasAction("UPDATE_DESCRIPTION")) {
            text = `
          ${this.getUserName(node)}
          ${this.descriptionText(node)}`;
          } else if (this.hasAction("UPDATE_DUE_DATE")) {
            text = this.dueDateText(node);
          } else if (this.hasAction("UPDATE_DONE")) {
            text = this.doneText(node);
          } else if (this.hasAction("UPDATE_STATUS")) {
            text = this.progressStatusText(node);
          } else if (this.hasAction("UPDATE_CLIENT")) {
            text = `
            ${this.getUserName(node)}
            ${this.$t("historicalLog.changedCustomerFrom")}
            <span class="font-weight-bold">
            ${node.oldValues.clientName || this.$t("historicalLog.noCustomer")}
            </span>
            ${this.$t("historicalLog.common.to")}
            <span class="font-weight-bold">
            ${node.newValues.clientName || this.$t("historicalLog.noCustomer")}
            </span>
            `;
          } else if (this.hasAction("UPDATE_OWNER")) {
            text = `
            ${this.getUserName(node)}
            ${this.$t("historicalLog.changedProjectManagerFrom")}
            <span class="font-weight-bold">
            ${node.oldValues.ownerName || this.$t("historicalLog.noProjectManager")}
            </span>
            ${this.$t("historicalLog.common.to")}
            <span class="font-weight-bold">
            ${node.newValues.ownerName || this.$t("historicalLog.noProjectManager")}
            </span>
            `;
          } else if (this.hasAction("UPDATE_ACTIVE_STATE")) {
            text = `${this.getUserName(node)}
            ${this.$t("historicalLog.updatedActiveStatus")}
            <span class="font-weight-bold">${
              node.newValues.isActive === "true"
                ? this.$t("historicalLog.common.active")
                : this.$t("historicalLog.common.inactive")
            }</span>`;
          } else if (this.hasAction("UPDATE_PERMISSION_LEVEL")) {
            const oldPermission = this.$t(
              `common.permissionLevel.${node.oldValues.permissionLevel.toLowerCase()}`,
            );
            const newPermission = this.$t(
              `common.permissionLevel.${node.newValues.permissionLevel.toLowerCase()}`,
            );
            text = `${this.getUserName(node)}
            ${this.$t("historicalLog.upadatedPermissionLevelFrom")}
            <span class="font-weight-bold">${oldPermission}</span>
            ${this.$t("historicalLog.common.to")}
            <span class="font-weight-bold">${newPermission}</span>`;
          }
          break;
        }
        case "meta_project": {
          if (this.hasAction("CREATE")) {
            text = `${this.getUserName(node)}
            ${this.$t("historicalLog.connectedTheProjectToBoligmappeAddress")}
            <span class="font-weight-bold">
            ${node.newValues.value}
            </span>
            `;
          }
          if (this.hasAction("UPDATE")) {
            text = `${this.getUserName(node)}
            ${this.$t("historicalLog.changedTheProjectsBoligmappeAddress")}
            <span class="font-weight-bold">
            ${node.oldValues.value}
            </span>
            ${this.$t("historicalLog.common.to")}
            <span class="font-weight-bold">
            ${node.newValues.value}
            </span>
            `;
          }
          if (this.hasAction("DELETE")) {
            text = `${this.getUserName(node)}
            ${this.$t("historicalLog.deletedTheProjectsBoligmappeConnection")}
            <span class="font-weight-bold">
            ${node.oldValues.value}
            </span>
            `;
          }
          break;
        }
        case "product_usage": {
          if (this.hasAction("CREATE")) {
            text = `
            ${this.getUserName(node)}
            ${this.$t("historicalLog.addedAProduct")}
            `;
          }
          if (this.hasAction("UPDATE_NAME")) {
            text = this.updateNameText(node);
          }
          if (this.hasAction("UPDATE_UNIT")) {
            text = `${this.getUserName(node)}
            ${this.$t("historicalLog.changedUnitType")}
            ${this.$t("historicalLog.common.from")}
            <span class="font-weight-bold">
            ${node.oldValues?.unit?.toLowerCase() || this.$t("historicalLog.noUnitType")}
            </span>
            ${this.$t("historicalLog.common.to")}
            <span class="font-weight-bold">
            ${node.newValues?.unit?.toLowerCase() || this.$t("historicalLog.noUnitType")}
            </span>
            `;
          }

          if (this.hasAction("UPDATE_AMOUNT")) {
            text = `${this.getUserName(node)}
            ${this.$t("historicalLog.changedAmount")}
            ${this.$t("historicalLog.common.from")}
            <span class="font-weight-bold">
            ${node.oldValues?.amount || this.$t("historicalLog.noAmount")}
            </span>
            ${this.$t("historicalLog.common.to")}
            <span class="font-weight-bold">
            ${node.newValues?.amount || this.$t("historicalLog.noAmount")}
            </span>
            `;
          }
          if (this.hasAction("UPDATE_ITEM_PRICE")) {
            text = `${this.getUserName(node)}
            ${this.$t("historicalLog.changedUnitPrice")}
            ${this.$t("historicalLog.common.from")}
            <span class="font-weight-bold">
            ${node.oldValues.unitPrice || this.$t("historicalLog.noUnitPrice")}
            </span>
            ${this.$t("historicalLog.common.to")}
            <span class="font-weight-bold">
            ${node.newValues.unitPrice || this.$t("historicalLog.noUnitPrice")}
            </span>
            `;
          }
          if (this.hasAction("UPDATE_STATUS")) {
            text = `${this.getUserName(node)}
            ${this.$t("historicalLog.sentToAccounting")}
            `;
          }
          if (this.hasAction("UPDATE_COMMENT")) {
            text = `${this.getUserName(node)}
            ${this.$t("historicalLog.changedComment")}
           ${this.$t("historicalLog.common.from")}
            <span class="font-weight-bold">
            ${node.oldValues.comment || this.$t("historicalLog.noComment")}
            </span>
            ${this.$t("historicalLog.common.to")}
            <span class="font-weight-bold">
            ${node.newValues.comment || this.$t("historicalLog.noComment")}
            </span>
            `;
          }
          if (this.hasAction("UPDATE_DATE")) {
            const fromDate = node.oldValues?.addedAt;
            const toDate = node.newValues?.addedAt;
            const fromDateFormatted =
              fromDate && moment(fromDate).isValid()
                ? moment(fromDate).format("DD.MM.YYYY")
                : this.$t("historicalLog.common.noDate");
            const toDateFormatted =
              toDate && moment(toDate).isValid()
                ? moment(toDate).format("DD.MM.YYYY")
                : this.$t("historicalLog.common.noDate");
            return `
             ${this.getUserName(node)}
            ${this.$t("historicalLog.changedDateAdded")}
            ${this.$t("historicalLog.common.from")}
            <span class="font-weight-bold">
            ${fromDateFormatted}
            </span> ${this.$t("historicalLog.common.to")}
            <span class="font-weight-bold">
            ${toDateFormatted}
            `;
          }
          break;
        }
        default:
          this.show = false;
          break;
      }

      return text;
    },
    hasAction(action) {
      return this.node.action.some((a) => a === action);
    },
    getUserName(node) {
      return node.userName
        ? node.userName.length > 1
          ? node.userName
          : node.userEmail
        : node.userEmail;
    },
    commentText(action, node) {
      let translationKey;
      switch (action) {
        case "CREATE":
          translationKey = "commentedOn";
          break;
        case "UPDATE":
          translationKey = "updatedCommentOn";
          break;
        case "DELETE":
          translationKey = "deletedCommentOn";
          break;
      }
      const value = node.oldValues ? node.oldValues : node.newValues;
      const text = `${this.getUserName(node)}
              ${this.$t(`historicalLog.${translationKey}`)}
              ${this.$t(`historicalLog.aModel.${value?.type}`)}
              ${node.modelName}`;
      return text;
    },
    getChildUserName(node) {
      return node.childUserName
        ? node.childUserName.length > 1
          ? node.childUserName
          : node.childUserEmail
        : node.childUserEmail;
    },
    dueDateText(node) {
      const fromDate = node.oldValues?.dueDate;
      const toDate = node.newValues?.dueDate;
      const fromDateFormatted =
        fromDate && moment(fromDate).isValid()
          ? moment(fromDate).format("DD.MM.YYYY")
          : this.$t("historicalLog.common.noDate");
      const toDateFormatted =
        toDate && moment(toDate).isValid()
          ? moment(toDate).format("DD.MM.YYYY")
          : this.$t("historicalLog.common.noDate");
      return `
        ${node.modelName}
        ${this.$t("historicalLog.changedDueDateFrom")}
        <span class="font-weight-bold">
        ${fromDateFormatted}
        </span> ${this.$t("historicalLog.common.to")}
        <span class="font-weight-bold">
        ${toDateFormatted}
        </span> ${this.$t("historicalLog.common.by")}
        ${this.getUserName(node)}
      `;
    },
    progressStatusText(node) {
      return `
          ${node.modelName}
          ${this.$t("historicalLog.changedStatusFrom")}
          ${this.statusMixins_statusTranslation(
            node.oldValues.status,
            this.statusMixins_progressStatusItems,
          ).toLowerCase()}
          ${this.$t("historicalLog.common.to")}
          ${this.statusMixins_statusTranslation(
            node.newValues.status,
            this.statusMixins_progressStatusItems,
          ).toLowerCase()}
          ${this.$t("historicalLog.common.by")}
          ${this.getUserName(node)}
        `;
    },
    doneText(node) {
      if (node.oldValues?.done === "false" && node.newValues?.done === "true") {
        return `
          ${node.modelName}
          ${this.$t("historicalLog.doneBy")}
          ${this.getUserName(node)}
        `;
      }
      return `
        ${node.modelName}
        ${this.$t("historicalLog.unDoneBy")}
        ${this.getUserName(node)}
      `;
    },
    updateNameText(node) {
      return `
        ${node.modelName}
        ${this.$t("historicalLog.renamedFrom")}
        <span class="font-weight-bold">
        ${node.oldValues?.name || this.$t("historicalLog.noName")}
        </span> ${this.$t("historicalLog.common.to")}
        <span class="font-weight-bold">
        ${node.newValues?.name || this.$t("historicalLog.noName")}
        </span> ${this.$t("historicalLog.common.by")}\n
        ${this.getUserName(node)}
      `;
    },
    descriptionText(node) {
      return `
        ${node.modelName}
        ${this.$t("historicalLog.changedDescriptionFrom")}
        <span class="font-weight-bold">
        ${node.oldValues?.description || this.$t("historicalLog.emptyDescription")}
        </span> ${this.$t("historicalLog.common.to")}
        <span class="font-weight-bold">
        ${node.newValues?.description || this.$t("historicalLog.emptyDescription")}
      `;
    },
    createdByText(node, hideModelName = false) {
      return `
        ${hideModelName ? "" : node.modelName}
        ${this.$t("historicalLog.createdBy")}
        ${this.getUserName(node)}
      `;
    },
    deletedByText(node, hideModelName = false) {
      return `
        ${hideModelName ? "" : node.modelName}
        ${this.$t("historicalLog.deletedBy")}
        ${this.getUserName(node)}
      `;
    },
    getFolderName(values) {
      return values.isRoot !== "true" ? values.folderName : this.$t("common.home");
    },
  },
};
</script>
