import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, userGuard } from "../../auth/authGuard";
import multiguard from "../multiguard";

const { ROOT, ALL_HOURS } = ROUTE_NAMES.TIME_REGISTRATION;

export const timeRegistrationRoutes = [
  {
    path: "/time-registration",
    name: ROOT,
    beforeEnter: multiguard([authGuard, userGuard()]),
    component: () =>
      import(
        /* webpackChunkName: "timeRegistration" */ "../../pages/TimeRegistrationPage"
      ),
    redirect: { name: ALL_HOURS },
    children: [
      {
        path: "all-hours",
        name: ALL_HOURS,
        component: () =>
          import(
            /* webpackChunkName: "timeRegistration" */ "../../pages/TimeRegistrationPage/AllHoursPage"
          ),
      },
    ],
  },
];
