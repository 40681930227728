export const FOLDER = "FOLDER";
export const FILE = "FILE";
export const FILE_RELATION = "FILE_RELATION";

export const PROJECT = "PROJECT";

export const OFFICE_WORD_MIMETYPES = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const OFFICE_EXCEL_MIMETYPES = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const OFFICE_POWERPOINT_MIMETYPES = [
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

export const ODT_MIMETYPE = "application/vnd.oasis.opendocument.text";
export const ODS_MIMETYPE = "application/vnd.oasis.opendocument.spreadsheet";
export const ODP_MIMETYPE = "application/vnd.oasis.opendocument.presentation";

export const MINE_TYPE_ALLOWED_TO_MERGE = [
  ...OFFICE_WORD_MIMETYPES,
  ...OFFICE_EXCEL_MIMETYPES,
  ...OFFICE_POWERPOINT_MIMETYPES,
  ODT_MIMETYPE,
  ODS_MIMETYPE,
  ODP_MIMETYPE,
  "text/plain",
  "application/rtf",
  "application/pdf",
  "image/bmp",
  "image/gif",
  "image/png",
  "image/jpeg",
];

export const TYPES_ALLOWED_TO_MERGE = [FILE, FILE_RELATION];

export const CMS_GROUPS = ["HSE", "QA"];

export const CMS_PROJECT_GROUPS = ["PROJECT_HSE", "PROJECT_QA"];

export const DOWNLOAD_WITHOUT_CONVERTED_MERGE_WORDS_MIMETYPES = [
  ...OFFICE_WORD_MIMETYPES,
  ...OFFICE_EXCEL_MIMETYPES,
  ...OFFICE_POWERPOINT_MIMETYPES,
];
