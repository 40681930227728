import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

const state = () => ({
  applications: [],
  isLoading: false,
});

const actions = {
  async getApplications({ commit }, { filters, projectId }) {
    commit("loading", true);

    const params = {
      ...filters,
    };
    const body = { params };
    return axios
      .get(`${apiUrl}ebyggesok/${projectId}/applications`, body)
      .then(({ data }) => {
        const { applications } = data;
        commit("setApplications", applications);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
};

const mutations = {
  setApplications(state, applications) {
    state.applications = applications;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const eByggesok = {
  namespaced: true,
  state,
  actions,
  mutations,
};
