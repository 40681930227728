<script>
import { VForm } from "vuetify/lib";
import { formTemplateBuilderMixins } from "@/helpers/mixins";

export default {
  mixins: [formTemplateBuilderMixins],
  props: {
    note: String,
    item: Object,
  },
  data() {
    return {
      value: {
        isValid: false,
      },
    };
  },
  methods: {
    onChange(e) {
      const newVal = typeof e === "object" && e !== null ? e?.target?.value : e;
      const oldVal = this.item.note;

      const isValidText = this.formTemplateBuilderMixins_validateTextInputValue({
        newVal,
        oldVal,
      });

      if (isValidText && this.value.isValid) {
        this.formTemplateBuilderMixins_updateItem({
          item: this.item,
          body: { note: newVal },
        });
      }

      if (!newVal) {
        this.$emit("note:close");
      }
    },
    genForm() {
      return this.$createElement(
        VForm,
        {
          // class: "text-border-reset",
          props: { value: this.value.isValid },
          on: {
            submit: (e) => e.preventDefault(),
            input: (e) => (this.value.isValid = e),
          },
        },
        [
          this.$createElement("AppDefaultTextarea", {
            props: {
              id: `note_${this.item.id}`,
              label: "Note",
              value: this.note,
              rows: 3,
              clearable: true,
              hideDetails: true,
            },
            on: { blur: (e) => this.onChange(e) },
          }),
        ],
      );
    },
  },
  render(h) {
    return h("div", { class: "py-2" }, [
      this.$createElement(
        "div",
        { class: "text-body-2 font-weight-bold pb-1" },
        this.$t("formManagement.preview.note"),
      ),
      this.genForm(),
    ]);
  },
};
</script>
