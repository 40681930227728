<template>
  <AppFormDrawer
    :forms="forms"
    :isLoading="isLoading"
    @form:update="onFormUpdate"
    @form:delete="onFormDelete"
    @form:select="onFormSelect"
    @form:nameClick="onFormNameClick"
  />
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    taskId: String,
  },
  computed: {
    ...mapState("taskForms", {
      forms: (state) => state.forms,
      isLoading: (state) => state.isLoading,
    }),
    projectId() {
      return this.$route.params.projectId;
    },
  },
  watch: {
    taskId() {
      this.getForms();
    },
  },
  methods: {
    getForms() {
      this.$store.dispatch("taskForms/getForms", { taskId: this.taskId });
    },
    async onFormUpdate({ body, form }) {
      await this.$store.dispatch("taskForms/updateForm", {
        id: form?.id,
        body,
      });
      this.$emit("form:update", { body, form });
    },
    async onFormDelete({ form }) {
      await this.$store.dispatch("taskForms/deleteForm", {
        id: form?.id,
      });
      this.$emit("form:delete", { form });
    },
    async onFormSelect({ templateId, onFormCreated, onFormFinally }) {
      const body = {
        ...(this.projectId && { projectId: this.projectId }),
        taskId: this.taskId,
      };
      await this.$store
        .dispatch("taskForms/convertTemplate", {
          id: templateId,
          body,
        })
        .then(() => {
          onFormCreated();
        })
        .finally(() => {
          onFormFinally();
        });

      this.$emit("form:create", { body, taskId: this.taskId });
    },
    onFormNameClick({ item }) {
      this.$router.push({
        name: this.$routeNames.FORM_VIEW.ROOT,
        params: { formId: item.id },
      });
    },
  },
  mounted() {
    this.getForms();
  },
};
</script>
