import { IMPORT_TYPES } from "@/helpers/constants";
import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

export async function importAccounting({ accounting, importType, excludeClosed }) {
  switch (importType) {
    case IMPORT_TYPES.USER:
      return await importAccountingUsers({ accounting });
    case IMPORT_TYPES.CLIENT:
      return await importAccountingClients({ accounting });
    case IMPORT_TYPES.CONTACT:
      return await importAccountingContacts({ accounting });
    case IMPORT_TYPES.PROJECT:
      return await importAccountingProjects({ accounting, excludeClosed });
    case IMPORT_TYPES.PRODUCT:
      return await importAccountingProducts({ accounting });
  }
}

export async function importAccountingUsers({ accounting }) {
  return axios
    .post(`${apiUrl}accounting/import/user`, {}, { params: { accounting } })
    .then(({ data }) => {
      const res = { ...data };
      return res;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function importAccountingClients({ accounting }) {
  return axios
    .post(`${apiUrl}accounting/import/client`, {}, { params: { accounting } })
    .then(({ data }) => {
      const res = { ...data };
      return res;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function importAccountingContacts({ accounting }) {
  return axios
    .post(`${apiUrl}accounting/import/contact`, {}, { params: { accounting } })
    .then(({ data }) => {
      const res = { ...data };
      return res;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function importAccountingProjects({ accounting, excludeClosed }) {
  return axios
    .post(
      `${apiUrl}accounting/import/project`,
      {},
      { params: { accounting, includeClosed: !excludeClosed } },
    )
    .then(({ data }) => {
      const res = { ...data };
      return res;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function importAccountingProducts({ accounting }) {
  return axios
    .post(`${apiUrl}accounting/import/products`, {}, { params: { accounting } })
    .then(({ data }) => {
      const res = { ...data };
      return res;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getExternalAccounts({ params } = {}) {
  return axios
    .get(`${apiUrl}accounting/account`, { params })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
