import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getChartData({ data }) {
  return axios
    .get(`${apiUrl}chart/chart-data`, { params: data })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
