import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  teams: [],
  isLoading: false,
});

const getters = {};

const actions = {
  async getTeams({ state, commit }) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}team`)
      .then(({ data }) => {
        const teams = data?.teams;
        commit("setTeams", teams);
        commit("loading", false);
      })
      .catch(error => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setTeams(state, teams) {
    state.teams = teams;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const selectableTeams = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
