<template>
  <span
    :class="[
      'keyboard-shortcut',
      { 'keyboard-shortcut--dark': dark },
      { 'keyboard-shortcut--small': small },
    ]"
  >
    {{ text }}
  </span>
</template>

<script>
export default {
  name: "AppKeyboardShortcut",
  props: {
    text: {
      type: String,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.keyboard-shortcut {
  background-color: #f5f5f5;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  color: #333;
  display: inline-block;
  font-size: 0.85em;
  font-weight: bold;
  padding: 2px 4px;
  white-space: nowrap;
}

.keyboard-shortcut--dark {
  background-color: #333;
  border-color: #555;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  color: #f5f5f5;
}

.keyboard-shortcut--small {
  font-size: 0.75em;
  padding: 1px 3px;
}
</style>
