<template>
  <tr
    class="list_tr list_tr-height"
    :class="rowStateClasses"
    @click="select(!isSelected)"
    @contextmenu="dataTableItemMixins_onRightClick"
  >
    <td>
      <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)" />
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.STATUS)">
      <AppCustomStatusIconBtn
        @click="(e) => $emit('status:click', { ...e, items: [form] })"
        model="form"
        :statusId="statusId"
        :loading="form?.isUpdatingStatus"
        :disabled="form?.isUpdatingStatus"
      />
    </td>
    <td>
      <div class="table-cell-content__breakable-long-text">
        <span
          class="d-inline-block underline-on-hover cursor-pointer"
          @click.stop="onFormFillOutClick()"
        >
          {{ form.name }}
        </span>
      </div>
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.SIGNATURE_STATUS)">
      <AppFormSignatureSessionStatusChip
        :signatureSession="form.signatureSession"
        @click.stop="$emit('signature:click', { form })"
      />
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.PROGRESS)">
      <AppFormProgressionChip :progress="form.progress" />
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.RESPONSIBLE)">
      <template v-if="form.member">
        <AppUserAvatar :user="form.member" show-name :truncate="20" />
      </template>
    </td>
    <td
      v-if="
        !isOnProjectLevel && groupByMixins_showColumn(filtersMixins_dataFields.PROJECT)
      "
    >
      <div v-if="form?.project?.id" class="table-cell-content__breakable-long-text">
        <AppActionText
          :to="{
            name: $routeNames.PROJECT.VIEW,
            params: { projectId: form?.project?.id },
          }"
        >
          {{ form.project.name }}
        </AppActionText>
      </div>
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.TASK)">
      <div v-if="form?.task?.id" class="table-cell-content__breakable-long-text">
        <AppActionText
          :to="{
            name: $routeNames.TASK_VIEW.ROOT,
            params: { taskId: form?.task?.id },
          }"
        >
          {{ taskName }}
        </AppActionText>
      </div>
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.CATEGORY)">
      <AppColorChip
        v-if="form.category"
        :text="
          $te(`formManagement.category.${form.category.name}`)
            ? $t(`formManagement.category.${form.category.name}`)
            : form.category.name
        "
        color="info--text"
      />
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.DUE_DATE)">
      <template v-if="form.dueDate">
        <div>{{ formatDueDate(form.dueDate) }}</div>
      </template>
    </td>
    <td>
      <v-btn icon @click="dataTableItemMixins_onEllipsisClick" ref="ellipsis">
        <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import moment from "moment";
import { groupByMixins } from "@/helpers/mixins";
import { dataTableItemMixins } from "@/helpers/mixins";

export default {
  mixins: [groupByMixins, dataTableItemMixins],
  props: {
    form: Object,
    selectedFormId: String,
    isHighlighted: Boolean,
    tableModel: String,
    headers: Array,
    select: Function,
    isSelected: Boolean,
    isContextMenuActiveItem: Boolean,
    isActiveItem: Boolean,
  },
  computed: {
    isOnProjectLevel() {
      return !!this.projectId;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    taskName() {
      return this.form?.task?.name;
    },
    rowStateClasses() {
      return {
        "list_tr--active": this.isContextMenuActiveItem,
        "list_tr--selected": this.isSelected,
        "list_tr--success": this.isActiveItem,
      };
    },
    statusId() {
      return this.form?.statusId;
    },
  },
  methods: {
    openMenu(params) {
      this.$emit("menu:click", { form: this.form, ...params });
    },
    formatDueDate(date) {
      return date ? moment(date).format("DD.MM.YYYY") : "";
    },
    onFormUpdate(body) {
      this.$emit("form:update", { form: this.form, body });
    },
    onFormNameChange(e) {
      const name = e.target.value ? e.target.value.trim() : null;
      this.onFormUpdate({ name });
    },
    onFormFillOutClick() {
      this.$emit("name:click", { form: this.form });
    },
    onFormDelete() {
      this.$emit("form:delete", { form: this.form });
    },
  },
};
</script>
