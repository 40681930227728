import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;

const state = () => ({
  settings: {},
  isLoading: false,
  isUpdating: false,
  updateSettingsCancel: null,
});

const getters = {
  getPreventTimeEntriesOnModel: (state) => (model) => {
    return state.settings?.timeEntry?.[model]?.statusCategory?.preventEntries ?? [];
  },
};

const actions = {
  async getSettings({ commit }) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}settings/domain`)
      .then(({ data }) => {
        const settings = data?.settings;
        commit("setSettings", settings);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  async updateSettings({ commit, state }, payload) {
    if (state.updateSettingsCancel) {
      state.updateSettingsCancel();
    }

    commit("updateSettingsOptimistic", { payload });

    const source = CancelToken.source();
    commit("setUpdateSettingsCancel", source.cancel);

    const url = `${apiUrl}settings/domain`;
    const data = { settings: payload };
    const config = {
      cancelToken: source.token,
    };

    return axios
      .put(url, data, config)
      .then((response) => {
        commit("setSettings", response.data.settings || payload);
        commit("setUpdateSettingsCancel", null);
        commit("updating", false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("updating", false);
          throw new Error(error);
        }
      });
  },
};

const mutations = {
  updateSettingsOptimistic(state, { payload }) {
    const mergedSettings = mergeSettings(state.settings, payload);
    state.settings = mergedSettings;
  },
  setSettings(state, payload) {
    const mergedSettings = mergeSettings(state, payload);
    state.settings = mergedSettings;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  updating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
  setUpdateSettingsCancel(state, cancel) {
    state.updateSettingsCancel = cancel;
  },
};

function mergeSettings(state, payload) {
  const clonedState = _.cloneDeep(state);
  const newState = _.mergeWith({}, clonedState, payload, mergeCustomizer);
  return newState;
}

function mergeCustomizer(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return srcValue;
  }
}

export const domainSettings = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
