<template>
  <div>
    <AppProjectDialog
      v-model="dialog.updateProject.active"
      :data="dialog.updateProject.item"
      :title="$t('project.updateProject.title')"
      :submitBtnText="$t('common.save')"
      @confirm="afterProjectUpdate"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteProject.active"
      :item="dialog.deleteProject.item"
      :title="$tc('project.deleteProject.title', dialog.deleteProject.item?.length)"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('project.deleteProject.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="deleteProject"
    />
    <AppDataTableServerSidePagination
      :loading="isLoading"
      :headers="_headers"
      :items="projects"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :noDataText="$t('common.noProjects')"
      :columnsToFreeze="columnsToFreeze"
      :groupHeaderColumnsToFreeze="2"
      :bulkActionConfigs="bulkActionConfigs"
      :getAllItems="getAllItems"
      removeMutationType="projects/removeProject"
      updateMutationType="projects/updateProject"
      :statsText="statsText"
      :isStatsLoading="isStatsLoading"
      show-select
    >
      <template
        v-slot:[`group.header`]="{
          toggle,
          group,
          isOpen,
          headers,
          isGroupIndeterminate,
          isGroupSelected,
          toggleGroup,
        }"
      >
        <td @click="toggle">
          <v-simple-checkbox
            :indeterminate="isGroupIndeterminate"
            :value="isGroupSelected"
            @input="toggleGroup"
          />
        </td>
        <td :colspan="headers.length - 4" @click="toggle">
          <div class="d-flex align-center">
            <v-btn class="mr-4" icon @click.stop="toggle">
              <v-icon>
                {{
                  isOpen
                    ? $icons.LIGHT.COMMON.CHEVRON_DOWN
                    : $icons.LIGHT.COMMON.CHEVRON_UP
                }}
              </v-icon>
            </v-btn>
            <div class="group-header__title">
              {{ headerTitle(group) }}
            </div>
          </div>
        </td>
        <td :colspan="3"></td>
      </template>
      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppProjectTableItem
          :project="item"
          :isSelected="isSelected"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :select="select"
          :headers="headers"
          :tableModel="tableModel"
          @menu:click="openContextMenu"
          @status:click="
            (e) => openEditMenu({ ...e, menuRef: 'statusMenu', direction: 'bottom' })
          "
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppContextMenu ref="contextMenu" @input="onContextMenuChange">
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.GO_TO).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.GO_TO).on"
        :selectedItems="[contextMenuActiveItem]"
      />
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.EDIT).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.EDIT).on"
        :selectedItems="[contextMenuActiveItem]"
      />
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.COPY_URL).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.COPY_URL).on"
        :selectedItems="[contextMenuActiveItem]"
      />
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.DELETE).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.DELETE).on"
        :selectedItems="[contextMenuActiveItem]"
      />
    </AppContextMenu>
    <AppCustomStatusPickerMenu
      ref="statusMenu"
      @submit="onProjectsUpdate"
      :closeOnContentClick="false"
      model="project"
    />
    <AppClientPickerMenu
      ref="customerMenu"
      @submit="onProjectsUpdate"
      :type="this.$constant.CUSTOMER"
      :queryParams="{ isActive: true, projectId: routeProjectId }"
    />
    <AppUserPickerMenu
      ref="projectManagerMenu"
      :queryParams="{ isAssignableAsProjectLeader: true, projectId: routeProjectId }"
      @submit="(id) => onProjectsUpdate({ ownerId: id })"
    />
    <AppAccessLevelPickerMenu ref="permissionLevelMenu" @submit="onProjectsUpdate" />
    <AppDatePickerMenu
      ref="startDateMenu"
      @submit="({ date }) => onProjectsUpdate({ startDate: date })"
    />
    <AppDatePickerMenu
      ref="endDateMenu"
      @submit="({ date }) => onProjectsUpdate({ endDate: date })"
    />
    <AppProjectPickerMenu
      ref="parentProjectMenu"
      @submit="({ projectId }) => onProjectsUpdate({ parentId: projectId })"
    />
  </div>
</template>

<script>
import { groupByMixins } from "@/helpers/mixins";
import { getAllProjects } from "@/services/project/allProjects";
import { mapGetters } from "vuex";

export default {
  mixins: [groupByMixins],
  props: {
    projects: Array,
    isLoading: Boolean,
    tableModel: String,
    serverItemsLength: Number,
    statsText: String,
    isStatsLoading: Boolean,
  },
  data() {
    return {
      dialog: {
        updateProject: {
          active: false,
          item: null,
        },
        deleteProject: {
          active: false,
          item: null,
        },
      },
      contextMenuActiveItem: null,
      isContextMenuOpen: false,
      itemsToEdit: [],
    };
  },
  computed: {
    ...mapGetters("activeIntegrations", {
      hasProjectImportIntegrationEnabled: "hasProjectImportIntegrationEnabled",
    }),
    routeProjectId() {
      return this.$route.params.projectId;
    },
    columnsToFreeze() {
      const isStatusGrouped = this.filtersMixins_dataTableOptions.groupBy?.includes(
        this.filtersMixins_dataFields.STATUS,
      );

      let columns = 2;

      if (!isStatusGrouped) {
        columns += 1;
      }

      if (this.hasProjectImportIntegrationEnabled) {
        columns += 1;
      }

      return columns;
    },
    bulkActionConfigs() {
      return this.$constant.getBulkActionConfigs(this.actionConfigs);
    },
    actionConfigs() {
      const {
        GO_TO,
        EDIT,
        COPY_URL,
        DELETE,
        EDIT_STATUS,
        EDIT_PROJECT_MANAGER,
        EDIT_CUSTOMER,
        EDIT_PERMISSION_LEVEL,
        EDIT_START_DATE,
        EDIT_END_DATE,
        EDIT_PARENT_PROJECT,
      } = this.$constant.ACTION_KEYS;

      const _openEditMenu = (e, menuRef) => {
        this.openEditMenu({ ...e, menuRef, direction: "top" });
      };

      const customActions = [
        {
          key: GO_TO,
          on: {
            click: this.onProjectOpen,
          },
        },
        {
          key: EDIT,
          props: {
            disabled: ({ items }) => !this.canEditItems({ items }),
          },
          on: {
            click: this.onProjectEdit,
          },
        },
        {
          key: COPY_URL,
          on: {
            click: this.onCopyUrl,
          },
        },
        {
          key: EDIT_STATUS,
          props: {
            disabled: ({ items }) => !this.canEditItems({ items }),
          },
          on: {
            click: (e) => _openEditMenu(e, "statusMenu"),
          },
        },
        {
          key: EDIT_PROJECT_MANAGER,
          props: {
            disabled: ({ items }) => !this.canEditItems({ items }),
          },
          on: {
            click: (e) => _openEditMenu(e, "projectManagerMenu"),
          },
        },
        {
          key: EDIT_CUSTOMER,
          props: {
            disabled: ({ items }) => !this.canEditItems({ items }),
          },
          on: {
            click: (e) => _openEditMenu(e, "customerMenu"),
          },
        },
        {
          key: EDIT_START_DATE,
          props: {
            disabled: ({ items }) => !this.canEditItems({ items }),
          },
          on: {
            click: (e) => _openEditMenu(e, "startDateMenu"),
          },
        },
        {
          key: EDIT_END_DATE,
          props: {
            disabled: ({ items }) => !this.canEditItems({ items }),
          },
          on: {
            click: (e) => _openEditMenu(e, "endDateMenu"),
          },
        },
        {
          key: EDIT_PERMISSION_LEVEL,
          props: {
            disabled: ({ items }) => !this.canEditItems({ items }),
          },
          on: {
            click: (e) => _openEditMenu(e, "permissionLevelMenu"),
          },
        },
        {
          key: EDIT_PARENT_PROJECT,
          props: {
            disabled: ({ items }) => !this.canEditItems({ items }),
          },
          on: {
            click: (e) => _openEditMenu(e, "parentProjectMenu"),
          },
        },
        {
          key: DELETE,
          props: {
            disabled: ({ items }) => !this.canEditItems({ items }),
          },
          on: {
            click: this.onProjectsDelete,
          },
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.PROJECT_ACTIONS,
      });

      return config;
    },
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          value: this.filtersMixins_dataFields.STATUS,
          sortable: false,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("project.list.number")),
          value: this.filtersMixins_dataFields.NUMBER,
          show: this.hasProjectImportIntegrationEnabled,
          sortable: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("project.list.projectName")),
          value: this.filtersMixins_dataFields.NAME,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("project.list.customer")),
          value: this.filtersMixins_dataFields.CLIENT,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("project.list.projectManager")),
          value: this.filtersMixins_dataFields.OWNER,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.members")),
          value: this.filtersMixins_dataFields.MEMBERS,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("project.list.startDate")),
          value: this.filtersMixins_dataFields.START_DATE,
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
    ...mapGetters("statuses", {
      hasFetchedStatuses: "hasFetchedStatuses",
      getStatusesByModel: "getStatusesByModel",
    }),
  },
  methods: {
    async getAllItems() {
      return await getAllProjects({
        filters: {
          ...this.filtersMixins_filters,
          ...(this.routeProjectId ? { projectId: this.routeProjectId } : {}),
        },
      });
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    canEditItems({ items }) {
      return items?.every((item) => item?.permissions?.canEdit);
    },
    actionConfigByKey(key) {
      return this.actionConfigs.find((action) => action.key === key) || {};
    },
    onCopyUrl({ items }) {
      const project = items[0];

      const route = {
        name: this.$routeNames.PROJECT.VIEW,
        params: { projectId: project?.id },
      };

      const projectUrl = this.$router.resolve(route).href;

      const fullPath = `${window.location.origin}${projectUrl}`;

      navigator.clipboard.writeText(fullPath);

      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text: this.$t("common.copied"),
        color: "success",
      });
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    openContextMenu({ from, project }) {
      this.$refs.contextMenu.open({ from });
      this.$nextTick(() => {
        this.contextMenuActiveItem = project;
      });
    },
    headerTitle(group) {
      const commonHeaderTitle = this.groupByMixins_commonHeaderTitle(group);
      if (commonHeaderTitle) return commonHeaderTitle;
      return group;
    },
    deleteProject({ item }) {
      this.$emit("projects:delete", { items: item });
    },
    onProjectsDelete({ items }) {
      this.dialog.deleteProject.active = true;
      this.dialog.deleteProject.item = items;
    },
    onProjectEdit({ items }) {
      const item = items[0];
      this.dialog.updateProject.active = true;
      this.dialog.updateProject.item = item;
    },
    onProjectOpen({ items }) {
      const item = items[0];
      this.$router.push({
        name: this.$routeNames.PROJECT.VIEW,
        params: { projectId: item.id },
      });
    },
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      this.itemsToEdit = items;
    },
    onProjectsUpdate(value) {
      const body = { ...value, ids: this.itemsToEdit.map((item) => item.id) };
      this.$emit("projects:update", body);
    },
    afterProjectUpdate(e) {
      this.$emit("project:afterUpdate", e);
    },
    async getStatuses() {
      await this.$store.dispatch("statuses/getStatuses", { model: "project" });
    },
  },
  async mounted() {
    if (!this.hasFetchedStatuses("project")) {
      this.getStatuses();
    }
  },
};
</script>
