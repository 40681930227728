//integration names

import { setupCustomAuth } from "@/services/integration/integrationSetup/customSetupService";
import { CDN_BASE_URL } from "./cdn";
import { oAuth2WithCodeAuth } from "@/services/integration/integrationSetup/oAuth2SetupService";

//Accounting integrations
export const BOLIGMAPPA = "BOLIGMAPPA";
export const TRIPLETEX = "TRIPLETEX";
export const POWEROFFICE = "POWEROFFICE";
export const FIKEN = "FIKEN";
export const DUETT = "DUETT";
export const UNIMICRO = "UNIMICRO";
export const DNB_REGNSKAP = "DNB_REGNSKAP";
export const SPAREBANK1_REGNSKAP = "SPAREBANK1_REGNSKAP";
export const SYSTIMA = "SYSTIMA";

//Other integrations
export const EXCEL = "EXCEL";
export const EBYGGESOK = "EBYGGESOK";
export const AUTOGEAR = "AUTOGEAR";

export const ACCOUNTING_INTEGRATIONS = [
  TRIPLETEX,
  POWEROFFICE,
  FIKEN,
  DUETT,
  UNIMICRO,
  DNB_REGNSKAP,
  SPAREBANK1_REGNSKAP,
  SYSTIMA,
];

export const EXCLUDE_CLOSED_PROJECTS_NOT_ALLOWED_INTEGRATIONS = [SYSTIMA];

export const PRO_INTEGRATIONS = [
  BOLIGMAPPA,
  EBYGGESOK,
  AUTOGEAR,
  ...ACCOUNTING_INTEGRATIONS,
];

//Authentication methods
export const O_AUTH2_INTEGRATIONS = [
  BOLIGMAPPA,
  FIKEN,
  UNIMICRO,
  DNB_REGNSKAP,
  SPAREBANK1_REGNSKAP,
];

export const AUTH_WITH_CODE_INTEGRATIONS = [
  {
    integrationName: DUETT,
    guideId: "v26E7nzD2G",
    completeMethod: setupCustomAuth,
    openConfigDialog: true,
  },
  { integrationName: TRIPLETEX, guideId: "16y5JsOB87", completeMethod: setupCustomAuth },
  {
    integrationName: POWEROFFICE,
    guideId: "N3OMEB7sCt",
    completeMethod: oAuth2WithCodeAuth,
  },
];

export const AUTH_WITH_EMAIL_PASS_INTEGRATIONS = [
  {
    integrationName: SYSTIMA,
    signUpUrl: "https://app.systima.no/register/1",
    completeMethod: setupCustomAuth,
  },
  {
    integrationName: AUTOGEAR,
    forgotPasswordUrl: "https://app.autogear.com/forgot-password",
    signUpUrl: "https://app.autogear.com/free-trial",
    completeMethod: setupCustomAuth,
  },
];

export const NO_CODE_INTEGRATIONS = [EBYGGESOK];

//Integrations that require "slug". Has to be set up in integration page
export const EXTERNAL_ACCOUNT_REQUIRED_INTEGRATIONS = [
  FIKEN,
  UNIMICRO,
  DNB_REGNSKAP,
  SPAREBANK1_REGNSKAP,
  SYSTIMA,
];

//Integrations that require "time entry article/product" when creating time entries
export const TIME_ENTRY_PRODUCT_REQUIRED_INTEGRATIONS = [DUETT];

//Some accounting integrations allows adding product directly on a the project
export const SEND_PRODUCT_TO_ACCOUNTING_ADD_TO_PROJECT_ALLOWED_INTREGRATIONS = [
  TRIPLETEX,
];

//Integrations that allows sending product to accounting as order lines as attachment
export const SEND_PRODUCT_TO_ACCOUNTING_WITHOUT_ATTACHMENT_INTEGRATION_NAMES = [
  TRIPLETEX,
];

//Some integrations have different tables for customer and supplier, we need to handle when user tries to make a client with type "customer and supplier"
export const CUSTOMER_AND_SUPPLIER_ARE_DIFFERENT_TABLES_INTEGRATIONS = [
  UNIMICRO,
  DNB_REGNSKAP,
  SPAREBANK1_REGNSKAP,
  DUETT,
];

//Integrations that doesnt allow editing client on contact.
export const EDITING_CLIENT_ON_CONTACT_NOT_ALLOWED_INTEGRATIONS = [
  POWEROFFICE,
  FIKEN,
  DUETT,
  UNIMICRO,
  DNB_REGNSKAP,
  SPAREBANK1_REGNSKAP,
];

export const IMPORT_STATES = {
  IMPORT: "IMPORT",
  SELECTING_FILE: "SELECTING_FILE",
  LOADING: "LOADING",
  IMPORT_SUCCESS: "IMPORT_SUCCESS",
  IMPORT_ERROR: "IMPORT_ERROR",
};

export const IMPORT_TYPES = {
  USER: "USER",
  CLIENT: "CLIENT",
  CONTACT: "CONTACT",
  PROJECT: "PROJECT",
  PRODUCT: "PRODUCT",
};

export const TIME_ENTRY_INTEGRATION_NAMES = [
  TRIPLETEX,
  POWEROFFICE,
  DUETT,
  UNIMICRO,
  DNB_REGNSKAP,
  SPAREBANK1_REGNSKAP,
];

//A list of integrations that supports import of a specific type
export const INTEGRATION_NAMES_SUPPORTING_IMPORT_TYPES = {
  [IMPORT_TYPES.USER]: [
    TRIPLETEX,
    POWEROFFICE,
    DUETT,
    UNIMICRO,
    DNB_REGNSKAP,
    SPAREBANK1_REGNSKAP,
  ],
  [IMPORT_TYPES.CLIENT]: [
    TRIPLETEX,
    POWEROFFICE,
    FIKEN,
    DUETT,
    UNIMICRO,
    DNB_REGNSKAP,
    SPAREBANK1_REGNSKAP,
    SYSTIMA,
  ],
  [IMPORT_TYPES.CONTACT]: [
    TRIPLETEX,
    POWEROFFICE,
    FIKEN,
    UNIMICRO,
    DNB_REGNSKAP,
    SPAREBANK1_REGNSKAP,
  ],
  [IMPORT_TYPES.PROJECT]: [
    TRIPLETEX,
    POWEROFFICE,
    FIKEN,
    DUETT,
    UNIMICRO,
    DNB_REGNSKAP,
    SPAREBANK1_REGNSKAP,
    SYSTIMA,
  ],
  [IMPORT_TYPES.PRODUCT]: [
    EXCEL,
    TRIPLETEX,
    POWEROFFICE,
    FIKEN,
    DUETT,
    UNIMICRO,
    DNB_REGNSKAP,
    SPAREBANK1_REGNSKAP,
    SYSTIMA,
  ],
};

export const IMPORT_SETTINGS = {
  UPDATE_IMPORTED_DATA: "UPDATE_IMPORTED_DATA",
};

export const TIME_ENTRY_ACCOUNTING_HOUR_TYPES = {
  INTERNAL: "internal",
  EXTERNAL: "external",
  BOTH: "both",
};

const INTEGRATION_ORDER = [
  TRIPLETEX,
  FIKEN,
  DUETT,
  POWEROFFICE,
  UNIMICRO,
  SPAREBANK1_REGNSKAP,
  DNB_REGNSKAP,
  SYSTIMA,
  BOLIGMAPPA,
  AUTOGEAR,
  EBYGGESOK,
];

export function orderIntegrations({ integrations, key }) {
  return integrations.sort(
    (a, b) => INTEGRATION_ORDER.indexOf(a[key]) - INTEGRATION_ORDER.indexOf(b[key]),
  );
}

const CDN_INTEGRATION_ICONS_BASE_URL = `${CDN_BASE_URL}/assets/integration_icons/`;
const INTEGRATION_ICONS = {
  [UNIMICRO]: `${CDN_INTEGRATION_ICONS_BASE_URL}unimicro.png`,
  [AUTOGEAR]: `${CDN_INTEGRATION_ICONS_BASE_URL}autogear.png`,
  [EBYGGESOK]: `${CDN_INTEGRATION_ICONS_BASE_URL}ebyggesok.png`,
  [BOLIGMAPPA]: `${CDN_INTEGRATION_ICONS_BASE_URL}boligmappa.png`,
  [POWEROFFICE]: `${CDN_INTEGRATION_ICONS_BASE_URL}poweroffice.png`,
  [TRIPLETEX]: `${CDN_INTEGRATION_ICONS_BASE_URL}tripletex.png`,
  [DUETT]: `${CDN_INTEGRATION_ICONS_BASE_URL}duett.png`,
  [FIKEN]: `${CDN_INTEGRATION_ICONS_BASE_URL}fiken.png`,
  [SPAREBANK1_REGNSKAP]: `${CDN_INTEGRATION_ICONS_BASE_URL}sparebank1-regnskap.png`,
  [DNB_REGNSKAP]: `${CDN_INTEGRATION_ICONS_BASE_URL}dnb-regnskap.png`,
  [SYSTIMA]: `${CDN_INTEGRATION_ICONS_BASE_URL}systima.png`,
};

export function getIntegrationBrandIconUrl(integrationName) {
  return INTEGRATION_ICONS[integrationName] || null;
}

const CDN_INTEGRATION_LOGOS_BASE_URL = `${CDN_BASE_URL}/assets/integration_logos/`;
const INTEGRATION_LOGOS = {
  [UNIMICRO]: `${CDN_INTEGRATION_LOGOS_BASE_URL}unimicro.png`,
  [POWEROFFICE]: `${CDN_INTEGRATION_LOGOS_BASE_URL}poweroffice.png`,
  [TRIPLETEX]: `${CDN_INTEGRATION_LOGOS_BASE_URL}tripletex.png`,
  [DUETT]: `${CDN_INTEGRATION_LOGOS_BASE_URL}duett.png`,
  [FIKEN]: `${CDN_INTEGRATION_LOGOS_BASE_URL}fiken.png`,
  [SPAREBANK1_REGNSKAP]: `${CDN_INTEGRATION_LOGOS_BASE_URL}sparebank1-regnskap.png`,
  [DNB_REGNSKAP]: `${CDN_INTEGRATION_LOGOS_BASE_URL}dnb-regnskap.png`,
  [EXCEL]: `${CDN_INTEGRATION_LOGOS_BASE_URL}excel.png`,
  [SYSTIMA]: `${CDN_INTEGRATION_LOGOS_BASE_URL}systima.png`,
};

export function getIntegrationLogoUrl(integrationName) {
  return INTEGRATION_LOGOS[integrationName] || null;
}
