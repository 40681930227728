import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

export async function setupCustomAuth({ integrationName, code }) {
  return axios
    .get(`${apiUrl}integration-auth/custom`, { params: { integrationName, code } })
    .then(({ data }) => {
      return;
    })
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
}
