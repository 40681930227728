var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',_vm._g(_vm._b({ref:"dataTable",staticClass:"elevation-0 border-a overflow-hidden",attrs:{"fixed-header":"","height":_vm.height,"loadingText":_vm.$t('common.loading'),"hide-default-footer":"","itemsPerPage":_vm.options.itemsPerPage,"page":_vm.options.page},on:{"update:page":function($event){return _vm.$set(_vm.options, "page", $event)},"page-count":function($event){_vm.options.pageCount = $event}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(item){return [_vm._t(slot,null,null,item)]}}}),(!_vm.disablePagination)?{key:"footer",fn:function(){return [_c('div',{ref:"dataTableFooter",staticClass:"py-1 border-t p-relative d-flex align-center justify-center"},[_c('AppStatsText',{attrs:{"isStatsLoading":_vm.isStatsLoading,"statsText":_vm.statsText}}),_c('v-pagination',{staticClass:"pagination-items-remove-box-shadow justify-center",attrs:{"length":_vm.options.pageCount,"total-visible":"7"},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1)]},proxy:true}:null,{key:"header.data-table-select",fn:function(){return [_c('v-simple-checkbox',{class:{ 'v-card--disabled': !_vm.selectableItems.length },attrs:{"disabled":!_vm.selectableItems.length,"value":_vm.isAllSelectableItemsSelected,"indeterminate":_vm.isIndeterminate},on:{"input":_vm.handleToggleSelectAll}})]},proxy:true},{key:"item",fn:function(slotProps){return [_vm._t("item",null,{"isSelectable":_vm.isItemSelectable(slotProps.item)},slotProps)]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.$attrs,false),_vm.$listeners)),_c('AppBulkActionMenu',{style:({
      bottom: _vm.disablePagination ? '60px' : '100px',
      zIndex: 3,
      transform: 'translateX(-50%)',
      left: '50%',
      position: 'absolute',
      width: 'fit-content',
    }),attrs:{"bulkActionConfigs":_vm.bulkActionConfigs,"selectedItems":_vm.selectedItems},on:{"clear:click":_vm.dataTableBulkActionMixin_clearSelection}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }