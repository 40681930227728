<template>
  <v-container>
    <v-row>
      <v-col v-for="col in 3" :key="col" cols="4" class="text-center">
        <v-skeleton-loader type="image" :height="skeletonLoaderHeight" max-width="100%" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-skeleton-loader type="image" :height="skeletonLoaderHeight" max-width="100%" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" class="text-center">
        <v-skeleton-loader type="image" :height="skeletonLoaderHeight" max-width="100%" />
      </v-col>
      <v-col cols="4" class="text-center">
        <v-skeleton-loader type="image" :height="skeletonLoaderHeight" max-width="100%" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      skeletonLoaderHeight: 200,
    };
  },
};
</script>
