import { teamPermissions } from "@/helpers/util";

export function normalizeTeams(teams, currentUser) {
  return (teams || []).map((team) => normalizeTeam(team, currentUser));
}

export function normalizeTeam(team, currentUser) {
  const permissions = teamPermissions(team, currentUser);
  return { ...team, ...permissions };
}
