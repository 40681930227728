<template>
  <v-chip
    v-bind="$attrs"
    @click="$emit('click', timeMixins_secondsToHours(duration))"
    x-small
    >{{ timeMixins_formatHoursShort(duration) }}</v-chip
  >
</template>

<script>
import { timeMixins } from "@/helpers/mixins";
export default {
  mixins: [timeMixins],
  props: {
    duration: Number,
  },
};
</script>
