<script>
import { projectTemplateBuilderMixins } from "@/helpers/mixins";
import Title from "./Title.vue";
import CustomIconTooltip from "./CustomIconTooltip.vue";
import TaskItem from "./TaskItem.vue";
export default {
  mixins: [projectTemplateBuilderMixins],
  props: {
    taskItems: Array,
  },
  data() {
    return {
      isAssigningTimeLimit: false,
    };
  },
  methods: {
    genTaskItems() {
      const taskRows = [];
      for (const item of this.taskItems) {
        const taskRow = this.$createElement(TaskItem, {
          props: { item },
          on: {
            "item:update": (e) => this.$emit("item:update", e),
            "item:delete": (e) => this.$emit("item:delete", e),
          },
        });
        taskRows.push(taskRow);
      }

      return this.$createElement(
        "div",
        {
          class: `border-l border-r ${this.removeBorderBotIfNoRows()} rounded-b-lg overflow-hidden project-template__item-container`,
        },
        taskRows,
      );
    },
    removeBorderBotIfNoRows() {
      return this.taskItems.length === 0 ? "" : "border-b";
    },
    showBorderAndBorderRadiusIfNoItems() {
      return this.taskItems.length === 0 ? "rounded-b-lg" : "";
    },
  },
  render(h) {
    return h("div", { class: "pb-8" }, [
      h(Title, {
        props: { title: this.$t("templateManagement.project.builder.task.title") },
      }),
      h("div", { class: "" }, [
        h(
          "div",
          {
            class: `d-flex rounded-t-lg ${this.showBorderAndBorderRadiusIfNoItems()} pa-2 px-4 border-a text-subtitle-2`,
          },
          [
            h(
              "div",
              {
                class: "",
                style: `width: ${this.projectTemplateBuilderMixins_taskNameWidth}%`,
              },
              this.$t("templateManagement.project.builder.task.taskName"),
            ),
            h(
              "div",
              {
                class: "d-flex pl-2",
                style: `width: ${this.projectTemplateBuilderMixins_dueDateWidth}%`,
              },
              [
                h("div", this.$t("templateManagement.project.builder.task.timeLimit")),
                h(CustomIconTooltip, {
                  props: {
                    text: this.$t(
                      "templateManagement.project.builder.task.timeLimitTooltip",
                    ),
                    icon: this.$icons.LIGHT.COMMON.CIRCLE_QUESTION,
                  },
                }),
              ],
            ),
          ],
        ),
        this.genTaskItems(),
      ]),
    ]);
  },
};
</script>
