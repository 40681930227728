//Subscription
export const PLAN_CODE = {
  GRIPR_FREE: "GRIPR_FREE",
  GRIPR_PRO: "GRIPR_PRO",
  PLATFORM_FEE: "PLATFORM_FEE",
  GRIPR_TRIAL: "GRIPR_TRIAL",
};

export const MAIN_PLAN_CODES = [
  PLAN_CODE.GRIPR_FREE,
  PLAN_CODE.GRIPR_PRO,
  PLAN_CODE.GRIPR_TRIAL,
];

export const MAIN_PLAN_MAX_QUANTITY = 1000;
export const ADDON_PLAN_MIN_QUANTITY = 0;
export const ADDON_PLAN_MAX_QUANTITY = 100;

//Products
export const PRODUCT_CODE = {
  E_SIGN: "E_SIGN",
};

export const E_SIGN_QUANTITY_STEP_AMOUNT = 10;
export const E_SIGN_MIN_QUANTITY = 10;
export const E_SIGN_MAX_QUANTITY = 10000;
