<script>
import { VTextarea } from "vuetify/lib";
export default {
  extends: VTextarea,
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: "input-background",
    },
    rows: {
      default: 4,
    },
    autoGrow: {
      type: Boolean,
      default: true,
    },
    solo: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
