import { DATA_TABLE_OPTIONS, DEFAULT_DATA_TABLE_OPTIONS } from "@/helpers/constants";
import router from "@/router";
import axios from "axios";
import _ from "lodash";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  filtersCollection: [
    //example
    // {
    //   model: "TASK",
    //   dataTableOptions: {
    //     itemsPerPage: 25,
    //     page: 1,
    //     sortBy: [],
    //     sortDesc: [],
    //     groupBy: [],
    //     groupDesc: [],
    //     multiSort: false,
    //     mustSort: false,
    //   },
    //   filters: {
    //     isInternal: false,
    //     status: [
    //       "NOT_STARTET",
    //       "IN_PROGRESS",
    //     ],
    //   }
    // }
  ],
  savedFiltersCollection: [],
  defaultFiltersCollection: [],
  isLoading: [],
  isSaving: [],
});

const getters = {
  isSaving: (state) => (model) => {
    return state.isSaving.find((obj) => obj.model === model)?.value || false;
  },
  isLoading: (state) => (model) => {
    return state.isLoading.find((obj) => obj.model === model)?.value || false;
  },
  filtersByModel: (state) => (model, key) => {
    const filters = state[key].find((filter) => {
      return filter.model === model;
    });
    return filters?.filters;
  },
  appliedFiltersCountByModel: (state, getters) => (model, key) => {
    const filters = getters.filtersByModel(model, key);

    if (!filters) {
      return 0;
    }

    const shouldIncludeValue = (value, prop) => {
      const isNotSearch = prop !== "search";
      const isNullOrUndefined = value === undefined || value === null;
      const isEmptyString = value === "";
      const isArrayEmpty = _.isArray(value) && value.length === 0;

      return isNotSearch && !isNullOrUndefined && !isEmptyString && !isArrayEmpty;
    };

    const count = _.values(_.pickBy(filters, shouldIncludeValue)).length;

    return count;
  },
  filtersIndexByModel: (state) => (model, key) =>
    state[key].findIndex((filter) => {
      return filter.model === model;
    }),

  dataTableOptionsByModel: (state, getters) => (model) => {
    const index = getters.dataTableOptionsIndexByModel(model);

    if (index === -1) {
      const options =
        DATA_TABLE_OPTIONS.find((o) => o.model === model)?.options ||
        DEFAULT_DATA_TABLE_OPTIONS;
      return options;
    }
    return state.filtersCollection?.find((obj) => obj.model === model)?.dataTableOptions;
  },
  dataTableOptionsIndexByModel: (state) => (model) => {
    return state.filtersCollection?.findIndex((obj) => obj?.model === model);
  },
};

const actions = {
  getFilters({ dispatch, commit, getters }, { model }) {
    commit("loading", { isLoading: true, model });
    axios
      .get(`${apiUrl}filter`, { params: { model } })
      .then(({ data }) => {
        const filterResponse = data.filter ?? data.defaultFilter;
        const parsedFilterValue = JSON.parse(filterResponse.value);

        let dataTableOptions = getters.dataTableOptionsByModel(model);
        const pageFromQuery = parseInt(router.currentRoute?.query?.page);
        if (pageFromQuery && !isNaN(pageFromQuery)) {
          dataTableOptions = {
            ...dataTableOptions,
            page: pageFromQuery,
          };
        }

        dispatch("updateFiltersByModel", {
          key: "filtersCollection",
          filters: parsedFilterValue,
          dataTableOptions,
          model,
        });
        dispatch("updateFiltersByModel", {
          key: "savedFiltersCollection",
          filters: parsedFilterValue,
          dataTableOptions,
          model,
        });
        dispatch("updateFiltersByModel", {
          key: "defaultFiltersCollection",
          filters: JSON.parse(data.defaultFilter.value),
          dataTableOptions,
          model,
        });
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", { isLoading: false, model });
      });
  },
  async saveFilter({ commit, dispatch }, { model, filter, dataTableOptions } = {}) {
    commit("setIsSaving", { isSaving: true, model });
    delete filter.search;
    await axios
      .post(`${apiUrl}filter`, {
        model,
        filter: JSON.stringify(filter),
      })
      .then(({ data }) => {
        const filters = JSON.parse(data.filter.value);
        dispatch("setFiltersByModel", {
          key: "savedFiltersCollection",
          filters,
          model,
          dataTableOptions,
        });
      })
      .finally(() => {
        commit("setIsSaving", { isSaving: false, model });
      });
  },
  setFiltersByModel({ commit, getters }, { key, filters, dataTableOptions, model } = {}) {
    const index = getters.filtersIndexByModel(model, key);
    const filtersObject = { model, filters: { ...filters }, dataTableOptions };
    if (index !== -1) {
      commit("updateFilters", { index, filtersObject, key });
    } else {
      commit("addFilters", { filtersObject, key });
    }
  },
  updateFiltersByModel(
    { commit, getters },
    { key, filters = {}, dataTableOptions = {}, model } = {},
  ) {
    delete filters.undefined;
    const index = getters.filtersIndexByModel(model, key);
    const oldFilter = getters.filtersByModel(model, key);
    const filtersObject = {
      model,
      filters: { ...oldFilter, ...filters },
      dataTableOptions,
    };
    if (index !== -1) {
      commit("updateFilters", { index, filtersObject, key });
    } else {
      commit("addFilters", { filtersObject, key });
    }
  },
  resetFiltersByModel({ commit, getters }, { model, key }) {
    const filtersIndex = getters.filtersIndexByModel(model, "filtersCollection");
    const search = getters.filtersByModel(model, "filtersCollection")?.search;
    const filtersToInsertKey = getters.filtersByModel(model, key);
    const dataTableOptions = getters.dataTableOptionsByModel(model);
    const filtersObject = {
      model,
      filters: { search, ...filtersToInsertKey },
      dataTableOptions,
    };
    if (filtersIndex !== -1) {
      commit("updateFilters", {
        index: filtersIndex,
        filtersObject,
        key: "filtersCollection",
      });
    } else {
      commit("addFilters", { filtersObject, key: "filtersCollection" });
    }
  },
};

const mutations = {
  updateFilters(state, { index, filtersObject, key }) {
    state[key].splice(index, 1, filtersObject);
  },
  addFilters(state, { filtersObject, key }) {
    state[key].push(filtersObject);
  },
  setIsSaving(state, { isSaving, model }) {
    const i = state.isSaving.findIndex((obj) => obj.model === model);
    const obj = { value: isSaving, model };
    if (i !== -1) {
      state.isSaving.splice(i, 1, obj);
    } else {
      state.isSaving.push(obj);
    }
  },
  loading(state, { isLoading, model }) {
    const i = state.isLoading.findIndex((obj) => obj.model === model);
    const newObj = { value: isLoading, model };
    if (i !== -1) state.isLoading.splice(i, 1, newObj);
    else state.isLoading.push(newObj);
  },
};

export const filters = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
