<template>
  <div>
    <AppContactDialog
      v-model="dialog.updateContact.active"
      :data="dialog.updateContact.item"
      :title="$t('contact.contactPerson.updateContact.title')"
      @confirm="afterContactUpdate"
      :confirmText="$t('common.save')"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteContact.active"
      :item="dialog.deleteContact.item"
      :title="
        $tc(
          'contact.contactPerson.deleteContact.title',
          dialog.deleteContact.item?.length,
        )
      "
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('contact.contactPerson.deleteContact.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="deleteContact"
    />
    <AppDataTableServerSidePagination
      :loading="isLoading"
      :headers="_headers"
      :items="contacts"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :noDataText="$t('common.noContacts')"
      :columnsToFreeze="2"
      :bulkActionConfigs="bulkActionConfigs"
      removeMutationType="contacts/removeContact"
      updateMutationType="contacts/updateContact"
      :show-select="!projectId"
      :disableMultiSelect="!!projectId"
    >
      <template v-slot:item="{ item, select, isSelected }">
        <AppContactTableItem
          :contact="item"
          :jobTitleLabel="getJobTitleLabel(item)"
          :responsibilityLabel="getResponsibilityLabel(item)"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :canEditLabels="isDomainOwner || isDomainAdmin"
          :isSelected="!projectId ? isSelected : undefined"
          :select="!projectId ? select : undefined"
          @contact:update="updateContact"
          @menu:click="onMenuClick"
          @responsibilityLabel:click="openResponsibilityMenu"
          @jobTitleLabel:click="openJobTitleMenu"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppActivityIndicator
      :show="isInviting"
      :text="$t('users.userInvite.sendingInvite')"
    />
    <AppLabelMenu
      ref="labelMenuJobTitle"
      @label:change="onJobTitleLabelChange"
      :labelType="jobTitleLabelType"
      selectedItemLabelPath="label"
    />
    <AppLabelMenu
      ref="labelMenuResponsibility"
      @label:change="onResponsibilityLabelChange"
      :labelType="responsibilityLabelType"
      selectedItemLabelPath="relation.label"
    />
    <AppContextMenu v-if="!projectId" ref="contextMenu" @input="onContextMenuChange">
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.EDIT).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.EDIT).on"
        :selectedItems="[contextMenuActiveItem]"
      />
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.INVITE).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.INVITE).on"
        :selectedItems="[contextMenuActiveItem]"
      />
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.DELETE).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.DELETE).on"
        :selectedItems="[contextMenuActiveItem]"
      />
      <!-- <AppMenuEditBtn @click="onContactEdit" :disabled="!!projectId" />
      <AppMenuItem
        @click="onInviteContactToWorkspace"
        :icon="$icons.REGULAR.ACTION.EMAIL"
        :text="$t('common.inviteToWorkspace')"
        :disabled="isInviteDisabled || !!projectId"
      />
      <AppMenuDeleteBtn @click="onContactDelete" :disabled="!!projectId" /> -->
    </AppContextMenu>
  </div>
</template>

<script>
import { inviteUsers } from "@/services/auth/inviteUsers";
import { mapGetters } from "vuex";

export default {
  props: {
    contacts: Array,
    isLoading: Boolean,
    serverItemsLength: Number,
    tableModel: String,
  },
  data() {
    return {
      isInviting: false,
      dialog: {
        updateContact: {
          active: false,
          item: null,
        },
        deleteContact: {
          active: false,
          item: [],
        },
      },
      contextMenuActiveItem: null,
      jobTitleLabelType: this.$constant.LABEL_TYPE.JOB_TITLE,
      responsibilityLabelType: this.$constant.LABEL_TYPE.RESPONSIBILITY,
    };
  },
  computed: {
    ...mapGetters("auth", {
      isDomainOwner: "isDomainOwner",
      isDomainAdmin: "isDomainAdmin",
    }),
    ...mapGetters("label", {
      labelsByType: "labelsByType",
    }),
    projectId() {
      return this.$route.params.projectId;
    },
    dataFields() {
      return this.$constant.TABLE_HEADER[this.tableModel];
    },
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    bulkActionConfigs() {
      return this.$constant.getBulkActionConfigs(this.actionConfigs);
    },
    actionConfigs() {
      const { EDIT, INVITE, DELETE } = this.$constant.ACTION_KEYS;

      const customActions = [
        {
          key: EDIT,
          props: {
            disabled: !!this.projectId,
          },
          on: {
            click: this.onContactEdit,
          },
        },
        {
          key: INVITE,
          props: {
            hidden: !!this.projectId,
            disabled: ({ items }) => this.isInviteDisabled({ items }),
          },
          on: {
            click: this.onInviteContactToWorkspace,
          },
        },
        {
          key: DELETE,
          props: {
            disabled: !!this.projectId,
            hidden: !!this.projectId,
          },
          on: {
            click: this.onContactDelete,
          },
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.CONTACT_ACTIONS,
      });

      return config;
    },
    headers() {
      return [
        {
          text: this.$options.filters.capitalize(this.$t("common.name")),
          value: this.dataFields.NAME,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.customerSupplier")),
          value: this.dataFields.CLIENT,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("users.label.jobTitle.title")),
          value: this.dataFields.LABEL,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("contact.contactPerson.label.responsibility.title"),
          ),
          value: this.dataFields.RELATION_LABEL,
          show: !!this.projectId,
          sortable: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.phoneNumber")),
          value: this.dataFields.PHONE,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.email")),
          value: this.dataFields.EMAIL,
          show: true,
        },
        {
          text: this.$t("common.active"),
          value: this.dataFields.ACTIVE,
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: !this.projectId,
        },
      ];
    },
  },
  methods: {
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    isInviteDisabled({ items }) {
      if (!!this.projectId) return true;
      if (!this.isDomainOwner && !this.isDomainAdmin) return true;
      if (items.every((item) => this.hasStatus(item, this.$constant.ACTIVE))) return true;
      return false;
    },
    actionConfigByKey(key) {
      return this.actionConfigs.find((action) => action.key === key) || {};
    },
    getResponsibilityLabel(item) {
      return this.labelsByType(this.responsibilityLabelType).find(
        (label) => label?.id === item?.relation?.labelId,
      );
    },
    getJobTitleLabel(item) {
      return this.labelsByType(this.jobTitleLabelType).find(
        (label) => label?.id === item?.labelId,
      );
    },
    getLabels() {
      this.$store.dispatch("label/getLabels", {
        type: this.jobTitleLabelType,
      });
      if (!this.projectId) return;
      this.$store.dispatch("label/getLabels", {
        type: this.responsibilityLabelType,
      });
    },
    onInviteContactToWorkspace({ items }) {
      const showSnack = ({ text, color = "success" }) => {
        this.$store.dispatch("snackbar/snackbar", {
          show: true,
          text,
          color,
        });
      };

      if (items.some((item) => !item?.email)) {
        showSnack({
          text: this.$t("common.emailIsRequiredToInvite"),
          color: "error",
        });
        return;
      }

      this.isInviting = true;

      const body = {
        users: items.map((item) => ({
          email: item.email,
          role: "CONTACT",
          contactId: item.id,
        })),
      };

      inviteUsers({ body })
        .then((data) => {
          if (!!data?.message?.key) {
            const translationKeys = {
              userAlreadyInvited: "userHasBeenReinvited",
              contactNotFound: "contactNotFound",
            };
            const translationKey = translationKeys[data.message.key];
            const path = `users.userInvite.messages.${translationKey}`;

            if (translationKey && this.$te(path)) {
              showSnack({
                text: this.$t(path),
              });
              return;
            }
          }
          showSnack({
            text: this.$t("users.userInvite.messages.inviteSent"),
          });
        })
        .finally(() => {
          this.isInviting = false;
        });
    },
    hasStatus(item, status) {
      if (!item) return false;
      return item?.user?.status === status;
    },
    openJobTitleMenu({ from, item: selectedItem }) {
      this.$refs.labelMenuJobTitle.open({
        from,
        data: { selectedItem },
      });
    },
    openResponsibilityMenu({ from, item: selectedItem }) {
      this.$refs.labelMenuResponsibility.open({
        from,
        data: { selectedItem },
      });
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    onMenuClick({ from, contact }) {
      if (this.projectId) return;
      this.$refs.contextMenu.open({ from });
      this.$nextTick(() => {
        this.contextMenuActiveItem = contact;
      });
    },
    onContactDelete({ items }) {
      this.dialog.deleteContact.active = true;
      this.dialog.deleteContact.item = items;
    },
    deleteContact({ item }) {
      this.$emit("contact:delete", { items: item });
    },
    onContactEdit({ items }) {
      const item = items[0];
      this.dialog.updateContact.active = true;
      this.dialog.updateContact.item = item;
    },
    onJobTitleLabelChange({ selectedItem, label }) {
      this.updateContact({
        id: selectedItem.id,
        body: {
          labelId: label.id,
          projectId: this.projectId,
        },
      });
    },
    onResponsibilityLabelChange({ selectedItem, label }) {
      this.updateContact({
        id: selectedItem.id,
        body: {
          relation: {
            model: "project",
            modelId: this.projectId,
            labelId: label.id,
          },
        },
      });
    },
    updateContact(e) {
      this.$emit("contact:update", e);
    },
    afterContactUpdate() {
      this.$emit("contact:afterUpdate");
    },
  },
  mounted() {
    this.getLabels();
  },
};
</script>
