<template>
  <v-menu
    v-model="localMenu"
    transition="fade-transition"
    :position-x="position.x"
    :position-y="position.y"
    absolute
    :closeOnContentClick="false"
  >
    <div
      class="input-border-transparent ui-background"
      :style="`width: ${size.width}px;`"
    >
      <AppDefaultTextarea
        ref="textarea"
        v-model="text"
        @keydown.enter.prevent="onEnter"
        :autoGrow="false"
        rows="2"
        :placeholder="textPlaceholder || this.$t('common.whatNeedsToBeDone')"
        hide-details
        autofocus
        no-resize
        background-color="transparent"
      />
    </div>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      text: "",
    };
  },
  props: {
    menu: Boolean,
    textPlaceholder: String,
    position: Object,
    size: Object,
  },
  model: {
    prop: "menu",
    event: "menu:change",
  },
  watch: {
    menu(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.textarea.focus();
        });
      }
    },
  },
  computed: {
    localMenu: {
      get() {
        return this.menu;
      },
      set(value) {
        this.$emit("menu:change", value);
      },
    },
  },
  methods: {
    onEnter() {
      const text = this.text.trim();
      this.text = "";
      if (text) {
        this.$emit("add", { text });
      }
    },
  },
};
</script>
