import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  products: [],
  isLoading: false,
  isUpdating: false,
  limit: 0,
  offset: 0,
  count: 0,
});

const actions = {
  async getProducts(
    { commit, state },
    { filter, projectId, limit = 30, shouldPushItems },
  ) {
    const prevLimit = state.limit;
    commit("loading", true);
    commit("setLimit", limit);
    commit("setOffset", { prevLimit });

    const params = {
      ...filter,
      offset: state.offset,
      limit: state.limit,
      projectId,
    };

    return axios
      .get(`${apiUrl}product`, { params })
      .then(({ data }) => {
        const { products, count } = data;
        commit("setCount", count);
        if (shouldPushItems) {
          commit("pushProducts", products);
        } else {
          commit("setProducts", products);
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
};

const mutations = {
  setCount(state, count) {
    state.count = count;
  },
  setOffset(state, { prevLimit }) {
    state.offset += prevLimit;
  },
  setLimit(state, limit) {
    state.limit = limit;
  },
  setProducts(state, products) {
    const uniqueProductIds = new Set();
    const uniqueProducts = products.filter((product) => {
      if (uniqueProductIds.has(product.id)) {
        return false;
      } else {
        uniqueProductIds.add(product.id);
        return true;
      }
    });

    state.products = uniqueProducts;
  },
  pushProducts(state, products) {
    state.products.push(...products);
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  resetState(state) {
    state.products = [];
    state.offset = 0;
    state.count = 0;
    state.limit = 0;
  },
};

export const productsInfinityList = {
  namespaced: true,
  state,
  actions,
  mutations,
};
