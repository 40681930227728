var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"list_tr list_tr-height",class:_vm.rowStateClasses,on:{"contextmenu":_vm.dataTableItemMixins_onRightClick,"click":function($event){return _vm.select(!_vm.isSelected)}}},[_c('td',[_c('v-simple-checkbox',{attrs:{"color":"primary","value":_vm.isSelected},on:{"input":function($event){return _vm.select($event)}}})],1),_c('td',[_c('AppUserAvatar',{attrs:{"user":_vm.user,"show-name":"","truncate":70}})],1),_c('td',[_c('AppLinkText',{attrs:{"link":`mailto:${_vm.user.email}`,"underline-on-hover":""}},[_c('AppTextTruncateValue',{attrs:{"text":_vm.user.email,"truncate":30}})],1)],1),_c('td',[_c('AppPhoneLink',{attrs:{"phone":_vm.phone}})],1),_c('td',[_c('AppLabelBtn',{ref:"jobTitleLabelBtn",attrs:{"title":_vm.jobTitle,"isUpdating":_vm.user.isUpdatingLabel,"clickable":_vm.canEditLabels},on:{"click":(e) =>
          _vm.$emit('jobTitleLabel:click', {
            from: _vm.$refs.jobTitleLabelBtn.$el,
            item: _vm.user,
          })}})],1),_c('td',[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-checkbox',{attrs:{"inputValue":_vm.user.isAdmin,"disabled":!_vm.canEdit},on:{"change":function($event){return _vm.onRoleChange($event, 'ADMIN')},"click":function($event){$event.stopPropagation();}}})],1)]),_c('td',[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-checkbox',{attrs:{"inputValue":_vm.user.isHrAdmin,"disabled":!_vm.canEdit},on:{"change":function($event){return _vm.onRoleChange($event, 'HR_ADMIN')},"click":function($event){$event.stopPropagation();}}})],1)]),_c('td',[_c('AppColorChip',{attrs:{"text":_vm.$te(`common.userStatus.${_vm.user.status.toLowerCase()}`)
          ? _vm.$t(`common.userStatus.${_vm.user.status.toLowerCase()}`)
          : _vm.user.status,"color":_vm.userStatusColor}})],1),_c('td',[_vm._v(_vm._s(_vm.user.number))]),_c('td',[_c('v-btn',{ref:"ellipsis",attrs:{"icon":""},on:{"click":_vm.dataTableItemMixins_onEllipsisClick}},[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.REGULAR.ACTION.MENU))])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }