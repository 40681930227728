import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
//unused store

const state = () => ({
  stats: [],
  isLoading: false,
});

const getters = {
  statCountByDone: (state) => (type, isDone) => {
    return (
      state.stats.find(
        (x) => (x.done === isDone || x.isDone === isDone) && x.type === type,
      )?.count || 0
    );
  },
};

const actions = {
  async getStats({ commit }, filter) {
    commit("loading", true);

    try {
      const taskRes = await axios.get(`${apiUrl}statistics/task`, {
        params: { ...filter },
      });
      const taskStats = taskRes?.data?.statistics;
      const formRes = await axios.get(`${apiUrl}statistics/form`, {
        params: { ...filter },
      });
      const formStats = formRes?.data?.statistics;

      const stats = [
        ...taskStats.map((td) => ({ ...td, type: "TASK" })),
        ...formStats.map((fd) => ({ ...fd, type: "FORM" })),
      ];

      commit("setStats", stats);
      commit("loading", false);
    } catch (error) {
      commit("loading", false);
      throw new Error(error);
    }
  },
};

const mutations = {
  setStats(state, stats) {
    state.stats = stats;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const collectionStats = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
