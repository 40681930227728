<script>
import { projectTemplateBuilderMixins } from "@/helpers/mixins";
import Title from "./Title.vue";
import CustomIconTooltip from "./CustomIconTooltip.vue";
import ToDoItem from "./ToDoItem.vue";
import { VIcon, VBtn } from "vuetify/lib";
import Draggable from "vuedraggable";
export default {
  mixins: [projectTemplateBuilderMixins],
  props: {
    toDoItems: Array,
  },
  data() {
    return {
      isAssigningTimeLimit: false,
      isDragging: false,
    };
  },
  methods: {
    onChange(e) {
      if (e.moved) {
        const item = e.moved?.element;
        const newIndex = e.moved?.newIndex;

        this.projectTemplateBuilderMixins_moveItem({
          item,
          body: { index: newIndex },
        });
      }
    },
    removeBorderOnChildrenIfNotDraggingChild() {
      return !this.isDragging ? "project-template__item-container" : "";
    },
    removeBorderBotIfNoRows() {
      return this.toDoItems.length === 0 ? "" : "border-b";
    },
    genToDoItem() {
      const toDoRows = [];
      for (const item of this.toDoItems) {
        const toDoRow = this.$createElement(ToDoItem, {
          props: {
            item,
            isDragging: this.isDragging,
          },
          on: {
            "item:update": (e) => this.$emit("item:update", e),
            "item:delete": (e) => this.$emit("item:delete", e),
          },
        });
        toDoRows.push(toDoRow);
      }
      return this.$createElement(
        Draggable,
        {
          class: `border-l border-r ${this.removeBorderBotIfNoRows()} rounded-b-lg overflow-hidden ${this.removeBorderOnChildrenIfNotDraggingChild()}`,
          props: {
            list: this.toDoItems,
          },
          attrs: {
            draggable: ".draggable-item",
            animation: this.$constant.DRAG_SETTINGS.ANIMATION_DURATION,
            ghostClass: this.$constant.DRAG_SETTINGS.GHOST_CLASS_HIDDEN,
            dragClass: this.$constant.DRAG_SETTINGS.DRAG_CLASS,
            filter: ".block-draggable",
            forceFallback: true,
            preventOnFilter: false,
          },
          on: {
            change: (e) => this.onChange(e),
            start: (e) => {
              this.isDragging = true;
            },
            end: (e) => {
              this.isDragging = false;
            },
          },
        },
        toDoRows,
      );
    },
    createUnsavedToDoItem() {
      const index = this.toDoItems.length;
      const data = { name: "", type: "TODO", values: null, index };
      this.$emit("item:createUnsavedItem", { data });

      setTimeout(() => {
        const toDoItem = this.toDoItems[this.toDoItems.length - 1];
        document.getElementById(`toDoItemTextField_${toDoItem?.refId}`)?.focus();
      }, 20);
    },
    showBorderAndBorderRadiusIfNoItems() {
      return this.toDoItems.length === 0 ? "rounded-b-lg" : "";
    },
  },
  render(h) {
    return h("div", [
      h(Title, {
        props: { title: this.$t("templateManagement.project.builder.toDo.title") },
      }),
      h("div", [
        h(
          "div",
          {
            class: `d-flex rounded-t-lg ${this.showBorderAndBorderRadiusIfNoItems()} pa-2 px-4 border-a text-subtitle-2`,
          },
          [h("div", this.$t("templateManagement.project.builder.toDo.name"))],
        ),
        this.genToDoItem(),
      ]),
      h("div", { class: "pt-2 mb-4" }, [
        h(
          VBtn,
          {
            props: { text: true },
            on: {
              click: () => this.createUnsavedToDoItem(),
            },
          },
          [
            h(VIcon, { class: "pr-3" }, this.$icons.LIGHT.ACTION.CIRCLE_PLUS),
            h(
              "div",
              { class: "font-weight-bold", attrs: { id: "create-toDo-btn" } },
              this.$t("templateManagement.project.builder.toDo.createToDo"),
            ),
          ],
        ),
      ]),
    ]);
  },
};
</script>
