import { normalizeDocumentTemplates } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import FileSaver from "file-saver";

const state = () => ({
  templates: [],
  isLoading: false,
  isSaving: false,
});

const getters = {
  alreadyImportedTemplates: (state) => {
    return state.templates.filter((item) => item.storage);
  },
};

const actions = {
  async getTemplates({ commit }, params) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}template/document`, { params })
      .then(async (res) => {
        const templates = res.data?.templates || [];
        const normalizedTemplates = normalizeDocumentTemplates(templates);
        commit("setTemplates", normalizedTemplates);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async saveTemplates({ commit, dispatch }, { body, params }) {
    commit("saving", true);
    return axios
      .put(`${apiUrl}template/document/convert`, body, { params })
      .then(async (res) => {
        commit("saving", false);
      })
      .catch((error) => {
        commit("saving", false);
        throw new Error(error);
      });
  },
  async downloadItem({ commit }, item) {
    commit("setItemPropValue", { item, prop: "isDownloading", value: true });
    return axios
      .get(`${apiUrl}template/document/${item.id}/download`, {
        responseType: "blob",
      })
      .then((res) => {
        commit("setItemPropValue", {
          item,
          prop: "isDownloading",
          value: false,
        });
        const fileName = `${item.name.replace(/\.[^/.]+$/, "")}`;
        FileSaver.saveAs(res.data, fileName);
      })
      .catch((error) => {
        commit("setItemPropValue", {
          item,
          prop: "isDownloading",
          value: false,
        });
        throw new Error(error);
      });
  },
  async deleteItem({ commit }, item) {
    commit("setItemPropValue", { item, prop: "isDeleting", value: true });
    return axios
      .delete(`${apiUrl}storage`, {
        data: { ids: [item.storage?.id] },
      })
      .then((res) => {
        commit("setItemPropValue", { item, prop: "isDeleting", value: false });
      })
      .catch((error) => {
        commit("setItemPropValue", { item, prop: "isDeleting", value: false });
        throw new Error(error);
      });
  },
};

const mutations = {
  setTemplates(state, templates) {
    state.templates = templates;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  saving(state, isSaving) {
    state.isSaving = isSaving;
  },
  setItemPropValue(state, { item, prop, value }) {
    if (!item) throw new Error("No item");
    const index = state.templates.findIndex((x) => x.id === item.id);
    if (index !== -1) {
      state.templates[index][prop] = value;
    }
  },
};

export const cmsDocumentTemplateLibrary = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
