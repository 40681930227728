<template>
  <div>
    <AppClientDialog
      v-model="dialog.updateClient.active"
      :data="dialog.updateClient.item"
      :title="$t('contact.client.updateClient.title')"
      @confirm="afterClientUpdate"
      :confirmText="$t('common.save')"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteClient.active"
      :item="dialog.deleteClient.item"
      :title="$tc('contact.client.deleteClient.title', dialog.deleteClient.item?.length)"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('contact.client.deleteClient.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="deleteClient"
    />
    <AppDataTableServerSidePagination
      :loading="isLoading"
      :headers="_headers"
      :items="clients"
      :serverItemsLength="serverItemsLength"
      :tableModel="tableModel"
      :noDataText="$t('common.noClients')"
      :columnsToFreeze="2"
      :bulkActionConfigs="bulkActionConfigs"
      removeMutationType="clients/removeClient"
      updateMutationType="clients/updateClient"
      :getAllItems="getAllItems"
      show-select
    >
      <template v-slot:item="{ item, select, isSelected }">
        <AppClientTableItem
          :client="item"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :isSelected="isSelected"
          :select="select"
          @client:update="updateClient"
          @menu:click="openContextMenu"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppContextMenu ref="contextMenu" @input="onContextMenuChange">
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.EDIT).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.EDIT).on"
        :selectedItems="[contextMenuActiveItem]"
      />
      <AppMenuActionBtn
        v-bind="actionConfigByKey($constant.ACTION_KEYS.DELETE).props"
        v-on="actionConfigByKey($constant.ACTION_KEYS.DELETE).on"
        :selectedItems="[contextMenuActiveItem]"
      />
    </AppContextMenu>
  </div>
</template>

<script>
export default {
  props: {
    clients: Array,
    isLoading: Boolean,
    serverItemsLength: Number,
    tableModel: String,
    getAllItems: Function,
  },
  data() {
    return {
      dialog: {
        updateClient: {
          active: false,
          item: null,
        },
        deleteClient: {
          active: false,
          item: null,
        },
      },
      contextMenuActiveItem: null,
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    dataFields() {
      return this.$constant.TABLE_HEADER[this.tableModel];
    },
    bulkActionConfigs() {
      return this.$constant.getBulkActionConfigs(this.actionConfigs);
    },
    actionConfigs() {
      const { EDIT, DELETE } = this.$constant.ACTION_KEYS;

      const customActions = [
        {
          key: EDIT,
          props: {
            disabled: !!this.projectId,
          },
          on: {
            click: this.onClientEdit,
          },
        },
        {
          key: DELETE,
          props: {
            disabled: ({ items }) =>
              !this.canDeleteSelectedItems({ items }) || !!this.projectId,
          },
          on: {
            click: this.onClientDelete,
          },
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.CLIENT_ACTIONS,
      });

      return config;
    },
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          text: this.$t("contact.client.list.clientName"),
          value: this.dataFields.NAME,
          show: true,
        },
        {
          text: this.$t("common.orgnr"),
          value: this.dataFields.ORGANIZATION_NUMBER,
          show: true,
          sortable: false,
        },
        {
          text: this.$t("contact.client.list.responsible"),
          value: this.dataFields.OWNER,
          show: true,
        },
        {
          text: this.$t("common.type"),
          value: this.dataFields.TYPE,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("contact.client.list.phone")),
          value: this.dataFields.PHONE,
          show: true,
        },
        {
          text: this.$t("contact.client.list.email"),
          value: this.dataFields.EMAIL,
          show: true,
        },
        {
          text: this.$t("common.active"),
          value: this.dataFields.ACTIVE,
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
  },
  methods: {
    canDeleteSelectedItems({ items }) {
      return items.every((item) => item?.canDelete);
    },
    actionConfigByKey(key) {
      return this.actionConfigs.find((action) => action.key === key) || {};
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    openContextMenu({ from, client, direction }) {
      this.$refs.contextMenu.open({ from, direction });
      this.$nextTick(() => {
        this.contextMenuActiveItem = client;
      });
    },
    onClientDelete({ items }) {
      this.dialog.deleteClient.active = true;
      this.dialog.deleteClient.item = items;
    },
    deleteClient({ item }) {
      this.$emit("client:delete", { items: item });
    },
    onClientEdit({ items }) {
      const item = items[0];
      this.dialog.updateClient.active = true;
      this.dialog.updateClient.item = item;
    },
    updateClient(e) {
      this.$emit("client:update", e);
    },
    afterClientUpdate() {
      this.$emit("client:afterUpdate");
    },
  },
};
</script>
