<template>
  <v-select
    :menuProps="{ offsetY: true }"
    v-model="localValue"
    v-bind="params"
    v-on="listeners"
    ref="select"
  >
    <template v-if="showSelectAll" v-slot:prepend-item>
      <v-list-item @mousedown.prevent @click="onSelectAll">
        <v-list-item-action>
          <v-icon :color="isSomeSelected ? 'primary' : undefined" class="mx-1">
            {{ selectAllIcon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("common.selectAll") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2" />
    </template>
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="item">
      <slot :name="slot" v-bind="item"></slot>
    </template>
  </v-select>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      localValue: null,
    };
  },
  props: {
    value: {
      type: [String, Array, Number, Boolean, Object],
      default: null,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    backgroundColor: {
      type: String,
      default: "input-background",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    itemText: {
      type: String,
      default: "text",
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    solo: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    value: {
      handler(value) {
        this.localValue = value;
      },
      immediate: true,
    },
  },
  computed: {
    listeners() {
      return { ...this.$listeners, change: this.onChange, blur: this.onBlur };
    },
    params() {
      return { ...this.$props, ...this.$attrs };
    },
    isAllSelected() {
      if (!this.multiple || !this.items?.length) return false;
      return this.localValue?.length === this.items?.length;
    },
    isSomeSelected() {
      return this.localValue?.length > 0;
    },
    selectAllIcon() {
      if (this.isAllSelected) return this.$icons.SOLID.COMMON.SQUARE_CHECK;
      if (this.isSomeSelected) return this.$icons.SOLID.COMMON.SQUARE_MINUS;
      return this.$icons.LIGHT.COMMON.SQUARE;
    },
    showSelectAll() {
      return this.multiple && this.selectAll && this.items.length;
    },
  },
  methods: {
    onBlur() {
      if (this.multiple) {
        this.emitValueChange();
      }
    },
    onChange(value) {
      this.localValue = value;
      if (!this.multiple) {
        this.emitValueChange();
        this.$refs.select.blur();
      }
    },
    onSelectAll() {
      this.$nextTick(() => {
        if (this.isAllSelected) {
          this.localValue = [];
        } else {
          this.localValue = this.items.map((item) => {
            if (typeof item === "object") {
              return item[this.itemValue];
            }
            return item;
          });
        }
      });
    },
    emitValueChange() {
      if (_.isEqualWith(this.localValue, this.value, this.isEqualCustomizer)) return;
      this.$emit("change", this.localValue);
    },
    isEqualCustomizer(objValue, othValue) {
      //to ignore order if array
      if (_.isArray(objValue) && _.isArray(othValue)) {
        return _.isEqual(_.sortBy(objValue), _.sortBy(othValue));
      }
    },
    focus() {
      this.$refs.select.focus();
    },
    reset() {
      this.$refs.select.reset();
    },
    activateMenu() {
      this.$refs.select.activateMenu();
    },
  },
};
</script>
