<script>
import moment from "moment";
export default {
  props: {
    item: Object,
  },
  data() {
    return {};
  },
  computed: {
    dateValue() {
      if (!this.item?.value) return "";
      return moment(this.item.value).format("DD.MM.YYYY");
    },
  },
  methods: {
    getAnswerElm() {
      if (!this.dateValue)
        return this.$createElement(
          "div",
          { class: "error--text" },
          this.$t("formManagement.preview.noAnswer"),
        );
      return this.$createElement("div", this.dateValue);
    },
  },
  render(h) {
    return this.getAnswerElm();
  },
};
</script>
