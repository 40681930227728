<template>
  <AppModuleAutocomplete
    ref="autocomplete"
    v-bind="params"
    v-on="listeners"
    item-text="name"
    item-value="id"
    :noDataText="$t('common.noForms')"
    :titleField="$constant.MODULE_FIELD_CONFIG.FORM.TITLE_FIELD"
    :subTitleFields="$constant.MODULE_FIELD_CONFIG.FORM.SUB_TITLE_FIELDS"
  />
</template>

<script>
export default {
  props: {
    value: [String, Array],
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
    listeners() {
      const listeners = {
        ...this.$listeners,
      };
      return listeners;
    },
  },

  methods: {
    focus() {
      this.$refs.autocomplete.focus();
    },
    activateMenu() {
      this.$refs.autocomplete.activateMenu();
    },
  },
};
</script>
