import axios from "axios";
import {
  E_SIGN_META_STATUS_FIELD,
  E_SIGN_REVOKE_STATUSES,
  E_SIGN_REMIND_STATUSES,
} from "../../../../helpers/constants";
const apiUrl = process.env.VUE_APP_API_URL;
const integrationApiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

const state = () => ({
  storage: null,
  isLoading: false,
  isSendingDocumentToESign: false,
  isRevokingDocument: false,
});

const getters = {
  metaValueByField: state => field =>
    state.storage?.meta?.find(meta => meta.field === field)?.value || null,
  metaValueByFieldAndName: state => (field, name) =>
    state.storage?.meta?.find(meta => meta.field === field && meta.name === name)
      ?.value || null,
  status: (state, getters) => {
    if (!Array.isArray(state.storage?.meta)) return null;
    const status = getters.metaValueByField(E_SIGN_META_STATUS_FIELD);
    return status;
  },
  canRevoke: (state, getters) => {
    return E_SIGN_REVOKE_STATUSES.includes(getters.status);
  },
  canRemind: (state, getters) => {
    return E_SIGN_REMIND_STATUSES.includes(getters.status);
  },
};

const actions = {
  async getStorage({ commit }, { id, load = true }) {
    if (load) {
      commit("setLoading", true);
    }
    return axios
      .get(`${apiUrl}storage/file/${id}`)
      .then(async ({ data }) => {
        const storage = data.storage;

        commit("setStorage", storage);
        return storage;
      })
      .catch(error => {
        throw new Error(error);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  async sendDocumentToESign({ commit }, { storageId, body }) {
    commit("setIsSendingDocumentToESign", true);

    return axios
      .post(`${integrationApiUrl}e-sign/signable-document/${storageId}/create`, body)
      .then(async ({ data }) => {
        return data;
      })
      .catch(error => {
        throw new Error(error);
      })
      .finally(() => {
        commit("setIsSendingDocumentToESign", false);
      });
  },
  async revokeDocument({ commit }, { storageId, body }) {
    commit("setIsRevokingDocument", true);

    return axios
      .post(`${integrationApiUrl}e-sign/signable-document/${storageId}/revoke`, body)
      .then(async ({ data }) => {
        return data;
      })
      .catch(error => {
        throw new Error(error);
      })
      .finally(() => {
        commit("setIsRevokingDocument", false);
      });
  },
  async sendReminder({ commit }, { storageId, body }) {
    return axios
      .post(`${integrationApiUrl}e-sign/signable-document/${storageId}/remind`, body)
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        throw new Error(error);
      })
      .finally(() => {});
  },
};

const mutations = {
  setStorage(state, storage) {
    state.storage = storage;
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setIsSendingDocumentToESign(state, isSendingDocumentToESign) {
    state.isSendingDocumentToESign = isSendingDocumentToESign;
  },
  setIsRevokingDocument(state, isRevokingDocument) {
    state.isRevokingDocument = isRevokingDocument;
  },
};

export const storageESign = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
