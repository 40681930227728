var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`
  w-100 
  mb-2
  d-flex 
  align-center 
  rounded 
  flex-wrap 
  cursor-pointer 
  ${_vm.backgroundColor} 
  ${_vm.border}`,staticStyle:{"min-height":"40px"},on:{"click":_vm.toggleChecked}},[_c('v-row',{staticClass:"align-center px-2",attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-simple-checkbox',{attrs:{"color":"success","value":_vm.isChecked},on:{"click":_vm.toggleChecked}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('div',[_vm._v(_vm._s(_vm.product.code))])]),_c('v-col',[_c('div',[_vm._v(_vm._s(_vm.product.name))])]),_c('v-col',{staticClass:"text-end"},[_c('div',[_vm._v(_vm._s(_vm.clientName))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }