export function normalizeTask(task) {
  return {
    ...task,
    isUpdatingStatus: false,
  };
}

export function normalizeTasks(tasks) {
  return (tasks || []).map((task) => normalizeTask(task));
}
