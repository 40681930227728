<template>
  <AppContextMenu ref="menu" :closeOnContentClick="false">
    <AppMenuItem
      :text="$t(activeLabelKey)"
      color="success"
      :icon="$icons.REGULAR.COMMON.TOGGLE_LARGE_ON"
      @click="onSubmit({ isActive: true })"
    />
    <AppMenuItem
      :text="$t(inactiveLabelKey)"
      color="error"
      :icon="$icons.REGULAR.COMMON.TOGGLE_LARGE_OFF"
      @click="onSubmit({ isActive: false })"
    />
  </AppContextMenu>
</template>

<script>
export default {
  props: {
    activeLabelKey: {
      type: String,
      default: "common.active",
    },
    inactiveLabelKey: {
      type: String,
      default: "common.inactive",
    },
  },
  methods: {
    onSubmit({ isActive }) {
      this.$emit("submit", { isActive });
      this.close();
    },
    close() {
      this.$refs.menu.close();
    },
    open(e) {
      this.$refs.menu.open(e);
    },
  },
};
</script>
