<template>
  <v-dialog
    v-model="localDialog"
    transition="dialog-bottom-transition"
    fullscreen
    persistent
    no-click-animation
  >
    <v-card
      class="ui-background p-relative d-flex align-center justify-center"
      :disabled="isLoading"
    >
      <div class="dialog__close-btn">
        <v-btn icon dark @click="close">
          <v-icon>
            {{ $icons.LIGHT.ACTION.CLOSE }}
          </v-icon>
        </v-btn>
      </div>
      <template v-if="isImport">
        <AppStateImport
          :integrations="integrations"
          :state="state"
          :importType="importType"
          :excludeClosed="excludeClosed"
          @excludeClosed:toggle="excludeClosed = $event"
          @close:click="close"
          @import:click="onImport"
        />
      </template>
      <template v-if="isSelectingFile">
        <AppStateSelectingFile
          :state="state"
          :importType="importType"
          @import:start="onExcelImport"
          @back:click="onAppStateSelectingFileBack"
        />
      </template>
      <template v-if="isLoading">
        <AppStateImporting :state="state" :importType="importType" />
      </template>
      <template v-if="isImportSuccess">
        <AppStateImportSuccess
          :state="state"
          :importType="importType"
          @close:click="close"
        />
      </template>
      <template v-if="isImportError">
        <AppStateImportError
          :state="state"
          :importType="importType"
          @import:click="onTryAgain"
          :error="error"
          :reportPath="reportPath"
        />
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { importAccounting } from "../../../services/integration/accounting/accounting.service";
import { importProducts } from "../../../services/product/productImport.service";
import { planRestrictionsMixins } from "@/helpers/mixins";
import { mapState } from "vuex";
import {
  EXTERNAL_ACCOUNT_REQUIRED_INTEGRATIONS,
  INTEGRATION_NAMES_SUPPORTING_IMPORT_TYPES,
} from "@/helpers/constants";

export default {
  mixins: [planRestrictionsMixins],
  props: {
    dialog: Boolean,
    importType: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      state: this.$constant.IMPORT_STATES.IMPORT,

      // states:
      //IMPORT (first step)
      //LOADING (second step, loading in progress)
      //SELECTING_FILE (Optional step for when user has to import from a file)
      //IMPORT_SUCCESS (third step, the success screen)
      //IMPORT_ERROR (third step in case of error)
      error: null,
      reportPath: null,
      excludeClosed: false,
    };
  },
  watch: {
    dialog(dialog) {
      if (dialog) {
        this.getIntegrations();
      }
    },
  },
  computed: {
    ...mapGetters("activeIntegrations", {
      isIntegrationEnabled: "isIntegrationEnabled",
    }),
    ...mapState("integrations", {
      actualIntegrations: (state) => state.integrations,
    }),
    integrations() {
      const activeActualIntegrationNames = this.activeActualIntegrationNames();
      const integrationNames = INTEGRATION_NAMES_SUPPORTING_IMPORT_TYPES[
        this.importType
      ].filter((name) => activeActualIntegrationNames.includes(name));

      const integrations = integrationNames.map((name) => {
        const isExcel = name === this.$constant.EXCEL;
        const actualIntegration = this.findActualIntegration(name);
        const isEnabled = isExcel || this.isIntegrationEnabled(name);
        const canIntegrate =
          isExcel || this.planRestrictionsMixins_canIntegrateByIntegrationName(name);
        const requireExternalAccountId =
          this.isRequireExternalAccountIntegration(name) &&
          !this.hasExternalAccountId(actualIntegration);

        return {
          name,
          isEnabled,
          canIntegrate,
          requireExternalAccountId,
          actualIntegration,
          requiredPlanCode: this.planRestrictionsMixins_planCodeByIntegrationName(name),
        };
      });

      const enabledIntegrations = integrations.filter((i) => i.isEnabled);
      const someActualIntegration = enabledIntegrations.some((i) => i.actualIntegration);

      if (enabledIntegrations.length > 0 && someActualIntegration) {
        return enabledIntegrations;
      }

      return integrations;
    },
    isImport() {
      return this.state === this.$constant.IMPORT_STATES.IMPORT;
    },
    isSelectingFile() {
      return this.state === this.$constant.IMPORT_STATES.SELECTING_FILE;
    },
    isLoading() {
      return this.state === this.$constant.IMPORT_STATES.LOADING;
    },
    isImportSuccess() {
      return this.state === this.$constant.IMPORT_STATES.IMPORT_SUCCESS;
    },
    isImportError() {
      return this.state === this.$constant.IMPORT_STATES.IMPORT_ERROR;
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    async getIntegrations(load) {
      await Promise.all([this.$store.dispatch("integrations/getIntegrations", load)]);
    },
    isRequireExternalAccountIntegration(integrationName) {
      return EXTERNAL_ACCOUNT_REQUIRED_INTEGRATIONS.includes(integrationName);
    },
    hasExternalAccountId(actualIntegration) {
      return actualIntegration?.integrationInstance?.settings?.externalAccountId;
    },
    findActualIntegration(integrationName) {
      return this.actualIntegrations.find((i) => {
        return i.integrationInstance?.active && i.name === integrationName;
      });
    },
    activeActualIntegrationNames() {
      const activeIntegrations = this.actualIntegrations.filter((i) => i.active) || [];
      return [...activeIntegrations.map((i) => i.name), this.$constant.EXCEL];
    },
    onAppStateSelectingFileBack() {
      this.updateState(this.$constant.IMPORT_STATES.IMPORT);
    },
    onTryAgain() {
      this.updateState(this.$constant.IMPORT_STATES.IMPORT);
    },
    onImport(selectedIntegrationName) {
      if (!selectedIntegrationName) return;
      if (selectedIntegrationName === this.$constant.EXCEL) {
        this.updateState(this.$constant.IMPORT_STATES.SELECTING_FILE);
        return;
      }
      this.accountingImport(selectedIntegrationName);
    },
    onExcelImport(data) {
      this.updateState(this.$constant.IMPORT_STATES.LOADING);
      importProducts(data)
        .then(({ path, products }) => {
          if (path) {
            this.reportPath = path;
            this.updateState(this.$constant.IMPORT_STATES.IMPORT_ERROR);
          } else {
            this.updateState(this.$constant.IMPORT_STATES.IMPORT_SUCCESS);
            this.$emit("import:success");
          }
        })
        .catch((err) => {
          this.updateState(this.$constant.IMPORT_STATES.IMPORT_ERROR);
        });
    },
    accountingImport(accounting) {
      this.updateState(this.$constant.IMPORT_STATES.LOADING);
      importAccounting({
        accounting,
        importType: this.importType,
        excludeClosed: this.excludeClosed,
      })
        .then((res) => {
          this.updateState(this.$constant.IMPORT_STATES.IMPORT_SUCCESS);
          this.$emit("import:success");
        })
        .catch((err) => {
          this.updateState(this.$constant.IMPORT_STATES.IMPORT_ERROR, err);
          this.$emit("import:error");
        });
    },
    updateState(state, error = null) {
      this.state = state;
      this.error = error;
    },
    close() {
      this.reportPath = null;
      this.localDialog = false;
      setTimeout(() => {
        this.updateState(this.$constant.IMPORT_STATES.IMPORT);
      }, 150);
    },
  },
};
</script>
