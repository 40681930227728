<script>
import { VIcon } from "vuetify/lib";

export default {
  props: {
    item: Object,
  },
  methods: {
    genTitle() {
      return this.$createElement("div", { class: "d-flex align-center" }, [
        [
          this.$createElement(
            "div",
            { class: "d-flex align-center justify-center", style: "min-width: 32px" },
            [this.$createElement(VIcon, this.$icons.REGULAR.ACTION.FORM_SECTION)],
          ),
          this.$createElement(
            "div",
            { class: "pl-3 font-weight-medium text-h6 w-100" },
            this.item.name,
          ),
        ],
      ]);
    },
  },
  render(h) {
    return h("div", { class: "d-flex align-center" }, [this.genTitle()]);
  },
};
</script>
