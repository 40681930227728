<template>
  <div
    class="d-flex flex-column align-center justify-center text-center pa-2 pt-4 px-6 h-100 overflow-y-auto"
  >
    <img style="min-width: 100px" :src="image" />
    <div class="text-h6 font-weight-bold pt-2 mb-4">{{ title }}</div>
    <div class="font-weight-medium mb-8">{{ subTitle }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
    image: String,
  },
};
</script>
