<template>
  <div
    class="h-100 d-flex justify-center align-center"
    @click="showMarkerArea"
    :class="localCanEditFile ? 'cursor-pointer' : ''"
  >
    <AppCenterLoader
      v-if="isLoading"
      show
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />
    <img
      v-show="!isLoading"
      ref="imgRef"
      :src="src"
      style="max-width: 100%; max-height: 100%; object-fit: contain"
      crossorigin="anonymous"
      @load="onImageLoad"
    />
  </div>
</template>

<script>
import { MarkerArea } from "markerjs2";
export default {
  props: {
    fileId: String,
    src: String,
    mime: String,
    canEditFile: Boolean,
    isLinkedFile: Boolean,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    isGif() {
      return this.mime?.match("gif");
    },
    localCanEditFile() {
      return this.canEditFile && !this.isGif && this.src && this.fileId;
    },
  },
  methods: {
    onImageLoad() {
      this.isLoading = false;
    },
    showMarkerArea() {
      if (!this.localCanEditFile) return;
      if (this.isLinkedFile) {
        this.$store.dispatch("snackbar/snackbar", {
          show: true,
          text: this.$t("fileManagement.linkedFile.cantEditImageWarning"),
          color: "info",
          timeout: 7000,
        });
        return;
      }
      const contentType = this.mime || "image/jpeg";
      const markerArea = new MarkerArea(this.$refs.imgRef);
      markerArea.addEventListener("render", (event) => {
        this.$refs.imgRef.src = event.dataUrl;
        this.replaceFile(event.dataUrl);
      });
      markerArea.settings.displayMode = "popup";
      markerArea.renderImageType = contentType;
      markerArea.renderAtNaturalSize = true;
      markerArea.show();
    },
    async replaceFile(dataUrl) {
      const file = await this.url2File(dataUrl, "name");
      const formData = new FormData();
      formData.append("file", file);

      await this.$store.dispatch("storageDetails/replaceFile", {
        id: this.fileId,
        formData,
      });
      this.$emit("preview:update", { fileId: this.fileId });
    },

    async url2File(url, fileName) {
      const blob = await (await fetch(url)).blob();
      return new File([blob], fileName);
    },
  },
};
</script>
