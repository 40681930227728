<template>
  <div style="height: calc(100% - 68px)" class="overflow-y-auto">
    <div class="d-flex flex-column pa-4">
      <div class="mb-4">
        <div class="mb-1 d-flex">
          <div class="font-weight-bold body-2">
            {{ $t("fileManagement.preview.navMenu.readConfirmation.COMPLETED.sentOn") }}
          </div>
          <v-spacer />

          <AppColorChip
            style="max-height: 24px"
            :text="completedChipText()"
            color="success--text"
          />
        </div>
        <div>
          {{ createdDate }}
        </div>
      </div>

      <div class="mb-4">
        <div class="mb-1 font-weight-bold body-2">
          {{
            $t("fileManagement.preview.navMenu.readConfirmation.COMPLETED.completedOn")
          }}
        </div>
        <div>
          {{ completedAt }}
        </div>
      </div>

      <div class="mb-4">
        <div class="mb-1 font-weight-bold body-2">
          {{ $t("fileManagement.preview.navMenu.readConfirmation.COMPLETED.sentBy") }}
        </div>
        <AppUserAvatar :user="sender" :truncate="30" show-name />
      </div>

      <div class="font-weight-bold body-2">
        {{ $t("fileManagement.preview.navMenu.readConfirmation.COMPLETED.recipients") }}
      </div>

      <div>
        <v-divider class="my-2" />
        <div v-for="(recipient, index) of recipients" :key="index">
          <div class="d-flex flex-column">
            <div class="mb-1">
              <div class="d-flex">
                <span class="text-caption">{{ $t("common.name") }}</span>
                <v-spacer />
                <AppColorChip
                  :text="recipientStatusText(recipient)"
                  color="success--text"
                />
              </div>
              <AppTextTruncateValue
                :disabled="recipient.name.length <= truncateLength"
                :text="recipient.name"
                :truncate="truncateLength"
              />
            </div>

            <div>
              <div class="d-flex">
                <span class="text-caption">{{ $t("common.email") }}</span>
              </div>
              <a
                class="underline-on-hover primary-link--text"
                :href="`mailto:${recipient.email}`"
                target="_blank"
              >
                <AppTextTruncateValue
                  :disabled="recipient.email.length <= truncateLength"
                  :text="recipient.email"
                  :truncate="truncateLength"
                />
              </a>
            </div>

            <div v-if="recipient.comment">
              <div class="d-flex">
                <span class="text-caption">{{ $t("common.comment") | capitalize }}</span>
              </div>
              <span>{{ recipient.comment }}</span>
            </div>
          </div>
          <v-divider class="my-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      truncateLength: 35,
    };
  },
  props: {
    actionRequest: Object,
  },
  computed: {
    sender() {
      return this.actionRequest?.user || null;
    },
    recipients() {
      return this.actionRequest?.recipients || [];
    },
    createdDate() {
      if (!this.actionRequest.createdAt) return "";
      return moment(this.actionRequest.createdAt).format("DD.MM.YYYY HH:mm");
    },
    completedAt() {
      if (!this.actionRequest.completedAt) return "";
      return moment(this.actionRequest.completedAt).format("DD.MM.YYYY HH:mm");
    },
  },
  methods: {
    completedChipText() {
      const count = `${this.recipients.length}/${this.recipients.length}`;
      const inProgressText = `${count} ${this.$t("actionRequest.status.COMPLETED")}`;

      return inProgressText;
    },
    recipientStatusText(recipient) {
      const date = recipient.completedAt
        ? moment(recipient.completedAt).format("DD.MM.YYYY HH:mm")
        : "";
      return `${this.$t("actionRequest.status.COMPLETED")} ${date}`;
    },
  },
};
</script>
