<script>
import { formTemplateBuilderMixins } from "@/helpers/mixins";
import SectionHeader from "./SectionHeader.vue";
import ItemHierarchy from "./ItemHierarchy.vue";
import { VCard } from "vuetify/lib";

export default {
  mixins: [formTemplateBuilderMixins],
  props: {
    template: Object,
    templateItems: Array,
    isLoading: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    genHeader() {
      return this.$createElement(
        VCard,
        {
          class: "border-a pa-8 mb-10",
          props: {
            flat: true,
            loading: this.isLoading,
            disabled: this.isLoading,
          },
        },

        [
          this.$createElement("div", [
            this.$createElement(
              "div",
              { class: "text-caption pb-1" },
              this.$t("cms.template.form.name"),
            ),
            this.$createElement("AppDefaultTextField", {
              props: {
                value: this.template?.name,
                disabled: true,
              },
            }),
          ]),
          this.$createElement("div", [
            this.$createElement(
              "div",
              { class: "text-caption pb-1" },
              this.$t("cms.template.form.nav.details.formCategory"),
            ),
            this.$createElement("AppDefaultTextField", {
              props: {
                value: this.$te(
                  `formManagement.category.${this.template?.category?.name}`,
                )
                  ? this.$t(`formManagement.category.${this.template?.category?.name}`)
                  : this.template?.category?.name,
                disabled: true,
              },
            }),
          ]),
          this.$createElement("div", [
            this.$createElement(
              "div",
              { class: "text-caption pb-1" },
              this.$t("cms.template.form.description"),
            ),
            this.$createElement("AppDefaultTextarea", {
              props: {
                value: this.template?.description,
                disabled: true,
                hideDetails: true,
              },
            }),
          ]),
        ],
      );
    },
    genSection(item, sectionIndex) {
      return this.$createElement(
        VCard,
        {
          class: "border-a",
          props: {
            flat: true,
          },
        },
        [
          this.$createElement("div", { class: "d-flex flex-column p-relative pa-4" }, [
            this.$createElement(SectionHeader, {
              props: { item },
            }),
            this.$createElement(ItemHierarchy, {
              props: { items: this.templateItems, item, sectionIndex },
            }),
          ]),
        ],
      );
    },
    genItemBuilder(items) {
      return items.map((item, index) => {
        const isLast = index === items.length - 1;
        return [
          this.genSection(item, index),
          this.$createElement("div", { class: isLast ? "" : "mb-12" }),
        ];
      });
    },
    genCard() {
      const rootItems = this.formTemplateBuilderMixins_getRootItems(this.templateItems);
      return this.$createElement("div", { class: "py-7" }, [
        this.genHeader(),
        this.genItemBuilder(rootItems),
      ]);
    },
  },
  render(h) {
    return this.genCard();
  },
};
</script>
