var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"list_tr list_tr-height",class:_vm.rowStateClasses,on:{"click":function($event){return _vm.select(!_vm.isSelected)},"contextmenu":_vm.dataTableItemMixins_onRightClick}},[_c('td',[_c('v-simple-checkbox',{attrs:{"color":"primary","value":_vm.isSelected},on:{"input":function($event){return _vm.select($event)}}})],1),(_vm.groupByMixins_showColumn(_vm.filtersMixins_dataFields.STATUS))?_c('td',[_c('AppCustomStatusIconBtn',{attrs:{"model":"form","statusId":_vm.statusId,"loading":_vm.form?.isUpdatingStatus,"disabled":_vm.form?.isUpdatingStatus},on:{"click":(e) => _vm.$emit('status:click', { ...e, items: [_vm.form] })}})],1):_vm._e(),_c('td',[_c('div',{staticClass:"table-cell-content__breakable-long-text"},[_c('span',{staticClass:"d-inline-block underline-on-hover cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.onFormFillOutClick()}}},[_vm._v(" "+_vm._s(_vm.form.name)+" ")])])]),(_vm.groupByMixins_showColumn(_vm.filtersMixins_dataFields.SIGNATURE_STATUS))?_c('td',[_c('AppFormSignatureSessionStatusChip',{attrs:{"signatureSession":_vm.form.signatureSession},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('signature:click', { form: _vm.form })}}})],1):_vm._e(),(_vm.groupByMixins_showColumn(_vm.filtersMixins_dataFields.PROGRESS))?_c('td',[_c('AppFormProgressionChip',{attrs:{"progress":_vm.form.progress}})],1):_vm._e(),(_vm.groupByMixins_showColumn(_vm.filtersMixins_dataFields.RESPONSIBLE))?_c('td',[(_vm.form.member)?[_c('AppUserAvatar',{attrs:{"user":_vm.form.member,"show-name":"","truncate":20}})]:_vm._e()],2):_vm._e(),(
      !_vm.isOnProjectLevel && _vm.groupByMixins_showColumn(_vm.filtersMixins_dataFields.PROJECT)
    )?_c('td',[(_vm.form?.project?.id)?_c('div',{staticClass:"table-cell-content__breakable-long-text"},[_c('AppActionText',{attrs:{"to":{
          name: _vm.$routeNames.PROJECT.VIEW,
          params: { projectId: _vm.form?.project?.id },
        }}},[_vm._v(" "+_vm._s(_vm.form.project.name)+" ")])],1):_vm._e()]):_vm._e(),(_vm.groupByMixins_showColumn(_vm.filtersMixins_dataFields.TASK))?_c('td',[(_vm.form?.task?.id)?_c('div',{staticClass:"table-cell-content__breakable-long-text"},[_c('AppActionText',{attrs:{"to":{
          name: _vm.$routeNames.TASK_VIEW.ROOT,
          params: { taskId: _vm.form?.task?.id },
        }}},[_vm._v(" "+_vm._s(_vm.taskName)+" ")])],1):_vm._e()]):_vm._e(),(_vm.groupByMixins_showColumn(_vm.filtersMixins_dataFields.CATEGORY))?_c('td',[(_vm.form.category)?_c('AppColorChip',{attrs:{"text":_vm.$te(`formManagement.category.${_vm.form.category.name}`)
          ? _vm.$t(`formManagement.category.${_vm.form.category.name}`)
          : _vm.form.category.name,"color":"info--text"}}):_vm._e()],1):_vm._e(),(_vm.groupByMixins_showColumn(_vm.filtersMixins_dataFields.DUE_DATE))?_c('td',[(_vm.form.dueDate)?[_c('div',[_vm._v(_vm._s(_vm.formatDueDate(_vm.form.dueDate)))])]:_vm._e()],2):_vm._e(),_c('td',[_c('v-btn',{ref:"ellipsis",attrs:{"icon":""},on:{"click":_vm.dataTableItemMixins_onEllipsisClick}},[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.REGULAR.ACTION.MENU))])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }