import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function createDomain(body) {
  return axios
    .post(`${apiUrl}cms/domain`, body)
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      throw error;
    });
}
