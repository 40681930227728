import { mapGetters, mapState } from "vuex";
import { canCreateSignableStorage, canMergeFile, isESignFile } from "@/helpers/util";

export const storageActionMixins = {
  computed: {
    ...mapGetters("activeIntegrations", {
      isIntegrationEnabled: "isIntegrationEnabled",
    }),
    ...mapGetters("auth", {
      domainId: "activeDomainId",
      isCmsAdmin: "isCmsAdmin",
    }),
    ...mapState("project", {
      project: (state) => state.project,
    }),
    ...mapState("storage", {
      isDownloading: (state) => state.isDownloading,
      isDeleting: (state) => state.isDeleting,
    }),
  },
  methods: {
    storageActionMixins_isESignFile({ items }) {
      return items.length === 1 && isESignFile(items[0]);
    },
    storageActionMixins_isFolderSelected({ items }) {
      return items.some((s) => s?.type === this.$constant.FOLDER);
    },
    storageActionMixins_isOnlyFoldersSelected({ items }) {
      return items.every((s) => s?.type === this.$constant.FOLDER);
    },
    storageActionMixins_isLockedSelected({ items }) {
      return items.some((s) => s?.isLocked);
    },
    storageActionMixins_isLinkedFileSelected({ items }) {
      return items.some((s) => s?.type === this.$constant.FILE_RELATION);
    },
    storageActionMixins_isSomeSelected({ items }) {
      return items.length > 0;
    },
    storageActionMixins_isMultipleSelected({ items }) {
      return items.length > 1;
    },
    storageActionMixins_isMultiplePermissionLevelsSelected({ items }) {
      const permissionLevels = items.map((s) => s?.permissionLevel);
      return !permissionLevels.every((level) => level === permissionLevels[0]);
    },
    storageActionMixins_isAllFilesAllowedToMerge({ items }) {
      return items.every((file) => canMergeFile(file));
    },
    storageActionMixins_showOpenWith({ items }) {
      return (
        !this.storageActionMixins_isFolderSelected({ items }) &&
        !this.storageActionMixins_isMultipleSelected({ items })
      );
    },
    storageActionMixins_showDownloadWithoutConvertedMergeWords({ items }) {
      return (
        this.isCmsAdmin &&
        items.some((s) =>
          this.$constant.DOWNLOAD_WITHOUT_CONVERTED_MERGE_WORDS_MIMETYPES.includes(
            s.mime,
          ),
        )
      );
    },
    storageActionMixins_canRename({ items }) {
      return (
        !this.storageActionMixins_isMultipleSelected({ items }) &&
        !this.storageActionMixins_isLinkedFileSelected({ items })
      );
    },
    storageActionMixins_canDownload() {
      return !this.isDownloading;
    },
    storageActionMixins_canMove({ items }) {
      return (
        !this.storageActionMixins_isMultiplePermissionLevelsSelected({ items }) &&
        !this.storageActionMixins_isLockedSelected({ items })
      );
    },
    storageActionMixins_canCopy({ items }) {
      return !this.storageActionMixins_isFolderSelected({ items });
    },
    storageActionMixins_canLink({ items }) {
      return (
        !this.storageActionMixins_isFolderSelected({ items }) &&
        !this.storageActionMixins_isLinkedFileSelected({ items }) &&
        !this.storageActionMixins_isLockedSelected({ items })
      );
    },
    storageActionMixins_canMerge({ items }) {
      return (
        !this.storageActionMixins_isFolderSelected({ items }) &&
        this.storageActionMixins_isAllFilesAllowedToMerge({ items })
      );
    },
    storageActionMixins_canCreateSignableStorage({ items }) {
      if (
        this.storageActionMixins_isFolderSelected({ items }) ||
        this.storageActionMixins_isMultipleSelected({ items })
      )
        return false;
      const file = items[0];
      return canCreateSignableStorage(file);
    },
    storageActionMixins_canRequestReadConfirmation({ items }) {
      return (
        !this.storageActionMixins_isFolderSelected({ items }) &&
        !this.storageActionMixins_isMultipleSelected({ items })
      );
    },
    storageActionMixins_canUploadToBoligmappa({ items }) {
      return !this.storageActionMixins_isFolderSelected({ items });
    },
    storageActionMixins_canDelete({ items }) {
      return (
        !this.storageActionMixins_isLockedSelected({ items }) &&
        this.storageActionMixins_isSomeSelected({ items }) &&
        !this.isDeleting
      );
    },
  },
};
