<template>
  <AppWidgetShortcutButton
    @click="navigateForms"
    @delete:click="onDelete"
    :templateProps="templateProps"
    :title="$t('dashboard.shortcutButton.formTemplatesTitle')"
    :description="$t('dashboard.shortcutButton.formTemplatesDescription')"
    :icon="$icons.LIGHT.COMMON.MEMO_CIRCLE_CHECK"
  />
</template>

<script>
export default {
  props: {
    templateProps: Object,
  },
  methods: {
    navigateForms() {
      this.$router.replace({
        name: this.$routeNames.TEMPLATE.FORM_TEMPLATES,
        query: { openTemplatePicker: true },
      });
    },
    onDelete() {
      this.$emit("delete:click");
    },
  },
};
</script>
