import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getDefaultRepeatOptions({ model }) {
  return axios
    .get(`${apiUrl}repeat/default`, { params: { model } })
    .then(({ data }) => {
      const repeatOption = data?.repeatOption;
      return repeatOption;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
