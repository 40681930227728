<template>
  <div class="vertical_nav_bar border-t border-l primary-box-shadow">
    <slot name="prepend" :items="items"></slot>
    <slot name="items" :items="items">
      <v-list nav dense class="px-4">
        <AppDefaultTooltip
          v-for="(item, i) of items"
          :key="i"
          :left="item.tooltipDirection === 'left'"
          :right="item.tooltipDirection === 'right'"
          :top="item.tooltipDirection === 'top'"
          :bottom="item.tooltipDirection === 'bottom'"
          :disabled="!item.tooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              v-if="route"
              class="d-flex align-center justify-center"
              link
              v-bind="attrs"
              v-on="on"
              :to="routerMixins_navigateToToggle(item.to, item.from)"
              replace
            >
              <template v-slot>
                <v-icon>{{ item.icon }}</v-icon>
              </template>
            </v-list-item>
            <v-list-item
              class="d-flex align-center justify-center"
              link
              :class="activeKey === item.key ? 'v-list-item--active' : ''"
              v-bind="attrs"
              v-on="on"
              @click="onItemClick(item)"
              v-else
            >
              <template v-slot>
                <v-icon>{{ item.icon }}</v-icon>
              </template>
            </v-list-item>
          </template>
          <span>{{ item.tooltip }}</span>
        </AppDefaultTooltip>
      </v-list>
    </slot>
    <slot name="append" :items="items"> </slot>
  </div>
</template>

<script>
import { routerMixins } from "../../helpers/mixins";
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    route: {
      type: Boolean,
      default: true,
    },
    activeKey: String,
  },
  name: "AppVerticalNavBar",
  methods: {
    onItemClick(item) {
      this.$emit("item:click", { item });
    },
  },
  mixins: [routerMixins],
};
</script>
