import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import i18n from "@/plugins/i18n";
const categoriesTranslation = i18n.t("cms.template.document.category");

const state = () => ({
  isLoading: false,
  items: [],
});

const getters = {};

const actions = {
  async getItems({ commit }, { showGroup = true } = {}) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}cms/category`, { params: { model: "storage" } })
      .then(({ data }) => {
        const categories = data?.categories;
        let normalized = (categories || []).map((item) => {
          const name = item.name;
          const translatedName = categoriesTranslation[name?.toLowerCase()];
          const text = `${translatedName || name}${showGroup ? ` (${item.group})` : ""}`;
          return {
            ...item,
            value: name,
            text,
          };
        });

        normalized.push({
          id: "uncategorized",
          name: "uncategorized",
          text: categoriesTranslation.uncategorized,
        });

        commit("setItems", normalized);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setItems(state, items) {
    state.items = items;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const cmsTemplateCategory = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
