<template>
  <v-dialog v-model="localDialog" max-width="500px" scrollable>
    <v-card>
      <v-progress-linear
        height="6"
        :active="isLoading"
        :indeterminate="isLoading"
        top
        absolute
      />
      <template v-if="state === 'BEFORE_LINK'">
        <v-card-title>
          <span class="headline">{{ $t("fileManagement.linkedFile.title") }}</span>
          <v-icon size="22" class="pl-3">{{ $icons.LIGHT.FILE.FILE_LINKED }}</v-icon>
          <v-spacer></v-spacer>
          <AppDefaultTooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small class="pl-2 cursor-pointer">
                {{ $icons.LIGHT.COMMON.CIRCLE_QUESTION }}
              </v-icon>
            </template>
            <span
              style="font-size: 13px"
              v-html="$t('fileManagement.linkedFile.tooltipLong')"
            ></span>
          </AppDefaultTooltip>
        </v-card-title>
        <v-card-text>
          <AppDefaultTextField
            ref="search"
            class="pb-3"
            :label="$t('common.search')"
            :append-icon="$icons.LIGHT.ACTION.SEARCH"
            v-model="search"
            hide-details
            autofocus
            clearable
          />
          <AppFolderPath
            :prependPath="storagePrependPath"
            :truncatedPath="storageTruncatedPath"
            @item:click="onPathItemClick"
          />
        </v-card-text>
        <v-divider />
        <v-card-text class="overflow-y-auto" style="height: 450px">
          <template v-if="!isLoading">
            <template v-if="filteredFolders.length > 0">
              <div
                v-for="folder of filteredFolders"
                :key="folder.id"
                @click="onToggleItem(folder)"
              >
                <AppStorageItem :item="folder" :isSelected="isSelected(folder)">
                  <template v-slot:prepend="{ item }">
                    <div class="h-100 d-flex align-center">
                      <v-checkbox
                        dense
                        hide-details
                        click.stop
                        class="ma-0 pa-0"
                        v-model="selected"
                        :value="item.id"
                        @click.stop
                        :disabled="isParentOfSelectedFile(item)"
                      />
                    </div>
                  </template>
                  <template v-slot:label="{ item, translation }">
                    <div class="h-100 d-flex align-center pl-3">
                      <span
                        class="text--primary underline-on-hover cursor-pointer"
                        @click="onFolderClick(item)"
                      >
                        {{ translation || item.name }}
                      </span>
                    </div>
                  </template>
                  <template v-slot:append="{ item }">
                    <div class="h-100 d-flex align-center">
                      <v-btn icon @click="onFolderClick(item)">
                        <v-icon>{{ $icons.LIGHT.COMMON.CHEVRON_RIGHT }}</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </AppStorageItem>
              </div>
            </template>
            <template v-else>
              <v-row
                class="fill-height w-100 h-100 ma-0"
                justify="center"
                align-content="center"
              >
                <v-col class="subtitle-1 text-center pb-0" cols="12">
                  <h2>{{ $t("fileManagement.linkedFile.folderEmpty.title") }}</h2>
                </v-col>
                <v-col class="subtitle-1 text-center" cols="12">
                  {{ $t("fileManagement.linkedFile.folderEmpty.subTitle") }}
                </v-col>
              </v-row>
            </template>
          </template>
        </v-card-text>

        <v-spacer />
        <v-divider />
        <v-card-actions>
          <AppDialogActionBtnPair
            @confirm="onSave"
            @cancel="onCancel"
            :confirmText="$t('common.save')"
            :disabled="!canSave"
            :loading="isLinking"
          />
          <v-spacer />
          <NumSelectedBulk
            :selectedCount="selected.length"
            top
            :text="$tc('fileManagement.linkedFile.toLink', selected.length)"
            @click="selected = []"
          />
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-text class="d-flex flex-column align-center">
          <v-img
            class="mt-15 mb-10"
            contain
            max-height="250"
            max-width="270"
            src="https://go-digital.azureedge.net/assets/images/success.png"
          />
          <h3 class="mb-10">{{ $t("fileManagement.linkedFile.success.title") }}</h3>
          <v-alert text outlined type="warning" v-if="rejected > 0">
            {{ $tc("fileManagement.linkedFile.success.rejected", rejected) }}
          </v-alert>
        </v-card-text>
        <v-spacer />
        <v-card-actions class="d-flex flex-column align-center">
          <v-btn text @click="onCancel" color="primary-link">
            {{ $t("common.close") }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import NumSelectedBulk from "@/components/common/menus/bulkActionMenu/NumSelectedBulk.vue";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    NumSelectedBulk,
  },
  data() {
    return {
      selected: [],
      state: "BEFORE_LINK",
      rejected: 0,
      search: null,
    };
  },
  props: {
    dialog: Boolean,
    files: {
      type: Array,
      default: () => [],
    },
    parentId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  watch: {
    dialog: {
      handler(value) {
        if (value) {
          this.onPathNavigate({ id: this.parentId });
        }
      },
    },
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapState("linkableFolders", {
      path: (state) => state.path,
      folders: (state) => state.items,
      isLinking: (state) => state.isLinking,
      storagePrependPath: (state) => state.prependPath,
      storageTruncatedPath: (state) => state.truncatedPath,
    }),
    ...mapGetters("linkableFolders", {
      isLoading: "isLoading",
    }),
    selectedIds() {
      return this.selected.map((x) => x.id);
    },
    canSave() {
      return this.selected.length > 0 ? true : false;
    },
    filteredFolders() {
      return this.folders.filter((folder) => {
        const name = folder.name.toLowerCase();
        const search = this.search ? this.search.toLowerCase() : "";
        return name.includes(search);
      });
    },
  },
  methods: {
    isSelected(item) {
      return this.selected.includes(item.id);
    },
    onToggleItem(item) {
      this.selected = this.selected.includes(item.id)
        ? this.selected.filter((x) => x !== item.id)
        : [...this.selected, item.id];
    },
    getFolders(id) {
      this.$store.dispatch("linkableFolders/getFolders", id);
    },
    getPath(id) {
      this.$store.dispatch("linkableFolders/getPath", id);
    },
    onFolderClick(item) {
      this.onPathNavigate(item);
    },
    onPathItemClick(item) {
      this.onPathNavigate(item);
    },
    onPathNavigate(item) {
      this.search = null;
      this.getFolders(item.id);
      this.getPath(item.id);

      if (this.$refs.search && !this.$refs.search.isFocused) {
        this.$refs.search.focus();
      }
    },
    isSelected(item) {
      return this.selected.find((x) => x === item.id) ? true : false;
    },
    isParentOfSelectedFile(folder) {
      return this.files.find((file) => file.parentId === folder.id) ? true : false;
    },
    onCancel() {
      this.localDialog = false;
      this.state = "BEFORE_LINK";
      this.$store.commit("linkableFolders/resetPaths");
    },
    async onSave() {
      const response = await this.$store.dispatch("linkableFolders/linkFilesToFolders", {
        files: this.files.map((file) => file.id),
        folders: this.selected,
      });
      if (response) {
        this.selected = [];
        this.rejected = response.rejected;
        this.state = "AFTER_LINK";
        this.$emit("after:save");
      }
    },
  },
};
</script>
