export function normalizeCmsDocumentTemplateHistory(data) {
  let items = [];

  for (const item of data.results) {
    for (const action of item.action) {
      items.push({
        ...item,
        action: [action],
        id: item.id + "_" + action,
      });
    }
  }

  return items;
}
