<template>
  <v-dialog v-model="localDialog" max-width="1000px" min-height="800px" scrollable>
    <v-card>
      <v-card-title>
        {{ $t("fileManagement.header.title") }}
      </v-card-title>
      <v-card-text>
        <AppStorageManager
          ref="storageManager"
          storeNamespace="storageDialog"
          :fileId="fileId"
          :rootId="computedRootId"
          :folderId="computedFolderId"
          :folderRouterName="folderRouterName"
          :contextMenuItemsToHide="contextMenuItemsToHide"
          :storageTypesNotAllowedToSelect="storageTypesNotAllowedToSelect"
          @navigateToFolder="navigateToFolder"
          @previewFile="previewFile"
          @input="onSelectedItemsChange"
          dataTableHeight="60vh"
        />
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair @confirm="onConfirm" @cancel="onClose" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { FILE_RELATION, FOLDER } from "@/helpers/constants";
import { mapGetters } from "vuex";

export default {
  props: {
    dialog: Boolean,
  },
  model: {
    prop: "dialog",
    event: "change",
  },
  data() {
    return {
      folderRouterName: this.$routeNames.STORAGE.ALL_FILES,
      contextMenuItemsToHide: {
        link: true,
        merge: true,
        signableDocument: true,
        requestReadConfirmation: true,
      },
      storageTypesNotAllowedToSelect: [FOLDER, FILE_RELATION],
      fileId: null,
      rootId: null,
      folderId: null,
      selectedItems: [],
      //requires some rewrite and isnt even used in this dialog.
      //Params needs to be passed deep into where its used instead of the components relying on query params
      // activePreviewMenu: null,
      // openAddRecipients: null,
    };
  },
  computed: {
    computedFolderId() {
      return this.folderId || this.activeDomainId;
    },
    computedRootId() {
      return this.rootId || this.activeDomainId;
    },
    ...mapGetters("auth", {
      activeDomainId: "activeDomainId",
    }),
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
  watch: {
    dialog(value) {
      if (value) {
        this.$nextTick(() => {
          this.initializeStorageManager();
        });
      }
    },
  },
  methods: {
    onSelectedItemsChange(items) {
      this.selectedItems = items;
    },
    onConfirm() {
      this.$emit("confirm", this.selectedItems);
      this.localDialog = false;
      this.clearSelection();
    },
    clearSelection() {
      this.$refs.storageManager.clearSelection();
    },
    onClose() {
      this.localDialog = false;
    },
    initializeStorageManager() {
      this.$refs.storageManager.initializeStorageManager();
    },
    navigateToFolder({ id, rootId }) {
      this.folderId = id;
      this.rootId = rootId;
    },
    previewFile({ fileId, activeMenu, openAddRecipients }) {
      this.fileId = fileId;
      // this.activePreviewMenu = activeMenu;
      // this.openAddRecipients = openAddRecipients;
    },
  },
};
</script>
