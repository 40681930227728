import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  profile: null,
  isLoading: false,
  isSaving: false,
});

const getters = {
  profileMeta: state => {
    return state.profile?.meta || [];
  },
  metaValueByField: (state, getters) => field => {
    return getters.profileMeta.find(meta => meta.field === field)?.value || "";
  },
};

const actions = {
  async getProfile({ commit }) {
    commit("profileLoading", true);
    return axios
      .get(`${apiUrl}profile`)
      .then(res => {
        const profile = res.data ? res.data.profile : null;
        commit("setProfile", profile);
        commit("profileLoading", false);
        return profile;
      })
      .catch(error => {
        commit("profileLoading", false);
        throw new Error(error);
      });
  },
  async updateProfile({ commit }, data) {
    commit("savingProfile", true);
    return axios
      .put(`${apiUrl}profile`, data)
      .then(res => {
        const profile = res.data ? res.data.profile : null;
        commit("setProfile", profile);
        commit("savingProfile", false);
        return profile;
      })
      .catch(error => {
        commit("savingProfile", false);
        throw new Error(error);
      });
  },
  async uploadAvatar({ commit }, { formData }) {
    return axios
      .post(`${apiUrl}profile/avatar`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(res => {})
      .catch(error => {
        throw new Error(error);
      });
  },
  async getAvatar({ commit }) {
    return axios
      .get(`${apiUrl}profile/avatar`, { responseType: "blob" })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  async deleteAvatar({ commit }) {
    return axios
      .delete(`${apiUrl}profile/avatar`)
      .then(res => {})
      .catch(error => {
        throw new Error(error);
      });
  },
};

const mutations = {
  setProfile(state, profile) {
    state.profile = profile;
  },
  profileLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  savingProfile(state, isSaving) {
    state.isSaving = isSaving;
  },
};

export const profile = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
