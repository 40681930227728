export const linkTextMixins = {
  methods: {
    linkTextMixins_formattedLinkText(text) {
      const regex = /(?:https?|ftp):\/\/[\n\S]+/g;
      return text.replace(regex, (match) => {
        return `<a class="underline-on-hover" href="${match}" target="_blank">${match}</a>`;
      });
    },
  },
};
