<template>
  <AppDefaultTooltip
    :top="top"
    :left="left"
    :right="right"
    :bottom="bottom"
    v-if="selectedCount > 0"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        style="border: 1px solid #d1d2d2 !important"
        v-on="on"
        v-bind="attrs"
        depressed
        small
        @click="$emit('click')"
        color="ui-background-black"
        class="text-transform-none dark-background-text--text"
      >
        <span class="mr-2">{{
          text || $t("common.numSelected", { n: selectedCount })
        }}</span>
        <v-icon small>{{ $icons.REGULAR.ACTION.CLOSE }}</v-icon>
      </v-btn>
    </template>
    <span class="break-word">
      {{ $t("common.clearSelection") }}
      <!-- <AppKbd dark class="ml-2" small text="Esc" /> -->
    </span>
  </AppDefaultTooltip>
</template>

<script>
export default {
  props: {
    text: String,
    selectedCount: Number,
    top: Boolean,
    left: Boolean,
    right: Boolean,
    bottom: Boolean,
  },
};
</script>
