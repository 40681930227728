import axios from "axios";
import { base64ToBlobObjectUrl } from "../../../helpers/util";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  item: null,
  view: null,
  isLoading: true,
  isValid: false,
});

const getters = {
  name: (state) => {
    return state.item ? state.item.name : "";
  },
};

const actions = {
  async getFile({ commit }, hash) {
    commit("loading", true);
    commit("valid", false);

    return axios
      .get(`${apiUrl}storage/file-share/${hash}`)
      .then(async (res) => {
        if (res && Object.prototype.hasOwnProperty.call(res, "data")) {
          if (
            res.data.view.contentType.match("application.pdf") ||
            res.data.view.contentType.match("image/*")
          ) {
            res.data.view.content = await base64ToBlobObjectUrl(
              res.data.view.content,
              res.data.view.contentType,
            );

            commit("setItem", res.data.storage);
            commit("setView", res.data.view);
            commit("loading", false);
            commit("valid", true);
          } else {
            commit("setItem", res.data.storage);
            commit("valid", false);
            commit("loading", false);
            commit("setView", null);
          }
        }
      })
      .catch((error) => {
        commit("valid", false);
        commit("loading", false);
        commit("setView", null);
        throw new Error(error);
      });
  },
};

const mutations = {
  setItem(state, item) {
    state.item = item;
  },
  setView(state, view) {
    state.view = view;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  valid(state, isValid) {
    state.isValid = isValid;
  },
};

export const storageSharing = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
