const AUTH = {
  ROOT: "auth",
  LOGIN: "login",
  SIGNUP: "signup",
};

const CMS = {
  ROOT: "cms",
  TEMPLATES: "cmsTemplates",

  //Document templates
  TEMPLATE_VIEW: "cmsTemplateView",
  TEMPLATE_DETAILS: "cmsTemplateDetails",
  TEMPLATE_CATEGORY: "cmsTemplateCategory",
  TEMPLATE_STATUS: "cmsTemplateStatus",
  TEMPLATE_HISTORY: "cmsTemplateHistory",

  //Project templates
  PROJECT_TEMPLATES: "cmsProjectTemplates",
  PROJECT_TEMPLATE_BUILDER: "cmsProjectTemplateBuilder",
  PROJECT_TEMPLATE_BUILDER_DETAILS: "cmsProjectTemplateBuilderDetails",
  PROJECT_TEMPLATE_BUILDER_HISTORY: "cmsProjectTemplateBuilderHistory",

  //Form templates
  FORM_TEMPLATES: "cmsFormTemplates",
  FORM_TEMPLATE_BUILDER: "cmsFormTemplateBuilder",
  FORM_TEMPLATE_BUILDER_DETAILS: "cmsFormTemplateBuilderDetails",
  FORM_TEMPLATE_BUILDER_TAGS: "cmsFormTemplateBuilderTags",
  FORM_TEMPLATE_BUILDER_HISTORY: "cmsFormTemplateBuilderHistory",
};

const CONTACT = {
  ROOT: "contact",
  CONTACT_PERSON_LIST: "contactPersonList",
  CLIENT_LIST: "clientList",
};

const DASHBOARD = {
  ROOT: "dashboard",
};

const EMPLOYEE = {
  ROOT: "employee",
  USER_LIST: "userList",
  CONTACT_USER_LIST: "contactUserList",
  TEAM_LIST: "teamList",
};

const EXTERNAL = {
  SHARED_STORAGE_VIEW: "sharedStorageView",
  SHARED_DOCUMENT_READ_CONFIRMATION_VIEW: "sharedDocumentReadConfirmationView",
  SHARED_FORM_SIGN_VIEW: "sharedFormSignView",
};

const FORM = {
  ROOT: "form",
  LIST: {
    ROOT: "formList",
    MENU: {
      ROOT: "formMenu",
      DETAILS: "formMenuDetails",
      COMMENTS: "formMenuComments",
      STORAGE: "formMenuStorage",
      HISTORY: "formMenuHistory",
      SIGNATURE: "formMenuSignature",
    },
  },
};

const FORM_VIEW = {
  ROOT: "formView",

  COMPLETION: {
    ROOT: "formCompletion",
    DETAILS: "formCompletionDetails",
    COMMENTS: "formCompletionComments",
    STORAGE: "formCompletionStorage",
    HISTORY: "formCompletionHistory",
    SIGNATURE: "formCompletionSignature",
  },

  PREVIEW: {
    ROOT: "formPreview",
    DETAILS: "formPreviewDetails",
    COMMENTS: "formPreviewComments",
    STORAGE: "formPreviewStorage",
    HISTORY: "formPreviewHistory",
    SIGNATURE: "formPreviewSignature",
  },
};

const GET_STARTED = {
  LANDING: "getStartedLanding",
  NEW_WORKSPACE: "getStartedNewWorkspace",
};

const OFFICE_ONLINE = {
  ROOT: "officeOnline",
};

const PROJECT = {
  ROOT: "project",
  LIST: "projectList",
  CALENDAR: "projectCalendar",
  VIEW: "projectView",
  INFO: "projectInfo",
  INFO_DETAILS: "projectInfoDetails",

  TASK: {
    ROOT: "projectViewTask",
    LIST: {
      ROOT: "projectTaskList",
      MENU: {
        ROOT: "projectTaskListMenu",
        DETAILS: "projectTaskListMenuDetails",
        HISTORY: "projectTaskListMenuHistory",
        TO_DO_LIST: "projectTaskListMenuToDoList",
        STORAGE: "projectTaskListMenuStorage",
        FORMS: "projectTaskListMenuForms",
        PRODUCT_USAGE: "projectTaskListMenuProductUsage",
        COMMENTS: "projectTaskListMenuComments",
      },
    },

    CALENDAR: {
      ROOT: "projectTaskCalendar",
      MENU: {
        ROOT: "projectTaskCalendarMenu",
        DETAILS: "projectTaskCalendarMenuDetails",
        HISTORY: "projectTaskCalendarMenuHistory",
        TO_DO_LIST: "projectTaskCalendarMenuToDoList",
        STORAGE: "projectTaskCalendarMenuStorage",
        FORMS: "projectTaskCalendarMenuForms",
        PRODUCT_USAGE: "projectTaskCalendarMenuProductUsage",
        COMMENTS: "projectTaskCalendarMenuComments",
      },
    },
  },

  //Storage
  STORAGE: "projectStorage",
  ALL_FILES: "projectStorageAllFiles",

  //Members
  VIEW_MEMBERS: "projectViewMembers",
  MEMBERS_EMPLOYEE_LIST: "projectMembersEmployeeList",
  MEMBERS_CONTACT_LIST: "projectMembersContactList",

  //Contacts
  VIEW_CONTACTS: "projectViewContacts",
  CONTACT_PERSON_LIST: "projectContactPersonList",
  CLIENT_LIST: "projectClientList",

  VIEW_COMMENTS: "projectViewComments",

  FORM: {
    ROOT: "projectViewForm",
    LIST: {
      ROOT: "projectFormList",
      MENU: {
        ROOT: "projectFormListMenu",
        DETAILS: "projectFormListMenuDetails",
        COMMENTS: "projectFormListMenuComments",
        STORAGE: "projectFormListMenuStorage",
        HISTORY: "projectFormListMenuHistory",
        SIGNATURE: "projectFormListMenuSignature",
      },
    },
  },

  PRODUCTS: "projectProducts",
  E_BYGGESOK: "projectEByggesok",

  //Sub projects
  SUB_PROJECTS: "projectSubProjects",
  SUB_PROJECTS_LIST: "projectSubProjectsList",
  SUB_PROJECTS_CALENDAR: "projectSubProjectsCalendar",

  //time entries
  TIME_REGISTRATION: "projectTimeRegistration",
  TIME_REGISTRATION_ALL_HOURS: "projectTimeRegistrationAllHours",
};

const REFERRAL = {
  ROOT: "referral",
};

const SETTINGS = {
  ROOT: "settings",

  //Profile
  PROFILE: "settingsProfile",
  PROFILE_DETAILS: "profileDetails",
  PROFILE_PERSONALIZATION: "profilePersonalization",
  PROFILE_PRIVACY: "profilePrivacy",
  WORKSPACES: "settingsWorkspaces",
  NOTIFICATIONS: "settingsNotifications",
  EMAIL_NOTIFICATIONS: "emailNotifications",
  PUSH_NOTIFICATIONS: "pushNotifications",

  //Account
  ACCOUNT: "settingsAccount",
  ACCOUNT_DETAILS: "accountDetails",
  ACCOUNT_REFERRAL: "accountReferral",
  ACCOUNT_SECURITY: "accountSecurity",

  PRODUCTS: "settingsProducts",
  PRODUCTS_LIST: "settingsProductsList",
  PRODUCT_SETTINGS: "settingsProductSettings",
  SYSTEM_SETTINGS: "settingsSystemSettings",
  TIME_ENTRY_SETTINGS: "timeEntrySettings",
  MODULE_CONTROL: "settingsModuleControl",
  INTEGRATIONS: "settingsIntegrations",
  SUPPORT_WORKSPACES: "settingsSupportWorkspaces",

  //Billing
  BILLING: "settingsBilling",
  BILLING_SUBSCRIPTION: "billingSubscription",
  BILLING_ADMIN: "billingAdmin",
  BILLING_USAGE: "billingUsage",
  BILLING_DETAILS: "billingDetails",
  BILLING_INVOICE_HISTORY: "billingInvoiceHistory",
};

const STORAGE = {
  ROOT: "storage",
  ALL_FILES: "storageAllFiles",
};

const TASK = {
  ROOT: "task",
  //Task list
  LIST: {
    ROOT: "taskList",
    MENU: {
      ROOT: "taskListMenu",
      DETAILS: "taskListMenuDetails",
      HISTORY: "taskListMenuHistory",
      TO_DO_LIST: "taskListMenuToDoList",
      STORAGE: "taskListMenuStorage",
      FORMS: "taskListMenuForms",
      PRODUCT_USAGE: "taskListMenuProductUsage",
      COMMENTS: "taskListMenuComments",
    },
  },

  CALENDAR: {
    ROOT: "taskCalendar",
    MENU: {
      ROOT: "taskCalendarMenu",
      DETAILS: "taskCalendarMenuDetails",
      HISTORY: "taskCalendarMenuHistory",
      TO_DO_LIST: "taskCalendarMenuToDoList",
      STORAGE: "taskCalendarMenuStorage",
      FORMS: "taskCalendarMenuForms",
      PRODUCT_USAGE: "taskCalendarMenuProductUsage",
      COMMENTS: "taskCalendarMenuComments",
    },
  },
};

const TASK_VIEW = {
  ROOT: "taskView",

  MENU: {
    HISTORY: "taskViewMenuHistory",
    STORAGE: "taskViewMenuStorage",
    FORMS: "taskViewMenuForms",
    PRODUCT_USAGE: "taskViewMenuProductUsage",
    TODO_LIST: "taskViewMenuToDoList",
    COMMENTS: "taskViewMenuComments",
  },
};

const TEMPLATE = {
  ROOT: "template",
  FORM_TEMPLATES: "formTemplates",
  PROJECT_TEMPLATES: "projectTemplates",

  FORM_TEMPLATE_BUILDER: "formTemplateBuilder",
  FORM_TEMPLATE_BUILDER_DETAILS: "formTemplateBuilderDetails",
  FORM_TEMPLATE_BUILDER_HISTORY: "formTemplateBuilderHistory",

  PROJECT_TEMPLATE_BUILDER: "projectTemplateBuilder",
  PROJECT_TEMPLATE_BUILDER_DETAILS: "projectTemplateBuilderDetails",
  PROJECT_TEMPLATE_BUILDER_HISTORY: "projectTemplateBuilderHistory",
};

const TIME_REGISTRATION = {
  ROOT: "timeRegistration",
  ALL_HOURS: "timeRegistrationAllHours",
};

export const ROUTE_NAMES = {
  AUTH,
  CMS,
  CONTACT,
  DASHBOARD,
  EMPLOYEE,
  EXTERNAL,
  FORM,
  FORM_VIEW,
  GET_STARTED,
  OFFICE_ONLINE,
  PROJECT,
  REFERRAL,
  SETTINGS,
  STORAGE,
  TASK,
  TASK_VIEW,
  TEMPLATE,
  TIME_REGISTRATION,
};
