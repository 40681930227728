<template>
  <div
    class="d-flex align-center outline-info rounded info-super-light--background"
    v-if="selectedCount > 0"
  >
    <div class="pl-3 text-no-wrap info--text font-weight-medium">
      {{ $t("common.numSelected", { n: selectedCount }) }}
    </div>
    <AppDefaultTooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" icon @click="$emit('click')" color="error">
          <v-icon>{{ $icons.SOLID.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </template>
      <span class="break-word">
        {{ $t("common.clearSelection") }}
      </span>
    </AppDefaultTooltip>
  </div>
</template>

<script>
export default {
  props: {
    selectedCount: Number,
  },
};
</script>
