import moment from "moment";

const weekdayValues = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

export const dateMixins = {
  data() {
    return {
      dateMixins_daysInMonthNumbers: [
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4", text: "4" },
        { value: "5", text: "5" },
        { value: "6", text: "6" },
        { value: "7", text: "7" },
        { value: "8", text: "8" },
        { value: "9", text: "9" },
        { value: "10", text: "10" },
        { value: "11", text: "11" },
        { value: "12", text: "12" },
        { value: "13", text: "13" },
        { value: "14", text: "14" },
        { value: "15", text: "15" },
        { value: "16", text: "16" },
        { value: "17", text: "17" },
        { value: "18", text: "18" },
        { value: "19", text: "19" },
        { value: "20", text: "20" },
        { value: "21", text: "21" },
        { value: "22", text: "22" },
        { value: "23", text: "23" },
        { value: "24", text: "24" },
        { value: "25", text: "25" },
        { value: "26", text: "26" },
        { value: "27", text: "27" },
        { value: "28", text: "28" },
        { value: "LAST", text: this.$t("repeatOption.last") },
      ],
      dateMixins_weekNumbers: [
        { value: "1", text: moment.localeData().ordinal(1) },
        { value: "2", text: moment.localeData().ordinal(2) },
        { value: "3", text: moment.localeData().ordinal(3) },
        { value: "4", text: moment.localeData().ordinal(4) },
        { value: "LAST", text: this.$t("repeatOption.last") },
      ],
      dateMixins_weekdaysAbbr: [
        { text: this.$t("common.daysShort.monday"), value: "MONDAY" },
        { text: this.$t("common.daysShort.tuesday"), value: "TUESDAY" },
        { text: this.$t("common.daysShort.wednesday"), value: "WEDNESDAY" },
        { text: this.$t("common.daysShort.thursday"), value: "THURSDAY" },
        { text: this.$t("common.daysShort.friday"), value: "FRIDAY" },
        { text: this.$t("common.daysShort.saturday"), value: "SATURDAY" },
        { text: this.$t("common.daysShort.sunday"), value: "SUNDAY" },
      ],
      dateMixins_localeAwareWeekdaysLong: moment.weekdays(true).map((weekday, index) => ({
        text: weekday, //true makes it locale aware, this messes up the order of the values as english starts with sunday
        // text: moment.dateMixins_weekdaysAbbr()[index + 1],
        value: weekdayValues[index],
      })),
      dateMixins_intervals: [
        {
          text: this.$t("common.frequencies.weekly"),
          value: "WEEKLY",
        },
        {
          text: this.$t("common.frequencies.monthly"),
          value: "MONTHLY",
        },
        {
          text: this.$t("common.frequencies.yearly"),
          value: "YEARLY",
        },
      ],
      dateMixins_weekdayValues: weekdayValues,
    };
  },
  computed: {
    dateMixins_firstDayOfWeek() {
      return 1;
      //TODO: implement when implementing locale
      return moment.localeData().firstDayOfWeek();
    },
  },
  methods: {
    dateMixins_localeAwareWeekdays(options) {
      // options: short, min
      let weekdays;
      if (options === "short") {
        weekdays = moment.weekdaysShort(true);
      } else if (options === "min") {
        weekdays = moment.weekdaysMin(true);
      } else if (options === "abbr") {
        weekdays = moment.weekdays(true).map((wd) => wd[0].toUpperCase());
      } else {
        weekdays = moment.weekdays(true);
      }
      //english order of weeksdays

      //reorder because expected weekday order starts with monday in half of the world and sunday in the other half
      // const reorderedWeekdayValues = [
      //   ...weekdayValues.slice(this.dateMixins_firstDayOfWeek),
      //   ...weekdayValues.slice(0, this.dateMixins_firstDayOfWeek),
      // ];

      const mappedWeekdays = weekdays.map((weekday, index) => ({
        text: weekday,
        // value: reorderedWeekdayValues[index],
        value: weekdayValues[index],
      }));

      return mappedWeekdays;
    },

    dateMixins_formatDateWithTranslationsLong(date) {
      const month = this.dateMixins_monthText(date);
      const dayNumberWithOrdinal = moment(date).format("Do");
      const day = moment(date).format("dddd");
      const locale = moment.locale();

      //MANUAL LOCALE FORMATTING (look up this comment when new locales are added)
      //https://momentjs.com/

      if (month === "Invalid date" || day === "Invalid date") {
        return false;
      }
      let text;
      switch (locale) {
        case "nb":
          text = `${day} ${dayNumberWithOrdinal} ${month}`;
          break;

        case "en":
          text = `${day}, ${month} ${dayNumberWithOrdinal}`;
          break;
      }

      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    dateMixins_monthText(date) {
      const month = moment(date).format("MMMM");
      return month;
    },
    dateMixins_yearText(date) {
      const year = moment(date).format("YYYY");
      return year;
    },
    dateMixins_incrementMonth(currentMonth) {
      const nextMonth = moment(currentMonth, "YYYY-MM").add(1, "month");
      return nextMonth.format("YYYY-MM");
    },
    dateMixins_decrementMonth(currentMonth) {
      const prevMonth = moment(currentMonth, "YYYY-MM").subtract(1, "month");
      return prevMonth.format("YYYY-MM");
    },
    dateMixins_addDaysToDate(date, days) {
      const math = moment(date, "YYYY-MM-DD").add(days, "day");
      return math;
    },
    dateMixins_subtractDaysFromDate(date, days) {
      const math = moment(date, "YYYY-MM-DD").subtract(days, "day");
      return math;
    },
  },
};
