<template>
  <div :class="`d-flex ${isLast ? '' : 'pb-3'} align-center`">
    <div class="d-flex align-center">
      <img class="pr-2" src="../../../assets/gripr_checkmark.svg" />
      <div class="font-weight-medium">{{ text }}</div>
    </div>
    <v-spacer />
    <AppStonlyGuideBtn
      :disabled="!isStonlyGuideBtnEnabled"
      :guideId="stonlyGuideId"
      :class="isStonlyGuideBtnEnabled ? '' : 'hide-stonly-guide-btn'"
    />
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    stonlyGuideId: String,
    isLast: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    isStonlyGuideBtnEnabled() {
      return !!this.stonlyGuideId;
    },
  },
};
</script>

<style>
.hide-stonly-guide-btn {
  opacity: 0;
}
</style>
