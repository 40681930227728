<template>
  <tr
    class="list_tr list_tr-height"
    :class="rowStateClasses"
    @contextmenu="dataTableItemMixins_onRightClick"
    @click="!projectId ? select(!isSelected) : undefined"
  >
    <td v-if="!projectId">
      <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)" />
    </td>
    <td>
      <div class="table-cell-content__breakable-long-text">
        <span class="d-inline-block">
          {{ contact.fullName }}
        </span>
      </div>
    </td>
    <td>
      <div class="table-cell-content__breakable-long-text">
        <span class="d-inline-block">
          {{ clientName }}
        </span>
      </div>
    </td>
    <td>
      <AppLabelBtn
        ref="jobTitleLabelBtn"
        @click="onJobTitleLabelClick()"
        :title="jobTitle"
        :clickable="canEditLabels && !projectId"
        :isUpdating="contact?.isUpdatingLabel"
      />
    </td>
    <td v-if="!!projectId">
      <AppLabelBtn
        ref="responsibilityLabelBtn"
        @click="onResponsibilityLabelClick()"
        :title="responsibility"
        :clickable="canEditLabels"
        :isUpdating="contact?.isUpdatingRelationLabel"
      />
    </td>
    <td>
      <AppPhoneLink :phone="contact.phone" />
    </td>

    <td>
      <AppLinkText :link="`mailto:${contact.email}`" underline-on-hover>
        {{ contact.email }}
      </AppLinkText>
    </td>
    <td>
      <div class="d-flex align-center">
        <v-checkbox
          :disabled="!!projectId"
          :inputValue="contact.isActive"
          @change="
            updateContact({ id: contact.id, body: { isActive: $event }, snack: true })
          "
        />
      </div>
    </td>
    <td class="text-end" v-if="!projectId">
      <v-btn icon @click="dataTableItemMixins_onEllipsisClick" ref="ellipsis">
        <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { dataTableItemMixins } from "@/helpers/mixins";

export default {
  mixins: [dataTableItemMixins],
  props: {
    contact: Object,
    jobTitleLabel: Object,
    responsibilityLabel: Object,
    isHighlighted: Boolean,
    canEditLabels: Boolean,
    isContextMenuActiveItem: Boolean,
    isSelected: Boolean,
    select: Function,
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    rowStateClasses() {
      return {
        "list_tr--active": this.isContextMenuActiveItem,
        "list_tr--selected": this.isSelected,
      };
    },
    clientName() {
      return this.contact?.client?.name;
    },
    jobTitle() {
      return this.jobTitleLabel?.name;
    },
    responsibility() {
      return this.responsibilityLabel?.name;
    },
  },
  methods: {
    openMenu(params) {
      this.$emit("menu:click", {
        contact: this.contact,
        ...params,
      });
    },
    onJobTitleLabelClick() {
      const from = this.$refs.jobTitleLabelBtn.$el;
      this.$emit("jobTitleLabel:click", { from, item: this.contact });
    },
    onResponsibilityLabelClick() {
      const from = this.$refs.responsibilityLabelBtn.$el;
      this.$emit("responsibilityLabel:click", { from, item: this.contact });
    },
    updateContact(e) {
      this.$emit("contact:update", e);
    },
  },
};
</script>
