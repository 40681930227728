<template>
  <div>
    <div class="d-flex pt-1">
      <div>{{ label }}:</div>
      <v-spacer />
      <div v-if="!isCurrency" class="font-weight-bold pl-2">{{ value ?? "-" }}</div>
      <AppCurrencyText v-else class="font-weight-bold" :value="value" />
    </div>
    <v-divider />
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: [String, Number],
    isCurrency: Boolean,
  },
};
</script>
