<template>
  <v-dialog v-model="localDialog" max-width="500" scrollable>
    <v-card class="add-products">
      <v-card-title class="headline font-weight-bold">
        {{
          $t(
            `settings.billing.subscription.dialogs.addProducts.${this.productCode}.title`,
          )
        }}
      </v-card-title>
      <v-card-text class="font-weight-medium primary-text--text pb-10">
        <div class="pb-10">
          {{
            $t(
              `settings.billing.subscription.dialogs.addProducts.${this.productCode}.subTitle`,
            )
          }}
        </div>
        <v-row style="height: 120px">
          <v-col>
            <div class="mr-4">
              <div class="mb-3 text-center">
                {{
                  $t(
                    `settings.billing.subscription.dialogs.addProducts.${productCode}.quantityLabel`,
                  )
                }}
              </div>
              <div class="d-flex">
                <v-btn
                  class="rounded border-a"
                  color="input-background--background"
                  icon
                  tile
                  depressed
                  v-ripple
                  :ripple="false"
                  @click="adjustQuantity(quantity - stepAmount)"
                >
                  <v-icon small> {{ $icons.SOLID.ACTION.MINUS }}</v-icon>
                </v-btn>
                <div class="mx-4">
                  <AppDefaultTextField
                    :value="quantity"
                    type="number"
                    class="text-centered-input"
                    readonly
                    hide-details
                  />
                </div>
                <v-btn
                  class="rounded border-a"
                  color="input-background--background"
                  icon
                  tile
                  depressed
                  v-ripple
                  :ripple="false"
                  @click="adjustQuantity(quantity + stepAmount)"
                >
                  <v-icon small>{{ $icons.SOLID.ACTION.PLUS }}</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-divider vertical class="my-2" />
          <v-col>
            <div class="text-center">
              <div class="mb-4 text-body-1">
                {{
                  $t("settings.billing.subscription.dialogs.addProducts.oneTimePayment")
                }}
              </div>

              <div class="mb-4 text-body-1 font-weight-bold">
                {{ calculatedPrice() }}
              </div>

              <i18n
                :path="`settings.billing.subscription.dialogs.addProducts.${productCode}.breakdown`"
                tag="div"
                class="text-caption font-weight-medium"
              >
                <template v-slot:quantity>
                  {{ quantity }}
                </template>
                <template v-slot:pricePerQuantity>
                  {{ pricePer() }}
                </template>
              </i18n>
              <span class="text-caption font-weight-medium">
                {{ $t("settings.billing.subscription.common.exVat") }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onConfirm"
          @cancel="localDialog = false"
          :confirmText="
            $t('settings.billing.subscription.dialogs.addProducts.confirmPurchase')
          "
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { billingMixins } from "@/helpers/mixins";
export default {
  mixins: [billingMixins],
  props: {
    dialog: Boolean,
    currency: String,
    pricePerQuantity: Number,
    productCode: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      quantity: 0,
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    stepAmount() {
      switch (this.productCode) {
        case this.$constant.PRODUCT_CODE.E_SIGN:
          return this.$constant.E_SIGN_QUANTITY_STEP_AMOUNT;
        default:
          return 1;
      }
    },
    minQuantity() {
      switch (this.productCode) {
        case this.$constant.PRODUCT_CODE.E_SIGN:
          return this.$constant.E_SIGN_MIN_QUANTITY;
        default:
          return 0;
      }
    },
    maxQuantity() {
      switch (this.productCode) {
        case this.$constant.PRODUCT_CODE.E_SIGN:
          return this.$constant.E_SIGN_MAX_QUANTITY;
        default:
          return 10000;
      }
    },
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.quantity = this.minQuantity;
        }
      },
    },
  },
  methods: {
    calculatedPrice() {
      const price = this.pricePerQuantity * this.quantity;
      return this.billingMixins_priceFormat(price, this.currency);
    },
    pricePer() {
      return this.billingMixins_priceFormat(this.pricePerQuantity, this.currency);
    },
    isQuantityInvalid(quantity) {
      return !quantity || isNaN(quantity);
    },
    isQuantityBelowMin(quantity) {
      return quantity < this.minQuantity;
    },
    isQuantityAboveMax(quantity) {
      return quantity > this.maxQuantity;
    },
    adjustQuantity(quantity) {
      quantity = parseInt(quantity);
      if (this.isQuantityInvalid(quantity) || this.isQuantityBelowMin(quantity)) {
        quantity = this.minQuantity;
      }
      if (this.isQuantityAboveMax(quantity)) {
        quantity = this.maxQuantity;
      }
      this.quantity = quantity;
    },
    onConfirm() {
      this.$emit("confirm", { quantity: this.quantity, productCode: this.productCode });
      this.localDialog = false;
    },
  },
};
</script>

<style lang="scss">
.add-products {
  .input-background--background {
    background-color: var(--v-input-background-base) !important;
  }
}
</style>
